import {CommonModule} from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";

import {HeaderIconComponent} from '../header-icon/header-icon.component';

@Component({
  selector: 'atl-ngb-modal-header',
  templateUrl: './ngb-modal-header.component.html',
  styleUrls: ['./ngb-modal-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, NgbTooltipModule, HeaderIconComponent],
})
export class NgbModalHeaderComponent {
  @Input() height = 56;
  @Input() title = '';
  @Input() arrowClose = false;
  @Input() isClient = false;
  @Output() closeModalEvent = new EventEmitter();



  public readonly closeIcon = '/assets/close-icon.svg';
  public readonly arrowCloseIcon = '/assets/unit_close.svg';
  public readonly tooltipDelay = 500;
}
