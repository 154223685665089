import { SafeHtml } from '@angular/platform-browser';
import { Expose, Type } from 'class-transformer';

import { FileModel, IFile } from './file.model';
import { IImgFile, ImageModel } from './image.model';

export interface IPractical {
  id: number;
  file_resources: IFile;
  images: IImgFile;
  name: string;
  description: string;
  created_at: string;
  show: boolean;
}

export class PracticalModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'created_at' })
  createdAt: string;
  @Expose({ name: 'name' })
  name: string;
  @Expose({ name: 'images' })
  @Type(() => ImageModel)
  images!: ImageModel[];
  @Expose({ name: 'file_resources' })
  @Type(() => FileModel)
  fileResources!: FileModel[];
  @Expose({ name: 'description' })
  description: string | SafeHtml | null;
  @Expose({ name: 'updated_at' })
  updatedAt!: string;
  @Expose({ name: 'author' })
  author!: { name: string } | null;
  @Expose({ name: 'preview_url' })
  previewUrl!: boolean | null;
  @Expose({ name: 'preview_in_progress' })
  previewInProgress!: boolean;

  show = false;

  constructor() {
    this.createdAt = '';
    this.id = 0;
    this.name = '';
    this.description = '';
  }
}
