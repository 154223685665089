export interface IVendor {
  id: number;
  title: string;
}

export interface IVendorCreate {
  vendorName: string;
}

export class VendorModel {
  id = 0;
  title = '';
}
