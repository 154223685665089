export interface IBreadCrumbs {
  id: number | null;
  title: string;
  unitOrLayout?: boolean;
}

export interface IDocumentsBreadcrumbs {
  breadCrumbs: IBreadCrumbs[];
  currentFolder: number;
}

export enum EDocBreadCrumbs {
  DocBreadCrumbs = 'documentBreadCrumbs',
}
