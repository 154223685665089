import { EventEmitter, Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root',
})
export class ModalFacadeService {
  constructor(private modalService: NgbModal) {}

  public openModal(content: any, options?: NgbModalOptions): NgbModalRef {
    return this.modalService.open(content, options);
  }

  public closeModal(): void {
    this.modalService.dismissAll();
  }

  public closeModalOutside(path: HTMLElement[], exceptions: string[]): void {
    if (!path) {
      return;
    }
    const index = path.findIndex((html) => exceptions.indexOf(html.localName) > -1);
    if (index === -1) {
      this.closeModal();
    }
  }

  get openedModals(): EventEmitter<NgbModalRef[]> {
    return this.modalService.activeInstances;
  }
}
