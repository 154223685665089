import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  private readonly currentStepSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public readonly currentStep$: Observable<number> = this.currentStepSubject.asObservable();

  private readonly currentStepProjectSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public readonly currentStepProject$: Observable<number> = this.currentStepProjectSubject.asObservable();

  private readonly completeOnboardingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly completeOnboarding$: Observable<boolean> = this.completeOnboardingSubject.asObservable();

  private readonly completeProjectOnboardingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly completeProjectOnboarding$: Observable<boolean> =
    this.completeProjectOnboardingSubject.asObservable();

  private readonly currentMobileStepSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public readonly currentMobileStep$: Observable<number> = this.currentMobileStepSubject.asObservable();

  public setCurrentStep(step: number): void {
    this.currentStepSubject.next(step);
  }

  public setCompleteOnboarding(): void {
    this.completeOnboardingSubject.next(true);
  }

  public resetCompleteOnboarding(): void {
    this.completeOnboardingSubject.next(false);
  }

  public setCurrentProjectStep(step: number): void {
    this.currentStepProjectSubject.next(step);
  }

  public setCompleteProjectOnboarding(): void {
    this.completeProjectOnboardingSubject.next(true);
  }

  public updateMobileStep(step: number): void {
    this.currentMobileStepSubject.next(step);
  }
}
