import { Pipe, PipeTransform } from '@angular/core';
import { IMark } from '@atlas-workspace/shared/models';

@Pipe({
  name: 'floorMark',
})
export class FloorMarkPipe implements PipeTransform {
  transform(value: IMark[], floorId: number): IMark | undefined {
    const res = value?.find((v) => v.floorId === floorId);
    return res ? { ...res } : undefined;
  }
}
