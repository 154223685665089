import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SignicatService {
  constructor(@Inject('ENVIRONMENT') private environment: IEnvironment, private http: HttpClient) {}

  public getLinkDocument(projectId: number, unitId: number): Observable<{ url: string }> {
    return this.http.get<{ url: string }>(
      this.environment.apiBaseUrl + `api/v1/client/projects/${projectId}/units/${unitId}/wishlist/upload_for_signing`
    );
  }

  public checkStatusSigningDocument(unitId: number, taskId: string): Observable<any> {
    return this.http.patch(
      this.environment.apiBaseUrl + `api/v1/client/units/${unitId}/signing_tasks/${taskId}/check_status`,
      {}
    );
  }

  changeRequestOfferSignIn(unitId: number, reqId: number, offerId: number): Observable<string> {
    return this.http
      .get<{ url: string }>(
        `${this.environment.apiBaseUrl}api/v1/client/units/${unitId}/change_requests/change_requests/${reqId}/offers/${offerId}/upload_for_signing`
      )
      .pipe(map((res) => res.url));
  }
}
