import { Expose, plainToClass, Transform, Type } from 'class-transformer';
import { keys } from 'lodash';

import { FileModel } from './file.model';
import { ImageModel } from './image.model';
import { IProject, ProjectModel } from './project.model';
import { ITablePagination } from './table-pagination.interface';
import { UnitAttributesModel } from './unit-attributes.model';
import { VendorModel } from './vendor.model';

export type UnitSaleStatus = 'sold' | 'unsold' | 'reserved';

export enum EUnitStatus {
  Sold = 'sold',
  Unsold = 'unsold',
  Reserved = 'reserved',
}

export enum EUnitOrGroup {
  Unit = 'Unit',
  Group = 'LayoutType',
}

export enum EUnitFloorPlansType {
  General = 'Shared.Entity.General_floor_plan',
  Electro = 'Shared.Entity.Electro_floor_plan',
}

export enum EUnitFloorPlansTypeKey {
  Default = 'plan',
  Electro = 'electroPlan',
}

export enum EUnitFloorPlansTypeFormKey {
  Default = 'plan',
  Electro = 'electro_plan',
}

export enum ECommonAreaPropertyType {
  CommonArea = 'common_area'
}

export const floorPlanMenu = [
  {
    title: EUnitFloorPlansType.General,
    name: '',
    key: EUnitFloorPlansType.General,
  },
  {
    title: EUnitFloorPlansType.Electro,
    name: '',
    key: EUnitFloorPlansType.Electro,
  },
];

export interface IUnit {
  id: number;
  title: string;
  address: string;
  unit_type: string;
  budgeted_price: string;
  sale_status: string;
  project_id: number;
  identifier: number;
}

export interface IUnitUser {
  identifier: string;
  name: string;
  unit_id: number;
  user_id: number;
  iconColor: string;
  iconBorderColor: string;
}

export interface IUnitStatusUpdate {
  unit: Partial<{
    layout_type_id: number | null;
    actual_price: string;
    sale_status: string;
    invite_is_sent?: boolean;
    allow_bank_id?: boolean;
    user: {
      name: string;
      email: string;
      phone: string;
      provider?: string;
    };
    apartment_number?: string;
    section_number?: string;
    unit_number?: string;
    street_name?: string;
    street_number?: string;
    parking_place_number?: string;
    storage_unit_number?: string;
    storm?: string;
    units_layout_option_categories_attributes?: {
      id?: number;
      layout_option_category_id?: number;
      start_date?: string;
      end_date?: string;
      _destroy?: boolean;
    }[];
  }>;
}

export enum EUnitType {
  House = 'house',
  Apartment = 'apartment',
  Cabin = 'cabin',
  Townhouse = 'townhouse',
  DetachedHouse = 'detached_house',
  Other = 'other',
  Duplex = 'duplex',
  SingleFamily = 'single-family_attached_home',
  TwoFloors = 'two_floors',
  Microhous = 'microhous',
  MicrohouseOnWheels = 'microhouse_on_wheels',
  CommonArea = 'common_area',
}

export enum ECustomType {
  TextField = 'text_field',
  SizeField = 'size_field',
  PriceField = 'price_field',
  DateField = 'date_field',
}

export interface IUnitMainBuyer {
  id: number;
  name: string;
  email: string;
  originalName?: string;
  disabled?: boolean;
  phone?: string;
  role?: string;
}

export class UnitPhaseListModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'start_date' })
  startDate!: string;
  @Expose({ name: 'end_date' })
  endDate!: string;
}

export class UnitLayoutOptionCategory {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'start_date' }) startDate!: string;
  @Expose({ name: 'end_date' }) endDate!: string;
  @Expose({ name: 'layout_option_category_id' }) layoutOptionCategoryId!: number;
}

export class UnitModel {
  @Expose({ name: 'budgeted_price' })
  budgetPrice: string;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'layout_type_status' })
  layoutTypeStatus!: 'unpublished' | 'published';
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'sale_status' })
  saleStatus: string;
  @Expose({ name: 'identifier' })
  identifier!: string;
  @Expose({ name: 'address' })
  address!: string;

  @Expose({ name: 'apartment_number' })
  apartmentNumber!: string | null;
  @Expose({ name: 'section_number' })
  sectionNumber!: string | null;
  @Expose({ name: 'unit_number' })
  unitNumber!: string | null;
  @Expose({ name: 'street_name' })
  streetName!: string | null;
  @Expose({ name: 'street_number' })
  streetNumber!: string | null;
  @Expose({ name: 'street_letter' })
  streetLetter!: string | null;
  @Expose({ name: 'parking_place_number' })
  parkingPlaceNumber!: string | null;
  @Expose({ name: 'storage_unit_number' })
  storageUnitNumber!: string | null;
  @Expose({ name: 'storm' })
  storm!: string | null;

  @Expose({ name: 'property_type' })
  propertyType: string;
  @Expose({ name: 'project' })
  project: ProjectModel | undefined | IProject;
  @Expose({ name: 'move_in_date' })
  moveInDate: string | null;
  @Expose({ name: 'progress' })
  progress!: string;
  @Expose({ name: 'sold_date' })
  soldDate!: string | Date | null;
  @Expose({ name: 'selected' })
  selected: boolean | null;
  @Expose({ name: 'main_buyer' })
  mainBuyer!: IUnitMainBuyer;
  @Expose({ name: 'primary_owners' })
  primaryOwners!: IUnitMainBuyer[];
  @Expose({ name: 'actual_price' })
  actualPrice!: string;
  @Expose({ name: 'layout_type_id' })
  layoutTypeId!: number | null;
  @Expose({ name: 'layout_type_ids' })
  layoutTypeIds!: number[] | null;
  @Expose({ name: 'layout_types' })
  @Type(() => LayoutTypesModel)
  layoutTypes!: LayoutTypesModel[] | null;
  @Expose({ name: 'currency' })
  currency!: string | null;
  @Expose({ name: 'price_profit' })
  priceProfit!: number;
  @Expose({ name: 'effective_price' })
  effectivePrice!: number;
  @Expose({ name: 'has_own_floor_plans' })
  hasOwnFloorPlans?: boolean;
  @Expose({ name: 'investor' })
  investor!: boolean;

  @Type(() => VendorModel)
  @Expose({ name: 'company' })
  company!: VendorModel;

  @Expose({ name: 'unit_floors' })
  @Type(() => UnitFloorModel)
  unitFloors!: UnitFloorModel[];

  @Expose({ name: 'mixin_floors' })
  @Type(() => UnitFloorModel)
  mixinFloors!: UnitFloorModel[];

  @Expose({ name: 'floors' })
  @Type(() => UnitFloorModel)
  floors!: UnitFloorModel[];

  @Expose({ name: 'main_primary_owner' })
  mainPrimaryOwner!: IUnitMainBuyer;

  @Expose({ name: 'unit_floor_show_on_client_portal' })
  unitFloorShowOnClientPortal = false;

  @Expose({ name: 'layout_type' })
  layoutType?: {
    id: number;
    name: string;
  };

  @Expose({ name: 'units_layout_option_categories' })
  @Type(() => UnitLayoutOptionCategory)
  unitsLayoutOptionCategories!: UnitLayoutOptionCategory[];


  @Type(() => UnitCustomField)
  @Expose({ name: 'unit_custom_fields' })
  unitCustomFields!: UnitCustomField[];
  @Expose({ name: 'allow_bank_id' })
  allowBankId!: boolean;
  @Expose({ name: 'rooms_count' })
  roomsCount!: number;
  @Expose({ name: 'bra' })
  bra!: string;
  @Expose({ name: 'show_option_popup' })
  showOptionPopup!: boolean;
  @Expose({ name: 'phases' })
  @Type(() => UnitPhaseListModel)
  phases!: UnitPhaseListModel[];

  groupSelect = 'unit';

  constructor() {
    this.selected = null;
    this.moveInDate = null;
    this.budgetPrice = '';
    this.id = 0;
    this.saleStatus = '';
    this.propertyType = '';
    this.project = undefined;
  }
}

export class UnitCustomField {
  @Expose({ name: 'field_type' })
  fieldType!: string;
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'value' })
  value!: string;
  @Expose({ name: 'for_all_project_units' })
  forAll!: string;
}

export enum EUnitSaleStatus {
  Sold = 'sold',
  Unsold = 'unsold',
  Reserved = 'reserved',
}

export interface IUnitData {
  id: number;
  identifier: string;
  selected: boolean;
}

export interface IConfirmPriceData {
  internalPrice: string | number;
  price: string | number;
  unitIds: number[];
  layoutTypeIds: number[];
  updateForAllUnits: boolean;
}

export class LayoutTypesModel {
  id!: number;
  name!: string;
  @Expose({ name: 'layout_option_id' })
  layoutOptionId!: number;
  selected?: boolean;
  units!: IUnitData[];
}

export class UnitUserModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'user_id' })
  userId!: number;
  @Expose({ name: 'identifier' })
  identifier!: string;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'sale_status' })
  saleStatus!: string;

  @Expose({ name: 'co_owners' })
  coOwners!: IUnitMainBuyer[];
  @Expose({ name: 'main_buyer' })
  mainBuyer!: IUnitMainBuyer | null;
  @Expose({ name: 'primary_owners' })
  primaryOwners!: IUnitMainBuyer[];
  @Expose({ name: 'main_primary_owner' })
  mainPrimaryOwner!: IUnitMainBuyer | null;

  @Expose({ name: 'actual_price' })
  actualPrice!: string | null;
  @Expose({ name: 'bra' })
  bra!: string;
  @Expose({ name: 'rooms_count' })
  roomsCount!: number;
  @Expose({ name: 'layout_type' })
  layoutType?: {
    id: number;
    name: string;
  };
  @Expose({ name: 'property_type' })
  propertyType: string;
  @Expose({ name: 'effective_price' })
  effectivePrice!: number | null;
  @Expose({ name: 'budgeted_price' })
  budgetedPrice!: number;

  @Expose({ name: 'layout_types' })
  layoutTypes!: {
    id: number;
    name: string;
    label?: string;
  }[];

  @Expose({ name: 'floors' })
  @Type(() => FloorModel)
  floors?: FloorModel[];
  @Expose({ name: 'rooms' })
  rooms?: RoomModel[];
  @Expose({ name: 'has_own_floor_plans' })
  hasOwnFloorPlans?: boolean;

  @Expose({ name: 'unit_floors' })
  @Type(() => UnitFloorModel)
  unitFloors?: UnitFloorModel[];

  @Expose({ name: 'mixin_floors' })
  @Type(() => Object)
  @Transform(
    ({ value }) => {
      if (value && Array.isArray(value)) {
        value.forEach((floor, index) => {
          if (floor.floor_type === 'UnitFloor') {
            value[index] = plainToClass(UnitFloorModel, floor);
          } else {
            value[index] = plainToClass(FloorModel, floor);
          }
        });
      }

      return value;
    },
    { toClassOnly: true },
  )
  mixinFloors?: (UnitFloorModel | FloorModel)[];

  selected = false;
  iconColor!: string;
  iconBorderColor!: string;

  budgetPrice!: string;
  groupSelect = 'unit';
  disabled?: boolean;

  constructor() {
    this.propertyType = '';
  }
}

export interface IUnitType {
  area_living: string;
  area_total: string;
  id: number;
  units_count: number;
  selected: boolean;
}

export interface IUpdateGroupEvent {
  removed: boolean;
  updated: boolean;
}

export interface IUnitShort {
  id: number;
  identifier: string;
}

class UnitTypeUnitModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'identifier' }) identifier!: string;
  @Expose({ name: 'sale_status' }) saleStatus!: string;
}
export class UnitTypeModel {
  @Expose({ name: 'area_living' })
  areaLiving!: string;
  @Expose({ name: 'area_total' })
  areaTotal!: string;
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'units_count' })
  unitsCount!: number;
  @Expose({ name: 'selected' })
  selected = false;
  @Expose({ name: 'property_type' })
  propertyType!: string;
  @Expose({ name: 'status' })
  status!: string;
  @Expose({ name: 'unit_progress' })
  unitProgress!: string;
  @Expose({ name: 'units' })
  @Type(() => UnitTypeUnitModel)
  units!: UnitTypeUnitModel[];
  @Expose({ name: 'units_layout_options' })
  @Type(() => UnitsLayoutOptionsModel)
  unitsLayoutOptions!: UnitsLayoutOptionsModel[];
  @Expose({ name: 'layout_option_id' })
  layoutOptionId!: number | null;
  @Expose({ name: 'purchases_amount' })
  purchasesAmount!: number;

  groupSelect = 'unit';
  isUnsoldUnitPresent = false;
  label?: string;
}

export class UnitsLayoutOptionsModel {
  id!: number;
  name!: string;
}

export enum EOptionStatus {
  Published = 'published',
  Unpublished = 'unpublished',
}

class UnitKindModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
}

interface IUnitKind {
  id: number;
  name: string;
}

export interface IUnitTypeUnits {
  identifier: string;
  id: number;
  property_type: string;
  unit_kind: IUnitKind;
}

export class UnitTypeUnitsModel {
  @Expose({ name: 'identifier' })
  identifier!: string;
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'property_type' })
  propertyType!: string;
  @Expose({ name: 'unit_kind' })
  @Type(() => UnitKindModel)
  unitKind!: null | UnitKindModel;
  @Expose({ name: 'selected' })
  selected = false;

  connected = false;

  initCheckConnected = false;
}

export interface IRoomType {
  id: number;
  title: string;
}

export interface IUnitRoom {
  type: string;
  name: string;
  size: number;
}

export class FloorModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'floor_number' }) floorNumber!: number;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'plan' })
  @Type((x) => {
    return x?.object?.plan?.type === 'file' ? FileModel : ImageModel;
  })
  plan!: FileModel | ImageModel | null;

  @Expose({ name: 'electro_plan' })
  @Type((e) => {
    return e?.object?.electro_plan?.type === 'file' ? FileModel : ImageModel;
  })
  electroPlan?: FileModel | ImageModel | null;

  @Type(() => RoomModel) @Expose({ name: 'rooms' }) rooms!: RoomModel[];
  @Expose({ name: 'is_default' })
  isDefault!: boolean;
  @Expose({ name: 'floor_type' })
  floorType!: string;
  @Expose({ name: 'floor_kind' })
  floorKind!: EUnitFloorPlansTypeKey;

  @Expose({ name: 'point_x' })
  pointX?: number;
  @Expose({ name: 'point_y' })
  pointY?: number;
  @Expose({ name: 'type' })
  type?: string;

  fileFullImageUrl?: string;
  version?: number;
}

export class UnitFloorModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'floor_number' })
  floorNumber!: number;
  @Expose({ name: 'plan' })
  @Type((x) => {
    return x?.object?.plan?.type === 'file' ? FileModel : ImageModel;
  })
  plan!: FileModel | ImageModel | null;

  @Expose({ name: 'electro_plan' })
  @Type((e) => {
    return e?.object?.electro_plan?.type === 'file' ? FileModel : ImageModel;
  })
  electroPlan?: FileModel | ImageModel | null;

  @Expose({ name: 'is_default' })
  isDefault!: boolean;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'floor_type' })
  floorType!: string;

  fileFullImageUrl?: string;
  version?: number;
}

export class UnitTypeDetailsModel {
  @Expose({ name: 'area_living' })
  areaLiving!: string;
  @Expose({ name: 'area_total' })
  areaTotal!: string;
  @Expose({ name: 'ceiling_height' })
  ceilingHeight!: string;
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'save_as_template' })
  isTemplate!: boolean;

  @Expose({ name: 'show_rooms' })
  showRooms!: boolean;

  markup!: number;

  @Expose({ name: 'property_type' })
  propertyType!: string;

  @Expose({ name: 'floors' })
  @Type(() => FloorModel)
  floors!: FloorModel[];

  @Expose({ name: 'units' })
  units!: {
    id: number;
    name: string;
  }[];

  @Expose({ name: 'layout_option_id' })
  layoutOptionId!: number | null;
  @Expose({ name: 'custom_fields' })
  @Type(() => UnitAttributesModel)
  customFields!: UnitAttributesModel[];
}

export class RoomModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'size' })
  size!: string | null;
  @Expose({ name: 'walls_area_total' })
  wallsAreaTotal!: string | null;
  @Expose({ name: 'room_type' })
  roomType!: {
    id: number;
    title: string;
  } | null;

  floorName!: string;
  roomId?: number;
  description!: string;
  custom = false;
  checked = true;
  position = 0;

  constructor() {
    this.roomId = this.id;
  }
}

class OptionWishlist {
  @Expose({ name: 'currency' })
  currency!: string;
  @Expose({ name: 'due_date' })
  dueDate!: string;
  @Expose({ name: 'price' })
  price!: number;
  @Expose({ name: 'status' })
  status!: string;
}

class PrimaryOwner {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
}

class LayoutTypeModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'name' }) name!: number;
  @Expose({ name: 'waited_price_offers_count' }) waitedPriceOffersCount!: number;
}

export class OptionUnitModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier!: string;
  @Expose({ name: 'layout_type' })
  @Type(() => LayoutTypeModel)
  layoutType!: LayoutTypeModel;
  @Expose({ name: 'wishlist' })
  @Type(() => OptionWishlist)
  wishlist!: null | OptionWishlist;
  @Expose({ name: 'primary_owner' })
  @Type(() => PrimaryOwner)
  primaryOwner!: null | PrimaryOwner;
  @Expose({ name: 'primary_owners' })
  @Type(() => PrimaryOwner)
  primaryOwners!: PrimaryOwner[];
  @Expose({ name: 'co_owners' })
  coOwners!: IUnitMainBuyer[];
  @Expose({ name: 'main_primary_owner' })
  mainPrimaryOwner!: IUnitMainBuyer | null;

  iconColor!: string;
  iconBorderColor!: string;
  @Expose({ name: 'waited_price_offers_count' }) waitedPriceOffersCount!: number;
}

class OptionUnitData {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'identifier' }) identifier!: string;
  @Expose({ name: 'waited_price_offers_count' }) waitedPriceOffersCount!: number;
}

export class UnitPhaseModel {
  @Expose({ name: 'column_name' }) columnName!: string;
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'connected_units_count' }) count!: number;
}

export enum EUnitsPhasesStatusesKey {
  AutoCompleted = 'auto_completed',
  Completed = 'completed',
  InProgress = 'in_progress',
  NotCompleted = 'not_completed',
  NotOpened = 'not_opened',
  NotSelected = 'not_selected',
  NotSigned = 'not_signed',
  Signed = 'signed',
}

export type UnitsPhasesStatuses = {
  [key in EUnitsPhasesStatusesKey]: string;
};

export class OptionWishlistPhaseModel {
  @Expose({ name: 'wishlists' })
  @Type(() => OptionWishlistModel)
  wishlists!: OptionWishlistModel[];

  @Expose({ name: 'phases' })
  @Type(() => UnitPhaseModel)
  phases!: UnitPhaseModel[];

  @Expose({ name: 'phases_statuses' })
  phasesStatuses!: UnitsPhasesStatuses;
}

export class OptionWishlistModel {
  @Expose({ name: 'unit' })
  @Type(() => OptionUnitData)
  unit!: OptionUnitData;
  @Expose({ name: 'co_owners' })
  coOwners!: IUnitMainBuyer[];
  @Expose({ name: 'layout_type' })
  @Type(() => LayoutTypeModel)
  layoutType!: LayoutTypeModel;

  @Expose({ name: 'primary_owner' })
  @Type(() => PrimaryOwner)
  primaryOwner!: null | PrimaryOwner;

  @Expose({ name: 'primary_owners' })
  @Type(() => PrimaryOwner)
  primaryOwners!: PrimaryOwner[];

  @Expose({ name: 'main_primary_owner' })
  mainPrimaryOwner!: IUnitMainBuyer | null;

  @Expose({ name: 'status' }) status!: string;
  @Expose({ name: 'updated_at' }) updatedAt!: string;
  @Expose({ name: 'due_date' }) due_date!: string | null;
  @Expose({ name: 'price' }) price!: string;
  @Expose({ name: 'currency' }) currency!: string;
  @Expose({ name: 'completed_phase_count' }) completedPhaseCount!: number;
  @Expose({ name: 'connected_phase_count' }) connectedPhaseCount!: number;
  @Expose({ name: 'allow_bank_id' }) allowBankId!: boolean;
  @Expose({ name: 'available_categories_count' }) availableCategoriesCount!: number;
  @Expose({ name: 'connected_categories_count' }) connectedCategoriesCount!: number;
  @Expose({ name: 'wi_count' }) wiCount!: number;
  @Expose({ name: 'completed_wi_count' }) completedWiCount!: number;
  @Expose({ name: 'signed_wi_count' }) signedWiCount!: number;

  iconColor!: string;
  iconBorderColor!: string;
}

export class UnitTypeTemplateListModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'area_living' })
  areaLiving!: string;
  @Expose({ name: 'area_total' })
  areaTotal!: string;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'property_type' })
  propertyType!: string;
  @Expose({ name: 'project_name' })
  projectName!: string;
  @Expose({ name: 'room_templates_names' })
  roomTemplatesNames!: string[];
  @Expose({ name: 'floor_templates' })
  @Type(() => FloorTemplatesModel)
  floorTemplates!: FloorTemplatesModel[];
}

export class UnitTypeTemplateModel extends UnitTypeTemplateListModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'ceiling_height' })
  ceilingHeight!: string;
  @Expose({ name: 'floors_amount' })
  floorsAmount!: number;
}

export class UrlModel {
  @Expose({ name: 'url' })
  url!: string;
  @Expose({ name: 'download_url' })
  downloadUrl!: string;
}

export class FilenamePlanTemplateModel extends UrlModel {
  @Expose({ name: 'w260' })
  @Type(() => UrlModel)
  w260!: UrlModel;
}

export class PlanTemplateModel {
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'type' })
  type!: string;
  @Expose({ name: 'filename' })
  @Type(() => FilenamePlanTemplateModel)
  fileName!: FilenamePlanTemplateModel;
  @Expose({ name: 'preview' })
  @Type(() => UrlModel)
  preview!: UrlModel;
  @Expose({ name: 'full_image' })
  @Type(() => UrlModel)
  fullImage!: UrlModel;
  @Expose({ name: 'w2532' })
  @Type(() => UrlModel)
  w2532!: UrlModel;
}

export class FloorTemplatesModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'floor_number' })
  floorNumber!: number;
  @Expose({ name: 'room_templates' })
  @Type(() => RoomTemplatesModel)
  roomTemplates!: RoomTemplatesModel[];
  @Expose({ name: 'plan' })
  @Type(() => PlanTemplateModel)
  plan!: PlanTemplateModel;
}

export class RoomTemplatesModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'size' })
  size!: string;
  @Expose({ name: 'walls_area_total' })
  wallsAreaTotal!: number;
  @Expose({ name: 'room_type' })
  roomType!: IRoomTypeTemplate;
}

export interface IRoomTypeTemplate {
  id: number;
  title: string;
}

export class UnitsHelper {
  public static readonly unitTypes = [
    { value: EUnitType.House, type: 'Shared.Entity.House' },
    { value: EUnitType.Apartment, type: 'Shared.Entity.Apartment' },
    { value: EUnitType.Cabin, type: 'Shared.Entity.Cabin' },
    { value: EUnitType.CommonArea, type: 'Shared.Entity.Control_unit' },
    { value: EUnitType.Townhouse, type: 'Shared.Entity.Townhouse' },
    { value: EUnitType.DetachedHouse, type: 'Shared.Entity.Detached_house' },
    { value: EUnitType.Duplex, type: 'Shared.Entity.Duplex' },
    { value: EUnitType.SingleFamily, type: 'Shared.Entity.Single-family' },
    { value: EUnitType.TwoFloors, type: 'Shared.Entity.Two-floors' },
    { value: EUnitType.Microhous, type: 'Shared.Entity.Microhous' },
    { value: EUnitType.MicrohouseOnWheels, type: 'Shared.Entity.Microhouse_on_wheels' },
    { value: EUnitType.Other, type: 'Shared.Entity.Other' },
  ];

  public static readonly unitStatuses = [
    { value: EUnitStatus.Unsold, type: 'Entity.Unsold' },
    { value: EUnitStatus.Reserved, type: 'Entity.Reserved' },
    { value: EUnitStatus.Sold, type: 'Entity.Sold' },
  ];

  public static unitTypesHash: { [key: string]: string } = {
    [EUnitType.House]: 'Shared.Entity.House',
    [EUnitType.Apartment]: 'Shared.Entity.Apartment',
    [EUnitType.Cabin]: 'Shared.Entity.Cabin',
    [EUnitType.CommonArea]: 'Shared.Entity.Control_unit',
    [EUnitType.Townhouse]: 'Shared.Entity.Townhouse',
    [EUnitType.DetachedHouse]: 'Shared.Entity.Detached_house',
    [EUnitType.Duplex]: 'Shared.Entity.Duplex',
    [EUnitType.SingleFamily]: 'Shared.Entity.Single-family',
    [EUnitType.TwoFloors]: 'Shared.Entity.Two-floors',
    [EUnitType.Microhous]: 'Shared.Entity.Microhous',
    [EUnitType.MicrohouseOnWheels]: 'Shared.Entity.Microhouse_on_wheels',
    [EUnitType.Other]: 'Shared.Entity.Other',
  };
}

export enum EUnitTab {
  Units = 'Title.Connect_units',
  Settings = 'Shared.Button.Details',
}

export enum EUnitCreationStep {
  Unit = 'unit_type',
  Property = 'property_type',
}

export class UnitEventLogModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'event_type' })
  eventType!: string;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'text' })
  text!: string;
  @Expose({ name: 'changed_data' })
  changedData!: { [key: string]: string };
  @Expose({ name: 'who_doing' })
  whoDoing!: IWhoDoing;
}

export interface IWhoDoing {
  type: string;
  id: number;
  name: string;
}

export interface IVisibleForList {
  originalId: number;
  originalName: string;
  id: string;
  name: string;
  group: boolean;
  groupSelect?: string;
  owner?: string;
}

export interface IViewedList {
  identifier: string;
  viewed: boolean;
  people: IUnitMainBuyer | null;
  title: string;
}

export interface IErrorsImport {
  [key: number]: string;
}

export class IUnitImportStatsKey {
  created?: number;
  updated?: number;
  skiped?: number;
  errors?: number;
}

export class UnitImportStatsModel {
  @Expose({ name: 'units' })
  units!: IUnitImportStatsKey;
  @Expose({ name: 'units_users' })
  unitsUsers!: IUnitImportStatsKey;
  @Expose({ name: 'users' })
  users!: IUnitImportStatsKey;
}

export class UnitImportsResponseModel {
  @Expose({ name: 'errors' })
  errors!: Record<number, string[]>;
  @Expose({ name: 'stats' })
  @Type(() => UnitImportStatsModel)
  stats!: UnitImportStatsModel;
}

export class DualViewURLModel {
  @Expose({ name: 'token' })
  token!: string;
  @Expose({ name: 'firm_email_templates_logic' })
  firmEmailTemplatesLogic!: string;
  @Expose({ name: 'dual_view_url' })
  dualViewUrl!: string;
}

export interface IUnitAnalyticsFilterItemModel {
  name: string;
  value: string;
  selected: boolean;
}

export enum EUnitEventLogType {
  Unit = 'unit',
  Members = 'members',
  Options = 'options',
  Documents = 'documents',
  Tasks = 'tasks',
  Surveys = 'surveys',
  Meetings = 'meetings',
  Reclamations = 'reclamations',
  ChangeRequests = 'change_requests',
}

export interface IGetUnitsLiteProps {
  projectId: string;
  sort?: string;
  search?: string;
  paginate?: ITablePagination;
  updateId?: number;
  practicalId?: number;
  filter?: string[];
  layoutTypeIds?: number[];
}

export enum EPackQRCodeStatus {
  Done = 'done_perf',
  Init = 'init_perf',
  Performing = 'performing',
  Error = 'error_perf',
}

export class PackQRCodeModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'unit_ids' }) unitIds!: number[];
  @Expose({ name: 'perform_status' }) status!: EPackQRCodeStatus;
  @Expose({ name: 'filename' }) filename!: null | string;
  @Expose({ name: 'download_url' }) downloadUrl!: null | string;
}

export interface IUnitsLayoutOptionCategoriesAttributes {
  units_layout_option_categories_attributes: [
    {
      id?: number;
      layout_option_category_id?: number;
      start_date?: string;
      end_date?: string;
      _destroy?: boolean
    }
  ]
}
