import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'atl-plan-warning',
  templateUrl: './plan-warning.component.html',
  styleUrls: ['./plan-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class PlanWarningComponent {
  @Input() title!: string;
  @Input() message!: string;
  @Input() imageName!: string;
  @Input() customBackground = '';
  @Input() class = 'card';
}
