import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  ETablePagination,
  FavouriteProject,
  FullInfoClientModel,
  GetProjectsProps,
  IClientProjectHeader,
  IClientUnitHeader,
  IFullInfoClient,
  IHeaderDropdown,
  IProjectOverviewDetailsModel,
  IProjectRoleUpdate,
  IProjectUpdate,
  ProjectBuyersData,
  ProjectBuyersModel,
  ProjectHealthDataModel,
  ProjectModel,
  UnitModel,
} from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { PaginationUtil } from '../helpers/pagination.util';
import { getEntityListByChunks, GetEntityListByChunksPropsCurrentObservable } from '../helpers/request-chunks.util';
import { DataTableHelperService } from './data-table-helper/data-table-helper.service';

@Injectable()
export class ProjectService {
  /**
   * @note Only used to avoid multiple `getProjectById` requests and their dependencies
   */
  private readonly _project$ = new BehaviorSubject<ProjectModel | null>(null);
  project$ = new ReplaySubject<IClientProjectHeader>(1);
  unit$ = new ReplaySubject<IClientUnitHeader>(1);

  adminProject$ = new ReplaySubject<ProjectModel>(1);

  unitData$ = new ReplaySubject<IHeaderDropdown | null>(1);

  openCreationModal = new ReplaySubject<boolean>(1);

  private _updateProject$ = new BehaviorSubject<ProjectModel | null>(null);

  public _updateProjectById$ = new Subject<boolean>();

  public _projectsList$ = new BehaviorSubject<ProjectModel[]>([]);

  private allProjectsLoading$: Partial<GetEntityListByChunksPropsCurrentObservable<ProjectModel>> = { observable: undefined };
  private _isLoadedAllProjects = false;

  public removeUnitFromList$ = new Subject<{ projectId: number; unitId: number }>();

  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private env: IEnvironment,
    private readonly tableService: DataTableHelperService
  ) {}

  public get isLoadedAllProjects(): boolean {
    return this._isLoadedAllProjects;
  }

  public resetProjects(): void {
    this._isLoadedAllProjects = false;
    this.setProjectsList([]);
  }

  get projectsList$(): Observable<ProjectModel[]> {
    return this._projectsList$.asObservable();
  }

  setProjectsList(projects: ProjectModel[]): void {
    this._projectsList$.next(projects);
  }

  get updateProject$(): Observable<ProjectModel | null> {
    return this._updateProject$.asObservable();
  }

  setUpdateProject(project: ProjectModel): void {
    this._updateProject$.next(project);
  }

  get updateProjectById$(): Observable<boolean> {
    return this._updateProjectById$.asObservable();
  }

  setUpdateProjectById(val: boolean): void {
    this._updateProjectById$.next(val);
  }

  /**
   * @Cypress
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Projects/paths/~1api~1v1~1projects/get
   */
  getProjects({
    search,
    projectId,
    isOwnProjects,
    isFavProjects,
    sortBy,
    pagination,
    noEmit,
  }: GetProjectsProps): Observable<ProjectModel[]> {
    if (pagination) {
      pagination.currentPage = pagination.currentPage || 1;
      pagination.pageItems = pagination.pageItems || 50;
    }
    let params: HttpParams = new HttpParams();
    if (isOwnProjects !== undefined) {
      params = params.set('my_projects', `${isOwnProjects}`);
    }
    if (isFavProjects !== undefined) {
      params = params.set('favorite_projects', `${isFavProjects}`);
    }
    if (sortBy !== undefined) {
      params = params.set('sort_by', sortBy);
    }
    if (search) {
      params = params.set('search', search);
    }
    if (pagination?.currentPage) {
      params = params.set('page', pagination.currentPage);
    }
    if (pagination?.pageItems) {
      params = params.set('per_page', pagination.pageItems);
    }
    return this.http
      .get<{ data: { projects: ProjectModel[] } }>(`${this.env.apiBaseUrl}api/v1/projects`, { params: params, observe: 'response' })
      .pipe(
        map(({ body, headers }) => {
          if (pagination) {
            pagination.currentPage = PaginationUtil.convertPaginationType(headers, ETablePagination.CurrentPage);
            pagination.pageItems = PaginationUtil.convertPaginationType(headers, ETablePagination.PageItems);
            pagination.totalPages = PaginationUtil.convertPaginationType(headers, ETablePagination.TotalPages);
            pagination.totalCount = PaginationUtil.convertPaginationType(headers, ETablePagination.TotalCount);
          }
          return body!;
        }),
        map((request) => {
          return plainToClass(ProjectModel, request.data.projects);
        }),
        tap((projects: ProjectModel[]) => {
          if (!noEmit) {
            this.setProjectsList(projects);
          }
          if (projectId) {
            const project = projects.find((x) => x.projectId === +projectId);
            this.project$.next(project || projects[0]);
          }
        }),
      );
  }

  public getProjectsChunks(ctx?: unknown, isOwnProjects?: boolean): Observable<ProjectModel[]> {
    return getEntityListByChunks<ProjectModel>({
      http: this.http,
      url: `${this.env.apiBaseUrl}api/v1/projects`,
      componentInstance: ctx,
      httpParams: {
        ...(isOwnProjects !== undefined && {
          'my_projects': Boolean(isOwnProjects),
        }),
      },
      currentObservable$: this.allProjectsLoading$,
      plainToClassCb: (body) => plainToClass(ProjectModel, <ProjectModel[]>body?.data?.projects),
      onFinalizeCb: () => {
        this.allProjectsLoading$.observable = undefined;
      },
      onLoadedCb: (projects: ProjectModel[]) => {
        this.setProjectsList(projects);
        this._isLoadedAllProjects = true;
      },
    }).observable;
  }

  getUnitsByProject(projectId: number, unitId?: number | null): Observable<UnitModel[]> {
    return this.http
      .get<{ data: { units: UnitModel[] } }>(`${this.env.apiBaseUrl}api/v1/projects/${projectId}/units`)
      .pipe(
        map((request: { data: { units: UnitModel[] } }) => {
          return plainToClass(UnitModel, request.data.units);
        }),
        tap((units: UnitModel[]) => {
          if (unitId) {
            const unit = units.find((x) => x.id === unitId) ?? units[0];
            this.unit$.next(unit);
          }
        }),
      );
  }

  getSelectedProject(): Observable<IClientProjectHeader> {
    return this.project$;
  }

  getSelectedUnit(): Observable<IClientUnitHeader> {
    return this.unit$;
  }

  setSelectedProject(selectedProject: IClientProjectHeader): void {
    this.project$.next(selectedProject);
  }

  setSelectedUnit(selectedUnit: IClientUnitHeader): void {
    this.unit$.next(selectedUnit);
  }

  setSelectedUnitData(unit: IHeaderDropdown | null): void {
    this.unitData$.next(unit);
  }

  deleteProject(projectId: number): Observable<string> {
    return this.http
      .delete(`${this.env.apiBaseUrl}api/v1/projects/${projectId}`)
      .pipe(map((res: any) => <string>res?.message));
  }

  /**
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Projects/paths/~1api~1v1~1projects~1%7Bid%7D/get
   */
  getProjectById(projectId: number, forcedUpdate = false): Observable<ProjectModel> {
    const currentProject = this._project$.getValue();
    if (!forcedUpdate && currentProject !== null && currentProject.projectId === projectId) {
      return of(currentProject);
    }
    return this.http.get<{ data: ProjectModel }>(`${this.env.apiBaseUrl}api/v1/projects/${projectId}`).pipe(
      map((request: { data: ProjectModel }) => plainToClass(ProjectModel, request.data)),
      tap((project: ProjectModel) => {
        this.adminProject$.next(project);
        this._project$.next(project);
      }),
    );
  }

  /**
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Projects/paths/~1api~1v1~1projects~1%7Bid%7D/put
   */
  updateProject(projectID: number, updatedProject: IProjectUpdate | FormData): Observable<ProjectModel> {
    return this.http
      .put(`${this.env.apiBaseUrl}api/v1/projects/${projectID}`, updatedProject, {
        headers: {
          timeout: '20000',
        },
      })
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(ProjectModel, data)),
        tap((project: ProjectModel) => this.adminProject$.next(project)),
      );
  }

  createProject(body: FormData): Observable<ProjectModel> {
    return this.http.post(this.env.apiBaseUrl + 'api/v1/projects', body).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(ProjectModel, data)),
    );
  }

  transferProjectsOwnership(value: IProjectRoleUpdate, firmId: number): Observable<string> {
    const params: HttpParams = new HttpParams().set('firm_id', firmId.toString());
    return this.http.post(`${this.env.apiBaseUrl}api/v1/projects/transfer_ownership`, value, { params: params }).pipe(
      map((res: any) => res?.message),
      map((data) => <string>data),
    );
  }

  setAsFavoriteProject(firmId: string, projectId: number): Observable<unknown> {
    return this.http.post(`${this.env.apiBaseUrl}/api/v1/firms/${firmId}/favorite_projects`, {
      project_id: projectId,
    });
  }

  unsetAsFavoriteProject(firmId: string, projectId: number): Observable<unknown> {
    return this.http.delete(`${this.env.apiBaseUrl}api/v1/firms/${firmId}/favorite_projects/${projectId}`, undefined);
  }

  getFavoriteProjects(firmId: string): Observable<FavouriteProject[]> {
    return this.http.get(`${this.env.apiBaseUrl}api/v1/firms/${firmId}/favorite_projects`).pipe(
      map((res: any) => res.data.projects),
      map((info) => plainToClass(FavouriteProject, info) as unknown as FavouriteProject[]),
    );
  }

  getFullInfo(): Observable<FullInfoClientModel[]> {
    return this.http
      .get<{ data: { projects: IFullInfoClient[] } }>(`${this.env.apiBaseUrl}api/v1/client/units/short_index`)
      .pipe(
        map((res: any) => res.data.projects),
        map((info: []) => plainToClass(FullInfoClientModel, info)),
      );
  }

  getProjectOverviewDetails(projectId: number): Observable<IProjectOverviewDetailsModel> {
    return this.http
      .get<{ data: ProjectModel }>(`${this.env.apiBaseUrl}api/v1/projects/${projectId}/details_for_overview_page`)
      .pipe(
        map((res) => res.data),
        map((data) => plainToClass(IProjectOverviewDetailsModel, data)),
      );
  }

  getUnitById(projectId: string, unitId: string, includeFloors = false): Observable<UnitModel> {
    let params = new HttpParams();
    if (includeFloors) {
      params = params.set('includes[]', 'floors');
    }
    return this.http
      .get(`${this.env.apiBaseUrl}api/v1/client/projects/${projectId}/units/${unitId}`, {
        params,
      })
      .pipe(
        map((res: any) => res.data),
        map((value) => plainToClass(UnitModel, value)),
      );
  }

 /**
 * @see  https://api.journeyapp.dev.scrij.com/api-docs#tag/Buyers/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1buyers~1search/get
 */
  getProjectBuyers(projectId: string, ignoreUnitUsersId: number | null = null, nextPage?: number, search = ''): Observable<ProjectBuyersData> {
    let params = new HttpParams();
    if (ignoreUnitUsersId) {
      params = params.set('ignore_unit_users_id', ignoreUnitUsersId.toString());
    }
    if (nextPage) {
      params = params.set('page', nextPage.toString());
    }

    if (search) {
      params = params.set('search', search);
    }
    return this.http
      .get<any>(this.env.apiBaseUrl + `api/v1/projects/${projectId}/buyers/search`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((result) => {
          const pagination = this.tableService.getPagination(result);
          const buyers = plainToClass(ProjectBuyersModel, result.body.data.buyers as ProjectBuyersModel[]);
          return { pagination, buyers };
        })
      );
  }


  getProjectHealth(projectId: number): Observable<ProjectHealthDataModel> {
    return this.http
      .get<{
        data: ProjectHealthDataModel;
      }>(`${this.env.apiBaseUrl}api/v1/projects/${projectId}/project_health`)
      .pipe(
        map((res) => res.data),
        map((value) => plainToClass(ProjectHealthDataModel, value)),
      );
  }

  setIdForRemove(value: { projectId: number; unitId: number }): void {
    this.removeUnitFromList$.next(value);
  }

  public hideLayoutOption(projectId: number, unitId: number): Observable<any> {
    return this.http.post(`${this.env.apiBaseUrl}api/v1/client/projects/${projectId}/units/${unitId}/hide_option_popup`, {});
  }
}
