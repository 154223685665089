import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { EPlatform, FdvModel } from '@atlas-workspace/shared/models';
import { WEB_PLATFORM_TYPE } from '@atlas-workspace/shared/translate';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'atl-fdv-breadcrumbs',
  templateUrl: './fdv-breadcrumbs.component.html',
  styleUrls: ['./fdv-breadcrumbs.component.scss'],
  standalone: true,
  imports: [CommonModule, NgbDropdownModule],
})
export class FdvBreadcrumbsComponent {
  @Input() selectFolder!: FdvModel;
  @Output() readonly selectParent = new EventEmitter<number>();

  public readonly isClient!: boolean;

  constructor(@Inject(WEB_PLATFORM_TYPE) platform: EPlatform) {
    this.isClient = platform === EPlatform.WEB_CLIENT;
  }

  select(id: number): void {
    this.selectParent.emit(id);
  }
}
