<div class="confirm d-flex">
  <div class="d-flex confirm__body">
    <img [src]="'assets/' + icon" alt="" />
    <p class="fz-20 confirm__title" [class.confirm__title--center]="isCenteredTitle">{{ title }}</p>
    <p class="confirm__description lh-24" [innerHtml]="description"></p>
  </div>
  <div class="d-flex confirm__footer">
    <button (click)="closeModal()" class="global-button global-button--gray global-button--fill">
      {{ 'Button.Cancel' | translate }}
    </button>
    <button [class]="button.class" (click)="confirm()" type="submit" class="global-button">
      {{ button.text | translate }}
    </button>
  </div>
</div>
