import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeHtmlTag',
})
export class RemoveHtmlTagPipe implements PipeTransform {
  transform(value: string): string | null {
    if (!value) {
      return null;
    }
    return value
      .replace(/<[^>]*>/gm, '')
      .replace(/\r?\n/g, ' ')
      .replace(/&amp;/g, '&')
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>');
  }
}
