<!--
  ******************************************************
  @Cypress
  - interaction with buttons
  - interaction with dropdown items
  ******************************************************
-->
<div class="delete-modal__wrapper" atlClickOutside (clickOutside)="closeAllPopups()">
  <div class="d-flex align-items-center">
    <img
      (click)="cancel()"
      class="cursor-pointer delete-modal__close"
      src="/assets/close-icon-v3.svg"
      alt="Close"
      data-cy="delete-modal-close"
    />
    <ng-content></ng-content>
  </div>

  <div *ngIf="showInvite" class="ml-12">
    <button (click)="sendInvite()" class="button-black">{{ 'Button.Send_invite' | translate }}</button>
  </div>

  <div *ngIf="showDownloadQR" class="ml-12">
    <button (click)="downloadQR()" class="button-black">{{ 'Button.Download_Options_QR_codes' | translate }}</button>
  </div>

  <div
    *ngIf="showRename"
    class="ml-12 cursor-pointer delete-modal__icon"
    [ngbTooltip]="'Shared.Entity.Rename' | translate"
    [tooltipClass]="'mb-4'"
    (click)="rename()"
    container="body"
    triggers="hover touchstart"
  >
    <img src="/assets/pencil.svg" width="16" height="16" alt="rename" />
  </div>

  <div
    *ngIf="showMoveIn"
    [class.ml-12]="!showRename"
    class="cursor-pointer delete-modal__icon"
    [ngbTooltip]="'Shared.Entity.Move_to_folder' | translate"
    [tooltipClass]="'mb-4'"
    data-cy="delete-modal-move"
    (click)="moveIn()"
    container="body"
    triggers="hover touchstart"
  >
    <img src="/assets/folder-move-icon.svg" width="16" height="16" alt="move folder" />
  </div>

  <div
    *ngIf="showDownload"
    [class.ml-12]="!showMoveIn"
    class="cursor-pointer delete-modal__icon"
    [ngbTooltip]="'Shared.Entity.Download' | translate"
    [tooltipClass]="'mb-4'"
    [disableTooltip]="disabledDownloadTooltips"
    [class.active]="disabledDownloadTooltips"
    data-cy="delete-modal-download"
    container="body"
    triggers="hover touchstart"
    (click)="download(true)"
  >
    <img src="/assets/download_white.svg" width="16" height="16" alt="download" />
    <ng-content select="downloadContent"></ng-content>
  </div>

  <div
    *ngIf="showTransfer"
    [class.ml-12]="!showDownload"
    (click)="transfer(true)"
    class="cursor-pointer delete-modal__icon position-relative"
    [ngbTooltip]="'Reclamation.Reassign_reclamation' | translate"
    [tooltipClass]="'mb-4'"
    [disableTooltip]="disabledTransferTooltips"
    [class.active]="disabledTransferTooltips"
    container="body"
    triggers="hover touchstart"
    data-cy="delete-modal-reassign"
  >
    <img src="/assets/transfer-white.svg" width="16" height="16" alt="delete" />
    <ng-content select="transferContent"></ng-content>
  </div>

  <div
    *ngIf="showMarkRead"
    [class.ml-12]="!showTransfer"
    class="cursor-pointer delete-modal__icon"
    [ngbTooltip]="'Reclamation.Mark_as_read' | translate"
    [tooltipClass]="'mb-4'"
    (click)="read()"
    container="body"
    triggers="hover touchstart"
  >
    <img src="/assets/mark_read_white.svg" width="16" height="16" alt="read" />
  </div>

  <div *ngIf="publishUnpublish" class="ml-12">
    <button (click)="publishUnpublishHandler()" class="button-black fz-14 lh-24 fw-500">
      <ng-content select="publishUnpublish"></ng-content>
      <span class="lowercase"> {{ 'Title.Option_stores' | translate }}</span>
    </button>
  </div>

  <div *ngIf="showSelectAll" class="ml-12">
    <button (click)="selectAll()" class="button-black fz-14 lh-24 fw-500">
      <span> {{ 'Select all' | translate }}</span>
    </button>
  </div>

  <div
    *ngIf="showDelete"
    (click)="confirm()"
    data-cy="delete-modal-icon"
    class="ml-12 cursor-pointer delete-modal__icon delete-modal__delete"
    [ngbTooltip]="deleteTooltip | translate"
    [tooltipClass]="'mb-4'"
    [disableTooltip]="!deleteTooltip || disabledTooltips"
    container="body"
    triggers="hover touchstart"
  >
    <atl-custom-icon [icon]="deleteIcon"></atl-custom-icon>
  </div>

  <div *ngIf="showMoreDropdown" class="ml-12">
    <atl-more-dropdown [header]="false" [footer]="false" placement="top">
      <ng-container ngProjectAs="[item]">
        <ng-content select="moreDropdown"></ng-content>
      </ng-container>
    </atl-more-dropdown>
  </div>
</div>
