import { Exclude, Expose, Type } from 'class-transformer';
import {AccessModel, EAccessTag} from "./project.model";
import {EProjectRoles} from "./user-roles.model";

export enum EActiveMember {
  Active = 'Entity.Active',
  Invited = 'Entity.Invited',
}

export interface IUserType {
  id: number;
  title: string;
}

interface IAdminFirms {
  id: number;
  form_id: number;
  role: string;
}

export class AdminProfileProject {
  @Expose({name: 'id'})
  id!: number;
  @Expose({name: 'name'})
  name!: string;
  @Expose({ name: 'acc_tags' })
  accTags!: EAccessTag[];
  @Expose({ name: 'role_v2' })
  roleV2!: EProjectRoles;
}

export interface IAdminProfileTransfer {
  members: {
    id: number;
    project_id: number;
  }[];
}

interface IAdminProjects {
  id: number;
  project_id: number;
  role: string;
}

export interface IAdmin {
  id: number;
  name: string;
  email: string;
  created_at: string;
  updated_at: string;

  admins_firms: IAdminFirms[];
  admins_projects: IAdminProjects[];
}

export class AdminProjects {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'project_id' })
  projectId!: number;
  @Expose({ name: 'role' })
  role!: string;
  @Expose({ name: 'name' })
  name!: string;
}

export class AdminFirmsModel {
  @Expose({ name: 'firm_id' })
  firmId!: number;
  @Expose({ name: 'role' })
  role!: string;
}

export class AdminModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'role' })
  role!: string;
  @Expose({ name: 'registered' })
  registered!: boolean;
  @Expose({ name: 'email' })
  email!: string;
  @Expose({ name: 'phone' })
  phone!: string;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'updated_at' })
  updatedAt!: string;
  @Expose({ name: 'admins_firms' })
  @Type(() => AdminFirmsModel)
  adminFirms!: AdminFirmsModel[];
  @Expose({ name: 'admins_projects' })
  @Type(() => AdminProjects)
  adminProjects!: AdminProjects[];
  @Expose({ name: 'pubsub_token' })
  pubsubToken!: string;

  color!: string;
  borderColor!: string;
  selected?: boolean;
}

export class AdminProfileModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'global_onboarding_completed' }) globalOnboardingCompleted!: boolean;
  @Expose({ name: 'project_onboarding_completed' }) projectOnboardingCompleted!: boolean;

  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'email' })
  email!: string;
  @Expose({ name: 'role' })
  role!: string;
  @Expose({ name: 'hidden_password' })
  hiddenPassword!: string;
  @Expose({ name: 'projects' })
  @Type(() => AdminProfileProject)
  projects: AdminProfileProject[] = [];
  @Expose({ name: 'pubsub_token' })
  @Exclude({ toPlainOnly: true })
  pubsubToken!: string;
}

export class AdminProjectModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'email' })
  email!: string;
  @Expose({ name: 'effective_role_name' })
  effectiveRoleName!: string;
  @Expose({ name: 'registered' })
  registered!: boolean;
  @Expose({ name: 'acc_tags' })
  accTags!: string[];

  selected?: boolean;
  all!: boolean;
  own!: boolean;
  hasCategories!: boolean;
  hasUsers!: boolean;
  permissionDescription!: string;
  hasUnits!: boolean;
  hasLayoutTypes!: boolean;
  groupSelect = 'admin';

  constructor() {
    this.all = false;
    this.own = false;
    this.hasCategories = false;
    this.hasUsers = false;
    this.hasUnits = false;
    this.hasLayoutTypes = false;
  }
}
