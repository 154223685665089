import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import { ProjectSettingsModel } from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import { Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ModuleAccessService {
  public accesses: ProjectSettingsModel | undefined;

  public accessHandler$ = new ReplaySubject<ProjectSettingsModel>(1);

  constructor(private http: HttpClient, @Inject('ENVIRONMENT') private environment: IEnvironment) {}

  public getModuleAccess(projectId: string | number): Observable<ProjectSettingsModel> {
    return this.http.get(`${this.environment.apiBaseUrl}api/v1/client/projects/${projectId}/project_settings`).pipe(
      map((res: any) => res.data),
      map((data) => (data ? plainToClass(ProjectSettingsModel, data) : new ProjectSettingsModel()))
    );
  }

  public setAccesses(_accesses: ProjectSettingsModel): void {
    this.accesses = _accesses;
    this.accessHandler$.next(this.accesses);
  }
}
