<div class="reset">
  <h3 class="fz-20 reset__title">{{ titleText | translate }}</h3>

  <div class="reset__desc mb-16">{{descriptionText | translate }}
    <span class="email">{{email}}</span></div>
  <div class="reset__desc mb-32">
    {{subDescriptionText | translate}}
    <a [href]="contactLink" target=”_blank”><span class="link">{{linkText | translate}}</span></a>
  </div>
  <button (click)="closeModal()" class="newest-button reset__back-btn">{{ buttonText | translate }}</button>
</div>
