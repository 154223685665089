<div class="plan-mark__wrapper" *ngIf="!displayFloors; else multipleFloorsBlock">
  <div class="plan-mark__title">{{ 'Shared.Entity.Floor_plan' | translate }}</div>
  <ng-container *ngIf="floorPlan?.length && mark?.length && previewPlan; else setPlanMark">
    <div class="plan-mark__plan" *ngIf="!displayFloors; else multipleFloorsBlock">
      <atl-image-mark
        (click)="selectFloorPlanModal()"
        #imageMark
        [mark]="mark[0]"
        [imageUrl]="previewPlan"
        [multiplePins]="multiplePins"
        class="v-scrollbar h-scrollbar"
      ></atl-image-mark>
    </div>
  </ng-container>
</div>

<ng-template #setPlanMark>
  <ng-container *ngIf="floorPlan?.length && previewPlan; else noPlan">
    <p *ngIf="!disabled" class="plan-mark__description mb-16" [class.client-desc]="client">
      {{ 'Shared.Mark.Message.Mark_floor_plan' | translate }}
    </p>
    <div class="plan-mark__plan">
      <atl-image-mark
        class="v-scrollbar h-scrollbar"
        (click)="selectFloorPlanModal()"
        [imageUrl]="previewPlan"
      ></atl-image-mark>
    </div>
  </ng-container>
</ng-template>

<ng-template #noPlan>
  <div class="plan-mark__warn">
    <p *ngIf="client || !hasUnitAccess || isContractor; else adminText">
      {{ 'Shared.Mark.Message.Floor_plan_empty' | translate }}
    </p>
  </div>
</ng-template>

<ng-template #multipleFloorsBlock>
  <ng-container *ngIf="floorPlan?.length > 1">
    <atl-simple-tabs
      [withId]="true"
      [activeId]="activeId"
      [tabs]="navList"
      [routeNavigation]="false"
      (selectingTab)="selectTab($event)"
    ></atl-simple-tabs>
  </ng-container>
  <div class="plan-mark__plan" [class.cursor-default]="!floorPlan?.length">
    <ng-container [ngSwitch]="activeId" *ngIf="!loading">
      <ng-container *ngIf="floorPlan?.length; else noFloorPlan">
        <ng-container *ngFor="let plan of floorPlan; index as i">
          <ng-container *ngSwitchCase="plan.id">
            <ng-container *ngIf="plan.plan; else noFloorPlan">
              <div class="plan-mark__plan-wrapper" (click)="selectFloorPlanModal(i)">
                <atl-image-mark
                  [mark]="mark | floorMark: plan.id"
                  [imageUrl]="plan.plan?.fullImage.url"
                  [floorId]="plan.id"
                  [floorNumber]="floorNumber"
                  [floorType]="floorType"
                  [multiplePins]="multiplePins"
                  class="v-scrollbar h-scrollbar"
                ></atl-image-mark>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #noFloorPlan>
  <div class="d-flex flex-column align-items-center justify-content-center empty-plan text-align-center">
    <h4 class="fz-20 fw-500 lh-24">{{ 'Shared.Entity.Floor_plan' | translate }}</h4>
    <p class="fz-14 lh-24 mt-16" [class.text-black-810]="!client" *ngIf="scope !== scopeTypes.Units; else adminText">
      {{ 'Shared.Mark.Message.No_floor_plan' | translate }}
      <span>{{ 'Shared.Mark.Message.Unit_details_page' | translate }}</span>
    </p>
  </div>
</ng-template>

<ng-template #adminText>
  <p class="fz-14 lh-24 text-black-810 admin-empty-floor-wrapper">
    {{ 'Shared.Mark.Message.No_floor_plan' | translate }}
    <span
      [ngbTooltip]="messageTooltip | translate"
      [disableTooltip]="scope === scopeTypes.Units"
      placement="auto"
      triggers="hover touchstart"
      (click)="unitRedirect()"
      class="text-blue-980 cursor-pointer"
      >{{ (noFloorPlanBtnText ? noFloorPlanBtnText : 'Shared.Mark.Message.Unit_details_page') | translate }}</span
    >
  </p>
</ng-template>
