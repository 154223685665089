<atl-ngb-modal-header [title]="title" [isClient]="true" (closeModalEvent)="closeModal()"></atl-ngb-modal-header>
<div class="message-popup">
  <div class="message-popup__description">
    <p>{{textStart | translate}}</p>
    <ul>
      <li *ngFor="let item of list" [innerHTML]="item"></li>
    </ul>
    <p class="mt-16">{{textEnd | translate}}</p>
  </div>
</div>
<atl-ngb-modal-footer>
  <div class="w-100 d-flex align-items-center justify-content-end">
    <button (click)="closeModal()" class="newest-button button-gray mr-16">
      {{ closeLabel | translate }}
    </button>
    <button (click)="closeModal(true)" class="newest-button">
      {{ confirmLabel | translate }}
    </button>
  </div>
</atl-ngb-modal-footer>
