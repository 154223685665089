export interface ITablePagination {
  currentPage: number;
  pageItems: number;
  totalCount: number;
  totalPages: number;
}

export enum ETablePagination {
  CurrentPage = 'current-page',
  PageItems = 'page-items',
  TotalCount = 'total-count',
  TotalPages = 'total-pages',
}
