import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { ClickOutsideDirective } from "@atlas-workspace/shared/directives";
import { ImageModel, IMessagesFile, ThreadAttachmentModel } from '@atlas-workspace/shared/models';
import { FileSizePipeModule, IconByTypePipeModule } from "@atlas-workspace/shared/pipes";
import { FileLoadService } from '@atlas-workspace/shared/service';
import { NgbTooltipModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";

import newestDownloadIconAttachment from '!!raw-loader?!@atlas-workspace/shared/assets/lib/download_simple.svg';
import dotsIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/dropdown-dot-2.svg';
import newestTrashIconAttachment from '!!raw-loader?!@atlas-workspace/shared/assets/lib/trash-icon-sm.svg';

import { CustomIconComponent } from '../custom-icon/custom-icon.component';
import { DownloadCardComponent } from '../download-card/download-card.component';

@Component({
  selector: 'atl-newest-document-card',
  templateUrl: './newest-document-card.component.html',
  styleUrls: ['./newest-document-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, NgbTooltipModule, TranslateModule, CustomIconComponent, ClickOutsideDirective, IconByTypePipeModule, FileSizePipeModule]
})
export class NewestDocumentCardComponent extends DownloadCardComponent implements AfterViewInit {
  @Input() isImage = false;
  @Input() image!: ImageModel | IMessagesFile | ThreadAttachmentModel;
  @Input() hasRemove = true;
  @Input() hasDownload = true;
  @Input() sharedText = '';
  @Input() useDropdownOptions = false;
  @Input() closestDropdownOptionsSelector?: string;

  @Output() readonly removeFileHandler = new EventEmitter();
  @Output() readonly optionsOpened = new EventEmitter<[boolean, number]>();

  public isOptionsOpened = false;
  public isOptionsAboveBtn = false;

  public readonly downloadIcon = newestDownloadIconAttachment;
  public readonly trashIcon = newestTrashIconAttachment;
  public readonly dotsIcon = dotsIcon;
  public readonly tooltipDelay = 500;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private fileLoadService: FileLoadService,
    private elementRef: ElementRef<HTMLDivElement>
  ) {
    super();
  }

  onRemoveFile(): void {
    this.removeFileHandler.emit();
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }

  public setOptionsVisibility(visible?: boolean): void {
    this.isOptionsOpened = typeof visible === 'boolean' ? visible : !this.isOptionsOpened;
    if (this.isOptionsOpened) {
      this.calcPosition();
    } else {
      this.isOptionsAboveBtn = false;
    }
    this.optionsOpened.emit([this.isOptionsOpened, this.image.id]);
  }

  public copyImage(): void {
    if (this.image instanceof ThreadAttachmentModel) {
      this.setOptionsVisibility(false);
      this.fileLoadService.copyFileFromModel({
        url: this.image.fileName.downloadUrl,
        name: this.image.name,
      });
    }
  }

  private calcPosition(): void {
    if (!this.closestDropdownOptionsSelector) {
      return;
    }
    const optionsEl = <HTMLElement>this.elementRef.nativeElement.querySelector('.newest-document-card__options');
    const relativeWrEl = <HTMLElement>this.elementRef.nativeElement.closest(this.closestDropdownOptionsSelector);
    if (relativeWrEl && optionsEl) {
      const relativeWrElRect = relativeWrEl.getBoundingClientRect();
      const optionsElRect = optionsEl.getBoundingClientRect();
      this.isOptionsAboveBtn = relativeWrElRect.bottom <= optionsElRect.bottom + 90;
    }
  }
}
