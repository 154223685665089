import {Injectable} from "@angular/core";
import {BehaviorSubject, Observable} from "rxjs";
import {share} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class LockFieldService {

  private locked$ = new BehaviorSubject<boolean>(false);

  get locked(): Observable<boolean> {
    return this.locked$.pipe(share());
  }

  setLocked(value: boolean): void {
    this.locked$.next(value);
  }
}
