import { Component, Input, OnInit } from '@angular/core';
import { ELang, ELocalStorageKeys } from '@atlas-workspace/shared/models';
import { LocalStorageService, OnboardingService } from '@atlas-workspace/shared/service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'atl-client-onboarding-modal',
  templateUrl: './client-onboarding-modal.component.html',
  styleUrls: ['./client-onboarding-modal.component.scss'],
})
export class ClientOnboardingModalComponent implements OnInit {
  @Input() public step = 0;
  @Input() public steps = 0;
  @Input() public icon!: string;
  @Input() public title!: string;
  @Input() public description!: string;
  @Input() public isMobile!: boolean;
  @Input() public localStorageKey!: string;

  public isSwedishLang = false;

  constructor(
    protected readonly ngbActiveModal: NgbActiveModal,
    protected readonly localStorageService: LocalStorageService,
    protected readonly onboardingService: OnboardingService,
  ) {}

  ngOnInit(): void {
    const lang = this.localStorageService.get(ELocalStorageKeys.Language);
    this.isSwedishLang = lang === ELang.Swedish;
  }

  public nextStep(): void {
    if (this.step === 0) {
      this.ngbActiveModal.close();
    }
    this.step++;
    this.saveStepToStorage();
    this.onboardingService.setCurrentStep(this.step);
  }

  public prevStep(): void {
    this.step--;
    this.saveStepToStorage();
    this.onboardingService.setCurrentStep(this.step);
  }

  public skipAllSteps(): void {
    this.ngbActiveModal.close();
    this.onboardingService.setCompleteOnboarding();
    this.localStorageService.remove(this.localStorageKey);
  }

  private saveStepToStorage(): void {
    this.localStorageService.set(this.localStorageKey, this.step);
  }
}
