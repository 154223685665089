import { ImageModel, PreviewModel } from '@atlas-workspace/shared/models';
import { Expose, Transform, Type } from 'class-transformer';

export class SelectedPackage {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'description' })
  description!: string;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'design_line' })
  isDesignLine!: boolean;
  @Expose({ name: 'images' })
  @Type(() => ImageModel)
  images!: ImageModel[];
  @Expose()
  @Transform(({ obj }) => {
    const previewUrl = obj.images[0]?.filename?.w260?.url || 'assets/no_images_placeholder.svg';
    obj.noPreview = !obj.images[0]?.filename?.w260?.url;

    return previewUrl;
  }, { toClassOnly: true })
  preview!: string;

  @Expose()
  noPreview!: boolean;
}
