<div (clickOutside)="close()" atlClickOutside class="global-label">
  <span *ngIf="inputTitle" class="label"> {{ inputTitle | translate }} </span>
  <div class="dropdown" #relativeRef>
    <button
      (click)="toggle()"
      *ngIf="!anchoredButton; else anchoredButtonRef"
      [class.opened]="isOpened"
      [disabled]="disabled"
      class="dropdown__main-container"
      data-cy="dropdown-toogle"
      type="button"
    >
      <ng-template #placeholderRef
        ><span class="dropdown__placeholder">{{ placeholder || label | translate }}</span></ng-template
      >
      <span *ngIf="inputValue && selectedItem; else placeholderRef" class="dropdown__value">{{
        selectedItem[bindLabel]
      }}</span>
      <img
        *ngIf="clearable && inputValue && selectedItem && !disabled"
        (click)="clearHandler($event)"
        alt="clear_icon"
        class="dropdown__icon-clear"
        src="assets/close-icon.svg"
      />
      <img [class.dropdown__rotate-icon]="isOpened" alt="" class="dropdown__icon" src="assets/arrow-down-sm.svg" />
    </button>

    <div
      [hidden]="!isOpened"
      class="dropdown__popup-container"
      data-cy="dropdown-list-wr"
      atlVerticalAlign
      [enableVerticalAlign]="enableVerticalAlign"
      [marginTop]="dropdownMargin"
      [positionFixed]="dropdownPosition === 'fixed'"
      [relativeElement]="relativeRef"
      [topOffset]="topOffset"
      [leftOffset]="leftOffset"
    >
      <div class="gap-wrapper pad-1">
        <div class="d-flex flex-column flex-gap-1">
          <div *ngIf="autocomplete" class="gap-wrapper position-relative">
            <input
              #searchInput
              (input)="autocompleteChanged(searchInput.value)"
              class="dropdown__popup-search-field"
              type="text"
              [placeholder]="placeholderInputSearch | translate"
              atlReplaceInput
              atlPreventDoubleSpace
              [maxLength]="50"
            />
            <img alt="" class="dropdown__popup-search-field__icon" src="assets/input_search_icon.svg" />
            <img *ngIf="isMac" alt="" class="dropdown__popup-search-field__key" src="assets/mac_os_key.svg" />
          </div>
          <div
            *ngIf="!autocompleteItems || autocompleteItems?.length; else emptyNotFound"
            class="gap-wrapper dropdown__items-wrapper v-scrollbar"
          >
            <div class="d-flex flex-column flex-gap-1">
              <div
                *ngFor="let item of autocompleteItems || listOfItems; index as i; trackBy: trackByFn"
                class="gap-wrapper"
              >
                <button
                  (click)="changeValue(item)"
                  [class.selected]="item[bindValue] === inputValue"
                  [value]="item[bindValue]"
                  class="dropdown__popup-item"
                  data-cy="dropdown-list-item"
                  type="button"
                >
                  <span>{{ item[bindLabel] }}</span>
                  <img
                    (click)="handleItemDelete(item, $event)"
                    *ngIf="item[itemCouldBeDeletedFlag] == itemCouldBeDeletedValue"
                    height="20"
                    src="assets/trash.svg"
                    width="20"
                    alt="trash"
                  />
                </button>
              </div>
            </div>
          </div>
          <ng-template #emptyNotFound>
            <ng-template [ngIf]="notFoundText">
              <div class="gap-wrapper dropdown__items-wrapper">
                <span *ngIf="matchesQuery; else emptyQuery" class="dropdown__empty-state">
                  {{ notFoundText | translate }} "{{ searchQuery | truncate: 30 }}"
                </span>
                <ng-template #emptyQuery>
                  <span>{{ notFoundText | translate }}</span>
                </ng-template>
              </div>
            </ng-template>
          </ng-template>
        </div>
      </div>

      <div *ngIf="addingAllowed" class="dropdown__add-new-item">
        <button
          (click)="showAddNewItemInput($event)"
          *ngIf="!newItemCreating; else addNewItemRef"
          class="dropdown__popup-item add-new"
          type="button"
        >
          <img alt="plus icon" class="plus-icon" src="assets/plus-blue.svg" />
          <span class="label-text">
            {{ addButtonLabel | translate }}
            <span class="text-lowercase">{{ customButtonText || label | translate }}</span></span
          >
        </button>
        <ng-template #addNewItemRef>
          <div class="position-relative">
            <input
              (input)="inputNewItem($event.target.value)"
              #inputNewItemRef
              class="new-item-input"
              atlReplaceInput
            />
            <button
              (click)="addItemToExistingList($event, inputNewItemRef.value)"
              class="new-item-button"
              type="button"
              [disabled]="buttonDisabled"
            >
              <img
                alt="plus icon"
                class="plus-icon"
                src="assets/{{ buttonDisabled ? 'plus-button-gray-disable.svg' : 'plus-blue.svg' }}"
              />
              <span class="label-text">{{ 'Shared.Button.Add' | translate }}</span>
            </button>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>

<ng-template #anchoredButtonRef>
  <button (click)="toggle()" class="anchored-button" type="button">
    <ng-content select="anchoredButton"></ng-content>
  </button>
</ng-template>
