export enum ELang {
  English = 'en',
  Norwegian = 'no',
  Swedish = 'sv',
}

export const langTranslatesMap = new Map([
  ['en', 'Shared.Languages.English'],
  ['no', 'Shared.Languages.Norwegian'],
  ['sv', 'Shared.Languages.Swedish'],
]);

export type ITranslateMapResponse<T> = {
  [K in 'main' | 'shared']?: T;
};

export interface ITranslateLocalize {
  localize: {
    locale: ELang;
  };
}
export interface IPluckedTranslateLocalize {
  locale: ELang;
}

export enum ELocalTime {
  Default = 'h:mm a',
  Norway = 'HH:mm',
}

export const authLinkRedirect = new Map([
  ['en', 'https://journeyapp.tech/'],
  ['no', 'https://journeytechnologies.no/'],
  ['sv', 'https://journeytechnologies.se/'],
]);
