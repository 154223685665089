export class FileHelper {
  static handleFileType(type: string): string {
    switch (type.toLowerCase()) {
      case 'png':
        return 'image/png';
      case 'jpeg':
        return 'image/jpeg';
      case 'jpg':
        return 'image/jpg';
      case 'svg':
        return 'image/svg';
      default:
        return type;
    }
  }

  static getFileTypeByExtension(file: File): string {
    const fileExtension: string = file.name.split('.').pop()?.toLocaleLowerCase() as string;
    let fileType: string;

    switch (fileExtension) {
      case 'avi':
        fileType = 'video/avi';
        break;
      case 'dng':
        fileType = 'image/x-adobe-dng';
        break;
      case 'xlsm':
        fileType = 'application/vnd.ms-excel.sheet.macroEnabled.12';
        break;
      case 'psd':
        fileType = 'image/vnd.adobe.photoshop';
        break;
      case 'rar':
        fileType = 'application/x-rar-compressed';
        break;
      case 'pages':
        fileType = 'application/vnd.apple.pages';
        break;
      case 'mov':
        fileType = 'video/quicktime';
        break;
      case 'heic':
        fileType = 'image/heic';
        break;
      case 'dxf':
        fileType = 'image/x-dxf';
        break;
      case 'dwg':
        fileType = 'image/vnd.dwg';
        break;
      case 'sos':
        fileType = 'text/vnd.sosi';
        break;
      case 'bak':
        fileType = 'application/octet-stream';
        break;
      default:
        fileType = '';
        break;
    }

    return fileType;
  }
}
