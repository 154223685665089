/* eslint-disable complexity */
import { Pipe, PipeTransform } from '@angular/core';
import { EDocumentType, fileExtensions } from '@atlas-workspace/shared/models';
import * as mimeTypes from 'mime';

@Pipe({
  name: 'iconByType',
})
export class IconByTypePipe implements PipeTransform {
  transform(extension: fileExtensions | string, type = EDocumentType.File): string {
    if (type === EDocumentType.Folder) {
      return '/assets/folder.svg';
    }
    const maybeExtensionFromMimetype = mimeTypes.getExtension(extension);
    if (maybeExtensionFromMimetype) {
      extension = maybeExtensionFromMimetype;
    }

    switch (extension?.toLowerCase()) {
      case 'txt':
        return '/assets/docs_txt_icon.svg';
      case 'pptx':
        return '/assets/pptx_type.svg';
      case 'ppt':
        return '/assets/pptx_type.svg';
      case 'doc':
        return '/assets/docx_type_new.svg';
      case 'docx':
        return '/assets/docx_type_new.svg';
      case 'pdf':
        return '/assets/pdf_type.svg';
      case 'xls':
        return '/assets/xlsx_type_new.svg';
      case 'xlsm':
          return '/assets/xlsx_type_new.svg';
      case 'xlsx':
        return '/assets/xlsx_type_new.svg';
      case 'png':
        return '/assets/png-icon.svg';
      case 'jpeg':
        return '/assets/jpg-icon.svg';
      case 'jpg':
        return '/assets/jpg-icon.svg';
      case 'avi':
        return '/assets/video_icon.svg';
      case 'video/avi':
        return '/assets/video_icon.svg';
      case 'mp4':
        return '/assets/video_icon.svg';
      case 'mov':
        return '/assets/video_icon.svg';
      case 'qt':
        return '/assets/video_icon.svg';
      case 'm4v':
        return '/assets/video_icon.svg';
      case 'svg':
        return '/assets/svg-icon.svg';
      case 'dfx':
        return '/assets/attach-dfx.svg';
      case 'csv':
        return '/assets/attach-csv.svg';
      case 'tiff':
        return '/assets/attach-tiff.svg';
      case 'zip':
          return '/assets/attach-zip.svg';
      case 'rar':
          return '/assets/attach-zip.svg';
      default:
        return '/assets/default-icon.svg';
    }
  }
}
