<div class="ngb-modal-header__wrapper" [ngStyle]="{ height: height + 'px' }">
  <div class="ngb-modal-header__content min-w-0">
    <span *ngIf="title" class="ngb-modal-header__title d-bloc ellipsis">{{ title | translate }}</span>
    <ng-content></ng-content>
  </div>

  <atl-header-icon
    (click)="closeModalEvent.emit()"
    [class]="isClient ? 'client' : 'admin'"
    [icon]="arrowClose ? arrowCloseIcon : closeIcon"
    [ngbTooltip]="'Alt.Close' | translate"
    [openDelay]="tooltipDelay"
    [container]="'body'"
    triggers="hover touchstart"
    data-cy="cy-close-btn-reclamation-creation"
  ></atl-header-icon>
</div>
