import { ILineChartDataset } from '../models/reclamations.model';

export function randomColor(str: string, id: number): string {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  const numInRange = Math.abs(id) % 31;

  const hue = Math.abs((hash + numInRange) % 360);
  const saturation = 70 + (hash % 31);
  const lightness = 35 + (numInRange % 16);

  const clampedSaturation = Math.min(100, Math.max(70, saturation));
  const clampedLightness = Math.min(50, Math.max(35, lightness));

  return `hsl(${hue}, ${clampedSaturation}%, ${clampedLightness}%)`;
}

export function transformUser(value: any, data: any): ILineChartDataset[] {
  const keys = Object?.keys(value);
  return keys
    .filter((key) => data[key])
    .map((key) => {
      const name = data[key];

      return {
        data: value[key],
        label: false,
        borderDash: false,
        borderColor: randomColor(name, +key),
        fill: false,
      } as unknown as ILineChartDataset;
    });
}
