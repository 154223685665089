<div class="checkbox__wr">
  <div 
    class="checkbox__el" 
    [class.checked]="innerValue" 
    [class.partially]="innerValue && checkedPartially" 
    [class.disabled]="disabled"
    [style.width.px]="size"
    [style.height.px]="size"
  ></div>

  <label 
    *ngIf="checkboxLabel; else content" 
    class="checkbox__label fz-14 lh-24 ellipsis ml-8"
  >{{ checkboxLabel }}</label>

  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>
</div>