import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'atl-header-icon',
  templateUrl: './header-icon.component.html',
  styleUrls: ['./header-icon.component.scss'],
  standalone: true,
  imports: [CommonModule],
})
export class HeaderIconComponent {
  @Input() icon!: string;
}
