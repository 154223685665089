import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';

import { CustomTextEditorComponent } from './custom-text-editor.component';

@NgModule({
  imports: [CommonModule, EditorModule, ReactiveFormsModule],
  declarations: [CustomTextEditorComponent],
  exports: [CustomTextEditorComponent],
})
export class CustomTextEditorModule {}
