import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'atl-ngb-modal-footer',
  templateUrl: './ngb-modal-footer.component.html',
  styleUrls: ['./ngb-modal-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true
})
export class NgbModalFooterComponent {}
