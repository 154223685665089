import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[atlCloseModalEsc]',
})
export class CloseModalEscDirective {
  @Output('atlCloseModalEsc') closeModal = new EventEmitter();

  @HostListener('window:keydown', ['$event'])
  keyEventEscape(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeModal.emit();
    }
  }
}
