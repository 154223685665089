<div
  class="firm-icon"
  [class.user]="isUser"
  [class.user__dropdown]="isDropdown"
  [class.monochrome]="monochrome"
  [ngStyle]="{ background: color, border: border, color: monochrome ? monochromeStyleColorVar : null }"
  [style.width.px]="size"
  [style.height.px]="size"
  [routerLink]="link"
>
  <span *ngIf="nameKey; else valueBlock" class="white-space-nowrap">{{ value[nameKey] | firmPrefix }}</span>
</div>

<ng-template #valueBlock>
  <span class="fz-11 fw-600"> {{ value | firmPrefix }} </span>
</ng-template>
