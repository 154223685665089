import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CommonHelper } from '@atlas-workspace/shared/service';

@Component({
  selector: 'atl-titled-checkbox',
  templateUrl: './titled-checkbox.component.html',
  styleUrls: ['./titled-checkbox.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TitledCheckboxComponent),
      multi: true,
    },
  ],
})
export class TitledCheckboxComponent implements ControlValueAccessor {
  @Input() @HostBinding('class.disabled-checkbox') disabledState = false;
  @Input() checkboxLabel: string | undefined;
  @Input() checkboxSize = 16;
  @Input() height: number | undefined;
  @Input() title: string | undefined;
  @Input() checkedPartially = false;
  @Input() uid?: any;
  @Output() changeEvent = new EventEmitter();

  @Input() value: number | boolean | undefined;
  randomizedID: string;

  constructor(private cd: ChangeDetectorRef) {
    this.randomizedID = CommonHelper.shuffleString(`${'' || this.checkboxLabel}-${new Date().getTime()}`);
  }

  onChange: any = () => {
    //
  };

  onTouched: any = () => {
    //
  };

  changed(event: boolean): void {
    this.value = event;
    // 'changeEvent.emit(event)' as next event after 'ngModelChange.emit(event)'
    this.changeEvent.emit(event);
    this.onChange(event);
    this.onTouched();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.value = !!value;
    this.cd.detectChanges();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabledState = isDisabled;
  }

  stopProp(ev: MouseEvent): void {
    ev.stopPropagation();
  }
}
