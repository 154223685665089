import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { ELang } from '@atlas-workspace/shared/models';
import { LocalStorageService } from '@atlas-workspace/shared/service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'atl-reset-pass-modal',
  templateUrl: './reset-password-modal.component.html',
  styleUrls: ['./reset-password-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetPasswordModalComponent implements OnInit {
  @Input() email = 'johndoe@gmail.com.';
  @Input() titleText = 'Shared.Auth.Forgot.Modal.Title';
  @Input() descriptionText = 'Shared.Auth.Forgot.Modal.Description';
  @Input() subDescriptionText = 'Shared.Auth.Forgot.Modal.Sub_description';
  @Input() linkText = 'Shared.Auth.Forgot.Modal.Contact_support';
  @Input() buttonText = 'Shared.Auth.Forgot.Modal.Button';

  public contactLink = 'https://journeyapp.tech/kontakt';

  constructor(
    private readonly ngbActiveModal: NgbActiveModal, 
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.setContactLink();
  }

  private setContactLink(): void {
    const lang = this.localStorageService.get('lang');

    if (lang) {
      if (lang === ELang.English) {
        this.contactLink = 'https://journeyapp.tech/contact-us';
        return;
      }
      if (lang === ELang.Norwegian) {
        this.contactLink = 'https://journeytechnologies.no/contact-us';
        return;
      }
      if (lang === ELang.Swedish) {
        this.contactLink = 'https://journeytechnologies.se/contact-us';
        return;
      }
    } else {
      this.contactLink = 'https://journeytechnologies.no/contact-us';
    }
  }

  public closeModal(): void {
    this.ngbActiveModal.close();
    void this.router.navigate(['auth/sign-in']);
  }
}
