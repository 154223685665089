import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FileSizePipeModule, IconByTypePipeModule, TruncatePipeModule} from '@atlas-workspace/shared/pipes';
import {CustomIconComponent} from "@atlas-workspace/shared/ui";

import {EditorFileUploadComponent} from './editor-file-upload.component';

@NgModule({
  imports: [CommonModule, FileSizePipeModule, IconByTypePipeModule, TruncatePipeModule, CustomIconComponent],
  declarations: [EditorFileUploadComponent],
  exports: [EditorFileUploadComponent],
})
export class EditorFileUploadModule {
}
