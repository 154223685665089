import {ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { UntilDestroy } from '@ngneat/until-destroy';

import { DropdownAutocompleteComponent } from '../dropdown-autocomplete/dropdown-autocomplete.component';

@UntilDestroy()
@Component({
  selector: 'atl-dropdown-autocomplete-v2',
  templateUrl: './dropdown-autocomplete-v2.component.html',
  styleUrls: ['./dropdown-autocomplete-v2.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewDropdownAutocompleteComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewDropdownAutocompleteComponent extends DropdownAutocompleteComponent {
  @Input() hasCreateButton = false;
  @Output() readonly addNewItemCreation = new EventEmitter();

  showAddNewItemInput(event: Event): void {
    if (this.hasCreateButton) {
      this.addNewItemCreation.emit();
      this.toggle();
      return;
    }
    event.stopPropagation();
    this.newItemCreating = true;
    this.newItemCreating$.next(this.newItemCreating);
    this.inputNewItemRef?.nativeElement.focus();
  }
}
