import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IImgFile, ImageModel } from '@atlas-workspace/shared/models';
import { environment } from '@environment-client';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class VisualsService {
  constructor(private http: HttpClient) {}

  public getVisuals(projectId: number): Observable<ImageModel[]> {
    return this.http
      .get<{ data: { visuals: IImgFile[] } }>(`${environment.apiBaseUrl}api/v1/projects/${projectId}/visuals`)
      .pipe(
        map((res) => res.data.visuals),
        map((data) => plainToClass(ImageModel, data))
      );
  }
}
