export const settings = {
  conditionals: {
    seconds: {
      min: 1,
      max: 59,
      single: 'Now',
      type: 'second',
    },
    minutes: {
      min: 1,
      max: 59,
      single: 'minute ago',
      type: 'minute',
    },
    hours: {
      min: 1,
      max: 23,
      single: 'hour ago',
      type: 'hour',
    },
    days: {
      min: 1,
      max: 6,
      single: 'day ago',
      type: 'day',
    },
    week: {
      min: 1,
      max: 4,
      single: 'week ago',
      type: 'week',
    },
    month: {
      min: 1,
      max: 11,
      single: 'month ago',
      type: 'month',
    },
    years: {
      min: 1,
      max: 11,
      single: 'year ago',
      type: 'year',
    },
  },
  dayjsConfig: {
    thresholds: [
      { l: 's', r: 1 },
      { l: 'm', r: 1 },
      { l: 'mm', r: 59, d: 'minute' },
      { l: 'h', r: 1 },
      { l: 'hh', r: 23, d: 'hour' },
      { l: 'd', r: 1 },
      { l: 'dd', r: 29, d: 'day' },
      { l: 'M', r: 1 },
      { l: 'MM', r: 11, d: 'month' },
      { l: 'y' },
      { l: 'yy', d: 'year' },
    ],
  },
};
