import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { OnboardingSelectorDirective, SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import { SharedPipesModule } from '@atlas-workspace/shared/pipes';
import { PageHelperService } from '@atlas-workspace/shared/service';
import { AutoResizeModule, CustomIconComponent, GroupedDropDownComponent } from '@atlas-workspace/shared/ui';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from "@ngx-translate/core";

import { HeaderComponent } from './components/header/header.component';
import { NotificationItemComponent } from './components/notification-item/notification-item.component';
import { NotificationsDropdownComponent } from './components/notifications-dropdown/notifications-dropdown.component';
import { ExpiredOrUrgentPipe } from './pipe/expired-or-urgent.pipe';
import { PropertyTypePipe } from './pipe/property-type.pipe';
import { OptionResolver } from './resolve/option-resolve.resolver';
import { AuthService } from './service/auth/auth.service';
import { GlobalStorageService } from './service/global-storage/global-storage.service';
import { LocalStorageService } from './service/local-storge/local-storage.service';
import { VisualsService } from './service/visuals/visuals.service';
import {LeftMenuModule} from "./components/left-menu/left-menu.module";

@NgModule({
  declarations: [
    HeaderComponent,
    NotificationsDropdownComponent,
    NotificationItemComponent,
    ExpiredOrUrgentPipe,
    PropertyTypePipe,
  ],
  providers: [
    GlobalStorageService,
    AuthService,
    DatePipe,
    LocalStorageService,
    VisualsService,
    OptionResolver,
    PageHelperService,
  ],
  exports: [HeaderComponent, NotificationsDropdownComponent, ExpiredOrUrgentPipe, PropertyTypePipe],
  imports: [
    CommonModule,
    RouterModule,
    SharedPipesModule,
    SharedDirectivesModule,
    AutoResizeModule,
    NgbTooltipModule,
    OnboardingSelectorDirective,
    LeftMenuModule,
    GroupedDropDownComponent,
    TranslateModule,
    CustomIconComponent
  ],
})
export class SharedModule {}
