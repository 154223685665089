import { Expose, Type } from 'class-transformer';

import { EUnitRoles } from './user-roles.model';
import { ITablePagination } from './table-pagination.interface';

export interface IUnitMember {
  deleted_at: string | null;
  id: number;
  name: string;
  email: string;
  role: string;
  joined_at: string;
  last_activity: string;
  phone: string;
  registered: boolean;
}

export interface ITransferMember {
  email: string;
  id: number;
  name: string;
  color?: string;
  borderColor?: string;
  disabled?: boolean;
  originalName?: string;
}

export interface IUnitMemberUpdate {
  member: Partial<{
    name: string;
    email: string;
    phone: string;
    role: EUnitRoles;
    invite_is_sent?: boolean;
    buying_through_the_company?: boolean;
    main_owner: boolean;
    company?: Partial<{
      id: number;
      title: string;
    }>;
  }>;
}

export interface IBatchUnitInvitingMembers {
  name: string;
  email: string;
  phone: string;
  with_invitation?: boolean;
}

export class MemberCompanyModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'title' })
  title!: string;
  @Expose({ name: 'address' })
  address!: string;
  @Expose({ name: 'longitude' })
  longitude!: number;
  @Expose({ name: 'latitude' })
  latitude!: number;
  @Expose({ name: 'org_number' })
  orgNumber!: string;
}

export class UnitMemberModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'last_activity' })
  lastActivity!: string;
  @Expose({ name: 'invite_sent' })
  inviteSent!: boolean;
  @Expose({ name: 'email' })
  email!: string;
  @Expose({ name: 'joined_at' })
  joinedAt!: string;
  @Expose({ name: 'role' })
  role!: string;
  @Expose({ name: 'deleted_at' })
  deletedAt!: string | null;
  @Expose({ name: 'phone' })
  phone!: string;
  @Expose({ name: 'registered' })
  registered!: boolean;
  @Expose({ name: 'buying_through_the_company' })
  buyingThroughTheCompany!: boolean;
  @Expose({ name: 'company' })
  @Type(() => MemberCompanyModel)
  company!: MemberCompanyModel;
  @Expose({ name: 'registration_link' })
  registrationLink!: string | null;
  @Expose({ name: 'effective_price' })
  effectivePrice!: number;
  @Expose({ name: 'main_owner' })
  mainOwner!: boolean;

  iconColor!: string;
  iconBorderColor!: string;

  activeTabTable = false;
}

export interface TProjectBuyers {
  id: number;
  name: string;
  email: string;
  phone: string;
  company: string;
}

export class ProjectBuyersModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'email' }) email!: string;
  @Expose({ name: 'phone' }) phone!: string;
  @Expose({ name: 'company' }) company!: string;
}

export class ProjectBuyersData {
  buyers!: ProjectBuyersModel[];
  pagination!: ITablePagination;
}