<div class="comment-textarea">
  <img
    class="comment-textarea__icon"
    [ngClass]="{ 'comment-textarea__hidden': focused || inputValue }"
    src="/assets/comment-icon.svg"
    alt="Icon"
  />
  <textarea
    atlReplaceInput
    class="v-scrollbar"
    atlAutoresize
    #input
    (input)="changeValue(input.value)"
    [placeholder]="placeholder"
    [value]="inputValue"
    (focus)="onFocus(true)"
    (focusout)="onFocus(false)"
    [ngClass]="{ 'comment-textarea__value': inputValue }"
  ></textarea>
</div>
