import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedFormModule } from '@atlas-workspace/shared/form';
import { CustomRouterStateSerializer, EPlatform } from '@atlas-workspace/shared/models';
import { CableService, ModuleAccessService, SentryService, SharedServiceModule } from '@atlas-workspace/shared/service';
import { SharedTranslateForRootModule, WEB_PLATFORM_TYPE } from '@atlas-workspace/shared/translate';
import { TranslateInterceptor } from '@atlas-workspace/shared/ui';
import { environment } from '@environment-client';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsRouterPluginModule, RouterStateSerializer } from '@ngxs/router-plugin';
import { NgxsModule } from '@ngxs/store';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { DragulaModule } from 'ng2-dragula';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgxsResetPluginModule } from 'ngxs-reset-plugin';
import { register } from 'swiper/element/bundle';

import { AppComponent } from './app.component';
import { AppInitService } from './app-init.service';
import { AppRoutingModule } from './app-routing.module';
import { NotificationService } from "./modules/settings/components/settings/services/notification.service";
import { ClientErrorHandlerInterceptor } from './modules/shared/service/auth/client-error-handler.interceptor';
import { SharedModule } from './modules/shared/shared.module';

register();

// TODO: ChunkLoadError delete after double check @AZ.

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    SharedModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    SharedServiceModule,
    ReactiveFormsModule,
    SharedFormModule,
    NgxMaskDirective,
    SharedTranslateForRootModule,
    NgxGoogleAnalyticsModule.forRoot(environment.analyticsIdClient),
    NgxGoogleAnalyticsRouterModule,
    DragulaModule.forRoot(),
    NgxsModule.forRoot([], {
      developmentMode: !environment.production,
    }),
    NgxsRouterPluginModule.forRoot(),
    NgxsResetPluginModule.forRoot(),
    NgxsReduxDevtoolsPluginModule.forRoot(),
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: WEB_PLATFORM_TYPE,
      useFactory: (): string => EPlatform.WEB_CLIENT,
    },
    provideNgxMask(),
    AppInitService,
    ModuleAccessService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [AppInitService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useFactory: (): SentryService => {
        return new SentryService(environment.dns, environment.production);
      },
    },
    { provide: 'ENVIRONMENT', useValue: environment },
    ToastrService,
    { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientErrorHandlerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TranslateInterceptor,
      multi: true,
    },
    CableService,
    NotificationService,
    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
  ],
})
export class AppModule {}

function initializeAppFactory(appInit: AppInitService): () => Promise<any> {
  return (): Promise<any> => appInit.init();
}
