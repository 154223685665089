import { EPositionControl } from '@atlas-workspace/shared/models';

export function calculationIndexItem(specificIndex: number, countAllItems: number, state: EPositionControl): number {
  let newestIndex!: number;

  if (state === EPositionControl.Next) {
    newestIndex = specificIndex + 1 > countAllItems - 1 ? 0 : specificIndex + 1;
  } else {
    newestIndex = specificIndex - 1 === -1 ? countAllItems - 1 : specificIndex - 1;
  }

  return newestIndex;
}
