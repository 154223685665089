import { Expose } from 'class-transformer';

export interface IContactPerson {
  id: number;
  name: string;
  email: string;
  phone: string;
  company_id: number;
}

export class ContactPersonModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'name' })
  name: string;
  @Expose({ name: 'email' })
  email: string;
  @Expose({ name: 'phone_number' })
  phone!: string;
  @Expose({ name: 'company_id' })
  companyId: number;
  @Expose({ name: 'company_title' })
  companyTitle!: string;

  constructor() {
    this.id = 0;
    this.name = '';
    this.email = '';
    this.companyId = 0;
  }
}
