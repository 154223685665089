import { Component, EventEmitter, Input, Output } from '@angular/core';

import {EDocumentType, FdvModel} from "@atlas-workspace/shared/models";

@Component({
  selector: 'atl-fdv-node',
  templateUrl: './fdv-node.component.html',
  styleUrls: ['./fdv-node.component.scss'],
})
export class FdvNodeComponent {
  @Input() folders!: FdvModel[];
  @Input() selectFolderId?: number;
  @Input() readonly folderMenu = true;
  @Output() private readonly selectHandler = new EventEmitter<FdvModel>();
  @Output() private readonly createHandler = new EventEmitter<FdvModel>();
  @Output() private readonly uploadFileHandler = new EventEmitter<FdvModel>();
  @Output() private readonly uploadFolderHandler = new EventEmitter<FdvModel>();
  @Output() private readonly renameHandler = new EventEmitter<FdvModel>();
  @Output() private readonly downloadHandler = new EventEmitter<FdvModel>();
  @Output() private readonly deleteHandler = new EventEmitter<FdvModel>();

  public readonly documentType = EDocumentType;

  selectItem(item: FdvModel): void {
    this.selectEvent(item);
  }

  activeItem(item: FdvModel): void {
    if (item.documentType === this.documentType.Folder) {
      item.active = !item.active;
    }
  }

  selectEvent(item: FdvModel): void {
    this.selectHandler.emit(item);
  }

  create(folder: FdvModel): void {
    this.createHandler.emit(folder);
  }

  uploadFile(folder: FdvModel): void {
    this.uploadFileHandler.emit(folder);
  }

  uploadFolder(folder: FdvModel): void {
    this.uploadFolderHandler.emit(folder);
  }

  rename(folder: FdvModel): void {
    this.renameHandler.emit(folder);
  }

  download(folder: FdvModel): void {
    this.downloadHandler.emit(folder);
  }

  delete(folder: FdvModel): void {
    this.deleteHandler.emit(folder);
  }
}
