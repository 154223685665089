import { Expose } from 'class-transformer';

export class ActivityModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'text' })
  text: string;
  @Expose({ name: 'created_at' })
  createdAt: string | Date;

  constructor() {
    this.id = 0;
    this.text = '';
    this.createdAt = new Date();
  }
}

export interface IActivity {
  id: number;
  text: string;
  created_at: string;
}
