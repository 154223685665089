import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';

@Pipe({
  name: 'defaultDate',
})
export class DefaultDatePipe implements PipeTransform {
  constructor(private datePipe: DatePipe, private translateService: TranslateService) {}

  transform(
    value: Date | string | number,
    isUnusualDate = false,
    showYear = false,
    fullYear = false,
    format = 'MMM d'
  ): string {
    const year = fullYear ? 'YYYY' : 'YY';
    const transformParam: string = showYear
      ? `${format}, YYYY`
      : dayjs().isSame(value, 'year')
      ? `${format}`
      : `${format}, ${year}`;

    if (isUnusualDate) {
      const today = dayjs();
      const specificDay = dayjs(value);
      const difference = Math.floor(today.diff(specificDay, 'day', true));

      if (difference === 0) {
        return this.translateService.instant('Shared.Date.Today');
      } else if (difference === -1) {
        return this.translateService.instant('Shared.Date.Tomorrow');
      } else if (difference === 1) {
        return this.translateService.instant('Shared.Date.Yesterday');
      }
    }

    return this.datePipe.transform(value, transformParam) || '';
  }
}
