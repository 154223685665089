import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';

import { DropdownPanel } from './dropdown-panel';

@Component({
  selector: 'atl-dropdown',
  templateUrl: './dropdown.component.html',
})
export class DropdownComponent implements DropdownPanel {
  @ViewChild(TemplateRef) templateRef!: TemplateRef<any>;
  @Output() closed = new EventEmitter<void>();
}
