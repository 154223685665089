import { Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { debounce } from 'lodash';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Directive({
  selector: '[atlImageResize]',
})
export class ImageResizeDirective implements OnInit, OnDestroy {
  private parentWidth!: number;
  private parentHeight!: number;
  private zoom?: number;
  private onDebouncedResizeFn?: ReturnType<typeof debounce>;
  private readonly onDebounceTime = 500;

  @Input('atlImageResize') parent!: HTMLDivElement;
  @Input() marginWidth = 0;
  @Input() marginHeight = 0;
  @Input() set zoomScale(num: number) {
    this.zoom = num/100;
    this.imageResize(this.zoom)
  }
  @Input() set resizeDebounceOnDeps(_: unknown) {
    of(null).pipe(delay(this.onDebounceTime)).subscribe(() => {
      this.onResize();
    })
  }
  @Input() set resizeOnDeps(_: unknown) {
    this.onResize();
  }

  @HostListener('window:resize')
  onResizeHandler(): void {
    !this.onDebouncedResizeFn || this.onDebouncedResizeFn();
  }

  constructor(private elementRef: ElementRef, private render: Renderer2) {}

  ngOnInit(): void {
    this.render.listen(this.elementRef.nativeElement, 'load', () => {
      this.parentSize();
      this.imageResize(this.zoom);
    });
    this.onDebouncedResizeFn = debounce(() => this.onResize(), this.onDebounceTime, { maxWait: 1 });
  }

  onResize(): void {
    this.parentSize();
    this.imageResize(this.zoom);
  }

  parentSize(): void {
    this.parentWidth = this.parent.clientWidth - this.marginWidth;
    this.parentHeight = this.parent.clientHeight - this.marginHeight;
    this.render.setStyle(this.elementRef.nativeElement, 'width', `auto`);
    this.render.setStyle(this.elementRef.nativeElement, 'height', `auto`);
  }

  imageResize(zoom?: number): void {
    const width = this.elementRef.nativeElement.naturalWidth;
    const height = this.elementRef.nativeElement.naturalHeight;

    const widthRatio = this.parentWidth / width;
    const heightRatio = this.parentHeight / height;

    const scale = Math.min(widthRatio, heightRatio);
    let newWidth = Math.floor(width * scale);
    let newHeight = Math.floor(height * scale);

    if (zoom) {
      newWidth = newWidth * zoom;
      newHeight = newHeight * zoom
    }

    this.render.setStyle(this.elementRef.nativeElement, 'width', `${newWidth}px`);
    this.render.setStyle(this.elementRef.nativeElement, 'height', `${newHeight}px`);
  }

  ngOnDestroy(): void {
    this.onDebouncedResizeFn?.cancel();
  }
}
