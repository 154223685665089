import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IProjectIllustration, ProjectIllustrationModel } from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProjectIllustrationsService {
  constructor(private http: HttpClient) {}

  getIllustrations(apiBaseUrl: string, projectId: number): Observable<ProjectIllustrationModel[]> {
    return this.http
      .get<{ data: { illustrations: IProjectIllustration[] } }>(
        `${apiBaseUrl}api/v1/projects/${projectId}/illustrations`
      )
      .pipe(
        map((res) => res.data.illustrations),
        map((data) => plainToClass(ProjectIllustrationModel, data))
      );
  }
}
