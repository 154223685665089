import { Pipe, PipeTransform } from '@angular/core';
import { EDocumentType, fileExtensions } from '@atlas-workspace/shared/models';
import * as mimeTypes from 'mime';

@Pipe({
  name: 'attachIcon',
  standalone: true,
})
export class AttachIconPipe implements PipeTransform {
  // eslint-disable-next-line complexity
  transform(extension: fileExtensions | string, type = EDocumentType.File): string {
    if (type === EDocumentType.Folder) {
      return '/assets/folder.svg';
    }
    const maybeExtensionFromMimetype = mimeTypes.getExtension(extension);
    if (maybeExtensionFromMimetype) {
      extension = maybeExtensionFromMimetype;
    }

    switch (extension?.toLowerCase()) {
      case 'pptx':
        return '/assets/attach-ppt.svg';
      case 'ppt':
        return '/assets/attach-ppt.svg';
      case 'doc':
        return '/assets/attach-doc.svg';
      case 'docx':
        return '/assets/attach-doc.svg';
      case 'pdf':
        return '/assets/attach-pdf.svg';
      case 'xls':
        return '/assets/attach-xls.svg';
      case 'xlsx':
        return '/assets/attach-xls.svg';
      case 'xlsm':
        return '/assets/attach-xls.svg';
      case 'txt':
        return '/assets/attach-txt.svg';
      case 'avi':
        return '/assets/attach-video.svg';
      case 'video/avi':
        return '/assets/attach-video.svg';
      case 'mp4':
        return '/assets/attach-video.svg';
      case 'mov':
        return '/assets/attach-video.svg';
      case 'qt':
        return '/assets/attach-video.svg';
      case 'm4v':
        return '/assets/attach-video.svg';
      case 'mkv':
        return '/assets/attach-video.svg';
      case 'jpeg':
        return '/assets/attach-jpg.svg';
      case 'jpg':
        return '/assets/attach-jpg.svg';
      case 'png':
        return '/assets/attach-png.svg';
      case 'svg':
        return '/assets/attach-svg.svg';
      case 'dfx':
        return '/assets/attach-dfx.svg';
      case 'csv':
        return '/assets/attach-csv.svg';
      case 'tiff':
        return '/assets/attach-tiff.svg';
      case 'zip':
        return '/assets/attach-zip.svg';
      case 'rar':
        return '/assets/attach-zip.svg';
      default:
        return '/assets/attach-default.svg';
    }
  }
}
