import { AfterViewInit, Directive, ElementRef, HostListener, Inject, Input, Optional, Renderer2 } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

import { AUTO_SIZE_INPUT_OPTIONS, AutoSizeInputOptions, DEFAULT_AUTO_SIZE_INPUT_OPTIONS } from './autoresize.options';

type WidthProperty = 'border-left-width' | 'border-right-width' | 'padding-left' | 'padding-right';

@UntilDestroy()
@Directive({
  selector: '[atlAutoSizeInput]',
  standalone: true,
})
export class AutoSizeInputDirective implements AfterViewInit {
  @Input() extraWidth = this.defaultOptions.extraWidth;
  @Input() includeBorders = this.defaultOptions.includeBorders;
  @Input() includePadding = this.defaultOptions.includePadding;
  @Input() includePlaceholder = this.defaultOptions.includePlaceholder;
  @Input() maxWidth = this.defaultOptions.maxWidth;
  @Input() minWidth = this.defaultOptions.minWidth;
  @Input() setParentWidth = this.defaultOptions.setParentWidth;
  @Input() usePlaceHolderWhenEmpty = this.defaultOptions.usePlaceHolderWhenEmpty;
  @Input() useValueProperty = false;
  @Input('atlAutoSizeInput') inputValue!: string;

  @HostListener('input', ['$event.target.value'])
  onInput(value: string): void {
    this.inputValue = value;
    this.updateWidth();
  }

  private span: HTMLSpanElement;

  constructor(
    private element: ElementRef,
    @Optional() private ngModel: NgModel,
    @Optional() private ngControl: NgControl,
    @Optional() @Inject(AUTO_SIZE_INPUT_OPTIONS) readonly options: AutoSizeInputOptions,
    private renderer: Renderer2
  ) {
    this.span = this.renderer.createElement('span');
    this.renderer.setStyle(this.span, 'position', 'absolute');
    this.renderer.setStyle(this.span, 'visibility', 'hidden');
    this.renderer.setStyle(this.span, 'white-space', 'nowrap');
    this.renderer.appendChild(document.body, this.span);
  }

  get borderWidth(): number {
    return this.includeBorders ? this.sumPropertyValues(['border-right-width', 'border-left-width']) : 0;
  }

  get defaultOptions(): any {
    return this.options || DEFAULT_AUTO_SIZE_INPUT_OPTIONS;
  }

  get paddingWidth(): number {
    return this.includePadding ? this.sumPropertyValues(['padding-left', 'padding-right']) : 0;
  }

  get style(): CSSStyleDeclaration {
    return getComputedStyle(this.element.nativeElement, '');
  }

  ngAfterViewInit(): void {
    if (this.ngModel) {
      this.ngModel.valueChanges
        ?.pipe(
          tap(() => this.updateWidth()),
          untilDestroyed(this)
        )
        .subscribe();
    } else if (this.ngControl) {
      this.ngControl.valueChanges
        ?.pipe(
          tap(() => this.updateWidth()),
          untilDestroyed(this)
        )
        .subscribe();
      this.updateWidth();
    } else {
      this.updateWidth();
    }
  }

  private getInputValue(): string {
    return this.inputValue || '';
  }

  private setWidth(width: number): void {
    const { nativeElement } = this.element;
    const parent = this.renderer.parentNode(nativeElement);
    this.setParentWidth
      ? this.renderer.setStyle(parent, 'width', width + 'px')
      : this.renderer.setStyle(nativeElement, 'width', width + 'px');
  }

  private setWidthUsingText(text: string): void {
    this.setWidth(this.measureTextWidth(text) + this.extraWidth + this.borderWidth + this.paddingWidth);
  }

  private textForWidth(inputText: string, placeHolderText: string, setPlaceHolderWidth: boolean): string {
    return setPlaceHolderWidth && (inputText.length === 0 || !this.usePlaceHolderWhenEmpty)
      ? placeHolderText
      : inputText;
  }

  private measureTextWidth(value: string): number {
    this.renderer.setStyle(this.span, 'font', this.style.font);
    this.renderer.setProperty(this.span, 'innerText', value);
    return this.span.offsetWidth;
  }

  private updateWidth(): void {
    const inputText = this.getInputValue();
    const placeHolderText = this.getElProperty('placeholder');
    const inputTextWidth = this.measureTextWidth(inputText) + this.extraWidth + this.borderWidth + this.paddingWidth;
    const setMinWidth = this.minWidth > 0 && this.minWidth > inputTextWidth;
    const setPlaceHolderWidth =
      this.includePlaceholder &&
      placeHolderText.length > 0 &&
      this.measureTextWidth(placeHolderText) > this.measureTextWidth(inputText);
    const setMaxWidth = this.maxWidth > 0 && this.maxWidth < inputTextWidth;

    if (setMinWidth) {
      this.setWidth(this.minWidth);
    } else if (setMaxWidth) {
      this.setWidth(this.maxWidth);
    } else {
      this.setWidthUsingText(this.textForWidth(inputText, placeHolderText, setPlaceHolderWidth));
    }
  }

  private getElProperty(property: 'value' | 'placeholder'): any {
    return this.element.nativeElement?.[property];
  }

  private sumPropertyValues(properties: WidthProperty[]): number {
    return properties.map((property) => parseInt(this.style.getPropertyValue(property), 10)).reduce((a, b) => a + b, 0);
  }
}
