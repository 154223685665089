import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { EventListenerService } from './services/event/event-listener.service';
import { ProjectService } from './services/project.service';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [ProjectService, EventListenerService],
})
export class SharedServiceModule {}
