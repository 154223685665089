<div class="global-label" [class.global-label__autoresize]="autoResizeInput">
  <span *ngIf="inputTitle" class="label"> {{ inputTitle | translate }} </span>
  <ng-select
    #ngSelect
    atlLockField
    [class.pointer-events-none]="locked"
    (isLocked)="isLocked($event, ngSelect)"
    [class.global-label--hidden]="chips && selectedItem && selectedItem[bindLabel]?.length"
    [class.global-label--special-style]="isSpecialStyle"
    [class.global-label--not-found]="!listOfItems?.length"
    [placeholder]="placeholder || label | translate"
    [items]="listOfItems"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [editableSearchTerm]="editableSearchTerm"
    [disabled]="disabled"
    [dropdownPosition]="position"
    [ngModel]="inputValue"
    (change)="changeValue($event)"
    [searchable]="searchable"
    [clearable]="clearable"
    (clear)="onRemoveValue()"
    (search)="onListenInput($event)"
    (input)="changeInput($event)"
    [searchFn]="searchFn"
    [groupBy]="groupBy"
    (blur)="onBlur()"
    (close)="onSaveSearchByClose()"
    (open)="openDropdown()"
    (focus)="initSearch()"
    #dropDownInput
    [appendTo]="appendTo"
    class="dropdown-writing"
    atlWindowResize
    (browserZoomLevel)="onResize()"
    [tabIndex]="tabIndex"
  >
    <ng-template ng-optgroup-tmp let-item="item">
      {{ item.name || ('Shared.Entity.Unnamed_group' | translate) }}
    </ng-template>

    <ng-container *ngIf="headerInfo">
      <ng-template ng-header-tmp>
        {{ emptyValueHideHeader && !savedValue && !listOfItems.length ? '' : headerInfo }}
      </ng-template>
    </ng-container>

    <ng-container *ngIf="customHeader">
      <ng-template ng-header-tmp>
        <ng-template [ngTemplateOutlet]="headerTemplate"></ng-template>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="customLabelTranslateView">
      <ng-template ng-label-tmp let-item="item">
        <ng-container>
          <ng-template
            [ngTemplateOutlet]="labelTranslateTemplate"
            [ngTemplateOutletContext]="{ item: item }"
          ></ng-template>
        </ng-container>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="customItemView">
      <ng-container *ngIf="!customLabelTranslateView && customLabelView">
        <ng-template ng-label-tmp let-item="item">
          <ng-container>
            <ng-template [ngTemplateOutlet]="labelTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-template>
          </ng-container>
        </ng-template>
      </ng-container>

      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <ng-container>
          <ng-template [ngTemplateOutlet]="valueTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-template>
        </ng-container>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="customUserForm">
      <ng-template ng-label-tmp let-item="item">
        <span>{{ item.select }}</span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <span>{{ item.option }}</span>
      </ng-template>
    </ng-container>

    <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
      <div class="ng-select__not-found ng-option disabled" *ngIf="notFoundText || customNotFoundView">
        <span [hidden]="customNotFoundView" [innerHTML]="notFoundText | translate"></span>
        <ng-content></ng-content>
        <ng-container *ngIf="customNotFoundView">
          <ng-template
            [ngTemplateOutlet]="notFoundTemplate"
            [ngTemplateOutletContext]="{ searchTerm: searchTerm }"
          ></ng-template>
        </ng-container>
      </div>
    </ng-template>

    <ng-container *ngIf="creatioTitle">
      <ng-template ng-footer-tmp>
        <div class="drop-down-v1-footer">
          <div
            class="drop-down-v1-footer-label fw-400 br-4 cursor-pointer"
            [class.d-none]="isActiveStaticCreation"
            (click)="openCreation()"
          >
            <span class="fz-20 lh-16">+</span><span class="fz-14 lh-24 ml-8">{{ creatioTitle | translate }}</span>
          </div>
          <div class="drop-down-v1-footer-control" [class.d-none]="!isActiveStaticCreation">
            <input
              #creationInput
              atlPreventDoubleSpace
              atlReplaceInput
              class="br-4 w-100 fz-14 lh-24"
              type="text"
              [value]="savedValue || ''"
              (input)="$event.stopPropagation()"
              (keydown.enter)="onCreation(creationInput.value, $event)"
            />
            <img
              src="assets/dropdown_accept_circle.svg"
              class="drop-down-v1-footer-actions cursor-pointer"
              (click)="onCreation(creationInput.value)"
              alt="Accept"
            />
            <img
              src="assets/dropdown_close_circle.svg"
              class="drop-down-v1-footer-actions cursor-pointer"
              (click)="closeCreation(true)"
              alt="Close"
            />
          </div>
        </div>
      </ng-template>
    </ng-container>
  </ng-select>

  <div class="chips__wrapper" *ngIf="chips && selectedItem && selectedItem[bindLabel].length">
    <div class="chips__card">
      <div class="chips__title">{{ selectedItem[bindLabel] }}</div>
      <div class="chips__close">
        <button (click)="dropDownInput.handleClearClick()">
          <atl-custom-icon [icon]="closeSvg"></atl-custom-icon>
        </button>
      </div>
    </div>
  </div>

  <div class="dropdown__input-width" *ngIf="autoResizeInput" #inputWidth>{{ savedOriginalValue }}</div>
</div>
