export enum ENotificationStatus {
  Read = 'read',
  Unread = 'unread',
}

export enum ENotificationType {
  Phase = 'phase',
  Update = 'update',
  Task = 'task',
  Visual = 'visual',
  Document = 'document',
  PracticalInfo = 'practical_info',
  Meeting = 'meeting',
  Reclamation = 'reclamation',
  UpdatesUnit = 'updates_unit',
  TasksUnit = 'tasks_unit',
  DocumentsUnit = 'documents_unit',
  Illustration = 'project_illustration',
  PriceOffer = 'price_offer',
  Survey = 'survey',
  ChangeRequest = 'change_requests_request',
}
