<div class="input-file__input-title">
  <span class="input-file__input-title__label">
    {{ label | translate }}
    <span *ngIf="isOptional" class="global-label--optional">({{ optionalText | translate }})</span>
  </span>
  <label *ngIf="disabled || (!asGallery && listOfPreview?.length && listOfPreview.length < maxUploadedFiles)">
    <ng-container *ngIf="!disabled" [ngTemplateOutlet]="uploadInputRef"></ng-container>
    <span *ngIf="!disabled" class="input-file__title--upload">{{ uploadFileType | translate }}</span>
  </label>
</div>

<ng-template #uploadInputRef>
  <input
    #uploadInput
    (change)="selectedFile($event)"
    [multiple]="multiple"
    [accept]="inputAcceptString"
    [disabled]="disabled"
    class="file-input"
    hidden
    id="file-input"
    type="file"
  />
</ng-template>

<ng-template #dragFilesRef let-asDraggableGallery="asDraggableGallery">
  <div
    atlFileDragDrop
    (dragenter)="handleDragEnter()"
    (dragleave)="handleDragLeave()"
    (dragover)="handleDragOver($event)"
    (drop)="handleDrop($event)"
    *ngIf="!asGallery || listOfPreview?.length < maxUploadedFiles || disabled"
    [class.input-file__draggable-gallery]="asDraggableGallery"
    class="input-file__container"
    [class.disabled]="disabled"
    tabindex="0"
    data-cy="cy-input-file-drag-drop-area"
  >
    <ng-container *ngIf="!asDraggableGallery">
      <img *ngIf="uploadIcon" [src]="uploadIcon" alt="icon" />
      <span *ngIf="disabled && !listOfPreview?.length" class="input-file__title">
        {{ 'Shared.Entity.No_uploaded_files' | translate }}
      </span>
      <span *ngIf="!disabled" class="input-file__title">
        <ng-container *ngIf="!monitoring; else monitoringState">
          {{ 'Shared.Description.Drag_file_or' | translate }}
          <span data-cy="cy-input-upload-file-label" class="input-file__title--underline">
            {{ uploadFileType | translate | lowercase }}
          </span>
        </ng-container>
      </span>
    </ng-container>
  </div>
</ng-template>

<ng-template
  #removeFileIconRef
  let-asDraggableGallery="asDraggableGallery"
  let-index="index"
  let-editFile="editFile"
  let-isPreviewImage="isPreviewImage"
>
  <ng-container *ngIf="asDraggableGallery; else defaultIconTrash">
    <img
      *ngIf="!loadingGallery && !disabled && (!disableDelete || (asGallery && listOfPreview?.length !== 1))"
      (click)="removeFile(index, $event, editFile)"
      [ngClass]="asDraggableGallery ? 'draggable-gallery__remove-icon' : 'input-file__remove-image'"
      [src]="asDraggableGallery ? 'assets/trash-at-white-back.svg' : removeIconSvg"
      alt="Remove image"
    />
  </ng-container>

  <ng-template #defaultIconTrash>
    <div
      *ngIf="!isPreviewImage && !disabled && (!disableDelete || (asGallery && listOfPreview?.length !== 1))"
      (click)="removeFile(index, $event, editFile)"
      class="newest-document-card__icon-control newest-document-card__icon-control-trash"
    >
      <atl-custom-icon [icon]="trashIcon"></atl-custom-icon>
    </div>

    <img
      *ngIf="
        isPreviewImage && !loadingGallery && !disabled && (!disableDelete || (asGallery && listOfPreview?.length !== 1))
      "
      (click)="removeFile(index, $event, editFile)"
      [ngClass]="asDraggableGallery ? 'draggable-gallery__remove-icon' : 'input-file__remove-image'"
      [src]="asDraggableGallery ? 'assets/trash-at-white-back.svg' : removeIconSvg"
      alt="Remove image"
    />
  </ng-template>
</ng-template>

<div (click)="handleUpload()" *ngIf="!files?.length && !editFiles?.length" class="global-label">
  <ng-container [ngTemplateOutlet]="uploadInputRef"></ng-container>
  <ng-container [ngTemplateOutlet]="dragFilesRef"></ng-container>
</div>

<ng-template #draggableGalleryRef>
  <div *ngIf="listOfPreview?.length" class="draggable-gallery">
    <div class="draggable-gallery__first-item">
      <span *ngIf="loadingGallery" class="custom-spinner custom-spinner-30"></span>
      <img
        [ngClass]="{ invisible: loadingGallery }"
        (load)="onLoad()"
        [src]="listOfPreview[draggableGallerySelectedIndex]?.previewImage"
        alt="draggable-gallery__item"
        class="draggable-gallery__first-item__image"
      />
      <ng-container
        *ngIf="minUploadedFiles !== listOfPreview.length"
        [ngTemplateOutletContext]="{
          index: draggableGallerySelectedIndex,
          editFile: listOfPreview[draggableGallerySelectedIndex]?.editFile,
          asDraggableGallery: true
        }"
        [ngTemplateOutlet]="removeFileIconRef"
      >
      </ng-container>
      <ng-container
        [ngTemplateOutletContext]="{ asDraggableGallery: true }"
        [ngTemplateOutlet]="dragFilesRef"
      ></ng-container>
    </div>
    <div class="pad-1">
      <div
        (cdkDropListDropped)="drop($event)"
        cdkDropList
        cdkDropListOrientation="horizontal"
        class="d-flex flex-gap-1 draggable-gallery__media-list"
      >
        <div *ngFor="let item of listOfPreview; index as i" class="gap-wrapper">
          <div
            [cdkDragData]="item"
            [class.draggable-gallery__selected-item]="draggableGallerySelectedIndex === i"
            cdkDrag
            class="draggable-gallery__item"
          >
            <atl-scalable-bordered-img
              (click)="saveGallerySelectedIndex(i)"
              [src]="item.previewImageW260"
              [border]="draggableGallerySelectedIndex === i ? borderBlue : borderGray"
              borderRadius="8px"
              height="48px"
              width="48px"
              class="draggable-gallery__item__image"
            ></atl-scalable-bordered-img>
          </div>
        </div>
        <div class="gap-wrapper">
          <ng-container [ngTemplateOutlet]="uploadInputRef"></ng-container>
          <button
            (click)="handleUpload()"
            *ngIf="!disabled && listOfPreview.length < maxUploadedFiles"
            class="d-flex justify-content-center align-items-center draggable-gallery__item draggable-gallery__add-button"
            type="button"
          >
            <img alt="add-button__plus" class="draggable-gallery__add-button__plus" src="/assets/plus-blue.svg" />
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-container *ngIf="listOfPreview?.length">
  <div
    *ngIf="!asGallery; else draggableGalleryRef"
    class="input-file__preview newest-document-card__preview"
    [class.v-scrollbar]="scrollable"
  >
    <ng-template
      #filePreviewCard
      let-editFile="editFile"
      let-extension="extension"
      let-i="i"
      let-mimetype="mimetype"
      let-name="name"
      let-previewImage="previewImage"
      let-size="size"
      let-type="type"
      let-downloadLink="downloadLink"
      let-isTemp="isTemp"
      let-isAutoSave="isAutoSave"
    >
      <ng-template #fileTemplateRef>
        <div class="input-file__item-preview newest-document-card__item-preview">
          <ng-container *ngIf="!((name | isVideo) && newVideo); else videoEmpty">
            <ng-container *ngIf="!(name | isRaw) || isRawIcon; else emptyRaw">
              <div class="newest-document-card__wrapper" (click)="selectFilePreview(editFile)">
                <div class="newest-document-card__info">
                  <div>
                    <img
                      class="newest-document-card__extension"
                      [src]="extension || mimetype | iconByType"
                      alt="icon"
                    />
                  </div>

                  <div>
                    <div class="newest-document-card__file-name">
                      {{ name | truncate: truncateFileName }}
                    </div>

                    <div class="fz-12 newest-document-card__file-size">{{ size | filesize }}</div>
                  </div>
                </div>
                <ng-container
                  [ngTemplateOutletContext]="{ index: i, editFile: editFile, isPreviewImage: false }"
                  [ngTemplateOutlet]="removeFileIconRef"
                ></ng-container>

                <ng-container *ngIf="downloadLink">
                  <a
                    [href]="downloadLink"
                    download=""
                    class="newest-document-card__icon-control newest-document-card__icon-control-download"
                    (click)="$event.stopPropagation()"
                  >
                    <atl-custom-icon [icon]="downloadIcon"></atl-custom-icon>
                  </a>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
          <ng-template #videoEmpty>
            <div class="empty-video">
              <img src="assets/video-empty.svg" alt="video-empty" />
              <img (click)="removeFile(i, $event, editFile)" class="remove" [src]="removeIconSvg" alt="Remove image" />
            </div>
          </ng-template>
          <ng-template #emptyRaw>
            <div class="empty-video">
              <img src="assets/no_images.png" alt="video-empty" />
              <img (click)="removeFile(i, $event, editFile)" class="remove" [src]="removeIconSvg" alt="Remove image" />
            </div>
          </ng-template>
        </div>
      </ng-template>

      <div
        *ngIf="type === 'image' && previewImage; else fileTemplateRef"
        class="gap-wrapper newest-document-card-disable-gap"
      >
        <div class="input-file__item-preview newest-document-card__item-preview" *ngIf="newestViewImage">
          <div class="newest-document-card__wrapper">
            <div class="newest-document-card__info">
              <div>
                <img class="newest-document-card__image-container" [src]="previewImage" alt="image" />
              </div>

              <div>
                <div class="newest-document-card__file-name">
                  {{ editFile?.name || name | truncate: truncateFileName }}
                </div>
              </div>
            </div>

            <ng-container *ngIf="downloadLink">
              <a
                [href]="downloadLink"
                download=""
                class="newest-document-card__icon-control newest-document-card__icon-control-download"
              >
                <atl-custom-icon [icon]="downloadIcon"></atl-custom-icon>
              </a>
            </ng-container>

            <ng-container *ngIf="isTemp; else existFile">
              <ng-container
                *ngIf="!isAutoSave"
                [ngTemplateOutletContext]="{ index: i, editFile: editFile }"
                [ngTemplateOutlet]="removeFileIconRef"
              ></ng-container>
            </ng-container>
            <ng-template #existFile>
              <ng-container
                [ngTemplateOutletContext]="{ index: i, editFile: editFile }"
                [ngTemplateOutlet]="removeFileIconRef"
              ></ng-container>
            </ng-template>
          </div>
        </div>

        <div class="input-file__item-preview" *ngIf="!newestViewImage" (click)="onPreviewImage()">
          <img [src]="previewImage" alt="Preview image" class="input-file__image" />
          <div class="input-file__item-preview__trash-wrapper">
            <ng-container *ngIf="isTemp; else existFile">
              <ng-container
                *ngIf="!isAutoSave"
                [ngTemplateOutletContext]="{ index: i, editFile: editFile, isPreviewImage: true }"
                [ngTemplateOutlet]="removeFileIconRef"
              ></ng-container>
            </ng-container>
            <ng-template #existFile>
              <ng-container
                [ngTemplateOutletContext]="{ index: i, editFile: editFile, isPreviewImage: true }"
                [ngTemplateOutlet]="removeFileIconRef"
              ></ng-container>
            </ng-template>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-container *ngIf="listOfPreview?.length">
      <ng-container *ngFor="let listItem of listOfPreview; index as i; trackBy: trackByFn">
        <ng-container *ngIf="!fileAsImagePreview">
          <ng-container
            *ngIf="listItem.file"
            [ngTemplateOutletContext]="{
              mimetype: listItem.file.type,
              name: listItem.file.name,
              previewImage: listItem.previewImage,
              size: listItem.file.size,
              type: listItem.type,
              downloadLink: listItem?.editFile?.fileName?.downloadUrl,
              i: i,
              isTemp: listItem?.isTemp,
              isAutoSave: listItem?.isAutoSave
            }"
            [ngTemplateOutlet]="filePreviewCard"
          ></ng-container>

          <ng-container
            *ngIf="listItem.editFile"
            [ngTemplateOutletContext]="{
              editFile: listItem.editFile,
              extension: listItem.editFile.fileExtension,
              downloadLink: listItem.editFile.fileName.downloadUrl,
              name: listItem.editFile.name,
              previewImage: listItem.previewImage,
              size: listItem.editFile.fileSize,
              type: listItem.type,
              i: i
            }"
            [ngTemplateOutlet]="filePreviewCard"
          ></ng-container>
        </ng-container>

        <ng-container *ngIf="fileAsImagePreview">
          <ng-container
            *ngIf="listItem.file"
            [ngTemplateOutletContext]="{
              mimetype: listItem.file.type,
              name: listItem.file.name,
              previewImage: listItem.previewImage,
              size: listItem.file.size,
              type: listItem.type,
              downloadLink: listItem?.editFile?.fileName?.downloadUrl,
              i: i,
              isTemp: listItem?.isTemp,
              isAutoSave: listItem?.isAutoSave
            }"
            [ngTemplateOutlet]="DocumentPreview"
          ></ng-container>

          <ng-container
            *ngIf="listItem.editFile"
            [ngTemplateOutletContext]="{
              editFile: listItem.editFile,
              extension: listItem.editFile.fileExtension,
              downloadLink: listItem.editFile.fileName.downloadUrl,
              name: listItem.editFile.name,
              previewImage: listItem.previewImage,
              size: listItem.editFile.fileSize,
              type: listItem.type,
              i: i
            }"
            [ngTemplateOutlet]="DocumentPreview"
          ></ng-container>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template
  #DocumentPreview
  let-editFile="editFile"
  let-extension="extension"
  let-i="i"
  let-mimetype="mimetype"
  let-name="name"
  let-previewImage="previewImage"
  let-size="size"
  let-type="type"
  let-downloadLink="downloadLink"
  let-isTemp="isTemp"
  let-isAutoSave="isAutoSave"
>
  <ng-template #fileTemplateRef>
    <div class="input-file__item-preview newest-document-card__item-preview">
      <div class="newest-document-card__wrapper" (click)="selectFilePreview(editFile)">
        <div class="newest-document-card__info">
          <div>
            <img class="newest-document-card__extension" [src]="extension || mimetype | iconByType" alt="icon" />
          </div>

          <div>
            <div class="newest-document-card__file-name">
              {{ name | truncate: truncateFileName }}
            </div>

            <div class="fz-12 newest-document-card__file-size">{{ size | filesize }}</div>
          </div>
        </div>
        <ng-container
          [ngTemplateOutletContext]="{ index: i, editFile: editFile, isPreviewImage: false }"
          [ngTemplateOutlet]="removeFileIconRef"
        ></ng-container>

        <ng-container *ngIf="downloadLink">
          <a
            [href]="downloadLink"
            download=""
            class="newest-document-card__icon-control newest-document-card__icon-control-download"
            (click)="$event.stopPropagation()"
          >
            <atl-custom-icon [icon]="downloadIcon"></atl-custom-icon>
          </a>
        </ng-container>
      </div>
    </div>
  </ng-template>

  <div class="w-100 h-100" *ngIf="type === 'image' && previewImage; else fileDocumentPreview">
    <div class="w-100 h-100" (click)="selectFilePreview(editFile)">
      <img class="cursor-pointer image-preview" [src]="previewImage" alt="image" />
      <div class="input-file__item-preview__trash-wrapper">
        <ng-container *ngIf="isTemp; else existFile">
          <ng-container
            *ngIf="!isAutoSave"
            [ngTemplateOutletContext]="{ index: i, editFile: editFile, isPreviewImage: true }"
            [ngTemplateOutlet]="removeFileIconRef"
          ></ng-container>
        </ng-container>
        <ng-template #existFile>
          <ng-container
            [ngTemplateOutletContext]="{ index: i, editFile: editFile, isPreviewImage: true }"
            [ngTemplateOutlet]="removeFileIconRef"
          ></ng-container>
        </ng-template>
      </div>
    </div>
  </div>

  <ng-template #fileDocumentPreview>
    <div class="w-100 h-100">
      <div class="w-100 h-100" *ngIf="editFile?.preview?.url" (click)="selectFilePreview(editFile)">
        <img class="cursor-pointer image-preview" [src]="editFile?.preview?.url" alt="image" />
        <div class="input-file__item-preview__trash-wrapper">
          <ng-container *ngIf="isTemp; else existFile">
            <ng-container
              *ngIf="!isAutoSave"
              [ngTemplateOutletContext]="{ index: i, editFile: editFile, isPreviewImage: true }"
              [ngTemplateOutlet]="removeFileIconRef"
            ></ng-container>
          </ng-container>
          <ng-template #existFile>
            <ng-container
              [ngTemplateOutletContext]="{ index: i, editFile: editFile, isPreviewImage: true }"
              [ngTemplateOutlet]="removeFileIconRef"
            ></ng-container>
          </ng-template>
        </div>
      </div>
    </div>
  </ng-template>
</ng-template>

<ng-template #monitoringState>
  <span class="input-file__monitoring">
    {{ 'Entity.Drop_upload_files' | translate }}
  </span>
</ng-template>
