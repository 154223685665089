export class CommonHelper {
  static arrayClearFromPrimitiveItem<T>(sourceArray: Array<T>, removableItem: T): void {
    let index;
    for (let i = 0; i < sourceArray.length; i++) {
      if (sourceArray[i] === removableItem) {
        index = i;
        sourceArray.splice(index, 1);
        --i;
      }
    }
  }

  static iterableAlreadyIncludesPrimitive<T>(source: Array<T>, element: T): boolean;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  static iterableAlreadyIncludesPrimitive(source: string | any[], element: any): boolean {
    if (!source || !element) {
      return false;
    }
    return source.includes(element);
  }

  static getItemFromObjectList<T>(
    sourceArray: Array<Record<string, T>>,
    value: T,
    field: string
  ): Record<string, T> | undefined {
    if (!value) {
      return undefined;
    }
    let foundItem;
    for (let i = 0; i < sourceArray.length; i++) {
      if (sourceArray[i][field] === value) {
        foundItem = sourceArray[i];
        break;
      }
    }
    return foundItem;
  }

  static shuffleString(str: string): string {
    return str
      .split('')
      .sort(() => {
        return 0.5 - Math.random();
      })
      .join('');
  }

  static convertPriceValue(val: string): number | string {
    if (val) {
      return Number(val.replace(/\s/g, '').replace(/,/, '.'));
    }
    return val;
  }
}
