import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SwiperDirectiveModule } from '@atlas-workspace/shared/directives';
import { SharedPipesModule, TruncatePipeModule } from '@atlas-workspace/shared/pipes';
import { TranslateModule } from '@ngx-translate/core';

import { ReadMoreComponent } from '../read-more/read-more.component';
import { SliderComponent } from './slider.component';

@NgModule({
  imports: [
    CommonModule,
    TruncatePipeModule,
    ReadMoreComponent,
    TranslateModule,
    SharedPipesModule,
    SwiperDirectiveModule,
  ],
  declarations: [SliderComponent],
  exports: [SliderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SliderModule {}
