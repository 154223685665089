import { Pipe, PipeTransform } from '@angular/core';
import {
  EAttachableType,
  FloorDrawVersionModel,
  FloorModel,
  MessageAttachmentsModel,
  UnitFloorModel,
  UnitUserModel,
} from '@atlas-workspace/shared/models';

@Pipe({
  name: 'threadMessageChangeRequest',
  standalone: true,
})
export class ThreadMessageChangeRequestPipe implements PipeTransform {
  transform(
    message: MessageAttachmentsModel,
    version: FloorDrawVersionModel[],
    unit: UnitUserModel
  ): FloorDrawVersionModel | FloorModel | UnitFloorModel | undefined {
    if (message.attachableType === EAttachableType.FloorVersion) {
      return version.find((v) => v.id === message.attachableId);
    } else {
      const floors = unit.hasOwnFloorPlans ? unit.unitFloors : unit.floors;
      return floors?.find((f) => f.id === message.attachableId);
    }
  }
}
