export interface IFeatureFlag {
  name: string;
  enabled: boolean;
}

export enum EFeatureFlags {
  HelpLink = 'help_link',
  Reclamations = 'reclamations',
  ChangeRequests = 'change_requests',
  Threads = 'threads',
  Fdv = 'fdv',
  MeetingProtocol = 'meeting_protocol',
  EmailTemplatesNewFirmMemberMail = 'email_templates_new_firm_member_mail',
  EmailTemplatesClientNewReclamationCreated = 'email_templates_client_new_reclamation_created',
  EmailTemplatesNotifyToBuyerOnAdminAddedUnitsToMeeting = 'email_templates_notify_to_buyer_on_admin_added_units_to_meeting',
  SpecialPermissions = 'special_permissions',
  SignBankId = 'sign_bank_id',
  ChangeRequestBankId = 'change_requests_bunk_id',
  ClientSupportChat = 'client_support_chat',
}
