<div
  #dropdown
  ngbDropdown
  [autoClose]="'outside'"
  class="availability-dropdown"
  [placement]="['top', 'bottom']"
>
  <button
    type="button"
    ngbDropdownToggle
    id="dropdown-{{index}}"
    class="availability-dropdown__button"
    [class.disabled]="disabled || !unitsArr?.length"
  >
    <span class="d-block ellipsis">
      {{selectedValue}}
    </span>
    <img src="assets/arrow_down_option_store.svg" alt="arrow">
  </button>
  <div
    ngbDropdownMenu
    [attr.aria-labelledby]="'dropdown-'+index"
  >
    <div
      class="availability-dropdown__dropdown"
      [style.width]="dropdown.clientWidth + 'px'"
    >
      <ng-container *ngTemplateOutlet="invite"></ng-container>
    </div>
  </div>
</div>

<ng-template #invite>
  <div class="availability-dropdown__invite">
    <div (click)="clearSelect()" class="availability-dropdown__free" [ngClass]="{active: !unitSelected?.length}">
      <span class="d-block ellipsis">{{placeholder}}</span>
      <img *ngIf="!unitSelected?.length" src="assets/multiple_select_checkbox.svg" alt="check">
    </div>
    <label>
      <input
        class="availability-dropdown__input"
        type="text"
        [placeholder]="'Shared.Entity.Search' | translate"
        (input)="searchUnit($event)"
      />
    </label>
    <div class="list v-scrollbar small">
      <div *ngIf="multi" (click)="selectAll()" class="list__item d-flex">
        <atl-titled-checkbox
          [value]="!!unitSelected?.length"
          [checkedPartially]="unitSelected?.length < unitsArr?.length"
          class="d-inline-block mr-8 pointer-events-none"
        ></atl-titled-checkbox>
        <span class="d-block ellipsis fz-14 lh-24">
          {{'Entity.All_units' | translate}}
        </span>
      </div>
      <div
        *ngFor="let unit of filteredUnits"
        (click)="selectUnit(unit)"
        class="list__item d-flex"
        [ngClass]="{
          select: unit.selected,
          disabled: !unit.selected && unit.disabled
        }"
      >
        <atl-titled-checkbox
          [value]="unit.selected"
          class="d-inline-block mr-8 pointer-events-none"
          [disabledState]="unit.disabled"
        ></atl-titled-checkbox>
        <div class="d-flex flex-column min-w-0">
          <span class="d-block ellipsis fz-14 lh-24">
            {{unit.identifier}}
          </span>
          <span *ngIf="unit.mainPrimaryOwner?.name || unit.coOwners?.[0]?.name" class="d-block ellipsis fz-12 lh-16 text-black-810">
            {{unit.mainPrimaryOwner?.name || unit.coOwners?.[0]?.name}}
          </span>
        </div>
      </div>
      <div *ngIf="search && !filteredUnits.length" class="list__item not-found">
        {{'Shared.Entity.Not_found' | translate}}
      </div>
    </div>
  </div>
</ng-template>
