import { Directive, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { round } from 'lodash';

@Directive({
  selector: '[atlWindowResize]',
})
export class WindowResizeDirective implements OnInit {
  @Output()
  browserZoomLevel: EventEmitter<number> = new EventEmitter();
  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    const w: Window = event.currentTarget as Window;
    const browserZoomLevel: number = round((w.outerWidth - 8) / w.innerWidth, 1);
    this.browserZoomLevel.emit(browserZoomLevel);
  }

  ngOnInit(): void {
    const browserZoomLevel: number = round((window.outerWidth - 8) / window.innerWidth, 1);
    this.browserZoomLevel.emit(browserZoomLevel);
  }
}
