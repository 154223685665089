import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

interface IFeatureFlag {
  name: string;
  enabled: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private _pageAccess$ = new BehaviorSubject<IFeatureFlag[] | null>(null);
  constructor(private http: HttpClient, @Inject('ENVIRONMENT') private env: IEnvironment) {}

  public getPageAccess$(): Observable<IFeatureFlag[] | null> {
    return this._pageAccess$.asObservable();
  }

  public getFeatureFlags(): Observable<IFeatureFlag[]> {
    return this.http
      .get<{ data: { feature_flags: IFeatureFlag[] } }>(`${this.env.apiBaseUrl}api/v1/feature_flags`)
      .pipe(
        map((res) => res.data.feature_flags),
        tap((v: IFeatureFlag[]) => {
          this.setPagesAccess(v);
        })
      );
  }

  private setPagesAccess(v: IFeatureFlag[]): void {
    this._pageAccess$.next(v);
  }
}
