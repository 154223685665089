import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TimePassedAgoPipe } from './time-passed-ago.pipe';

@NgModule({
  declarations: [TimePassedAgoPipe],
  exports: [TimePassedAgoPipe],
  providers: [TimePassedAgoPipe],
  imports: [CommonModule],
})
export class TimePassedAgoModule {}
