<div #wrapperElementRef class="options-popover__wrapper" atlClickOutside (clickOutside)="close()">
  <button
    type="button"
    (click)="toggle($event)"
    class="options-popover__button"
    [class.options-popover__button-active]="!overflowRef.hidden"
  >
    <img alt="dots-icon" src="assets/dots-three-outline.svg" />
  </button>
  <div #overflowRef [ngStyle]="{ top: top + 'px', left: left + 'px' }" class="options-popover__popup" hidden>
    <ng-content select="popup-content"></ng-content>
  </div>
</div>
