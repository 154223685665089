import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import { SharedPipesModule, UnderscoreReplacerModule } from '@atlas-workspace/shared/pipes';
import {DefaultDropdownModule, FirmIconComponent, NotFoundModule} from '@atlas-workspace/shared/ui';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { TitledCheckboxModule } from '../titled-checkbox/titled-checkbox.module';
import { FilterDropdownComponent } from './filter-fropdown.component';

@NgModule({
  imports: [
    CommonModule,
    NgbDropdownModule,
    DefaultDropdownModule,
    UnderscoreReplacerModule,
    ReactiveFormsModule,
    TranslateModule,
    SharedPipesModule,
    NotFoundModule,
    TitledCheckboxModule,
    SharedDirectivesModule,
    FirmIconComponent
  ],
  declarations: [FilterDropdownComponent],
  exports: [FilterDropdownComponent],
})
export class FilterDropdownModule {}
