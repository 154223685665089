import {inject, Pipe, PipeTransform} from '@angular/core';
import {EPlatform, ETimeFormat} from '@atlas-workspace/shared/models';
import {WEB_PLATFORM_TYPE} from "@atlas-workspace/shared/translate";
import dayjs from 'dayjs';

@Pipe({
  name: 'dateTimePipe',
})
export class DateTimePipe implements PipeTransform {
  private readonly platform = inject(WEB_PLATFORM_TYPE);

  transform(value: string | undefined): string {
    if (!value) value;

    const timeFormat = ETimeFormat.FormatEu;
    const dot = this.platform === EPlatform.WEB_CLIENT ? '•' : '·';

    return `${dayjs(value).format('DD MMM YYYY')} ${dot} ${dayjs(value).format(timeFormat)}`;
  }
}
