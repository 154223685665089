import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'atl-complete-protocol',
  templateUrl: './complete-protocol-modal.component.html',
  styleUrls: ['./complete-protocol-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CompleteProtocolModalComponent {
  constructor(private ngbActiveModal: NgbActiveModal) {}

  public closeModal(): void {
    this.ngbActiveModal.close();
  }

  public navigateToDocuments(): void {
    this.ngbActiveModal.close(true);
  }
}
