import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbModalModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import {TranslateModule} from '@ngx-translate/core';

import { DefaultModalComponent } from './default-modal.component';
import {HeaderIconComponent} from "@atlas-workspace/shared/ui";

@NgModule({
  imports: [CommonModule, NgbModalModule, TranslateModule, NgbTooltipModule, HeaderIconComponent],
  declarations: [DefaultModalComponent],
  exports: [DefaultModalComponent],
})
export class DefaultModalModule {}
