import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpSentEvent } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import { ELocalStorageKeys, EUserAuthHeaders, IAuthHeaders } from '@atlas-workspace/shared/models';
import { LocalStorageService } from '@atlas-workspace/shared/service';
import { Observable } from 'rxjs';


@Injectable()
export class HttpAuthInterceptor implements HttpInterceptor {
  constructor(private localStorageService: LocalStorageService, @Inject('ENVIRONMENT') private env: IEnvironment) {}
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<HttpSentEvent>> {
    const authHeaders: IAuthHeaders | null = this.localStorageService.get<IAuthHeaders>(ELocalStorageKeys.AuthHeaders);

    if (authHeaders) {
      request = request.clone({
        headers: request.headers
          .set(EUserAuthHeaders.AccessToken, authHeaders.token)
          .set(EUserAuthHeaders.Uid, authHeaders.uid)
          .set(EUserAuthHeaders.Client, authHeaders.client),
      });
    }
    return next.handle(request);
  }
}
