import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import { OptionsShortInfoModel } from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import {Observable, of, Subject} from 'rxjs';
import { map, share, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ClientCounterService {
  private changeRequestCounter$ = new Subject<number>();
  private optionsOfferCounter$ = new Subject<number>();
  private notCompletedOptionsProductsCounter$ = new Subject<number>();

  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private environment: IEnvironment
  ) {}

  unviewedChangeRequestCounter(unitId: number): Observable<number> {
    return this.http
      .get<{ data: number }>(
        `${this.environment.apiBaseUrl}api/v1/client/units/${unitId}/change_requests/change_requests/unviewed_offers_counter`
      )
      .pipe(
        map((res: any) => res.data),
        tap((count) => this.setChangeRequestCounter(count))
      );
  }

  unviewedOfferCounter(projectId: number, unitId: number, layoutTypeId?: number | null): Observable<number> {
    if (!layoutTypeId) {
      this.setOptionsOfferCounter(0);
      return of(0)
    }
    const params = new HttpParams({ fromObject: { unit_id: unitId?.toString() } });
    return this.http
      .get<{ data: number }>(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/layout_types/${layoutTypeId}/price_offers/unviewed_counter`,
        {
          params,
        }
      )
      .pipe(
        map((res: any) => res.data),
        tap((count) => this.setOptionsOfferCounter(count))
      );
  }

  /**
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Client-Options-Dashboard/paths/~1api~1v1~1client~1projects~1%7Bproject_id%7D~1units~1%7Bunit_id%7D~1options~1short_info/get
   */
  getNotCompletedOptionsProductsCounter(projectId: number, unitId: number): Observable<number> {
    return this.http
      .get<{ data: Partial<OptionsShortInfoModel> }>(this.environment.apiBaseUrl + `api/v1/client/projects/${projectId}/units/${unitId}/options/short_info`)
      .pipe(
        map((res) => plainToClass(OptionsShortInfoModel, res.data)),
        map((data) => data?.notCompletedItemsCount),
        tap((counter = 0) => {
          this.setNotCompletedOptionsProductsCounter(counter);
        }),
      );
  }

  get changeRequestCounter(): Observable<number> {
    return this.changeRequestCounter$.asObservable().pipe(share());
  }

  setChangeRequestCounter(count: number): void {
    this.changeRequestCounter$.next(count);
  }

  get optionsOfferCounter(): Observable<number> {
    return this.optionsOfferCounter$.asObservable().pipe(share());
  }

  setOptionsOfferCounter(count: number): void {
    this.optionsOfferCounter$.next(count);
  }

  setNotCompletedOptionsProductsCounter(count: number): void {
    this.notCompletedOptionsProductsCounter$.next(count);
  }

  get notCompletedOptionsProductsCounter(): Observable<number> {
    return this.notCompletedOptionsProductsCounter$.asObservable().pipe(share());
  }
}
