export function isElementToLeft(container: HTMLElement, el: HTMLElement): boolean {
  const containerRect = container.getBoundingClientRect();
  const elRect = el.getBoundingClientRect();

  return elRect.left < containerRect.left;
}

export function isElementToRight(container: HTMLElement, el: HTMLElement): boolean {
  const containerRect = container.getBoundingClientRect();
  const elRect = el.getBoundingClientRect();

  return elRect.right > containerRect.right;
}

export function isElementInViewport(container: HTMLElement, el: HTMLElement): boolean {
  const containerRect = container.getBoundingClientRect();
  const elRect = el.getBoundingClientRect();

  return (
    elRect.left >= containerRect.left &&
    elRect.right <= containerRect.right &&
    elRect.top >= containerRect.top &&
    elRect.bottom <= containerRect.bottom
  );
}
