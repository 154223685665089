import { Injectable } from '@angular/core';
import { NewestConfirmModalComponent } from '@atlas-workspace/shared/modals';
import { ModalFacadeService } from '@atlas-workspace/shared/service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

import { ImageCropModalComponent } from '../components/image-crop-modal/image-crop-modal.component';

@UntilDestroy()
@Injectable()
export class CropImageModalService {
  private modalRef: NgbModalRef | undefined;
  private deleteModalRef: NgbModalRef | undefined;

  constructor(private modalFacadeService: ModalFacadeService, private translateService: TranslateService) {}

  public cropImage(file: File | null, event: Event, cropperPosition?: any): Promise<File> {
    this.modalRef = this.modalFacadeService.openModal(ImageCropModalComponent, {
      keyboard: false,
      modalDialogClass: 'wider-modal',
      windowClass: 'custom-modal-main centered-modal-crop-image',
    });
    this.modalRef.componentInstance.originalImage = file;
    this.modalRef.componentInstance.event = event;
    this.modalRef.componentInstance.cropperPosition = cropperPosition || null;

    return this.modalRef.result;
  }

  public confirmDelete(icon: string): Observable<boolean> {
    this.deleteModalRef = this.modalFacadeService.openModal(NewestConfirmModalComponent, {
      centered: true,
      windowClass: 'confirm-action-modal-v2',
    });
    this.deleteModalRef.componentInstance.title = this.translateService.instant('Shared.Title.Delete_project_cover');
    this.deleteModalRef.componentInstance.description = this.translateService.instant(
      'Shared.Description.Crop_img_delete_text'
    );
    this.deleteModalRef.componentInstance.button.text = this.translateService.instant('Shared.Button.Delete');
    this.deleteModalRef.componentInstance.icon = icon;
    return this.deleteModalRef.componentInstance.passConfirm;
  }

  public closeDeleteModal(): void {
    this.deleteModalRef?.close();
  }
}
