import { Pipe, PipeTransform } from '@angular/core';
import { isNil } from 'lodash';

@Pipe({
  name: 'customDecimal',
})
export class CustomDecimalPipe implements PipeTransform {
  transform(value: string | number, fixed = 0, nonZeroFixed?: boolean): string | number {
    if (Number.isInteger(value)) {
      return this.replaceNumber(value.toString());
    }
    if (typeof value === 'string') {
      const parsedValue = parseFloat(value);

      if (isNaN(parsedValue)) {
        return '';
      }

      if (nonZeroFixed && Number.isInteger(parsedValue)) {
        return this.replaceNumber(parsedValue.toString());
      }

      const num = parsedValue.toFixed(fixed);
      return this.replaceNumber(num);
    }

    if(isNil(value)) {
      return '';
    }

    const num = parseFloat(value.toFixed(fixed));

    if (isNaN(num)) {
      return '';
    }

    return this.replaceNumber(num.toString());
  }

  replaceNumber(num: string): string {
    return num.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ');
  }
}
