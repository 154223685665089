import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'atl-default-dropdown',
  templateUrl: './default-dropdown.component.html',
  styleUrls: ['./default-dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefaultDropdownComponent  {}
