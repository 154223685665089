import {Component, Input} from '@angular/core';

import { CompleteProtocolModalComponent } from '../complete-protocol-modal/complete-protocol-modal.component';

@Component({
  selector: 'atl-confirm-signicat',
  templateUrl: './confirm-signicat.component.html',
  styleUrls: ['./confirm-signicat.component.scss'],
})
export class ConfirmSignicatComponent extends CompleteProtocolModalComponent {
  @Input() title = 'Modal.Signicat.Confirm.Header';
  @Input() description = 'Modal.Signicat.Confirm.Description';

}
