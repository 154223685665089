import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { ClickOutsideDirective } from '@atlas-workspace/shared/directives';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, merge, Observable, take } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'atl-options-popover',
  templateUrl: './options-popover.component.html',
  styleUrls: ['./options-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective],
})
export class OptionsPopoverComponent implements OnInit {
  @Input() popupTopPosition = 26;
  @Input() exceptionLeftPos = 0;
  @Input() closeDropdown$!: Observable<boolean>;
  @Input() showOnTopPosition = false;
  @Output() openHandler = new EventEmitter();
  @ViewChild('overflowRef') overflowRef!: ElementRef;
  @ViewChild('wrapperElementRef') wrapperElementRef!: ElementRef;
  left: any;
  top: any;
  isOpen = false;

  constructor(
    private readonly ngZone: NgZone
  ) {}

  ngOnInit(): void {
    this.close();
    this.subscribeToScrollEvent();
    this.closeDropdown$.pipe(untilDestroyed(this)).subscribe((val) => {
      if (!val) {
        this.close();
      }
    });
  }

  toggle(event: Event): void {
    event.stopPropagation();
    const hiddenAttr: boolean = this.overflowRef.nativeElement.hidden;
    if (hiddenAttr) {
      this.open();
    } else {
      this.close();
    }
  }

  close(): void {
    if (this.overflowRef?.nativeElement) {
      this.overflowRef.nativeElement.hidden = true;
      this.isOpen = false;
    }
  }

  open(): void {
    const domRect = this.wrapperElementRef.nativeElement.getBoundingClientRect();
    if (!this.showOnTopPosition) {
      this.top = domRect.top + this.popupTopPosition;
      this.left = domRect.left - this.exceptionLeftPos;
      if (this.overflowRef?.nativeElement) {
        this.overflowRef.nativeElement.hidden = false;
        this.isOpen = true;
      }
    } else {
      if (this.overflowRef?.nativeElement) {
        this.overflowRef.nativeElement.hidden = false;
        this.isOpen = true;
      }
      const popupRect = this.overflowRef.nativeElement.getBoundingClientRect();
      this.top = domRect.top - popupRect.height;
      this.left = domRect.left - this.exceptionLeftPos;
    }
    this.openHandler.emit();
  }

  private subscribeToScrollEvent(): void {
    merge(fromEvent(window, 'wheel'), fromEvent(window, 'scroll'))
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.close();
      });
  }
}
