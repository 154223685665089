/* eslint-disable max-len */
export class RegExpHelper {
  /**
   * Test the value using a regular expression that matches valid e-mail addresses.
   *
   * It is based on the Angular Validators.email.
   *
   * The main differences from the Angular version are:
   *  - The domain part requires a dot.
   *  - There must be at least 2 users after the dot.
   *
   * ex.
   *  Valid email: test@mail.co
   *  Invalid email: test@mail
   */
  static email =
    /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9]))$/;

  static integerOrFloatNumber = '^-?[0-9]\\d*(\\.\\d{1,6})?$';

  static integerNumbers = '^[0-9]*$';

  static number = /^-?[0-9]+(\.[0-9]*){0,1}$/g;

  static numbersWithCommasOnly = '^[-,0-9]+$';

  static urls = /((?:(?:http?|ftp)[s]*:\/\/)?[a-z0-9-%/&=?.]+\.[a-z]{2,4}\/?([^\s<>#%",{}\\|\\^[\]`]+)?)/gi;

  static password = /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).{8,30}$/;

  static whiteSpaces = /&(nbsp|amp|quot|lt|gt);/g;

  static tags = /(<([^>]+)>)/gi;

  static inputWithOnlyMinus = /^-+$/;

  static validDayMonthYear = /^\d{2}\.\d{2}\.\d{4}$/;

  static validLinkUrl = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
}
