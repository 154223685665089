import { Expose } from 'class-transformer';

export enum EAttributeType {
  Text = 'text_field',
  Price = 'price_field',
  Size = 'size_field',
  Date = 'date_field',
  AmountField = 'amount_field',
}

export interface ICreateUnitAttribute {
  name: string;
  field_type: string;
  for_all_project_units: boolean;
  custom_field?: {
    value: string;
  };
}

export interface IEditUnitAttribute {
  custom_field?: {
    name: string;
    field_type: string;
    value: string;
  };
}

export class UnitAttributesModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'field_type' })
  fieldType!: string;
  @Expose({ name: 'for_all_project_units' })
  forAll!: string;
  @Expose({ name: 'value' })
  value!: string;
}
