import { AfterViewInit, Directive, EventEmitter, Input, OnDestroy, Output, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[atlIsVisible]',
  standalone: true
})
export class IsVisibleDirective implements AfterViewInit, OnDestroy {
  @Input() threshold = 1;
  @Output() visible = new EventEmitter<boolean>();
  private observer!: IntersectionObserver;
  constructor(private vcRef: ViewContainerRef) {}

  ngAfterViewInit(): void {
    const observedElement = this.vcRef.element.nativeElement.parentElement;
    const options = {
      threshold: this.threshold,
    };
    this.observer = new IntersectionObserver(([entry]) => {
      this.renderContents(entry);
    }, options);
    this.observer.observe(observedElement);
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  private renderContents(entry: IntersectionObserverEntry): void {
    this.visible.emit(entry.isIntersecting && entry.intersectionRatio >= this.threshold);
  }
}
