import { Expose, Type } from 'class-transformer';

import { IUnitMainBuyer } from './unit.model';

export enum EMemberType {
  Buyers = 'buyer',
  ProjectOwner = 'project_owner',
  ProjectMember = 'project_member',
}

export enum EStatus {
  Active = 'Entity.Active',
  Invite = 'Entity.Invited',
  NoSent = 'Entity.Not_invited',
}

export interface IBuyerUnit {
  id: number;
  role: string;
  identifier: string;
  company?: string;
}

export class BuyerModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'email' })
  email!: string;
  @Expose({ name: 'phone' })
  phone!: string;
  @Expose({ name: 'role' })
  role!: string;
  @Expose({ name: 'last_activity' })
  lastActivity!: string | null;
  @Expose({ name: 'units' })
  @Type(() => BuyerUnitsModel)
  units!: BuyerUnitsModel[];
  @Expose({ name: 'registered' })
  registered!: boolean;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'user_type' })
  userType?: string;
}

export class BuyerUnitsModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier!: string;
  @Expose({ name: 'property_type' })
  type!: string;
  @Expose({ name: 'role' })
  role!: string;
  @Expose({ name: 'will_project_owner' })
  willProjectOwner!: boolean;
  @Expose({ name: 'company' })
  @Type(() => BuyerCompanyModel)
  company?: BuyerCompanyModel | null;
  @Expose({ name: 'invite_sent' })
  inviteSent!: boolean;
  @Expose({ name: 'co_owners' })
  coOwners!: IUnitMainBuyer[];
  @Expose({ name: 'main_primary_owner' })
  mainPrimaryOwner!: IUnitMainBuyer | null;
  @Expose({ name: 'main_owner' })
  mainOwner!: boolean;
  @Expose({ name: 'registration_link' })
  registrationLink!: string;

  activeTabTable = false;
  selected = false;
}

export class BuyerUpdateModel {
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'email' })
  email!: string;
  @Expose({ name: 'phone' })
  phone!: string;
  @Expose({ name: 'units' })
  @Type(() => BuyerUnitsUpdateModel)
  units_users_attributes!: BuyerUnitsUpdateModel[] | [];
}

export class BuyerUnitsUpdateModel {
  @Expose({ name: 'unitId' })
  unit_id!: number;
  @Expose({ name: 'userRole' })
  user_role!: string;
  @Expose({ name: 'mainOwner' })
  main_owner!: boolean;
}

export class MemberModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'email' })
  email!: string;
  @Expose({ name: 'phone' })
  phone!: string;
  @Expose({ name: 'role' })
  role!: string;
  @Expose({ name: 'role_v2' })
  roleV2!: string;
  @Expose({ name: 'user_type' })
  userType!: string;
  @Expose({ name: 'system_role' })
  systemRole!: string;
  @Expose({ name: 'admins_project_id' })
  adminsProjectId!: number;
  @Expose({ name: 'registered' })
  registered!: boolean;
  @Expose({ name: 'invite_sent' })
  inviteSent!: boolean;
  @Expose({ name: 'last_activity' })
  lastActivity!: string;
  @Expose({ name: 'joined_at' })
  joinedAt!: string;
  @Expose({ name: 'project_accesses_attributes' })
  @Type(() => AccessesAttributesModel)
  accessesAttributes!: AccessesAttributesModel[];
  @Expose({ name: 'as_a_guest' })
  guest!: boolean;
  @Expose({ name: 'company_name' })
  company!: string | null;
  @Expose({ name: 'admins_firm_id' })
  adminsFirmId!: number;
  @Expose({ name: 'invite_link' })
  inviteLink!: string;
}

export class AccessesAttributesModel {
  @Expose({ name: 'acc_tag' })
  accTag!: string;
}

export interface ICreateMember {
  name?: string;
  email?: string;
  phone?: string;
  invite_is_sent?: boolean;
  as_a_guest?: boolean;
  admin_firm_attributes?: IAdminFirmAttributes;
  admins_projects_attributes: IAdminsProjectsAttributes[];
}

export interface IAdminFirmAttributes {
  company_name: string;
}

export interface IAdminsProjectsAttributes {
  role_v2: string;
  user_type: 'project_member';
  project_accesses_attributes: IProjectAccessesAttributes[];
}

export interface IProjectAccessesAttributes {
  acc_tag: string;
}

export class CreateUserModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'email' })
  email!: string;
  @Expose({ name: 'phone' })
  phone!: string;
  @Expose({ name: 'role' })
  role!: string;
  @Expose({ name: 'last_activity' })
  lastActivity!: string | null;
  @Expose({ name: 'units' })
  units!: IBuyerUnit[];
  @Expose({ name: 'registered' })
  registered!: boolean;
}

export interface ICreateBuyerInterface {
  name: string;
  email: string;
  phone: string;
  unit: Partial<ICreateBuyerUnitsInterface>[];
}

export interface ICreateBuyerUnitsInterface {
  id: number;
  role: string;
  invite: boolean;
  buyingCompany: boolean;
  company: ICreateBuyerComopanyInterface | null;
  mainOwner: boolean;
}

export interface ICreateBuyerComopanyInterface {
  title?: string;
  address?: string;
  longitude?: number;
  latitude?: number;
}

export interface IUserRequest {
  value: PersonModel[];
  filter: IUserFilter[];
}

export interface IUserFilter {
  name: string;
  count: number;
}

export class CreateBuyerModel {
  @Expose({ name: 'name' })
  name: string;
  @Expose({ name: 'email' })
  email: string;
  @Expose({ name: 'phone' })
  phone: string;
  @Expose({ name: 'unit' })
  @Type(() => CreateBuyerUnitsModel)
  units_users_attributes: CreateBuyerUnitsModel[];

  constructor() {
    this.name = '';
    this.email = '';
    this.phone = '';
    this.units_users_attributes = [];
  }
}

export class CreateBuyerUnitsModel {
  @Expose({ name: 'id' })
  unit_id!: number;
  @Expose({ name: 'role' })
  user_role!: string;
  @Expose({ name: 'invite' })
  invite_is_sent!: boolean;
  @Expose({ name: 'buyingCompany' })
  buying_through_the_company!: boolean;
  @Expose({ name: 'company' })
  @Type(() => BuyerCompanyModel)
  company?: BuyerCompanyModel | null;

  @Expose({ name: 'mainOwner' })
  main_owner!: boolean;
}

export class BuyerCompanyModel {
  @Expose({ name: 'title' })
  title!: string | null;
  @Expose({ name: 'address' })
  address!: string | null;
  @Expose({ name: 'longitude' })
  longitude!: number | null;
  @Expose({ name: 'latitude' })
  latitude!: number | null;
  @Expose({ name: 'org_number' })
  orgNumber!: string | null;
}

export class PersonModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'person_id' })
  personId!: number;
  @Expose({ name: 'person_type' })
  personType!: string;
  @Expose({ name: 'user_name' })
  name!: string;
  @Expose({ name: 'user_email' })
  email!: string;
  @Expose({ name: 'user_phone' })
  phone!: string;
  @Expose({ name: 'user_role' })
  role!: string;
  @Expose({ name: 'user_status' })
  status!: string;
  @Expose({ name: 'user_type' })
  type!: string;
  @Expose({ name: 'company' })
  company!: string | null;
  @Expose({ name: 'unit_names' })
  unitNames!: string[];
  @Expose({ name: 'last_activity' })
  lastActivity!: string;
  @Expose({ name: 'invite_sent' })
  inviteSent!: boolean;
  @Expose({ name: 'invite_sent_at' })
  inviteSentAt!: boolean | null;

  selected = false;
  originalName?: string;
}

export interface IMemberUpdateTable {
  role?: string;
  people?: BuyerModel | MemberModel;
  unitName?: string;
  unitRename?: { oldName: string; newName: string };
  reloadTable?: boolean;
}
