import { Expose, Type } from 'class-transformer';

export interface IContractor {
  created_at: string;
  id: number;
  title: string;
  updated_at: string;
}

export class ContractorModel {
  @Expose({ name: 'created_at' })
  createdAt: string;
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'title' })
  title: string;
  @Expose({ name: 'updated_at' })
  updatedAt: string;

  constructor() {
    this.createdAt = '';
    this.id = 0;
    this.title = '';
    this.updatedAt = '';
  }
}

export class FirmContractorModel {
  @Expose({ name: 'main_admin_id' })
  adminId!: number;
  @Expose({ name: 'main_admin_name' })
  name!: string;
  @Expose({ name: 'main_admin_email' })
  email!: string;
  @Expose({ name: 'company_id' })
  companyId!: number;
  @Expose({ name: 'company_name' })
  companyName!: string;

  id?: string | number;
  personId?: number;
  originalName!: string;
}

export enum EContractorTypeForReclamationsModel {
  ContractorCompany = 'contractor_company',
  Contractor = 'contractor',
}

export class ContractorForReclamationsModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'reclamations_count' })
  count!: number;

  type = EContractorTypeForReclamationsModel.Contractor;
  company?: string;
}

export class ContractorCompanyForReclamationsModel extends ContractorForReclamationsModel {
  @Expose({ name: 'contractors' })
  @Type(() => ContractorForReclamationsModel)
  contractors?: ContractorForReclamationsModel[];

  override type = EContractorTypeForReclamationsModel.ContractorCompany;
}

export type ContractorAndCompanyListForReclamations = (
  | ContractorCompanyForReclamationsModel
  | ContractorForReclamationsModel
)[];
