import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

export enum EDoAddNew { Message = 1, Reclamation, ChangeRequest }

@Injectable({
  providedIn: 'root'
})
export class DoAddNewService {
  private _doAddNew$ = new BehaviorSubject<EDoAddNew | null>(null);
  doAddNew$ = this._doAddNew$.asObservable();

  constructor(private router: Router) { }

  doAddNew(type: EDoAddNew, url: string): void {
    void this.router.navigateByUrl(url);
    queueMicrotask(() => {
      this._doAddNew$.next(type);
    });
  }

  reset(): void {
    this._doAddNew$.next(null);
  }
}
