import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { WindowResizeDirective } from './detect-zoom.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [WindowResizeDirective],
  exports: [WindowResizeDirective],
})
export class DetectZoomModule {}
