import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { NgbCalendar, NgbDate, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of } from 'rxjs';
import {delay, take} from 'rxjs/operators';

import { DateAdapterService } from '../../services/date-adapter.service';

@UntilDestroy()
@Component({
  selector: 'atl-input-date-range',
  templateUrl: './input-date-range.component.html',
  styleUrls: ['./input-date-range.component.scss'],
})
export class InputDateRangeComponent {
  @Input() mask = 'd0.M0.0000';
  @Input() navigation: 'select' | 'arrows' | 'none' = 'arrows';
  @Input() outsideDays: 'visible' | 'hidden' | 'collapsed' = 'visible';
  @Input() set updateDate(date: any | null) {
    if (!date) {
      this.clearAll();
    } else {
      this.updateDateInput(date);
    }
  }
  @Input() showEndDate = true;
  @Input() placeholder = 'dd.mm.yyyy';

  @Output() private readonly selectDate = new EventEmitter<{ from: string; to: string | null } | null>();
  @Output() private readonly resetEvent = new EventEmitter();

  @ViewChild('dp') datePicker!: NgbDatepicker;
  @ViewChild('toInputRef') toInputRef!: ElementRef;

  public activeInput = false;

  public hoveredDate: NgbDate | null = null;

  public from = '';
  public to = '';

  public fromInput = '';
  public toInput = '';

  public fromDate: NgbDateStruct | null = null;
  public toDate: NgbDateStruct | null = null;

  public isEndDate = false;
  public currentDate!: NgbDate;
  public isValidEnteredDate = false;

  constructor(
    private readonly dateAdapter: DateAdapterService,
    private readonly calendar: NgbCalendar,
  ) {
    this.currentDate = calendar.getToday();
  }

  focus(): void {
    this.activeInput = true;
  }

  focusToInput(): void {
    if (this.toDate || (this.showEndDate && !this.isEndDate)) return;
    of(true)
      .pipe(untilDestroyed(this), delay(200))
      .subscribe(() => {
        this.toInputRef.nativeElement.focus();
      });
  }

  blur(): void {
    this.activeInput = false;
  }

  toggleEndDate(checked: boolean): void {
    this.isEndDate = checked;
    if (!this.isEndDate) {
      this.toDate = null;
      this.toInput = '';
      this.datePicker.writeValue(null);
    }
  }

  onDateSelection(date: NgbDate): void {
    if (!this.fromDate && !this.toDate) {
      this.setFromDate(date);
    } else if ((this.isEndDate || !this.showEndDate) && this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.setToDate(date);
    } else {
      this.clearInputTo();
      this.setFromDate(date);
    }
    this.isValidEnteredDate = true;
  }

  isHovered(date: NgbDate): boolean | null {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate): boolean | null {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate): boolean | null {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  inputFrom(e: Event): void {
    const target = (e.target as HTMLInputElement).value;
    if (!target.length) {
      this.clearInputFrom();

      this.isValidEnteredDate = false;
      return;
    }
    const value = this.dateTransform(target);
    const toDateObj = this.toDate ? new NgbDate(this.toDate?.year, this.toDate?.month, this.toDate?.day) : undefined;
    if ((this.currentDate.after(value) || this.currentDate.equals(value)) && (!toDateObj || toDateObj.after(value))) {
      this.setFromDate(new NgbDate(value.year, value.month, value.day));
      this.datePicker.focusDate(this.fromDate);
      this.isValidEnteredDate = true;
      this.focusToInput();
    } else if (this.currentDate.before(value)) {
      this.setFromDate(this.currentDate);
      this.datePicker.focusDate(this.fromDate);
      this.isValidEnteredDate = true;
      this.clearInputTo();
    } else {
      this.isValidEnteredDate = false;
    }
  }

  inputTo(e: Event): void {
    const target = (e.target as HTMLInputElement).value;
    if (!target.length) {
      this.isValidEnteredDate = true;
      return;
    }
    const value = this.dateTransform(target);
    const fromDateObj = this.fromDate
      ? new NgbDate(this.fromDate?.year, this.fromDate?.month, this.fromDate?.day)
      : undefined;
    if (
      (this.currentDate.after(value) || this.currentDate.equals(value)) &&
      (!fromDateObj || fromDateObj.before(value))
    ) {
      this.setToDate(new NgbDate(value.year, value.month, value.day));
      this.datePicker.focusDate(this.toDate);
      this.isValidEnteredDate = true;
    } else if (this.currentDate.before(value)) {
      if (this.fromDate && !this.currentDate.equals(this.fromDate)) {
        this.setToDate(this.currentDate);
        this.datePicker.focusDate(this.toDate);
        this.isValidEnteredDate = true;
      } else {
        this.clearInputTo();
        this.isValidEnteredDate = true;
      }
    } else {
      this.isValidEnteredDate = false;
      if (fromDateObj?.after(value)) {
        this.setToDate(new NgbDate(value.year, value.month, value.day));
        this.clearInputTo();
        this.isValidEnteredDate = true;
      }
    }
  }

  dateTransform(value: string): NgbDateStruct {
    const transform = this.dateAdapter.convertToFormat(value, 'DD.MM.YYYY', 'YYYY-MM-DD');
    return this.dateAdapter.transformDate(transform, true) as NgbDateStruct;
  }

  setFromDate(date: NgbDate): void {
    this.fromDate = date;
    this.fromInput = this.dateAdapter.toModel(this.fromDate);
    this.from = this.dateAdapter.toModelYYYYMMDD(this.fromDate);
  }

  setToDate(date: NgbDate): void {
    this.toDate = date;
    this.toInput = this.dateAdapter.toModel(this.toDate);
    this.to = this.dateAdapter.toModelYYYYMMDD(this.toDate);
  }

  clearInputFrom(): void {
    this.fromInput = '';
    this.from = '';
    this.fromDate = null;
  }

  clearInputTo(): void {
    this.toDate = null;
    this.toInput = '';
    if (this.toInputRef) this.toInputRef.nativeElement.value = '';
  }

  clearAll(skipEmit = false): void {
    this.fromInput = '';
    this.toInput = '';
    this.fromDate = null;
    this.toDate = null;
    if (!skipEmit) this.selectDate.emit(null);
    this.resetEvent.emit();
  }

  setDate(): void {
    if (this.fromDate) {
      const from = this.dateAdapter.toModelYYYYMMDD(this.fromDate);
      const to =
        ((this.showEndDate && this.isEndDate) || !this.showEndDate) && this.toDate
          ? this.dateAdapter.toModelYYYYMMDD(this.toDate)
          : null;

      this.selectDate.emit({ from, to });
    }
  }

  get disabled(): boolean {
    return !(this.isEndDate && this.showEndDate ? this.fromDate && this.toDate : this.fromDate);
  }

  private updateDateInput(date: {from: string, to: string}): void {
    const from = this.dateAdapter.transformDate(date.from, true) as NgbDateStruct;
    const to = this.dateAdapter.transformDate(date.to, true) as NgbDateStruct;

    of(null).pipe(take(1), delay(100)).subscribe(() => {
      this.setFromDate(new NgbDate(from!.year, from!.month, from!.day));
      this.setToDate(new NgbDate(to!.year, to!.month, to!.day));
      if (date?.from && date?.to) {
        this.isEndDate = true;
      }
    });
  }
}
