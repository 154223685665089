<div
  #dropdown
  ngbDropdown
  [autoClose]="'outside'"
  [display]="'dynamic'"
  class="availability-dropdown"
  [placement]="['top-right', 'bottom-right']"
>
  <button
    type="button"
    ngbDropdownToggle
    id="limits-dropdown-{{index}}"
    class="availability-dropdown__button"
    [class.disabled]="disabled"
  >
    <span class="d-block ellipsis">
      {{selectedValue}}
    </span>
    <img src="assets/arrow_down_option_store.svg" alt="arrow">
  </button>
  <div
    ngbDropdownMenu
    [attr.aria-labelledby]="'limits-dropdown-'+index"
  >
    <div
      class="availability-dropdown__dropdown"
      [style.width]="dropdown.clientWidth + 'px'"
    >
      <ng-container *ngTemplateOutlet="multi"></ng-container>
    </div>
  </div>
</div>


<ng-template #multi>
  <div class="availability-dropdown__multi">
    <div class="no-limit" [class.select]="!limitCount" (click)="selectLimit(false)">
      <span>{{'Shared.Entity.No_limit' | translate}}</span>
      <img src="/assets/multiple_select_checkbox.svg" alt="check">
    </div>
    <div class="limit" [class.select]="limitCount" (click)="selectLimit(true)">
      <span>
        {{'Shared.Entity.Limit' | translate}}
        <img src="/assets/multiple_select_checkbox.svg" alt="check">
      </span>
      <input
        #limitInput
        (input)="inputCount(limitInput.value)"
        (keydown.enter)="closeInput()"
        class="availability-dropdown__input"
        type="text"
        [placeholder]="'Shared.Entity.Set_limit' | translate"
        mask="separator.0"
        separatorLimit="1000000"
        thousandSeparator=" "
        [value]="limitCount"
      />
    </div>
  </div>
</ng-template>


