import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DateTimePipe } from './date-time.pipe';

@NgModule({
  declarations: [DateTimePipe],
  exports: [DateTimePipe],
  imports: [CommonModule],
})
export class DateTimePipeModule {}
