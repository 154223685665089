import { Pipe, PipeTransform } from '@angular/core';
import { EWishlistStatus,RuleDependableProduct } from '@atlas-workspace/shared/models';

@Pipe({
  name: 'wishlistFilter',
  standalone: true,
})
export class WishlistFilterPipe implements PipeTransform {
  transform(
    dependableProducts: RuleDependableProduct[],
    wishlistStatus = EWishlistStatus.NotSelected
  ): RuleDependableProduct[] {
    if (!Array.isArray(dependableProducts)) {
      return [];
    }

    return dependableProducts.filter((item) => item.wishlistItemStatus !== wishlistStatus);
  }
}
