import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { InputComponent } from '../input/input.component';

const keyBackspace = 'Backspace';
const nonBrakingKeys: Array<string> = ['ArrowLeft', 'ArrowRight', 'Tab'];
const enterKey = 'Enter';
// TODO will be removed
@Component({
  selector: 'atl-prefixed-suffixed-input',
  templateUrl: './prefixed-suffixed-input.component.html',
  styleUrls: ['../input/input.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PrefixedSuffixedInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PrefixedSuffixedInputComponent extends InputComponent {
  focusEvent(contentEditableRef: HTMLDivElement): void {
    this.setEndOfContenteditable(contentEditableRef);
    contentEditableRef.style.zIndex = '1';
  }

  changeValue(value: string | number, contentEditableRef?: HTMLDivElement): void {
    super.changeValue(value);
    if (contentEditableRef) {
      setTimeout(() => {
        this.setEndOfContenteditable(contentEditableRef);
      }, 0);
    }
  }

  keydown(e: KeyboardEvent): void {
    if (this.type !== 'number') {
      return;
    }

    const currentKey: string = e.key,
      existingValue = (e.currentTarget as HTMLDivElement).innerText || (e.currentTarget as HTMLInputElement).value;

    let valuesBeforeAndAfterPoint: Array<string> | undefined,
      lengthIntegerReached,
      lengthFractionsReached = true;

    if (existingValue?.includes('.')) {
      valuesBeforeAndAfterPoint = existingValue.split('.');
      lengthIntegerReached = valuesBeforeAndAfterPoint[0].length === this.maxIntegerDigits;
      lengthFractionsReached = valuesBeforeAndAfterPoint[1].length === this.maxFractionDigits;
    } else {
      lengthIntegerReached = existingValue?.length === this.maxIntegerDigits;
    }

    switch (currentKey) {
      case keyBackspace: {
        break;
      }
      case '.': {
        const pointCouldBeAdded = this.maxFractionDigits && !existingValue?.includes('.');
        if (!pointCouldBeAdded) {
          e.preventDefault();
        }
        break;
      }
      case ',': {
        const pointCouldBeAdded = this.maxFractionDigits && !existingValue?.includes('.');
        if (!pointCouldBeAdded) {
          e.preventDefault();
        }
        break;
      }
      default: {
        let validKey = !isNaN(+currentKey);
        validKey ||= nonBrakingKeys.some((key) => key === currentKey);
        const inputCouldBeContinued =
          (valuesBeforeAndAfterPoint && !lengthFractionsReached) ||
          (!valuesBeforeAndAfterPoint && !lengthIntegerReached);
        if (!(validKey && inputCouldBeContinued)) {
          e.preventDefault();
        }
        if (currentKey === enterKey) {
          // TODO handler for Enter key will be implemented later
          // const form = this.elRef.nativeElement.parentNode;
          // const curIndex = (e.currentTarget as HTMLDivElement).tabIndex;
          // const focusable = form.find('input,a,select,button,textarea').filter(':visible');
          // const next = focusable.eq(focusable.index(this.elRef.nativeElement.tabIndex)+1);
          // next.focus();
        }
        break;
      }
    }
  }

  setEndOfContenteditable(contentEditableElement: HTMLDivElement): void {
    contentEditableElement.focus();
    document.execCommand('selectAll', false, undefined);
    const selection = document.getSelection();
    if (selection) {
      selection.collapseToEnd();
    }
  }
}
