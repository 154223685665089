import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'redDatePipe',
  standalone: true
})
export class WarningDatePipe implements PipeTransform {

  transform(dueDate: string): string {
    const now = dayjs();
    const due = dayjs(dueDate);
    const daysLeft = due.diff(now, 'day', true);
    const roundedDaysLeft = Math.ceil(daysLeft);

    if (roundedDaysLeft <= 7) {
      return 'var(--color-red-830)';
    }

    return '';
  }
}
