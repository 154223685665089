import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CustomIconComponent } from "../custom-icon/custom-icon.component";
import { TableSortComponent } from './table-sort.component';

@NgModule({
  imports: [CommonModule, CustomIconComponent],
  declarations: [TableSortComponent],
  exports: [TableSortComponent],
})
export class TableSortModule {}
