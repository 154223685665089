import { Expose } from 'class-transformer';

export interface IAdminMember {
  id: number;
  name: string;
  email: string;
  role: string;
  registered: boolean;
  last_activity: string;
  joined_at: string;
}

export class AdminMember {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'email' })
  email!: string;
  @Expose({ name: 'role' })
  role!: string;
  @Expose({ name: 'registered' })
  registered!: boolean;
  @Expose({ name: 'last_activity' })
  lastActivity!: string;
  @Expose({ name: 'joined_at' })
  joinedAt!: string;
}
