import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouterUtil {

  constructor(private location: Location, private router: Router) {}

  public removeQueryParamsWithoutEmittingEvents(): void {
    const currentUrl = this.router.url.split('?')[0];
    this.location.replaceState(currentUrl, '', {
      queryParams: {}
    });
  }
}
