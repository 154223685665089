import { SafeHtml } from '@angular/platform-browser';
import {Expose, Transform, Type} from 'class-transformer';

import { FileModel } from './file.model';
import { ImageModel } from './image.model';
import { IUnitShort, LayoutTypesModel } from './unit.model';
import { EUserType } from './user-model';

export interface IUpdate {
  id: number;
  name: string;
  description: string;
  main_admin: {
    id: number;
    email: string;
    username: string;
  };
  user: {
    id: number;
    email: string;
    name: string;
  };
}

export interface IFormUpdate {
  name: string;
  type: string;
  text: string;
  published: string;
  document: (FileModel | ImageModel | File)[];
}

export interface IPutUpdateProps {
  projectId: number;
  updateId: number;
  formControls: IFormUpdate;
  draft: boolean | undefined;
  unitIds: number[];
  addTypes: number[];
  removeTypes: number[];
  sendSms: boolean;
  removeFiles?: (FileModel | ImageModel | File)[];
}

export enum EModalState {
  RemoveTemplate = 'removed_template',
  CreatedTemplate = 'created_template',
  EditTemplate = 'edit_template',
}

export interface IModalTemplateUpdate {
  state: EModalState;
  updatedTemplate?: TemplateUpdateModel;
}

export interface IModalUpdate {
  state: EModalState;
  updatedTemplate?: UpdateModel;
}

export class ShortUpdateModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'description' })
  description!: string | SafeHtml | null;
  @Expose({ name: 'main_admin' })
  @Type(() => CreatorModel)
  creator: CreatorModel | undefined;
  @Expose({ name: 'user' })
  @Type(() => RecipientModel)
  recipient: RecipientModel | undefined;
  @Expose({ name: 'image' })
  @Type(() => ImageModel)
  image!: ImageModel;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'last_edit_at' })
  editedAt!: string;
  @Expose({ name: 'draft' })
  draft!: boolean;
  @Expose({ name: 'user_type' })
  userType!: EUserType;
  @Expose({ name: 'published_at' })
  publishedUTC!: string;
  @Expose({ name: 'published_at_in_timezone' })
  published!: string;
  @Expose({ name: 'units_ids' })
  unitsIds!: number[];
  @Expose({ name: 'units' })
  units!: IUnitShort[];
  @Expose({ name: 'layout_types' })
  layoutTypes!: LayoutTypesModel[];
  @Expose({ name: 'layout_types_ids' })
  layoutTypesIds!: number[];
  @Expose({ name: 'preview_url' })
  previewUrl!: boolean | null;
  @Expose({ name: 'preview_in_progress' })
  previewInProgress!: boolean;
  @Expose({ name: 'read_by_unit_ids' })
  @Transform(({value}) => {
    return value || [];
  })
  viewed!: number[];

  existPreview = false;
  selected = false;
}

export class UpdateModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'name' })
  name: string;
  @Expose({ name: 'description' })
  description: string | SafeHtml | null;
  @Expose({ name: 'main_admin' })
  @Type(() => CreatorModel)
  creator: CreatorModel | undefined;
  @Expose({ name: 'user' })
  @Type(() => RecipientModel)
  recipient: RecipientModel | undefined;
  @Expose({ name: 'images' })
  @Type(() => ImageModel)
  images!: ImageModel[];
  @Expose({ name: 'file_resources' })
  @Type(() => FileModel)
  fileResources: FileModel[] | undefined;
  @Expose({ name: 'created_at' })
  createdAt: string;
  @Expose({ name: 'last_edit_at' })
  editedAt!: string;
  @Expose({ name: 'published_at' })
  publishedUTC!: string;
  @Expose({ name: 'published_at_in_timezone' })
  published!: string;
  @Expose({ name: 'draft' })
  draft!: boolean;
  @Expose({ name: 'user_type' })
  userType!: EUserType;
  @Expose({ name: 'units' })
  units!: IUnitShort[];
  @Expose({ name: 'units_ids' })
  unitsIds!: number[];
  @Expose({ name: 'layout_types' })
  layoutTypes!: LayoutTypesModel[];
  @Expose({ name: 'layout_types_ids' })
  layoutTypesIds!: number[];
  @Expose({ name: 'preview_url' })
  previewUrl!: boolean | null;
  @Expose({ name: 'preview_in_progress' })
  previewInProgress!: boolean;
  @Expose({ name: 'read_by_unit_ids' })
  @Transform(({value}) => {
    return value || [];
  })
  viewed!: number[];

  previewImage!: ImageModel | null;

  show = false;
  selected: boolean | undefined = false;
  template = false;

  constructor() {
    this.id = 0;
    this.name = '';
    this.description = '';
    this.createdAt = '';
  }
}

class CreatorModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'email' })
  email!: string;
  @Expose({ name: 'name' })
  name!: string;

  iconColor!: string;
  iconBorderColor!: string;
}

class RecipientModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'email' })
  email: string;
  @Expose({ name: 'name' })
  name: string;

  constructor() {
    this.id = 0;
    this.email = '';
    this.name = '';
  }
}

export class TemplateUpdateModel extends UpdateModel {
  @Expose({ name: 'selected' })
  selected: boolean | undefined = false;
  borderColor?: string;
  color?: string;
}
