import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { VerticalAlignDirective } from './vertical-align.directive';



@NgModule({
  imports: [CommonModule],
  declarations: [VerticalAlignDirective],
  exports: [VerticalAlignDirective],
})
export class VerticalAlignDirectiveModule {}
