import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ELocalStorageKeys, EUserAuthHeaders, IAuthHeaders } from '@atlas-workspace/shared/models';
import { AuthAdminService, LocalStorageService, ToasterService } from '@atlas-workspace/shared/service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap } from 'rxjs/operators';

@Injectable()
export class AdminErrorHandlerInterceptor implements HttpInterceptor {
  private readonly jwtExpiredMsg = 'JWT::ExpiredSignature: Signature has expired';
  private isRefreshing = false;
  private readonly hasRefreshTokenSubject$: BehaviorSubject<boolean | null> = new BehaviorSubject<any>(null);

  constructor(
    private readonly toasterService: ToasterService,
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService,
    private readonly authAdminService: AuthAdminService,
    private modalService: NgbModal
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          if (error.error?.error?.message === this.jwtExpiredMsg) {
            const headers: IAuthHeaders | null = this.localStorageService.get(ELocalStorageKeys.AuthHeaders);
            const refreshToken = headers?.refreshToken;
            if (!refreshToken || !headers) {
              this.logoutUser();
              return throwError(error);
            }
            if (refreshToken && !this.isRefreshing) {
              this.isRefreshing = true;
              this.hasRefreshTokenSubject$.next(null);

              return this.authAdminService.refreshAdminToken(headers.client, refreshToken).pipe(
                switchMap(() => {
                  this.isRefreshing = false;
                  this.hasRefreshTokenSubject$.next(true);
                  return next.handle(this.addToken(request));
                }),
                catchError((refreshError) => {
                  this.isRefreshing = false;
                  this.logoutUser();
                  return throwError(refreshError);
                }),
                finalize(() => {
                  this.isRefreshing = false;
                })
              );
            } else if (this.isRefreshing) {
              return this.hasRefreshTokenSubject$.pipe(
                filter((token) => token !== null),
                switchMap(() => next.handle(this.addToken(request)))
              );
            }

            return throwError(error);
          }

          this.logoutUser();
        } else if (error.status === 404) {
          void this.router.navigate(['/']);
        }

        if (request.headers.get('X-Skip-Global-Interceptor') === 'true') {
          return throwError(error);
        }

        const message = error.error?.error?.message ?? '';
        this.toasterService.openErrorToast(message);
        return throwError(error);
      })
    );
  }

  private addToken(request: HttpRequest<any>): HttpRequest<any> {
    const authHeaders: IAuthHeaders | null = this.localStorageService.get<IAuthHeaders>(ELocalStorageKeys.AuthHeaders);
    return request.clone({
      headers: request.headers
        .set(EUserAuthHeaders.AccessToken, authHeaders?.token as string)
        .set(EUserAuthHeaders.Uid, authHeaders?.uid as string)
        .set(EUserAuthHeaders.Client, authHeaders?.client as string),
    });
  }

  private logoutUser(): void {
    this.localStorageService.clear();
    this.modalService.dismissAll();
    void this.router.navigate(['/auth/sign-in']);
  }
}
