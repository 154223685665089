import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef,HostListener,Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'atl-checkbox',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './checkbox.component.html',
  styleUrls: [ './checkbox.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() set value(_value: unknown) {
    this.innerValue = Boolean(_value);
  };
  @Input() disabled = false;
  @Input() checkedPartially = false;
  @Input() size = 16;
  @Input() checkboxLabel?: string = undefined;

  @HostListener('click') onclick (): void {
    this.toggle();
  }
  
  @Output() changed = new EventEmitter<boolean>();
  
  public innerValue!: boolean;

  private onChange = (value: boolean) => null;
  private onTouched = () => null;

  constructor(private cd: ChangeDetectorRef) {}
  
  writeValue(value: boolean): void {
    this.innerValue = value;
    this.cd.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }

  setValue(newValue: boolean): void {
    if (!this.disabled && this.innerValue !== newValue) {
      this.innerValue = newValue;
      this.onChange(newValue);
      this.changed.emit(newValue);
    }
  }

  public toggle(): void {
    this.setValue(!this.innerValue);
  }
}
