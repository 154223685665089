import { Expose } from 'class-transformer';

import { EPriceSystem } from './firm.model';

export interface IAdminCompany {
  id: number;
  title: string;
  country: string;
  address: string;
  currency: string;
  longitude: string;
  latitude: string;
}

export class AdminCompanyModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'title' })
  title: string;
  @Expose({ name: 'country' })
  country: string;
  @Expose({ name: 'address' })
  address: string;
  @Expose({ name: 'currency' })
  currency: string;
  @Expose({ name: 'longitude' })
  longitude: string;
  @Expose({ name: 'latitude' })
  latitude: string;

  @Expose({ name: 'price_provider' })
  priceProvider!: EPriceSystem;
  @Expose({ name: 'markup' })
  markup!: string | null;
  @Expose({ name: 'vat' })
  vat!: string | null;

  constructor() {
    this.id = 0;
    this.title = '';
    this.country = '';
    this.address = '';
    this.currency = '';
    this.longitude = '';
    this.latitude = '';
  }
}
