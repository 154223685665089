<div class="users-viewer" atlClickOutside (clickOutside)="close()">
  <div *ngIf="!hoverOption" class="users-viewer__selected" (click)="toggle()">
    <atl-firm-icon [model]="_users[0]" [isUser]="true" [nameKey]="'name'" [isMonochrome]="true"></atl-firm-icon>
    <span class="fz-14 lh-24 text-black-960 ml-4">{{ _users[0].name }}</span>
  </div>

  <div class="users-viewer__dropdown" *ngIf="expanded">
    <div class="users-viewer__row">
      <atl-firm-icon
        *ngIf="selectedUser"
        [model]="selectedUser"
        [isUser]="true"
        [nameKey]="'name'"
        [isMonochrome]="true"
      ></atl-firm-icon>
    </div>
    <div class="users-viewer__row body">
      <div class="users-viewer__item ellipsis" (click)="copyToClipBoardData(selectedUser.name, $event)">
        <span class="fz-14 lh-24 fw-500 text-black-960">{{ selectedUser?.name }}</span>
        <img src="/assets/copy-gray.svg" alt="copy" />
      </div>
      <div class="users-viewer__item role">
        <span class="fz-14 lh-24 text-black-960">{{ selectedUser?.role }}</span>
      </div>
      <div class="users-viewer__item ellipsis" (click)="copyToClipBoardData(selectedUser.phone, $event)">
        <span class="fz-14 lh-24 text-black-960">{{ selectedUser?.phone }}</span>
        <img src="/assets/copy-gray.svg" alt="copy" />
      </div>
      <div class="users-viewer__item ellipsis" (click)="copyToClipBoardData(selectedUser.email, $event)">
        <span class="fz-14 lh-24 text-black-960">{{ selectedUser?.email }}</span>
        <img src="/assets/copy-gray.svg" alt="copy" />
      </div>
    </div>

    <div *ngIf="_users?.length > 1" class="users-viewer__navigation">
      <div class="arrow arrow-left" (click)="prev()">
        <img src="/assets/arrow-gray-sm.svg" alt="prev" />
      </div>
      <div class="dots-wrapper">
        <div class="dots-container">
          <div *ngFor="let item of _users; let i = index" class="dot" [class.active]="selectedIndex === i"></div>
        </div>
      </div>
      <div class="arrow arrow-right" (click)="next()">
        <img src="/assets/arrow-gray-sm.svg" alt="next" />
      </div>
    </div>
  </div>
</div>