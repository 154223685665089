import { Pipe, PipeTransform } from '@angular/core';
import { fileUploaderImageTypes } from '@atlas-workspace/shared/models';

@Pipe({
  name: 'isImage',
  standalone: true,
})
export class IsImagePipe implements PipeTransform {
  transform(name: string): boolean {
    const arr = name?.split('.');
    if (!arr) {
      return false;
    }

    return fileUploaderImageTypes.includes(arr[arr.length - 1].toLowerCase());
  }
}
