export interface ITimePassedConditionals {
  [seconds: string]: {
    min: number;
    max: number;
    single: string;
    type: any;
  };
  minutes: {
    min: number;
    max: number;
    single: string;
    type: any;
  };
  hours: {
    min: number;
    max: number;
    single: string;
    type: any;
  };
  days: {
    min: number;
    max: number;
    single: string;
    type: any;
  };
  week: {
    min: number;
    max: number;
    single: string;
    type: any;
  };
  month: {
    min: number;
    max: number;
    single: string;
    type: any;
  };
  years: {
    min: number;
    max: number;
    single: string;
    type: any;
  };
}

export enum EException {
  Seconds = 'seconds',
  Years = 'years',
}
