import { AbstractControl, ValidationErrors } from '@angular/forms';

export class WhitespaceValidator {
  static noWhiteSpace(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      return control.value.trim().length === 0 ? { whitespace: true } : null;
    }
    return null;
  }

  static noWhiteSpaceV2(control: AbstractControl): ValidationErrors | null {
    if (control.value) {
      const regex = /^\s+$/;

      return regex.test(control.value) ? { whitespace: true } : null;
    }
    return null;
  }
}
