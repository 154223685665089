import { Expose, Transform, Type } from 'class-transformer';
import dayjs from 'dayjs';

import { IUnitItem } from '../interface/chat-filter.interface';
import { IKonvaObject } from '../interface/konva.interface';
import { AdminModel } from './admin.model';
import { FileModel } from './file.model';
import { ImageModel } from './image.model';
import { ITablePagination } from './table-pagination.interface';
import { IThreadState } from './thread.model';
import { FloorModel, UnitFloorModel, UnitUserModel } from './unit.model';
import { IUser } from './user-model';
import { TermsConditionsDataModel } from './terms-conditions.model';

export enum EChangeRequestTab {
  General = 'Shared.Button.Details',
  Internal = 'Reclamation.Tab.Discussion',
  Offer = 'Shared.Title.Offer',
  Customer = 'Title.Customer_communication',
  ClientCustomer = 'Shared.Title.Messages',
  Activities = 'Shared.Entity.Activities',
  Notes = 'Shared.Title.Internal_notes',
}

export interface IChangeRequestCount {
  [Key: string]: { count: number; localizedStatus: string };
}

export enum EChangeRequestStatus {
  New = 'pending',
  InProgress = 'in_progress',
  Offered = 'offer_sent',
  OfferExpired = 'offer_expired',
  OfferApproved = 'offer_approved',
  OfferDeclined = 'offer_declined',
  OfferCanceled = 'offer_cancelled',
  Approved = 'approved',
  RequestDeclined = 'request_declined',
}

export const statusClasses = {
  [EChangeRequestStatus.New]: 'status__new',
  [EChangeRequestStatus.InProgress]: 'status__in-progress',
  [EChangeRequestStatus.Approved]: 'status__in-progress',
  [EChangeRequestStatus.RequestDeclined]: 'status__request-declined',
  [EChangeRequestStatus.Offered]: 'status__offered',
  [EChangeRequestStatus.OfferExpired]: 'status__offer-expired',
  [EChangeRequestStatus.OfferApproved]: 'status__offer-approved',
  [EChangeRequestStatus.OfferDeclined]: 'status__offer-declined',
  [EChangeRequestStatus.OfferCanceled]: 'status__offer-canceled',
};

export enum EChangeRequestStatusTitle {
  New = 'Shared.Change_request.Status.New',
  Approved = 'Shared.Change_request.Status.Offer_accepted',
  Declined = 'Shared.Change_request.Status.Declined',
  InProgress = 'Shared.Change_request.Status.In_progress',
  Offered = 'Shared.Change_request.Offer_sent',
  OfferExpired = 'Shared.Change_request.Offer_expired',
  OfferApproved = 'Shared.Change_request.Status.Offer_accepted',
  OfferDeclined = 'Shared.Change_request.Offer_declined',
  OfferCanceled = 'Shared.Change_request.Offer_canceled',
}

export enum EOfferStatusTitle {
  Offered = 'Shared.Change_request.Offer.Sent',
  OfferExpired = 'Shared.Change_request.Offer.Expired',
  OfferApproved = 'Shared.Change_request.Offer.Approved',
  OfferDeclined = 'Shared.Change_request.Offer.Declined',
  OfferCanceled = 'Shared.Change_request.Offer.Canceled',
}

export const changeReqStatusList = {
  [EChangeRequestStatus.New]: {
    style: 'new',
    title: EChangeRequestStatusTitle.New,
  },
  [EChangeRequestStatus.InProgress]: {
    style: 'in-progress',
    title: EChangeRequestStatusTitle.InProgress,
  },
  [EChangeRequestStatus.Approved]: {
    style: 'approved',
    title: EChangeRequestStatusTitle.Approved,
  },
  [EChangeRequestStatus.Offered]: {
    style: 'offered',
    title: EChangeRequestStatusTitle.Offered,
  },
  [EChangeRequestStatus.RequestDeclined]: {
    style: 'request-declined',
    title: EChangeRequestStatusTitle.Declined,
  },
  [EChangeRequestStatus.OfferExpired]: {
    style: 'offer-expired',
    title: EChangeRequestStatusTitle.OfferExpired,
  },
  [EChangeRequestStatus.OfferApproved]: {
    style: 'offer-approved',
    title: EChangeRequestStatusTitle.OfferApproved,
  },
  [EChangeRequestStatus.OfferDeclined]: {
    style: 'offer-declined',
    title: EChangeRequestStatusTitle.OfferDeclined,
  },
  [EChangeRequestStatus.OfferCanceled]: {
    style: 'offer-canceled',
    title: EChangeRequestStatusTitle.OfferCanceled,
  },
};

export const offerStatusList = {
  [EChangeRequestStatus.Offered]: {
    style: 'offered',
    title: EOfferStatusTitle.Offered,
  },
  [EChangeRequestStatus.OfferExpired]: {
    style: 'offer-expired',
    title: EOfferStatusTitle.OfferExpired,
  },
  [EChangeRequestStatus.OfferApproved]: {
    style: 'offer-approved',
    title: EOfferStatusTitle.OfferApproved,
  },
  [EChangeRequestStatus.OfferDeclined]: {
    style: 'offer-declined',
    title: EOfferStatusTitle.OfferDeclined,
  },
  [EChangeRequestStatus.OfferCanceled]: {
    style: 'offer-canceled',
    title: EOfferStatusTitle.OfferCanceled,
  },
};

export class ChangeRequestOfferModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'description' })
  description!: string;
  @Expose({ name: 'expiration_date' })
  @Transform(({ value }) => (value ? dayjs(value).format('D.MM.YYYY') : ''))
  expirationDate!: string;
  @Expose({ name: 'price' })
  price!: number;
  @Expose({ name: 'title' })
  title!: string;
  @Expose({ name: 'file_resources' })
  @Type(() => FileModel)
  fileResources?: (FileModel | ImageModel)[];
  @Expose({ name: 'status' })
  status!: EChangeRequestStatus;
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
  @Expose({ name: 'version_number' })
  versionNumber!: number;

  selected: boolean;

  constructor() {
    this.selected = false;
  }
}

export class FloorDrawVersionModel {
  @Expose({ name: 'change_request_id' })
  changeRequestId!: number;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'creator' })
  creator!: {
    id: number;
    name: string;
    type: string;
  };
  @Expose({ name: 'draw_data' })
  drawData!: IKonvaObject;

  @Expose({ name: 'filename' })
  filename!: {
    name: string | null;
    url: string | null;
  };
  @Expose({ name: 'filename_remote_url' })
  filenameRemoteUrl!: string;

  @Expose({ name: 'floor' })
  @Type(() => FloorModel)
  floor?: FloorModel;
  @Expose({ name: 'floor_type' }) floorType!: string;
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'version_number' }) versionNumber!: number;
  @Expose({ name: 'floor_name' }) floorName!: string;
  @Expose({ name: 'floor_number' }) floorNumber!: number;
}

export class ChangeRequestEventLogModal {
  @Expose({ name: 'change_request_offer_id' })
  changeRequestOfferId!: number | null;
  @Expose({ name: 'comment' })
  comment!: string;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'creator' })
  creator!: { id: number; name: string };
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
  @Expose({ name: 'status' })
  status!: EChangeRequestStatus;
  @Expose({ name: 'new_responsible' })
  newResponsible!: { id: number; name: string };
}

export class ChangeRequestTypeModal {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'kind' })
  kind!: string;
  @Expose({ name: 'firm_id' })
  firmId!: number;
}

export class ChangeRequestResponseModel {
  @Expose({ name: 'change_requests' })
  @Type(() => ChangeRequestListModel)
  changeRequests!: ChangeRequestListModel[];
  @Expose({ name: 'status_stats' })
  @Transform(({ value }) => {
    return value.reduce((acc: IChangeRequestCount, val: any) => {
      acc[val.name] = { count: val.count, localizedStatus: val.localized_status };
      return acc;
    }, {});
  })
  statusStats!: IChangeRequestCount;
  @Expose({ name: 'total_count' })
  totalCount!: number;
}

export class UnitChangeRequestModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'address' })
  address!: string | null;
  @Expose({ name: 'identifier' })
  identifier!: string;
  @Expose({ name: 'primary_alphabetically_owner' })
  primaryOwner!: {
    email: string;
    id: number;
    name: string;
    phone: string;
  }
  @Expose({ name: 'property_type' })
  propertyType!: string;
  @Expose({ name: 'sale_status' })
  saleStatus!: string;
}

export class ChangeRequestListModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier?: string;
  @Expose({ name: 'unit' })
  @Type(() => UnitChangeRequestModel)
  unit!: UnitChangeRequestModel;
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
  @Expose({ name: 'status' })
  status!: EChangeRequestStatus;
  @Expose({ name: 'responsible' })
  @Transform(({ value }) => value || null)
  responsible?: Partial<IUser> | null;
  @Expose({ name: 'description' })
  description?: string;
  @Expose({ name: 'created_at' })
  createdAt?: string;
  @Expose({ name: 'active_offer_price' })
  activeOfferPrice!: number | null;
  @Expose({ name: 'message_thread_id' })
  messageThreadId?: number;
  @Expose({ name: 'message_thread_unread_count' })
  messageThreadUnreadCount?: number;
  @Expose({ name: 'notes_message_thread_unread_count' })
  notesMessageThreadUnreadCount?: number;
  @Expose({ name: 'message_thread_state' })
  messageThreadState!: IThreadState;
  @Expose({ name: 'message_thread_has_messages' })
  messageThreadHasMessages!: boolean;
  @Expose({ name: 'viewed' })
  viewed!: boolean;

  selected?: boolean;
}

export class ChangeRequestModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier?: string;
  @Expose({ name: 'unit' })
  unit!: Partial<UnitUserModel>;
  @Expose({ name: 'event_logs' })
  @Type(() => ChangeRequestEventLogModal)
  eventLogs!: ChangeRequestEventLogModal[];
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
  @Expose({ name: 'status' })
  status!: EChangeRequestStatus;
  @Expose({ name: 'responsible' })
  @Transform(({ value }) => value || null)
  responsible?: Partial<IUser> | null;
  @Expose({ name: 'description' })
  description?: string;
  @Expose({ name: 'created_at' })
  createdAt?: string;
  @Expose({ name: 'file_resources' })
  @Type(() => FileModel)
  fileResources?: (FileModel | ImageModel)[];
  @Expose({ name: 'floor_id' })
  floorId?: number;
  @Expose({ name: 'floor_type' })
  floorType?: string;
  @Expose({ name: 'message_thread_id' })
  messageThreadId?: number;
  @Expose({ name: 'message_thread_unread_count' })
  messageThreadUnreadCount?: number;
  @Expose({ name: 'notes_message_thread_id' })
  notesMessageThreadId?: number;
  @Expose({ name: 'notes_message_thread_unread_count' })
  notesMessageThreadUnreadCount?: number;
  @Expose({ name: 'message_thread_state' })
  messageThreadState!: IThreadState;
  @Expose({ name: 'message_thread_has_messages' })
  messageThreadHasMessages!: boolean;
  @Expose({ name: 'active_offer_id' })
  activeOfferId!: null | number;
  @Expose({ name: 'active_offer_price' })
  activeOfferPrice!: number | null;
  @Expose({ name: 'floor_draw_versions' })
  @Type(() => FloorDrawVersionModel)
  floorDrawVersions!: FloorDrawVersionModel[];
  @Expose({ name: 'viewed' })
  viewed!: boolean;
  @Expose({ name: 'custom_note_id' })
  customNoteId!: number | null;
  @Expose({ name: 'archived' })
  archived!: boolean;

  selected?: boolean;
}

export class GlobalChangeRequestResponseModel {
  @Expose({ name: 'change_requests' })
  @Type(() => GlobalChangeRequestModel)
  changeRequests!: GlobalChangeRequestModel[];
  @Expose({ name: 'status_stats' })
  @Transform(({ value }) => {
    return value.reduce((acc: IChangeRequestCount, val: any) => {
      acc[val.name] = { count: val.count, localizedStatus: val.localized_status };
      return acc;
    }, {});
  })
  statusStats!: IChangeRequestCount;
  @Expose({ name: 'project_data' })
  projectsWithAmounts!: IGlobalChangeRequestProject[];
  @Expose({ name: 'total_count' })
  totalCount!: number;
}

export interface IGlobalChangeRequestProject {
  id: number;
  name: string;
  amount?: number;
  selected?: boolean;
}

export class GlobalChangeRequestModel {
  @Expose({ name: 'active_offer_price' })
  activeOfferPrice!: number;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'description' })
  description!: string;
  @Expose({ name: 'id' })
  id!: string;
  @Expose({ name: 'identifier' })
  identifier!: string;
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
  @Expose({ name: 'status' })
  status!: EChangeRequestStatus;
  @Expose({ name: 'unit' })
  unit!: Partial<UnitUserModel>;
  @Expose({ name: 'project' })
  project!: IGlobalChangeRequestProject;
  @Expose({ name: 'message_thread_id' })
  messageThreadId?: number;
  @Expose({ name: 'message_thread_unread_count' })
  messageThreadUnreadCount?: number;
  @Expose({ name: 'notes_message_thread_id' })
  notesMessageThreadId?: number;
  @Expose({ name: 'notes_message_thread_unread_count' })
  notesMessageThreadUnreadCount?: number;
  @Expose({ name: 'message_thread_state' })
  messageThreadState!: IThreadState;
  @Expose({ name: 'message_thread_has_message' })
  messageThreadHasMessages!: boolean;
}

export class ClientChangeRequestListModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier?: string;
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
  @Expose({ name: 'status' })
  status!: EChangeRequestStatus;
  @Expose({ name: 'description' })
  description?: string;
  @Expose({ name: 'created_at' })
  createdAt?: string;
  @Expose({ name: 'message_thread_id' })
  messageThreadId?: number;
  @Expose({ name: 'message_thread_unread_count' })
  messageThreadUnreadCount?: number;
  @Expose({ name: 'active_offer_id' })
  activeOfferId?: number;
  @Expose({ name: 'message_thread_state' })
  messageThreadState!: IThreadState;
  @Expose({ name: 'message_thread_has_messages' })
  messageThreadHasMessages!: boolean;
  @Expose({ name: 'active_offer_price' })
  activeOfferPrice!: number | null;
  @Expose({ name: 'active_offer_viewed_by_user' })
  viewed!: boolean;
}

export class ClientChangeRequestModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier?: string;
  @Expose({ name: 'event_logs' })
  @Type(() => ChangeRequestEventLogModal)
  eventLogs!: ChangeRequestEventLogModal[];
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
  @Expose({ name: 'status' })
  status!: EChangeRequestStatus;
  @Expose({ name: 'description' })
  description?: string;
  @Expose({ name: 'created_at' })
  createdAt?: string;
  @Expose({ name: 'file_resources' })
  @Type(() => FileModel)
  fileResources?: (FileModel | ImageModel)[];
  @Expose({ name: 'floor_id' })
  floorId?: number;
  @Expose({ name: 'floor_type' })
  floorType?: string;
  @Expose({ name: 'message_thread_id' })
  messageThreadId?: number;
  @Expose({ name: 'message_thread_unread_count' })
  messageThreadUnreadCount?: number;
  @Expose({ name: 'active_offer_id' })
  activeOfferId?: number;
  @Expose({ name: 'message_thread_state' })
  messageThreadState!: IThreadState;
  @Expose({ name: 'message_thread_has_messages' })
  messageThreadHasMessages!: boolean;
  @Expose({ name: 'floor_draw_versions' })
  @Type(() => FloorDrawVersionModel)
  floorDrawVersions!: FloorDrawVersionModel[];
  @Expose({ name: 'active_offer_price' })
  activeOfferPrice!: number | null;
  @Expose({ name: 'active_offer_viewed_by_user' })
  viewed!: boolean;
}

export class ClientChangeRequestResponseModel {
  @Expose({ name: 'change_requests' })
  @Type(() => ClientChangeRequestListModel)
  changeRequests!: ClientChangeRequestListModel[];
  @Expose({ name: 'status_stats' })
  @Transform(({ value }) => {
    return value.reduce((acc: IChangeRequestCount, val: any) => {
      acc[val.name] = { count: val.count, localizedStatus: val.localized_status };
      return acc;
    }, {});
  })
  statusStats!: IChangeRequestCount;
  pagination!: ITablePagination;
}

export class PdfDocumentUrlModel {
  @Expose({ name: 'url' })
  url!: string;
  @Expose({ name: 'download_url' })
  downloadUrl!: string;
}

export class DocumentPdfModel {
  @Expose({ name: 'entity_type' })
  entityType!: string;
  @Expose({ name: 'entity_id' })
  entityId!: number;
  @Expose({ name: 'file_size' })
  fileSize!: number | null;
  @Expose({ name: 'file_extension' })
  fileExtension!: string | null;
  @Expose({ name: 'document' })
  @Type(() => PdfDocumentUrlModel)
  document!: PdfDocumentUrlModel;
}

export class RemoveOfferModel {
  @Expose({ name: 'entity_type' })
  message!: string;
  @Expose({ name: 'new_change_request_status' })
  newStatus!: EChangeRequestStatus;
}

export enum EChangeRequestStatusFilter {
  All = '',
  Pending = 'pending',
  OfferSent = 'offer_sent',
  OfferExpired = 'offer_expired',
  OfferDeclined = 'offer_declined',
  OfferCancelled = 'offer_cancelled',
  OfferApproved = 'offer_approved',
  RequestDeclined = 'request_declined',
  InProgress = 'in_progress',
}

export interface IStatusArray {
  status: EChangeRequestStatusFilter;
  title: EChangeRequestStatusTitle;
  active: boolean;
}

export const changeRequestStatusList: IStatusArray[] = [
  { status: EChangeRequestStatusFilter.Pending, title: EChangeRequestStatusTitle.New, active: false },
  { status: EChangeRequestStatusFilter.InProgress, title: EChangeRequestStatusTitle.InProgress, active: false },
  { status: EChangeRequestStatusFilter.OfferSent, title: EChangeRequestStatusTitle.Offered, active: false },
  { status: EChangeRequestStatusFilter.OfferApproved, title: EChangeRequestStatusTitle.Approved, active: false },
  { status: EChangeRequestStatusFilter.OfferExpired, title: EChangeRequestStatusTitle.OfferExpired, active: false },
  { status: EChangeRequestStatusFilter.RequestDeclined, title: EChangeRequestStatusTitle.Declined, active: false },
  { status: EChangeRequestStatusFilter.OfferDeclined, title: EChangeRequestStatusTitle.OfferDeclined, active: false },
  { status: EChangeRequestStatusFilter.OfferCancelled, title: EChangeRequestStatusTitle.OfferCanceled, active: false },
];

export interface IChangeRequestFilter {
  projects?: number[];
  units: number[];
  status: string[];
  responsible: number[];
  date: { from: string; to: string | null } | null;
}

export interface IChangeRequestListsForFilter {
  units: IUnitItem[];
  projects: IGlobalChangeRequestProject[];
  users: AdminModel[];
}

export class ChangeRequestSettingModel {
  @Expose({ name: 'project_id' })
  projectId!: number;
  @Expose({ name: 'allow_bank_id' })
  allowBankId!: boolean;
  @Expose({ name: 'layout_option_sync' })
  layoutOptionSync!: boolean;
  @Expose({ name: 'terms_conditions' })
  termsConditions!: boolean;
  @Expose({ name: 'open_date' })
  openDate!: string | null;
  @Expose({ name: 'closed_date' })
  closedDate!: string | null;
  @Expose({ name: 'terms_conditions_data' })
  @Type(() => TermsConditionsDataModel)
  termsConditionsData!: TermsConditionsDataModel;
  @Expose({ name: 'is_now_open_date' })
  startOpenDate!: boolean;
  @Expose({ name: 'is_now_closed_date' })
  startCloseDate!: boolean;
}

export interface IReqChangeRequestSetting {
  allow_bank_id: boolean;
  layout_option_sync: boolean;
  terms_conditions: boolean;
}

export class OptionChangeRequestModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'identifier' })
  identifier!: string;
  @Expose({ name: 'description' })
  description!: string;
  @Expose({ name: 'status' })
  status!: string;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'localized_status' })
  localizedStatus!: string;
  @Expose({ name: 'price' })
  price!: number;
  @Expose({ name: 'due_date' })
  dueDate!: string;
}

export interface IFloorItemVersionList {
  plan: FloorDrawVersionModel | FloorModel | UnitFloorModel | undefined;
  selected: boolean;
}

export interface IFloorVersionList {
  version: number;
  creator: string;
  createAt: string;
  floor: IFloorItemVersionList[];
}
