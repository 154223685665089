import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {LockFieldDirective, SharedDirectivesModule} from '@atlas-workspace/shared/directives';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

import { NativeElementInjectorDirective } from './directives/native-element-injector.directive';
import { NgxIntlTelInputComponent } from './ngx-intl-tel-input.component';
import {NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";

export const dropdownModuleForRoot: ModuleWithProviders<BsDropdownModule> = BsDropdownModule.forRoot();

@NgModule({
  declarations: [NgxIntlTelInputComponent, NativeElementInjectorDirective],
	imports: [CommonModule, FormsModule, ReactiveFormsModule, dropdownModuleForRoot, NgxMaskDirective, SharedDirectivesModule, LockFieldDirective, NgbDropdownModule],
  exports: [NgxIntlTelInputComponent, NativeElementInjectorDirective],
  providers: [provideNgxMask()],
})
export class NgxIntlTelInputModule {}
