import {CommonModule} from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {DisplayDownloadLinkPipe, FileSizePipeModule, IconByTypePipeModule} from "@atlas-workspace/shared/pipes";

@Component({
  selector: 'atl-download-card',
  templateUrl: './download-card.component.html',
  styleUrls: ['./download-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, DisplayDownloadLinkPipe, IconByTypePipeModule, FileSizePipeModule]
})
export class DownloadCardComponent {
  @Input() isFluid = false;
  @Input() isChat = false;
  @Input() showPNGAsImg = false;
  @Input() fileName!: string;
  @Input() fileType!: string;
  @Input() fileSize!: string | number;
  @Input() downloadLink!: string;
  @Input() id?: number;
}
