import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  ContractorCompany,
  ContractorCompanyModel,
  ETablePagination,
  IContactorCompanies,
  IContractorCompany,
  ICreateContractorCompany,
  IDefaultCompanyRecipientResponce,
  IEditContractorCompany,
  ITablePagination,
} from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { PaginationUtil } from '../../helpers/pagination.util';
import { DataTableHelperService } from '../data-table-helper/data-table-helper.service';

@Injectable({
  providedIn: 'root',
})
export class CompaniesService {
  public companiesContractorPagination$ = new BehaviorSubject<ITablePagination | null>(null);

  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private http: HttpClient,
    private tableService: DataTableHelperService
  ) {}

  getCompaniesUsers(
    contractorCompanyId: number,
    sort = 'default',
    search = '',
    paginate?: ITablePagination
  ): Observable<ContractorCompanyModel[]> {
    const params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    return this.http
      .get<any>(
        this.environment.apiBaseUrl + `api/v1/contractor_companies/${contractorCompanyId}/contractor_company_members`,
        {
          params: params,
          observe: 'response',
        }
      )
      .pipe(
        tap((res) => {
          const pagination: ITablePagination = {
            currentPage: PaginationUtil.convertPaginationType(res.headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(res.headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(res.headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(res.headers, ETablePagination.TotalPages),
          };
          this.companiesContractorPagination$.next(pagination);
        }),
        map((res) => res.body.data.contractor_company_members),
        map((value: IContactorCompanies[]) => plainToClass(ContractorCompanyModel, value))
      );
  }

  postCompaniesUsers(contractorCompanyId: number, body: ICreateContractorCompany): Observable<ContractorCompanyModel> {
    return this.http
      .post(
        this.environment.apiBaseUrl + `api/v1/contractor_companies/${contractorCompanyId}/contractor_company_members`,
        {
          contractor_company_member: body,
        }
      )
      .pipe(
        map((res: any) => res.data),
        map((value) => plainToClass(ContractorCompanyModel, value))
      );
  }

  getCompaniesUsersId(
    contractorCompanyId: number,
    contactorId: number | undefined
  ): Observable<ContractorCompanyModel> {
    return this.http
      .get(
        this.environment.apiBaseUrl +
          `api/v1/contractor_companies/${contractorCompanyId}/contractor_company_members/${contactorId}`
      )
      .pipe(
        map((res: any) => res.data),
        map((value) => plainToClass(ContractorCompanyModel, value))
      );
  }

  putCompaniesUsers(
    contractorCompanyId: number,
    contactorId: number | undefined,
    body: IEditContractorCompany
  ): Observable<ContractorCompanyModel> {
    return this.http
      .put(
        this.environment.apiBaseUrl +
          `api/v1/contractor_companies/${contractorCompanyId}/contractor_company_members/${contactorId}`,
        { contractor_company_member: body }
      )
      .pipe(
        map((res: any) => res.data),
        map((value) => plainToClass(ContractorCompanyModel, value))
      );
  }

  deleteCompaniesUsers(contractorCompanyId: number, ids: number[]): Observable<unknown> {
    const httpOptions = {
      params: new HttpParams(),
      body: { ids: ids },
    };
    return this.http.delete(
      this.environment.apiBaseUrl +
        `api/v1/contractor_companies/${contractorCompanyId}/contractor_company_members/batch_destroy`,
      httpOptions
    );
  }

  resendInviteContractor(contractorCompanyId: number, contactorId: number | undefined): Observable<unknown> {
    return this.http.patch(
      this.environment.apiBaseUrl +
        `api/v1/contractor_companies/${contractorCompanyId}/contractor_company_members/${contactorId}/resend_invite`,
      undefined
    );
  }

  transformContractorInfo(value: ICreateContractorCompany): ContractorCompanyModel {
    return plainToClass(ContractorCompanyModel, value);
  }

  /**
   * @Cypress
   */
  public getCompaniesList(
    page: number,
    perPage = 100,
    search = '',
    sortParam = 'default',
    withMembers?: boolean
  ): Observable<ContractorCompany[]> {
    let params = new HttpParams()
      .set('page', String(page))
      .set('per_page', String(perPage))
      .set('search', search)
      .set('sort_by', sortParam);
    if (withMembers) params = params.set('with_key_contacts', withMembers);
    return this.http.get(`${this.environment.apiBaseUrl}api/v1/contractor_companies`, { params }).pipe(
      map((res: any) => res.data.contractor_companies),
      map((data) => plainToClass(ContractorCompany, data) as unknown as ContractorCompany[]),
      map((values) => {
        return values.map((company) => {
          if (company.keyContacts.length) {
            const [first] = company.keyContacts;
            company.keyContacts = company.keyContacts.slice(1);
            company.firstKeyContact = first;
          }
          return company;
        });
      })
    );
  }

  public removeCompany(companyId: number): Observable<ContractorCompany> {
    return this.http.delete(`${this.environment.apiBaseUrl}api/v1/contractor_companies/${companyId}`).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(ContractorCompany, data))
    );
  }

  public removeCompanies(ids: number[]): Observable<ContractorCompany> {
    let params: HttpParams = new HttpParams();
    ids.forEach((id) => {
      params = params.append('ids[]', String(id));
    });

    const httpOptions = {
      params: params,
    };
    return this.http
      .delete(`${this.environment.apiBaseUrl}api/v1/contractor_companies/batch_destroy`, httpOptions)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(ContractorCompany, data))
      );
  }

  public createCompany(data: IContractorCompany): Observable<ContractorCompany> {
    const fd = new FormData();
    fd.append('contractor_company[name]', data.name);
    fd.append('contractor_company[description]', data.description);
    if (data.address) {
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fd.append('contractor_company[address]', data.address);
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fd.append('contractor_company[latitude]', data.latitude);
      // eslint-disable-next-line sonarjs/no-duplicate-string
      fd.append('contractor_company[longitude]', data.longitude);
    }
    return this.http.post(`${this.environment.apiBaseUrl}api/v1/contractor_companies`, fd).pipe(
      map((res: any) => res.data),
      map((x) => plainToClass(ContractorCompany, x))
    );
  }

  public updateCompany(data: IContractorCompany, companyId: number): Observable<ContractorCompany> {
    const fd = new FormData();
    fd.append('contractor_company[name]', data.name);
    fd.append('contractor_company[description]', data.description);
    if (data.address) {
      fd.append('contractor_company[address]', data.address);
      fd.append('contractor_company[latitude]', data.latitude);
      fd.append('contractor_company[longitude]', data.longitude);
    } else {
      fd.append('contractor_company[address]', '');
      fd.append('contractor_company[latitude]', '');
      fd.append('contractor_company[longitude]', '');
    }
    return this.http.put(`${this.environment.apiBaseUrl}api/v1/contractor_companies/${companyId}`, fd).pipe(
      map((res: any) => res.data),
      map((x) => plainToClass(ContractorCompany, x))
    );
  }

  public updateDefaultCompanyRecipient(
    companyId: number,
    mainDefaultRecipientId: number | null,
    defaultRecipientId: number | null
  ): Observable<IDefaultCompanyRecipientResponce> {
    const fd = new FormData();
    fd.append('contractor_company_default_recipient[recipient_id]', String(defaultRecipientId));
    return this.http
      .put<any>(
        `${this.environment.apiBaseUrl}api/v1/contractor_companies/${companyId}/contractor_company_default_recipients/${mainDefaultRecipientId}`,
        fd
      )
      .pipe(map((res: any) => res.data));
  }

  public addDefaultCompanyRecipient(
    companyId: number,
    defaultRecipientId: number | null
  ): Observable<IDefaultCompanyRecipientResponce> {
    const fd = new FormData();
    fd.append('contractor_company_default_recipient[recipient_id]', String(defaultRecipientId));
    return this.http
      .post<any>(
        `${this.environment.apiBaseUrl}api/v1/contractor_companies/${companyId}/contractor_company_default_recipients`,
        fd
      )
      .pipe(map((res: any) => res.data));
  }

  public deleteDefaultCompanyRecipient(companyId: number, defaultRecipientId: number): Observable<void> {
    return this.http.delete<any>(
      `${this.environment.apiBaseUrl}api/v1/contractor_companies/${companyId}/contractor_company_default_recipients/batch_destroy?ids[]=${defaultRecipientId}`
    );
  }
}
