<div class="move-to__wrapper" *ngIf="showMoveTo">
  <div class="move-to__header fz-14 lh-24 d-flex align-items-center d-flex justify-content-between">
    <ng-container *ngIf="!createState; else createFolderBlock">
      <div *ngIf="currentFolder?.id; else defaultTitle" class="d-inline-flex">
        <img class="cursor-pointer mr-8" (click)="back()" src="/assets/arrow-back-md.svg" alt="back" />
        <p>{{ currentFolder.title | truncate: 16 }}</p>
      </div>
      <img (click)="closeMoveToBlock()" class="mr-16 cursor-pointer" src="/assets/close-icon.svg" alt="close" />
    </ng-container>
  </div>

  <div class="move-to__body v-scrollbar">
    <ng-container *ngIf="folders?.length; else emptyFolder">
      <div
        *ngFor="let folder of folders; trackBy: trackByFn"
        class="d-flex move-to__folder align-items-center justify-content-between"
        [ngClass]="{
          'move-to__selected-folder': selectedFolderId === folder.id,
          'move-to__current-folder': selectedItems | iterableAlreadyIncludesPrimitive: folder.id
        }"
        (click)="selectFolder(folder)"
      >
        <div class="d-flex">
          <img class="mr-10 cursor-pointer" src="/assets/message-folder.svg" alt="icon" />
          <p class="fz-16 lh-24 move-to__folder-title">{{ folder.title }}</p>
        </div>
        <img
          (click)="openInnerFolder(folder)"
          *ngIf="selectedFolderId === folder.id"
          src="/assets/arrow_right.svg"
          alt="icon"
        />
      </div>
    </ng-container>
  </div>

  <div
    class="move-to__footer d-flex align-items-center"
    [ngClass]="folderBreadcrumbs.length >= this.maxNestedFolders ? 'justify-content-end' : 'justify-content-between'"
  >
    <img
      *ngIf="folderBreadcrumbs.length < this.maxNestedFolders"
      [ngClass]="{ 'move-to__create': createState }"
      (click)="changeCreateFolderState()"
      class="cursor-pointer"
      src="/assets/folder-plus-icon.svg"
      alt="icon"
    />
    <button
      *ngIf="selectedFolderId || currentFolder === selectedFolderId; else moveToPlaceholder"
      class="global-button global-button--primary move-to__move-btn fz-12"
      (click)="moveSelectedToFolder()"
    >
      {{ 'Shared.Button.Move' | translate }}
    </button>
  </div>
</div>

<div class="delete-modal-wrapper">
  <img (click)="cancelModal()" class="mr-12 cursor-pointer" src="/assets/close-icon.svg" alt="Close" />
  <div class="mr-16">
    <ng-content></ng-content>
  </div>
  <div>
    <button (click)="moveTo()" class="global-button global-button--primary mr-16 fz-12">
      {{ 'Shared.Entity.Move_to' | translate }}
    </button>
    <button (click)="confirm()" class="global-button global-button--danger fz-12">
      {{ 'Shared.Button.Delete' | translate }}
    </button>
  </div>
</div>

<ng-template #defaultTitle>
  <p>{{ 'Shared.Entity.Select_folder' | translate }}</p>
</ng-template>

<ng-template #moveToPlaceholder>
  <p class="fz-12 lh-16 move-to__text">{{ 'Shared.Description.Move_to_folder' | translate }}</p>
</ng-template>

<ng-template #createFolderBlock>
  <div class="move-to move-to__create-folder d-inline-flex">
    <img class="cursor-pointer mr-8" (click)="back()" src="/assets/arrow-back-md.svg" alt="back" />
    <input [(ngModel)]="newFolderName" placeholder="Type name" type="text" />
    <div class="cursor-pointer" (click)="createNewFolder()">
      <img
        [ngClass]="{ 'move-to__create': newFolderName }"
        alt="plus icon"
        class="move-to__plus-icon"
        src="/assets/plus-button-gray-disable.svg"
      />
      <span [ngClass]="{ 'move-to__valid': newFolderName }" class="move-to__create-text"
        >{{ 'Button.Add_new' | translate }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template #emptyFolder>
  <p class="move-to__empty fz-12 lh-16 d-flex justify-content-center align-items-center">
    {{ 'Shared.Entity.Empty_folder' | translate }}
  </p>
</ng-template>
