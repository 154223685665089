import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ImageModel } from '@atlas-workspace/shared/models';
import { FileLoadService } from '@atlas-workspace/shared/service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SwiperOptions } from 'swiper/types';

import { IModalHeaderInterface } from '../../interfaces/modal-header.interface';

@Component({
  selector: 'atl-image-modal-v2',
  templateUrl: './image-modal-v2.component.html',
  styleUrls: ['./image-modal-v2.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageModalV2Component {
  public config!: SwiperOptions;
  readonly tooltipDelay = 500;
  @Input() slides: ImageModel[] = [];
  @Input() index!: number;

  @Input() set swiperConfig(c: SwiperOptions) {
    this.config = c;
    this.config.resizeObserver = true;
    if (typeof this.index === 'number') {
      this.config.initialSlide = this.index;
    }
    this.config.navigation = this.slides.length > 1;
  }

  @Input() modalRef!: NgbModalRef;
  @Input() getUrlFromProperty = 'fileName';
  @Input() isClient = false;
  @Input() isFloorPlan = false;

  private floorTitle = this.translateService.instant('Shared.Entity.Floor_plan');

  constructor(private readonly translateService: TranslateService, private readonly fileLoadService: FileLoadService) {}

  get headerData(): IModalHeaderInterface {
    return {
      title: this.isFloorPlan ? this.floorTitle : this.slides[this.index].name,
      subtitle: this.slides[this.index].createdAt,
      downloadLink: this.slides[this.index].fileName.downloadUrl,
    };
  }

  public onIndexChange(i: number): void {
    this.index = i;
  }

  public onCopyImage(): void {
    this.fileLoadService.copyFileFromModel({
      url: this.headerData.downloadLink!,
      name: this.headerData.title!,
    });
  }
}
