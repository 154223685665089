import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';

import { ImageViewerComponent } from './image-viewer.component';

@NgModule({
  imports: [CommonModule, SharedDirectivesModule],
  declarations: [ImageViewerComponent],
  exports: [ImageViewerComponent],
})
export class ImageViewerModule {}
