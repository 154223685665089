import { Editor } from 'tinymce';

import { ITextEditorInitConfig } from '../interface/text-editor.interface';

export interface IThreadEditorInitToolbarCallbacksProps {
  [k: string]: [(e?: any) => void, string];
}
export interface IThreadEditorInitCustomButtonsProps {
  [k: string]: {
    onAction: (e?: any) => void;
    onClick?: (e?: any) => void;
    setEnabled?: () => boolean;
    toolbar?: 'toolbar' | 'toolbar1' | 'toolbar2';
    icon: string;
    tooltip?: string;
    text?: string;
  };
}

export const CUSTOM_FILE_LIST_CHANGE_EDITOR_EVENT_NAME = 'CustomFileListChange';

export const initEditorCustomButtons = (
  config: ITextEditorInitConfig,
  props: IThreadEditorInitCustomButtonsProps,
): ITextEditorInitConfig => {
  config.setup = (editor: Editor): void => {
    for (const btnName in props) {
      const _id = String(Math.random() * 10e16);
      editor.ui.registry.addIcon(_id, props[btnName].icon);
      editor.ui.registry.addButton(btnName, {
        type: 'button',
        icon: _id,
        ...(props[btnName].tooltip && {
          tooltip: props[btnName].tooltip,
          ariaLabel: props[btnName].tooltip,
        }),
        ...(props[btnName].text && {
          text: props[btnName].text,
        }),
        onAction: () => props[btnName].onAction(editor),
        ...(props[btnName].setEnabled && {
          onSetup: (buttonApi): (() => void) => {
            const editorEventCallback = (): void => {
              buttonApi.setEnabled(Boolean(props[btnName].setEnabled?.()));
            };
            editor.on(CUSTOM_FILE_LIST_CHANGE_EDITOR_EVENT_NAME, editorEventCallback);

            /* onSetup should always return the unbind handlers */
            return () => editor.off(CUSTOM_FILE_LIST_CHANGE_EDITOR_EVENT_NAME, editorEventCallback);
          },
        }),
      });
    }
  };

  for (const btnName in props) {
    const _toolbarKey = props[btnName].toolbar || 'toolbar';
    config[_toolbarKey] = config[_toolbarKey] + ` ${btnName}`;
  }
  return config;
};

export const initEditorToolbarCallbacks = (
  config: ITextEditorInitConfig,
  callbacks: IThreadEditorInitToolbarCallbacksProps,
): ITextEditorInitConfig => {
  config.setup = (editor: Editor): void => {
    for (const key in callbacks) {
      const _id = String(Math.random() * 10e16);
      editor.ui.registry.addIcon(_id, callbacks[key][1]);
      editor.ui.registry.addButton(key, {
        icon: _id,
        onAction: () => callbacks[key][0](editor),
      });
    }
  };
  for (const key in callbacks) {
    if (config.toolbar) {
      config.toolbar = config.toolbar + ` ${key}`;
    }
  }
  return config;
};
