import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NewNotificationModalComponent } from './notification-modal-v2.component';

@NgModule({
  declarations: [NewNotificationModalComponent],
  exports: [NewNotificationModalComponent],
  imports: [CommonModule],
})
export class NewNotificationModalModule {}
