import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { scan, takeWhile, tap } from 'rxjs/operators';

@Component({
  selector: 'atl-countdown',
  templateUrl: './countdown.component.html',
  styleUrls: ['./countdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
@UntilDestroy()
export class CountdownComponent {
  @Output()
  countdownFinished: EventEmitter<boolean> = new EventEmitter<boolean>();
  public radius = 54;
  public circumference!: number;
  public timer$!: Observable<number>;
  private dashoffset$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  _timeRemaining = new BehaviorSubject<number | undefined>(0);

  @Input() asIconOnly = false;
  @Input() set timeRemaining(v: number) {
    this._timeRemaining.next(v || undefined);
    this.timer$ = v ? this.initCountdown(v) : of(0);
  }

  public get getDashoffset$(): Observable<number> {
    return this.dashoffset$.asObservable();
  }

  private initCountdown(value: number): Observable<number> {
    this.circumference = 2 * Math.PI * this.radius;
    if (this.asIconOnly) {
      this.dashoffset$.next(this.circumference * (1 - (this._timeRemaining.value as number)));
      return of(0);
    }
    return timer(0, 1000).pipe(
      untilDestroyed(this),
      scan((acc: number) => --acc, value),
      tap((x: number) => {
        if (this._timeRemaining.value) {
          const progress = x / this._timeRemaining.value;
          this.dashoffset$.next(this.circumference * (1 - progress));
        }
      }),
      takeWhile((x: number) => {
        if (x === 0) {
          this.countdownFinished.emit(true);
        }
        return x > 0;
      }),
    );
  }
}
