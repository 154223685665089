import { Pipe, PipeTransform } from '@angular/core';
import { ErrorFilePreview } from '@atlas-workspace/shared/models';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'attachError',
  standalone: true,
})
export class AttachErrorPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: ErrorFilePreview, maxFileUpload: number, extensionString: string): string {
    const size = value.size ? this.translate.instant('Shared.Attachment.Error.Size') + `\n` : '';
    const extension = value.extension
      ? this.translate.instant('Shared.Attachment.Error.Extension', {
          extension: extensionString,
        }) + `\n`
      : '';
    const maxUpload = value.maxUpload
      ? this.translate.instant('Shared.Attachment.Error.Max_upload', {
          maxUpload: maxFileUpload,
        })
      : '';

    return `${size}${extension}${maxUpload}`;
  }
}
