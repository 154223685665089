import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[atlVerticalAlign]',
})
export class VerticalAlignDirective implements OnChanges {

  @Input() bottom = 0;
  @Input() enableVerticalAlign = false;
  @Input() left = 0;
  @Input() leftOffset!: number;
  @Input() marginTop = 24;
  @Input() positionFixed = false;
  @Input() relativeElement!: HTMLElement;
  @Input() top = 0;
  @Input() topOffset!: number;

  constructor(private elementRef: ElementRef) {
  }

  ngOnChanges({topOffset, leftOffset}: SimpleChanges): void {
    if (topOffset && topOffset.currentValue || leftOffset && leftOffset.currentValue) {
      this.recalculatePosition();
    }
  }


  recalculatePosition(): void {
    if (!this.enableVerticalAlign) {
      return;
    }
    const domRect: DOMRect = this.relativeElement.getBoundingClientRect();
    const relativeElementWidth = this.relativeElement.offsetWidth;
    if (this.positionFixed) {
      this.elementRef.nativeElement.style.position = 'fixed';
      this.elementRef.nativeElement.style.left = relativeElementWidth / 2 + domRect.left + 'px';
    } else {
      this.elementRef.nativeElement.style.position = 'absolute';
      this.elementRef.nativeElement.style.left = '50%';
    }
    const windowHeight = window.innerHeight;
    const elementPosition = windowHeight / 2 > this.topOffset ? 'below' : 'above';
    if (elementPosition === 'below') {
      if (this.positionFixed) {
        this.elementRef.nativeElement.style.bottom = windowHeight - (this.bottom + domRect.bottom) + 'px';
      }
    } else {
      if (this.positionFixed) {
        this.elementRef.nativeElement.style.top = (this.top + domRect.top) + 'px';
      } else {
        this.elementRef.nativeElement.style.top = `calc(50% + (${this.marginTop}px))`;
        this.elementRef.nativeElement.style.transform = 'translate(-50%, -100%)';
      }
    }
  }
}
