import { CommonModule, registerLocaleData } from '@angular/common';
import localeNb from '@angular/common/locales/nb';
import localeSv from '@angular/common/locales/sv';
import { NgModule } from '@angular/core';
import { TranslateModule, TranslateService, TranslateStore } from '@ngx-translate/core';

import {
  localizationDatePipeProvider,
  translateModuleForChildConfig,
  translateModuleForRootConfig,
} from './helpers/shared-translate-helpers';

registerLocaleData(localeNb);
registerLocaleData(localeSv);

@NgModule({
  imports: [CommonModule, TranslateModule.forRoot(translateModuleForRootConfig)],
  providers: [TranslateService, TranslateStore, localizationDatePipeProvider],
  exports: [TranslateModule],
})
export class SharedTranslateForRootModule {}

@NgModule({
  imports: [CommonModule, TranslateModule.forChild(translateModuleForChildConfig)],
  exports: [TranslateModule],
})
export class SharedTranslateForChildModule {}
