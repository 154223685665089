import { microsoftDocumentViewerMimetypeList, pdfMime } from '@atlas-workspace/shared/models';
import * as mime from 'mime';
import { viewerType } from 'ngx-doc-viewer';

export class DocumentPreviewHelper {
  static getDocumentViewerType(fileExtension: string): viewerType {
    const mimetype = mime.getType(fileExtension);
    if (!mimetype) {
      return 'google';
    }

    switch (true) {
      case mimetype === pdfMime:
        return 'pdf';
      case microsoftDocumentViewerMimetypeList.includes(mimetype):
        return 'office';
      default:
        return 'google';
    }
  }
}
