import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { ProjectIllustrationModel } from '@atlas-workspace/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { SwiperOptions } from 'swiper/types';

export type SliderTheme = 'slideshow' | 'carousel';

export enum EToggleStatus {
  Live = 'live',
  Photos = 'photos',
}

@Component({
  selector: 'atl-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SliderComponent {
  @Input() sliderData!: ProjectIllustrationModel[];
  @Input() liveData!: ProjectIllustrationModel[];
  @Input() config!: SwiperOptions;
  @Input() theme: SliderTheme = 'carousel';
  @Output() openFullScreen = new EventEmitter<number>();

  public selectedStatus: EToggleStatus = EToggleStatus.Photos;
  public toggleStatus = EToggleStatus;
  public photosFromProjectTitle: string = this.translateService.instant('Entity.Monitoring');

  constructor(private cdr: ChangeDetectorRef, private translateService: TranslateService) {}

  public openFullscreenModal(index: number, event: Event): void {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.openFullScreen.emit(index);
  }
}
