import { INoDataWarning } from '@atlas-workspace/shared/models';
import { BehaviorSubject } from 'rxjs';

export class DisplayContentHelper<T> {
  readonly defaultLastPage = 1;
  totalPages = 1;
  currentPage = 1;
  isEnd = true;
  projectId!: number;
  unitId!: number;

  $showContent = new BehaviorSubject(false);

  warningInfo: INoDataWarning = {
    icon: 'image_no_tasks.svg',
    title: 'Shared.Entity.No_data',
    message: 'Shared.Description.No_data',
  };

  resetValues(cb: () => void): void {
    this.totalPages = 1;
    this.currentPage = 1;
    this.isEnd = true;
    cb();
  }

  onCheckLastPage(totalPages: string | null): void {
    this.totalPages = totalPages ? Number(totalPages) : this.defaultLastPage;
    this.isEnd = this.currentPage === this.totalPages;
  }

  onSaveInformation(cb: (value: T) => void, value: T, totalPages: string | null): void {
    cb(value);
    this.onCheckLastPage(totalPages);
    this.$showContent.next(true);
  }
}
