import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClientNotifyResponseModel } from '@atlas-workspace/shared/models';
import { environment } from '@environment-client';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class NotificationService {
  constructor(private http: HttpClient) {}

  getNotifySettings(projectId: number): Observable<ClientNotifyResponseModel> {
    return this.http
      .get<{ data: ClientNotifyResponseModel }>(
        `${environment.apiBaseUrl}api/v1/client/projects/${projectId}/mnt_notify_settings`
      )
      .pipe(
        map((res) => res.data),
        map((data) => plainToClass(ClientNotifyResponseModel, data))
      );
  }

  setNotifySettings(projectId: number, body: any): Observable<ClientNotifyResponseModel> {
    return this.http
      .put<{ data: ClientNotifyResponseModel }>(
        `${environment.apiBaseUrl}api/v1/client/projects/${projectId}/mnt_notify_settings`,
        body
      )
      .pipe(
        map((res) => res.data),
        map((data) => plainToClass(ClientNotifyResponseModel, data))
      );
  }
}
