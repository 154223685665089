import { ELang } from "../models/lang.enum"
import { ELocalStorageKeys } from "../models/local-storage.enum";

export const termsLinks = {
  [ELang.English]: 'https://journeyapp.tech/terms-conditions',
  [ELang.Norwegian]: 'https://journeytechnologies.no/terms-conditions',
  [ELang.Swedish]: 'https://journeytechnologies.se/terms-conditions'
}

export const privacyLinks = {
  [ELang.English]: 'https://journeyapp.tech/privacy-policy',
  [ELang.Norwegian]: 'https://journeytechnologies.no/privacy-policy',
  [ELang.Swedish]: 'https://journeytechnologies.se/privacy-policy'
}

export function openTerms(): void {
  const currentLang = JSON.parse(<string>localStorage.getItem(ELocalStorageKeys.Language))?.value as ELang;
  window.open(termsLinks[currentLang], '_blank');
}

export function openPrivacy(): void {
  const currentLang = JSON.parse(<string>localStorage.getItem(ELocalStorageKeys.Language))?.value as ELang;
  window.open(privacyLinks[currentLang], '_blank');
}