<div class="carousel__title-wrapper">
  <h2 class="fz-20 lh-24">
    {{ photosFromProjectTitle }}
  </h2>
</div>
<div class="carousel__swiper">
  <ng-container
    *ngIf="
      (selectedStatus === toggleStatus.Live && liveData && liveData.length) ||
        (selectedStatus === toggleStatus.Photos && sliderData && sliderData.length);
      else emptySlider
    "
  >
    <swiper-container
      #swiper
      atlSwiper
      [config]="config"
      [slides]="selectedStatus === toggleStatus.Photos ? sliderData : liveData"
      init="false"
      class="swiper-container"
    >
      <swiper-slide
        *ngFor="let slide of selectedStatus === toggleStatus.Photos ? sliderData : liveData; let i = index"
        (click)="openFullscreenModal(i, $event)"
        class="swiper-slide"
      >
        <div class="carousel__image-container">
          <div class="carousel__image">
            <ng-container *ngIf="!slide.fileResources?.length; else emptyVideo">
              <ng-container *ngIf="!(slide.name | isRaw); else emptyRaw">
                <img [src]="slide.fileName.url" alt="image" class="carousel__slide" />
              </ng-container>
              <ng-container *ngIf="slide.comment">
                <div class="comment-cyrcle" (click)="openFullscreenModal(i, $event)">
                  <div class="comment-counter">
                    <span class="comment-counter-value">1</span>
                    <img src="/assets/client_home_illustation_comment.svg" alt="Counter icon" />
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <ng-template #emptyVideo>
              <div class="carousel__empty-video" (click)="openFullscreenModal(i, $event)">
                <img src="assets/video-empty.svg" alt="video-empty" />
              </div>
            </ng-template>

            <ng-template #emptyRaw>
              <div class="carousel__empty-video" (click)="openFullscreenModal(i, $event)">
                <img src="assets/no_images.png" alt="raw-empty" />
              </div>
            </ng-template>
          </div>

          <div class="carousel__icon-full-view">
            <img src="/assets/full_screen_v2.svg" alt="full screen" />
          </div>
        </div>
      </swiper-slide>
    </swiper-container>
  </ng-container>
</div>

<ng-template #emptySlider>
  <div>
    <div class="carousel__construction-empty">
      <img src="/assets/no_images_v2.svg" alt="icon" />
      <p class="lh-24">{{ 'Shared.Description.No_images' | translate }}.</p>
    </div>
  </div>
</ng-template>
