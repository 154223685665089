import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {AdminFirmsModel, AdminProfileModel, ELocalStorageKeys} from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthAdminService } from '../auth/auth-admin.service';
import {LocalStorageService} from "../local-storge/local-storage.service";

@Injectable({
  providedIn: 'root',
})
export class AdminProfileService {
  private $adminProfile = new BehaviorSubject<AdminProfileModel | null>(null);

  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private http: HttpClient,
    private authAdminService: AuthAdminService,
    private localStorageService: LocalStorageService,
  ) {}

  public getUserProfile(firmId?: number): Observable<AdminProfileModel> {
    return this.http.get(`${this.environment.apiBaseUrl}/api/v1/firms/${firmId}/admin/profile`).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(AdminProfileModel, data)),
      tap((data) => this.setProfile(data))
    );
  }

  public removeProfile(firmId?: number): Observable<unknown> {
    return this.http.delete<unknown>(`${this.environment.apiBaseUrl}/api/v1/firms/${firmId}/admin/profile`);
  }

  public completeGlobalOnboarding(value = false): Observable<unknown> {
    const fd = new FormData();
    fd.append('profile[global_onboarding_completed]', value.toString());
    const firmId = this.authAdminService.firm?.firmId;
    return this.http.put(this.environment.apiBaseUrl + `/api/v1/firms/${firmId}/admin/profile`, fd);
  }

  public completeProjectOnboarding(value = false): Observable<unknown> {
    const fd = new FormData();
    fd.append('profile[project_onboarding_completed]', value.toString());
    const firmId = this.authAdminService.firm?.firmId;
    return this.http.put(this.environment.apiBaseUrl + `/api/v1/firms/${firmId}/admin/profile`, fd);
  }

  public updateProfile(body: Partial<{ email: string; name: string }>): Observable<AdminProfileModel> {
    return this.http.put(this.environment.apiBaseUrl + '/api/v1/admin_auth', body).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(AdminProfileModel, data))
    );
  }

  public get profile(): Observable<AdminProfileModel | null> {
    return this.$adminProfile.asObservable();
  }

  public setProfile(profile: AdminProfileModel): void {
    const firm = this.localStorageService.get<AdminFirmsModel>(ELocalStorageKeys.Firm);
    if (firm) {
      firm.role = profile.role;
      this.localStorageService.set(ELocalStorageKeys.Firm, firm);
    }
    this.$adminProfile.next(profile);
  }
}
