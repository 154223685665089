// eslint-disable-next-line max-len
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { WEB_PLATFORM_TYPE } from '@atlas-workspace/shared/translate';
import { EPlatform } from '@atlas-workspace/shared/models';

@Component({
  selector: 'atl-newest-confirm-modal',
  templateUrl: './newest-confirm-modal.component.html',
  styleUrls: ['./newest-confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewestConfirmModalComponent extends ConfirmModalComponent implements OnInit, AfterViewInit {
  @Input() public buttonRed = true;
  private _loading = false;
  @Input()
  set loading(value: boolean) {
    this._loading = value;
    this.cdr.detectChanges();
  }
  get loading(): boolean {
    return this._loading;
  }
  
  @Input() showCancel = true;
  public cancelBtnLabel = this.translateService.instant('Button.Cancel');
  public confirmInputText!: string;
  @Input() public readonly confirmRemoval = false;
  @Input() public readonly deletePlaceholder = 'Shared.Button.Delete';
  @Input() public readonly deleteDescription = 'Description.Delete_phrase';
  @Input() public readonly secondaryDescription: string | undefined;
  @Input() public readonly deleteVerificationKey!: string;

  @ViewChild('submitButton', { static: false }) submitButton!: ElementRef;
  
  public isClient!: boolean;
  constructor(
    ngbActiveModal: NgbActiveModal,
    public translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private readonly renderer: Renderer2,
    @Inject(WEB_PLATFORM_TYPE) private platformType: EPlatform
  ) {
    super(ngbActiveModal, translateService);
  }

  ngOnInit(): void {
    this.isClient = this.platformType === EPlatform.WEB_CLIENT;
  }

  ngAfterViewInit(): void {
    this.setButtonWidth();
  }

  onInputChange(value: string): void {
    if (value) {
      this.confirmInputText = value[0].toUpperCase() + value.substring(1);
    } else {
      this.confirmInputText = value;
    }
  }

  private setButtonWidth(): void {
    const buttonElement = this.submitButton.nativeElement;
    const buttonWidth = buttonElement.offsetWidth;
    this.renderer.setStyle(buttonElement, 'width', buttonWidth + 'px');
  }
}
