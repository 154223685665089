import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  DocumentUrlModel, ECategoryStatus,
  EReclamationStatusKey,
  ETablePagination,
  ReclamationCategoryModel,
  ReclamationCategoryTypeModel,
  IReclamationData,
  IReclamationProducts,
  ITablePagination,
  ReclamationRuleModel,
  ReclamationSettingsModel,
  ReclamationsModel,
  ReclamationStatusCountModel,
  TClientReclamationHome,
  UnitUserModel,
} from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { FileHelper } from '../../helpers/file';
import { PaginationUtil } from '../../helpers/pagination.util';
import { DataTableHelperService } from '../data-table-helper/data-table-helper.service';

@Injectable({
  providedIn: 'root',
})
export class ReclamationClientService {
  private reclamationPagination$ = new BehaviorSubject<ITablePagination | null>(null);

  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private http: HttpClient,
    private tableService: DataTableHelperService
  ) {}

  get pagination$(): Observable<ITablePagination | null> {
    return this.reclamationPagination$.asObservable();
  }

  public getReclamationsList(
    unitId: number,
    sort = '',
    search = '',
    filter = '',
    paginate?: ITablePagination
  ): Observable<IReclamationData> {
    let params: HttpParams = this.tableService.paramsHandler(search, sort, paginate);
    if (filter) {
      params = params.set('statuses[]', filter);
    }
    return this.http
      .get<any>(this.environment.apiBaseUrl + `api/v1/client/units/${unitId}/reclamations`, {
        params,
        observe: 'response',
      })
      .pipe(
        tap((result) => {
          const pagination: ITablePagination = {
            currentPage: PaginationUtil.convertPaginationType(result.headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(result.headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalPages),
          };
          this.reclamationPagination$.next(pagination);
        }),
        map((res: any) => res.body?.data),
        tap((data) => {
          data.reclamations = data.reclamations.map((x) => plainToClass(ReclamationsModel, x));
          data.status_stats = data.status_stats.map((s) => plainToClass(ReclamationStatusCountModel, s));
        })
      );
  }

  public getCategories(projectId: number, areaStatus = ECategoryStatus.All): Observable<ReclamationCategoryModel[]> {
    const params = new HttpParams({fromObject: {
        area_status: areaStatus
      }});
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/client/projects/${projectId}/reclamation_categories`, {
        observe: 'response',
        params
      })
      .pipe(map((res) => res.body.data.reclamation_categories));
  }

  public getTypes(projectId: number, categoryId: number, areaStatus = ECategoryStatus.All): Observable<ReclamationCategoryTypeModel[]> {
    const params = new HttpParams({fromObject: {
        area_status: areaStatus
      }});
    return this.http
      .get<any>(
        `${this.environment.apiBaseUrl}api/v1/client/projects/${projectId}/reclamation_categories/${categoryId}/reclamation_types`,
        {
          observe: 'response',
          params
        }
      )
      .pipe(map((res) => res.body.data.reclamation_types));
  }

  public getProducts(projectId: number, unitId: number, search: string): Observable<IReclamationProducts[]> {
    let params: HttpParams = new HttpParams();
    if (search) params = params.set('search', search);

    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/client/projects/${projectId}/units/${unitId}/wishlist/products`, {
        params: params,
        observe: 'response',
      })
      .pipe(map((res) => res.body.data.products));
  }

  public createProjectReclamation(unitId: number, body: FormData): Observable<ReclamationsModel> {
    return this.http.post(this.environment.apiBaseUrl + `api/v1/client/units/${unitId}/reclamations`, body).pipe(
      map((res: any) => res.data),
      map((reclamation) => plainToClass(ReclamationsModel, reclamation))
    );
  }

  public getUnitDetails(projectId: number, id: number): Observable<UnitUserModel> {
    const includes = ['floors', 'rooms'];
    const params = new HttpParams({
      // eslint-disable-next-line @typescript-eslint/naming-convention
      fromObject: { 'includes[]': includes },
    });
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/client/projects/${projectId}/units/${id}`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((res: any) => res.body?.data),
        map((data: UnitUserModel) => plainToClass(UnitUserModel, data))
      );
  }

  public getReclamationDetails(unitId: number, id: number): Observable<ReclamationsModel> {
    return this.http
      .get<any>(`${this.environment.apiBaseUrl}api/v1/client/units/${unitId}/reclamations/${id}`, {
        observe: 'response',
      })
      .pipe(
        map((res: any) => res.body?.data),
        map((data) => plainToClass(ReclamationsModel, data))
      );
  }

  public updateProjectReclamation(projectId: number, id: number, body: FormData): Observable<ReclamationsModel> {
    return this.http.patch(this.environment.apiBaseUrl + `api/v1/projects/${projectId}/reclamations/${id}`, body).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(ReclamationsModel, data))
    );
  }

  public changeReclamationStatus(
    unitId: number,
    reclamationId: number,
    status: EReclamationStatusKey,
    body?: FormData
  ): Observable<ReclamationsModel> {
    let params: HttpParams = new HttpParams();
    params = params.set('status', status);

    return this.http
      .patch(
        this.environment.apiBaseUrl + `api/v1/client/units/${unitId}/reclamations/${reclamationId}/change_status`,
        body,
        {
          params: params,
          observe: 'response',
        }
      )
      .pipe(
        map((res: any) => res.body?.data),
        map((data) => plainToClass(ReclamationsModel, data))
      );
  }

  getRules(projectId: string, categoryId: number, typeId?: number): Observable<ReclamationRuleModel[]> {
    let params: HttpParams = new HttpParams();
    if (typeId) params = params.set('reclamation_type_id', typeId.toString());
    if (categoryId) params = params.set('reclamation_category_id', categoryId.toString());
    return this.http
      .get<any>(this.environment.apiBaseUrl + `api/v1/client/projects/${projectId}/project_reclamation_rules`, {
        params,
        observe: 'response',
      })
      .pipe(
        map((res) => res.body?.data?.project_reclamation_rules),
        map((data: ReclamationRuleModel[]) => plainToClass(ReclamationRuleModel, data))
      );
  }

  public getReclamationSettings(projectId: number): Observable<ReclamationSettingsModel> {
    return this.http
      .get(this.environment.apiBaseUrl + `api/v1/client/projects/${projectId}/project_reclamation_settings`)
      .pipe(
        map((res: any) => res.data),
        map((data) => plainToClass(ReclamationSettingsModel, data))
      );
  }

  public getIndexReclamationsList(
    projectId: number,
    unitId: number,
    page = 1,
    perPage = 10,
    sortBy = 'identifier_desc'
  ): Observable<TClientReclamationHome> {
    let params: HttpParams = new HttpParams();
    params = params.set('page', page.toString());
    params = params.set('per_page', perPage.toString());
    params = params.set('sort_by', sortBy);
    let pagination!: ITablePagination;

    return this.http
      .get<any>(this.environment.apiBaseUrl + `api/v1/client/units/${unitId}/reclamations`, {
        params,
        observe: 'response',
      })
      .pipe(
        tap((result) => {
          pagination = {
            currentPage: PaginationUtil.convertPaginationType(result.headers, ETablePagination.CurrentPage),
            pageItems: PaginationUtil.convertPaginationType(result.headers, ETablePagination.PageItems),
            totalCount: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalCount),
            totalPages: PaginationUtil.convertPaginationType(result.headers, ETablePagination.TotalPages),
          };
        }),
        map((res) => res.body?.data?.reclamations),
        map((data) => {
          return {
            pagination,
            value: data.map((source: any) => plainToClass(ReclamationsModel, source)),
          };
        })
      );
  }

  public generateDocumentUrlsS3(file: File): Observable<DocumentUrlModel[]> {
    const fd = new FormData();
    fd.append('filenames[]', file.name);
    return this.http
      .post<{
        data: DocumentUrlModel[];
      }>(`${this.environment.apiBaseUrl}api/v1/files/generate_multiple_presigned_urls`, fd, {})
      .pipe(
        map((res: any) => res.data),
        map((data: DocumentUrlModel[]) => plainToClass(DocumentUrlModel, data))
      );
  }

  uploadFileToS3(url: DocumentUrlModel, file: File): Observable<any> {
    let fileType = '';
    if (!file.type) {
      fileType = FileHelper.getFileTypeByExtension(file);
    } else {
      fileType = file.type;
    }
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const headers = new HttpHeaders({ 'Content-Type': fileType });

    return this.http.put(url.uploadUrl, file, { headers });
  }
}
