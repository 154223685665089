<!--
  ******************************************************
  @Cypress
  - interaction with confirm button
  ******************************************************
-->
<div class="confirm-modal" [ngClass]="{ 'pointer-events-none': loading }">
  <div class="confirm-modal__header d-flex">
    <div class="confirm-modal__header-title">{{ title }}</div>
    <div
      class="confirm-modal__header-close"
      (click)="closeModal()"
      [ngbTooltip]="'Shared.Close' | translate"
      triggers="hover touchstart"
      [class.client]="isClient"
    >
      <img src="/assets/close_newest_gray.svg" alt="Close modal" />
    </div>
  </div>

  <div class="confirm-modal__body">
    <p class="confirm-modal__description lh-24 word-break" [innerHtml]="description"></p>
    <ng-container *ngIf="confirmRemoval">
      <p *ngIf="secondaryDescription" class="lh-24 fz-16 mb-12 mt-12">{{ secondaryDescription | translate }}</p>
      <p class="lh-24 fz-16 mb-20 mt-12">{{ deleteDescription | translate }}</p>
      <label>
        <span class="fz-14 fw-600 lh-16">{{ 'Title.Confirmation_phrase' | translate }}</span>
        <input
          [(ngModel)]="confirmInputText"
          (ngModelChange)="onInputChange($event)"
          type="text"
          [placeholder]="deletePlaceholder | translate"
          class="mt-8"
          atlPreventDoubleSpace
          atlReplaceInput
          atlCapitalLetter
          tabindex="-1"
        />
      </label>
    </ng-container>
  </div>

  <div class="d-flex confirm-modal__footer">
    <button *ngIf="showCancel" (click)="closeModal()" class="newest-button button-gray mr-16">
      {{ cancelBtnLabel }}
    </button>
    <button
      [disabled]="confirmRemoval && deleteVerificationKey !== confirmInputText"
      #submitButton
      [class]="button.class"
      (click)="confirm()"
      type="submit"
      data-cy="newest-confirm-modal-submit"
      [ngClass]="{ 'newest-button': !buttonRed, 'button-red': buttonRed }"
    >
      <ng-container *ngIf="!loading; else loadingBtn">
        {{ button.text }}
      </ng-container>
    </button>
  </div>
</div>

<ng-template #loadingBtn>
  <img class="spinner" src="assets/spinner-blue.svg" alt="spinner" />
</ng-template>
