import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LockFieldDirective, SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import { AutoResizeModule } from '@atlas-workspace/shared/ui';

import { TextareaAutoresizeComponent } from './textarea-autoresize.component';



@NgModule({
  declarations: [
    TextareaAutoresizeComponent
  ],
  exports: [
    TextareaAutoresizeComponent
  ], imports: [CommonModule, AutoResizeModule, SharedDirectivesModule, LockFieldDirective]
})
export class TextareaAutoresizeModule { }
