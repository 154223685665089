import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TitledCheckboxComponent } from './titled-checkbox.component';

@NgModule({
  declarations: [TitledCheckboxComponent],
  imports: [CommonModule, FormsModule],
  exports: [TitledCheckboxComponent],
})
export class TitledCheckboxModule {}
