import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedPipesModule } from '@atlas-workspace/shared/pipes';
import { TranslateModule } from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';

import { NewestTextEditorComponent } from './newest-text-editor.component';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';

@NgModule({
  declarations: [NewestTextEditorComponent],
  exports: [NewestTextEditorComponent],
  imports: [CommonModule, EditorModule, ReactiveFormsModule, TranslateModule, SharedPipesModule, SharedDirectivesModule],
})
export class NewestTextEditorModule {}
