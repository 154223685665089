import { Expose, Type } from 'class-transformer';

import { fileExtensions } from '../helpies/files';
import { FileNameModel } from './file.model';

type DocumentType = 'file' | 'folder';

export enum DownloadStatus {
  Done = 'done',
}

export enum DocumentsLoadingType {
  Items = 'items',
  Folder = 'folder',
}

export interface IDocument {
  created_at: string;
  document_type: DocumentType;
  file_extension: fileExtensions;
  file_size: number;
  filename: {
    download_url: string;
    url: string;
  };
  folder_id: number | null;
  id: number;
  name: string;
  title: string;
}

export interface IPackDocument {
  id: number;
  status: string;
  updated_at: string;
  documents: IDocument[];
}

export interface IPackShowDocument {
  id: number;
  status: string | DownloadStatus.Done;
  updated_at: string;
  filename: {
    url: string;
    download_url: string;
  };
  file_extension: string;
  file_size: number;
}

class ParentFolder {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'title' })
  title!: string;
}

interface IDocumentCreator {
  type: string;
  name: string;
  id: number;
  email: string;
}

export class DocumentModel {
  @Expose({ name: 'archived_at' })
  archivedAt?: string;
  @Expose({ name: 'created_at' })
  createdAt?: string;
  @Expose({ name: 'document_type' })
  documentType!: DocumentType;
  @Expose({ name: 'file_extension' })
  fileExtension!: fileExtensions;

  @Expose({ name: 'file_size' })
  fileSize!: number;
  @Expose({ name: 'folder_size' })
  folderSize!: number;
  @Expose({ name: 'filename' })
  @Type(() => FileNameModel)
  fileName!: FileNameModel;

  @Expose({ name: 'folder_id' })
  folderId!: number | null;
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'main_admin_name' })
  mainAdminName!: string | null;
  @Expose({ name: 'title' })
  title!: string;
  @Expose({ name: 'localized_title' })
  localizedTitle!: string;

  selected = false;
  disabled = false;

  @Expose({ name: 'inner_items' })
  @Type(() => DocumentModel)
  children!: DocumentModel[];

  @Expose({ name: 'parents' })
  @Type(() => ParentFolder)
  parents!: ParentFolder[];

  @Expose({ name: 'updated_at' })
  updatedAt?: string;
  @Expose({ name: 'updater_name' })
  updaterName!: string;
  @Expose({ name: 'creator' })
  creator!: IDocumentCreator;

  type!: string;
}

export class DocumentUrlModel {
  @Expose({ name: 'upload_url' })
  uploadUrl!: string;
  @Expose({ name: 'public_url' })
  publicUrl!: string;
  @Expose({ name: 'name' })
  name!: string;
}

export interface Folder {
  name: string;
  file?: File;
  children: Folder[];
  position?: number;
}

export interface DocumentTreeStructure {
  filename_remote_url?: string;
  title: string;
  children: DocumentTreeStructure[];
  position?: number;
}

export interface UploadDocumentsTreeBody {
  documents: DocumentTreeStructure[];
  folder_id?: string;
}

export interface IFileFolderLoading {
  loading: boolean;
  type: DocumentsLoadingType | '';
  alreadyLoaded: number;
}

export interface IOpenDocumentQueryParams {
  selectedId: string;
  filterVal: string;
  unitId: string;
  layoutId: string;
  folderId: string;
  isInternal: string;
}

export interface IDocumentHistory {
  filterVal: string;
  currentFolder: string;
  docUnitId: string;
  docLayoutId: string;
  isInternal: boolean;
  document: DocumentModel;
}

export const DOCUMENTS_HISTORY = 'documentsHistory';
