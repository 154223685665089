<div class="global-label" [class.string-input]="stringMode">
  <textarea
    atlLockField
    atlMaxLength
    [currentValue]="inputValue"
    [maxLength]="maxLength"
    atlCapitalLetter
    [capitalize]="capitalize"
    class="textarea v-scrollbar"
    [class.textarea__string-mode]="stringMode"
    atlAutoresize
    [offset]="autoresizeOffset"
    [maxHeight]="maxHeight"
    #input
    (keydown)="enter($event)"
    (input)="changeValue(input.value)"
    (paste)="changeValue(input.value)"
    [placeholder]="!stringMode ? placeholder : ''"
    [value]="inputValue"
    (focus)="onFocus(true)"
    (focusout)="onFocus(false)"
    (blur)="onBlur(input.value)"
    [readOnly]="disabled"
    [tabindex]="tabindex"
    rows="1"
    atlReplaceInput
    [ngClass]="{ 'fixed-textarea': fixedPosition, 'position-absolute': absolutePosition }"
  ></textarea>
  <div *ngIf="stringMode" class="string-mode" [class.placeholder]="!input.value.length">
    {{ input.value.length ? input.value : placeholder }}
  </div>
</div>
