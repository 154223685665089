import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import {CommonModule} from "@angular/common";

@UntilDestroy()
@Component({
  selector: 'atl-shared-table',
  templateUrl: './shared-table.component.html',
  styleUrls: ['./shared-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule]
})
export class SharedTableComponent implements OnInit {
  @Input() bodyOverflow = false;
  @Input() scrollToBottom$?: Subject<boolean>;
  @Input() scrollToTop$?: Subject<boolean>;
  @Input() scrollToTopOnTbody = true;
  @Input() loading = false;

  @Output() scrollTable = new EventEmitter<void>();

  @ViewChild('tbody', { static: true }) tbody!: ElementRef<HTMLTableElement>;
  @ViewChild('tableWrapper', { static: true }) tableWrapper!: ElementRef;

  ngOnInit(): void {
    if (this.scrollToTop$) {
      this.scrollToTop$.pipe(untilDestroyed(this)).subscribe(() => {
        (this.scrollToTopOnTbody ? this.tbody : this.tableWrapper).nativeElement.scrollTo(0, 0);
      });
    }
    if (this.scrollToBottom$) {
      this.scrollToBottom$.pipe(untilDestroyed(this)).subscribe(() => {
        const tableHeight = this.tableWrapper.nativeElement.scrollHeight;
        this.tableWrapper.nativeElement.scrollTo(0, tableHeight);
      });
    }
  }

  onScroll(): void {
    this.scrollTable.next();
  }
}
