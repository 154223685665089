import { Component, Input } from '@angular/core';
import { FileModel } from '@atlas-workspace/shared/models';
import { SwiperOptions } from 'swiper/types';

import { IModalHeaderInterface } from '../../interfaces/modal-header.interface';

@Component({
  selector: 'atl-video-modal',
  templateUrl: './video-modal.component.html',
  styleUrls: ['./video-modal.component.scss'],
})
export class VideoModalComponent {
  @Input() slides!: FileModel;
  @Input() index!: number;
  @Input() swiperConfig!: SwiperOptions;

  get headerData(): IModalHeaderInterface {
    return {
      title: this.slides.name,
      subtitle: this.slides.uploadDate,
      downloadLink: this.slides.fileName.downloadUrl,
    };
  }

  public onIndexChange(i: number): void {
    this.index = i;
  }
}
