<div
  class="custom-filter-dropdown"
  atlClickOutside
  (clickOutside)="closeExpand()"
  atlBlockResizeListener
  [isSpecialElement]="true"
  [resizeDebounceTime]="resizeDebounceTime"
  (resizeBlockHandler)="onHostResize()"
>
  <div
    (click)="expandHandler()"
    class="custom-filter-dropdown__btn"
    [class.active]="asButton ? selectValue.length : null"
    [class.open]="expanded"
    [class.cursor-default]="disabled"
    [ngStyle]="{
      'padding-left.px': chipsDropdownButtonPadding,
      'padding-right.px': chipsDropdownButtonPadding
    }"
    #chipsContainer
  >
    <ng-container *ngIf="asButton">
      <span class="mr-4">{{ placeholder | translate }}</span>
      <span *ngIf="selectValue.length" class="custom-filter-dropdown__btn-count"> +{{ selectValue.length }} </span>
      <span *ngIf="showArrow" class="arrow-icon ml-8">
        <img src="assets/arrow_down_option_store.svg" alt="arrow" />
      </span>
    </ng-container>

    <ng-container *ngIf="!asButton">
      <ng-container *ngIf="allPlaceholder">
        <ng-container *ngIf="selectValue.length < listOfItems.length">
          <ng-container *ngIf="chips">
            <div #chipsListWrapper class="d-flex align-items-center">
              <span
                #chipsItem
                *ngFor="let item of arrLabel"
                class="mr-8 d-block ellipsis"
                [ngClass]="[
                  !selectValue.length ? 'text-gray-902' : '',
                  (chips && selectValue.length) ? (chipsClassName + ' ' + chipsHiddenClassName) : '',
                ]"
                [ngbTooltip]="item"
                [disableTooltip]="chipsItem.offsetWidth >= chipsItem.scrollWidth"
                container="body"
                triggers="hover touchstart"
              >
                {{ item }}
              </span>
            </div>
          </ng-container>
          <ng-container *ngIf="!chips">
            <span
              #labelField
              *ngIf="selectValue.length < listOfItems.length"
              class="mr-4 d-block ellipsis"
              [class.text-gray-902]="!selectValue.length"
              [ngbTooltip]="label"
              [disableTooltip]="labelField.offsetWidth >= labelField.scrollWidth"
              container="body"
              triggers="hover touchstart"
            >
              {{ label }}
            </span>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="selectValue.length === listOfItems.length">
          <span [class.chips]="chips">{{ allPlaceholder | translate }}</span>
        </ng-container>
        <span
          *ngIf="
            (useDynamicCountViewSelectItems
              ? selectValue.length && notVisibleChipsCount
              : selectValue.length > countViewSelectItems)
            && selectValue.length < listOfItems.length
          "
          class="custom-filter-dropdown__select-count ml-8"
        >
          +{{ useDynamicCountViewSelectItems ? notVisibleChipsCount : (selectValue.length - countViewSelectItems) }}
        </span>
      </ng-container>
      <ng-container *ngIf="!allPlaceholder">
        <span
          #labelField
          class="mr-4 d-block ellipsis"
          [class.text-gray-902]="!selectValue.length"
          [ngbTooltip]="label"
          [disableTooltip]="labelField.offsetWidth >= labelField.scrollWidth"
          container="body"
          triggers="hover touchstart"
          >{{ label }}</span
        >
        <span
          *ngIf="
            useDynamicCountViewSelectItems
              ? (selectValue.length && notVisibleChipsCount)
              : (selectValue.length > countViewSelectItems)
          "
          class="custom-filter-dropdown__select-count ml-8"
        >
          +{{ useDynamicCountViewSelectItems ? notVisibleChipsCount : (selectValue.length - countViewSelectItems) }}
        </span>
      </ng-container>
    </ng-container>
  </div>

  <div class="custom-filter-dropdown__expanded" [class.expanded]="expanded">
    <span *ngIf="title" class="custom-filter-dropdown__expanded-title">{{ title | translate }}</span>
    <label *ngIf="searchable" class="search-input">
      <input
        [value]="search"
        (input)="searchHandler($event)"
        type="text"
        [placeholder]="'Shared.Entity.Search' | translate"
      />
      <span class="clear-search" (click)="searchHandler($event)">
        <img *ngIf="search" src="/assets/newest_remove_text_gray.svg" alt="icon" />
      </span>
    </label>
    <div class="custom-filter-dropdown__expanded-wrap v-scrollbar small">
      <ng-container *ngIf="listFiltered?.length; else notFound">
        <ng-container *ngFor="let item of listFiltered">
          <div class="custom-filter-dropdown__expanded-item" (click)="itemSelect(item)">
            <atl-titled-checkbox [value]="item.selected"></atl-titled-checkbox>
            <span
              #title
              class="d-block ellipsis"
              [ngbTooltip]="item[bindLabel]"
              [openDelay]="tooltipOpenDelay"
              container="body"
              [disableTooltip]="title.offsetWidth >= title.scrollWidth"
              triggers="hover touchstart"
              >{{ translateTexts ? (item[bindLabel] | translate): item[bindLabel] }}</span
            >
          </div>
        </ng-container>
      </ng-container>
    </div>
    <div *ngIf="clearable" (click)="clearList($event)" class="custom-filter-dropdown__expanded-footer">
      <span>
        {{ 'Shared.Entity.Clear_all' | translate }}
      </span>
    </div>
  </div>
</div>

<ng-template #notFound>
  <div class="custom-filter-dropdown__expanded-item">
    {{ 'Shared.Entity.No_matches' | translate }}
  </div>
</ng-template>
