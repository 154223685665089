import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { CountDownModule } from '../countdown/countdown.module';
import { UndoChangesComponent } from './undo-changes.component';

@NgModule({
  imports: [CommonModule, CountDownModule],
  declarations: [UndoChangesComponent],
  exports: [UndoChangesComponent, CountDownModule],
})
export class UndoCHangeslModule {}
