import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { EBookingType, UnitUserModel } from '@atlas-workspace/shared/models';
import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';
import { of, Subject } from 'rxjs';
import { delay, take } from 'rxjs/operators';

import {TitledCheckboxModule} from "../titled-checkbox/titled-checkbox.module";

@UntilDestroy()
@Component({
  selector: 'atl-availability-dropdown',
  templateUrl: './availability-dropdown.component.html',
  styleUrls: ['./availability-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AvailabilityDropdownComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, NgbDropdownModule, NgxMaskDirective, TitledCheckboxModule]
})
export class AvailabilityDropdownComponent implements ControlValueAccessor, OnInit {
  @Input() multi = true;
  @Input() index = 0;
  @Input() set units(units: UnitUserModel[]) {
    if (units) {
      this.unitsArr = units;
    }
    this.cdr.detectChanges();
  }

  @Input() parentScroll$!: Subject<boolean>;
  @Input() disabled  = false

  @ViewChild(NgbDropdown, { static: true }) dropdownRef!: NgbDropdown;
  @ViewChild('limitInput') limitInput!: ElementRef<HTMLInputElement>;

  public unitsArr!: UnitUserModel[];
  public selectedValue = '';
  public readonly bookingTypeList = EBookingType;
  public unitSelected: UnitUserModel[] = []
  public search = '';

  public placeholder = '';
  public allSelected = false;

  private value: number[] = []

  public onChange!: (value:number[]) => void;
  public onTouched!: () => void;

  constructor(private readonly cdr: ChangeDetectorRef, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.dropdownRef.openChange.pipe(untilDestroyed(this)).subscribe((change) => {
      if (!change) {
        this.closeDropdown();
      }
    });

    this.placeholder = this.translateService.instant('Meeting.Modal.Free_for_booking');
    this.selectedValue = this.placeholder;

    this.parentScroll$.pipe(untilDestroyed(this)).subscribe(value => {
      if (value) {
        this.dropdownRef.close();
        this.parentScroll$.next(false);
      }
    })
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public searchUnit(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.search = target.value;
  }

  get filteredUnits(): UnitUserModel[] {
    return this.unitsArr?.filter((unit) => unit.identifier.toLowerCase().includes(this.search.toLowerCase()));
  }

  public selectAll(): void {
    this.allSelected = !this.allSelected;
    this.unitsArr.forEach((unit: UnitUserModel) => {
      if (!unit.disabled) {
        unit.selected = this.allSelected;
      }
    });
    this.unitSelected = this.unitsArr.filter(u => u.selected && !u.disabled);
    this.updateSelectedValue();
  }

  public selectUnit(unit: UnitUserModel): void {
    if (unit.disabled) return;
    if (this.multi) {
      unit.selected = !unit.selected;
    } else {
      const currentState = unit.selected;
      this.unitsArr.forEach(u => {
        if (!u.disabled) {
          u.selected = false;
        }
      });
      unit.selected = !currentState;
    }
    this.unitSelected = this.unitsArr.filter(u => u.selected && !u.disabled);
    this.allSelected = this.unitSelected.length === this.unitsArr.length;
    this.updateSelectedValue();
  }

  closeDropdown(): void {
    this.dropdownRef.close();
    this.setOnChange();
  }

  initValue(): void {
    this.value = this.unitSelected.map(unit => unit.id);
  }

  setOnChange(): void {
    this.initValue();
    this.onChange(this.value);
    this.cdr.markForCheck();
  }

  writeValue(value: any[]): void {
    if (value) {
      this.unitSelected = this.unitsArr?.filter(unit => value.some(v => (v?.id || v) === unit.id)) || [];
      this.updateSelectedValue();
    } else {
      this.unitSelected = [];
    }

    of(null)
      .pipe(take(1), delay(0))
      .subscribe(() => this.setOnChange());
  }

  clearSelect(): void {
    this.unitsArr.forEach(u => u.selected = false);
    this.unitSelected = [];
    this.updateSelectedValue();
  }

  private updateSelectedValue(): void {
    this.selectedValue = this.unitSelected.length ? this.unitSelected.map(u => `${u?.identifier  || ''}`).join(', ') : this.placeholder;
  }
}
