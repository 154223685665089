<div class="document-preview">
  <div class="document-preview__header d-flex align-items-center justify-content-between fw-500">
    <div class="document-preview__header-wr">
      <p *ngIf="doc?.name" class="document-preview__header-title ellipsis fz-16 fw-500 lh-24">
        {{ doc.name }}
      </p>
      <p *ngIf="doc?.createdAt" class="document-preview__header-subtitle text-gray-890 fz-12 lh-16">
        {{ 'Title.Uploaded_at' | translate }} {{ doc.createdAt | date: 'MMM d, y' }}
      </p>
    </div>
    <div class="document-preview__header-actions d-flex align-items-center justify-content-between">
      <a class="document-preview__header-actions-link" *ngIf="doc?.downloadLink" [href]="doc?.downloadLink" download>
        <atl-header-icon
          icon="/assets/download-icon-new.svg"
          [ngbTooltip]="'Shared.Entity.Download' | translate"
          [openDelay]="tooltipDelay"
          placement="bottom"
          triggers="hover touchstart"
          [class.client]="platform === platformTypes.WEB_CLIENT"
        ></atl-header-icon>
      </a>
      <img src="/assets/separator.svg" *ngIf="doc?.downloadLink" alt="icon" class="ml-4 mr-4" />
      <atl-header-icon
        icon="/assets/close_newest_gray.svg"
        (click)="close()"
        [ngbTooltip]="'Alt.Close' | translate"
        [openDelay]="tooltipDelay"
        placement="bottom"
        triggers="hover touchstart"
        [class.client]="platform === platformTypes.WEB_CLIENT"
      ></atl-header-icon>
    </div>
  </div>
  <div class="document-preview__preview">
    <ngx-doc-viewer
      *ngIf="previewType !== pdfViewerType"
      [url]="doc.previewLink"
      [viewer]="previewType"
      [googleCheckContentLoaded]="googleCheckContentLoaded"
      [googleCheckInterval]="googleCheckInterval"
      [googleMaxChecks]="googleMaxChecks"
      class="document-preview__preview-lib"
    ></ngx-doc-viewer>
    <pdf-viewer
      *ngIf="previewType === pdfViewerType"
      [src]="doc.previewLink"
      [render-text]="true"
      [original-size]="false"
      class="document-preview__preview-lib"
    ></pdf-viewer>
  </div>
</div>
