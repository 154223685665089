import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { UnitModel } from '@atlas-workspace/shared/models';
import { ProjectService } from '@atlas-workspace/shared/service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { CommonService } from '../service/common/common.service';

@Injectable({
  providedIn: 'root',
})
export class OptionResolver {
  constructor(private projectService: ProjectService, private commonService: CommonService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<UnitModel> {
    const projectId = route.parent?.parent?.params.projectId;
    const unitId = route.parent?.parent?.params.unitId;
    return this.projectService.getUnitById(projectId, unitId).pipe(
      take(1),
      map((value: UnitModel) => {
        const layoutData = {
          layoutTypeId: value?.layoutTypeId,
          layoutTypeStatus: value?.layoutTypeStatus,
        };
        this.commonService.setSelectedUnitTypeData(layoutData);
        return value;
      })
    );
  }
}
