import { Expose, Type } from 'class-transformer';

import { FileModel, IFile } from './file.model';
import { IImgFile, ImageModel } from './image.model';

export interface IAdminTaskDetail {
  id: number;
  name: number;
  due_date: number;
  company: {
    id: number;
    title: string;
  };
  contact_person: {
    id: number;
    name: string;
    email: string;
    phone: string;
    company_id: number;
  };
  images: IImgFile[] | ImageModel;
  file_resources: IFile[] | FileModel;
}

export type FieldsTaskName = string | 'name' | 'description' | 'dueDate' | 'files';

export class AdminTaskModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'name' })
  name!: number;
  @Expose({ name: 'due_date' })
  dueDate!: number;
  @Expose({ name: 'units_count' })
  unitsCount!: number;
  @Expose({ name: 'layout_type' })
  layoutType!: string;
  @Expose({ name: 'task_type' })
  taskType!: string;
  @Expose({ name: 'units_completed_task_count' })
  unitsCompletedTaskCount!: number;
  @Expose({ name: 'layout_type_id' })
  layoutTypeId!: number;
  @Expose({ name: 'progress_percentage' })
  progressPercentage!: number;

  selected = false;
  isOption = false;

  constructor() {
    this.id = 0;
  }
}

export class AdminTaskDetailModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: number;
  @Expose({ name: 'description' })
  description!: number;
  @Expose({ name: 'due_date' })
  dueDate!: string;
  @Expose({ name: 'layout_type_name' })
  layoutTypeName!: string;
  @Expose({ name: 'layout_option_id' })
  layoutOptionId!: number;
  @Expose({ name: 'company' })
  company!: {
    id: number;
    title: string;
  };
  @Expose({ name: 'contact_person' })
  contactPerson!: {
    id: number;
    name: string;
    email: string;
    phone: string;
    company_id: number;
  };
  @Expose({ name: 'images' })
  @Type(() => ImageModel)
  images!: ImageModel[];
  @Expose({ name: 'file_resources' })
  @Type(() => FileModel)
  fileResources!: FileModel[];

  @Expose({ name: 'responsible_vendor' })
  responsibleVendor!: string;
  @Expose({ name: 'contact_vendor' })
  contactVendor!: string;
  @Expose({ name: 'contact_phone' })
  contactPhone!: string;
  @Expose({ name: 'contact_email' })
  contactEmail!: string;
  @Expose({ name: 'units_ids' })
  unitsIds?: number[];
}
