import {CommonModule} from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output, QueryList,
  ViewChild, ViewChildren
} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {BlockResizeListenerDirective, ClickOutsideDirective} from "@atlas-workspace/shared/directives";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {cloneDeep} from 'lodash';

//@ts-ignore
import arrowDownIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/arrow-down-sm.svg';

import {TitledCheckboxModule} from "../titled-checkbox/titled-checkbox.module";

@Component({
  selector: 'atl-custom-filter-dropdown',
  templateUrl: './custom-filter-dropdown.component.html',
  styleUrls: ['./custom-filter-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomFilterDropdownComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    BlockResizeListenerDirective,
    ClickOutsideDirective,
    TranslateModule,
    NgbTooltipModule,
    TitledCheckboxModule
  ],
})
export class CustomFilterDropdownComponent implements ControlValueAccessor {
  @Input() set setListOfItems(value: any) {
    this.listOfItems = cloneDeep(value);
  }
  @Input() listOfItems!: any[];
  @Input() disabled = false;
  @Input() clearable = false;
  @Input() bindLabel!: string;
  @Input() bindValue!: string;
  @Input() showArrow = false;
  @Input() placeholder = '';
  @Input() title = '';
  @Input() asButton = true;
  @Input() searchable = true
  @Input() countViewSelectItems = 1;
  @Input() useDynamicCountViewSelectItems = false;
  @Input() singleSelect = false;
  @Input() allPlaceholder = '';
  @Input() chips = false;
  @Input() translateTexts = true;

  @Output() selectHandler = new EventEmitter();

  @ViewChild('chipsContainer', { static: true }) chipsContainer!: ElementRef<HTMLElement>;
  @ViewChild('chipsListWrapper', { static: false }) chipsListWrapper!: ElementRef<HTMLElement>;
  @ViewChildren('chipsItem') chipItems!: QueryList<ElementRef<HTMLElement>>;

  public selectValue: any[] = [];
  public readonly arrowDownIcon = arrowDownIcon;
  public readonly tooltipOpenDelay = 400;
  public readonly chipsHiddenClassName = 'hidden';
  public readonly chipsClassName = 'chips';
  public readonly notVisibleChipsCounterWidth = 36;
  public readonly chipsDropdownButtonPadding = 12;
  public readonly resizeDebounceTime = 100;
  public notVisibleChipsCount = 0;

  public expanded = false;
  public search = '';

  protected onChange: (value: any[]) => void = () => null;
  protected onTouched: () => void = () => null;

  constructor(private readonly cdr: ChangeDetectorRef, readonly translateService: TranslateService) {}

  private calculateVisibleChips(): void {
    if (!this.chipsContainer) return;

    setTimeout(() => {
      for (const chip of this.chipItems) {
        if (chip.nativeElement.classList.contains(this.chipsClassName)) {
          chip.nativeElement.classList.add(this.chipsHiddenClassName);
        }
      }
      let notVisibleChipsCount = 0;
      for (let i = 0; i < this.chipItems.length; i++) {
        if (notVisibleChipsCount) {
          break;
        }
        const chip = this.chipItems.get(i);
        chip?.nativeElement.classList.remove(this.chipsHiddenClassName);

        const isOverflow = (): boolean => {
          const maxAvailableWidth = this.chipsContainer.nativeElement.offsetWidth - this.chipsDropdownButtonPadding * 2;
          const chipsWithCounterWidth = this.chipsListWrapper.nativeElement.offsetWidth + (
            notVisibleChipsCount ? this.notVisibleChipsCounterWidth : 0
          );
          return chipsWithCounterWidth > maxAvailableWidth;
        }
        if (isOverflow()) {
          notVisibleChipsCount = this.chipItems.length - i;
          chip?.nativeElement.classList.add(this.chipsHiddenClassName);
          // Execute again because counter element will be added and need to take into account the width of counter
          // element as well. If chips width with counter is more - hide last visible chip.
          if (isOverflow()) {
            const prevChip = this.chipItems.get(i - 1);
            prevChip?.nativeElement.classList.add(this.chipsHiddenClassName);
            notVisibleChipsCount++;
          }
        }
      }
      this.notVisibleChipsCount = notVisibleChipsCount;
    });
  }

  onHostResize(): void {
    if (this.useDynamicCountViewSelectItems) {
      this.calculateVisibleChips();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any[] | null): void {
    this.selectValue = value ?? [];
  }

  eventStop(e: Event): void {
    e.stopPropagation();
  }

  expandHandler(): void {
    if (this.disabled) return;
    this.expanded = !this.expanded;
  }

  closeExpand(): void {
    this.expanded = false;
    this.search = '';
  }

  itemSelect(item: any): void {
    const itemSelect = item.selected;
    if (this.singleSelect) {
      this.allSelectList(false);
    }
    item.selected = !itemSelect;
    this.selectValue = this.getSelectList;
    this.changeValue();
  }

  clearList(e: Event): void {
    e.stopPropagation();
    if (!this.selectValue.length) return;
    this.clearAll();
    this.changeValue();
  }

  private clearAll(): void {
    this.selectValue = [];
    this.listOfItems.forEach((item: any) => (item.selected = false));
  }

  searchHandler(e: Event): void {
    this.eventStop(e);
    this.search = (e.target as HTMLInputElement)?.value || '';
    this.cdr.detectChanges();
  }

  get listFiltered(): any[] {
    this.listOfItems?.map(item => {
      item.selected = this.selectValue.some(s => s[this.bindValue] === item[this.bindValue])
      return item;
    });
    return this.listOfItems?.filter((item: any) => item[this.bindLabel].toLowerCase().includes(this.search.toLowerCase()));
  }

  get label(): string {
    if (!this.selectValue.length) {
      return this.translateService.instant(this.placeholder);
    }
    const endIndex = this.useDynamicCountViewSelectItems
      ? (this.selectValue.length - this.notVisibleChipsCount)
      : this.countViewSelectItems;
    return this.selectValue
      .slice(0, endIndex)
      .map(item => this.translateTexts ? this.translateService.instant(item[this.bindLabel]) : item[this.bindLabel])
      .join(', ');
  }

  get arrLabel(): string[] {
    // eslint-disable-next-line no-nested-ternary
    const itemsToMap = this.selectValue.length
      ? (this.useDynamicCountViewSelectItems ? this.selectValue : this.selectValue.slice(0, this.countViewSelectItems))
      : null;
    return itemsToMap
      ? itemsToMap.map(item => this.translateService.instant(item[this.bindLabel]))
      : [this.translateService.instant(this.placeholder)];
  }

  private get getSelectList(): number[] {
    return this.listOfItems.filter((item: any) => item.selected);
  }

  private allSelectList(select: boolean): void {
    this.listOfItems.forEach((item: any) => item.selected = select)
  }

  private changeValue(): void {
    // @ts-ignore
    const value = this.bindValue ? this.selectValue.map(item => item[this.bindValue]) : this.selectValue;
    this.selectHandler.emit(value);
    this.onChange(value);
    this.calculateVisibleChips();
  }
}
