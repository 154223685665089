<div class="complete-protocol">
  <div class="complete-protocol__header d-flex align-items-center">
    <h3 class="fz-26 lh-24 fw-500 notification__title ml-24">{{ 'Title.Protocol_was_completed' | translate }}</h3>
  </div>

  <div class="fz-14 lh-24 complete-protocol__desc">
    <span class="fz-16 lh-24">
      {{ 'Description.Complete_protocol_text' | translate }}
      <span (click)="navigateToDocuments()" class="complete-protocol__nav-link cursor-pointer"
        >{{ 'Title.Unit_documents' | translate }}.</span
      >
    </span>
  </div>

  <div class="complete-protocol__footer d-flex justify-content-end align-items-center">
    <button (click)="closeModal()" class="newest-button mr-24 capitalize">{{ 'Title.Ok' | translate }}</button>
  </div>
</div>
