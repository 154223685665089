<div class="google-autocomplete__header">
  <span *ngIf="label" class="google-autocomplete__label"> {{ label | translate }} </span>
</div>

<label class="global-label">
  <input
    atlLockField
    class="global_input"
    (blur)="onTouched()"
    [value]="value"
    #inputElement
    (input)="change($event.target.value)"
    [disabled]="disabled"
    [placeholder]="placeholder"
    atlReplaceInput
  />

  <span
    *ngIf="isRequire && location && !value?.length"
    [class.map-height]="isHeightMap"
    [hidden]="isHeightMap"
    class="global-caption global-caption--error google-autocomplete__error"
  >
    {{ 'Input.Address.Error' | translate }}
  </span>

  <ng-content select="wedge-controls"></ng-content>

  <ng-content select="google-autocomplete-error"></ng-content>
</label>

<div [class.map-height]="isHeightMap" [hidden]="isHideMap" class="google-autocomplete__google-map" #googleMap></div>
