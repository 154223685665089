export enum EDateFormat {
  Month = 'month',
  Quarter = 'quarter',
  Half = 'half',
  Year = 'year',
  NotDisplay = 'not_display',
}

export interface IDateFormat {
  type: EDateFormat;
  value: string;
}

export enum ETimeFormat {
  FormatEng = 'h A',
  FormatEu = 'HH:mm',
}
