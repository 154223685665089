import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'afterSlash',
})
export class AfterSlashPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';

    const slashIndex = value.indexOf('/');

    if (slashIndex === -1) return value;

    return value.substring(slashIndex + 1).trim();
  }
}
