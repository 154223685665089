import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import Swiper from 'swiper';
import { SwiperContainer } from "swiper/swiper-element";
import { SwiperOptions } from 'swiper/types/swiper-options';

@Directive({
  selector: '[atlSwiper]',
})
export class SwiperDirective implements AfterViewInit {
  @Input() config!: SwiperOptions;
  @Input() slides: unknown[] = [];

  @Output() indexChanged = new EventEmitter<number>();
  @Output() initSwiper = new EventEmitter();

  @HostListener('slidechange', ['$event'])
  slideChange(e: CustomEvent<Swiper[]>): void {
    const newindex = e.detail[0].realIndex;
    this.config.initialSlide = newindex;
    this.indexChanged.emit(newindex);
  }

  @HostBinding('style.width')
  get height(): string {
    return 'inherit';
  }

  constructor(public el: ElementRef<SwiperContainer>) {}

  ngAfterViewInit(): void {
    Object.assign(this.el.nativeElement, this.config);
    this.el.nativeElement.initialize();
  }
}
