import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

export function pwdMatchValidator(frm: FormGroup): { mismatch: boolean } | null {
  return frm.get('password')?.value === frm.get('confirmPassword')?.value ? null : { mismatch: true };
}

export function pwdMatchValidatorV2(frm: FormGroup): ValidationErrors | null {
  const passwordControl = frm.get('password');
  const confirmPasswordControl = frm.get('confirmPassword');

  if (!passwordControl?.value && !confirmPasswordControl?.value) {
    return null;
  }

  if (passwordControl?.value === confirmPasswordControl?.value) {
    frm.get('confirmPassword')?.setErrors(null);
    return null;
  }

  frm.get('confirmPassword')?.setErrors({ mismatch: true });
  return { mismatch: true };
}

export function negativeValueValidator(ctrl: AbstractControl): ValidationErrors | null {
  const ctrlValue = ctrl.value;
  const exception = ['-', '-0'];
  if (exception.indexOf(ctrlValue) !== -1) {
    return { required: true };
  }
  return null;
}
