import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[atlCapitalLetter]',
  standalone: true
})
export class CapitalLetterDirective {
  @Input() maxLength = 30;
  @Input() capitalize = true;

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    const value = (event.target as HTMLInputElement).value;
    if (this.capitalize && value) {
      event.preventDefault();
      const start = (event.target as HTMLInputElement).selectionStart;
      const end = (event.target as HTMLInputElement).selectionEnd;
      (event.target as HTMLInputElement).value = value[0]?.toUpperCase() + value?.substring(1);
      if (start && end) {
        (event.target as HTMLInputElement).selectionStart = start;
        (event.target as HTMLInputElement).selectionEnd = end;
      }
    }
  }
}
