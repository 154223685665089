import { DocumentTreeStructure, Folder } from '@atlas-workspace/shared/models';
import { NgxFileDropEntry } from 'ngx-file-drop';

/**
 * @see https://agilie.atlassian.net/browse/AT-10917
 */
export function sortNgxFileDroppedEntries(entries: NgxFileDropEntry[]): NgxFileDropEntry[] {
  // * Remove empty folders
  entries = entries.filter(entry => entry.relativePath.slice(-1) !== '/');

  // * Set directories ordering indexes
  const directoryTopLevelMapIndexes: { [key: string]: number } = {};
  entries.forEach((entry, index) => {
    const topLevelDirectory = entry.relativePath.split('/')[0];
    if (!(topLevelDirectory in directoryTopLevelMapIndexes)) {
      directoryTopLevelMapIndexes[topLevelDirectory] = index;
    }
  });

  // * Sort nested directories
  sortNestedNgxFileDroppedEntries(entries);

  // * Order (restore) top-level nesting by initial indexing.
  // * Files in top-level nesting move to the bottom without sorting.
  entries.sort((a: NgxFileDropEntry, b: NgxFileDropEntry) => {
    const segmentsA = a.relativePath.split('/');
    const segmentsB = b.relativePath.split('/');

    const isFileA = segmentsA.length === 1;
    const isFileB = segmentsB.length === 1;

    if (isFileA && !isFileB) {
      return 1;
    }
    if (!isFileA && isFileB) {
      return -1;
    }
    if (isFileA && isFileB) {
      return 1;
    }
    return directoryTopLevelMapIndexes[segmentsA[0]] - directoryTopLevelMapIndexes[segmentsB[0]];
  });

  return entries;
}

/**
 * @see https://agilie.atlassian.net/browse/AT-10917
 */
function sortNestedNgxFileDroppedEntries(files: NgxFileDropEntry[]): void {
  let maxDeepIndex = 0;
  files.forEach(file => {
    const segments = file.relativePath.split('/');
    if (segments.length > maxDeepIndex) {
      maxDeepIndex = segments.length - 1;
    }
  });

  for (let currentDeepIndex = maxDeepIndex; currentDeepIndex > 0; currentDeepIndex--) {
    files.sort((a, b) => {
      const segmentsA = a.relativePath.split('/');
      const segmentsB = b.relativePath.split('/');
      const segmentA = segmentsA[currentDeepIndex];
      const segmentB = segmentsB[currentDeepIndex];

      if (segmentA === undefined || segmentB === undefined) {
        return 0;
      }

      const isFileA = currentDeepIndex + 1 === segmentsA.length;
      const isFileB = currentDeepIndex + 1 === segmentsB.length;

      if (isFileA && !isFileB) {
        return 1;
      }
      if (!isFileA && isFileB) {
        return -1;
      }
      return segmentA < segmentB ? -1 : 1;
    });
  }
}

/**
 * @see https://agilie.atlassian.net/browse/AT-10937
 */
export function setDeepPositionsForFoldersAndFiles(tree: Folder[]): void {
  tree.forEach((item, i) => {
    item.position = i;
    if (item.children.length) setDeepPositionsForFoldersAndFiles(item.children);
  })
}

/**
 * @see https://agilie.atlassian.net/browse/AT-10937
 */
export function deepSortFoldersAndFilesByPosition(tree: DocumentTreeStructure[]): void {
  tree.forEach((item) => {
    if (item.children.length) deepSortFoldersAndFilesByPosition(item.children);
  });
  tree.sort((a, b) => {
    return (a.position || 0) < (b.position || 0) ? -1 : 1;
  });
}
