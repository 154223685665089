import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ChangeReqHelperService {
  private openDetailsTab$ = new Subject<boolean>();

  get openDetailsTabEvent$(): Observable<boolean> {
    return this.openDetailsTab$.asObservable();
  }

  public triggerOpenChangeReqDetailsTab(): void {
    this.openDetailsTab$.next(true);
  }
}
