<div class="input-file__wrapper">
  <label data-cy="cy-input-file-browse">
    <input
      accept="{{ inputAcceptString }}"
      id="file-input-preview"
      class="file-input"
      [multiple]="multiple"
      (change)="selectedFile($event)"
      type="file"
      hidden
    />
    <span class="input-file__btn input-file__title--upload">
      <img src="/assets/paperclip.svg" alt="" />
    </span>
  </label>

  <ng-content select="otherPanel"></ng-content>

  <ng-template #removeFileIconRef let-index="index" let-type="type" let-editFile="editFile">
    <div
      (click)="removeFile(index, $event, editFile)"
      class="newest-document-card__icon-control newest-document-card__icon-control-trash"
      [class.mobile]="isMobileView"
    >
      <atl-custom-icon [attr.data-sectionvalue]="index" [icon]="trashIcon" [size]="16"></atl-custom-icon>
    </div>
  </ng-template>

  <ng-template #downloadFileIconRef let-index="index" let-type="type" let-editFile="editFile">
    <ng-container *ngIf="editFile?.fileName?.downloadUrl">
      <a
        [href]="editFile?.fileName?.downloadUrl"
        download=""
        class="newest-document-card__icon-control newest-document-card__icon-control-download"
        (click)="$event.stopImmediatePropagation()"
      >
        <atl-custom-icon [icon]="downloadIcon"></atl-custom-icon>
      </a>
    </ng-container>
  </ng-template>

  <ng-container *ngIf="listOfPreview?.length">
    <div *ngIf="!asGallery" class="input-file__preview" [class.v-scrollbar]="scrollable">
      <ng-template
        #filePreviewCard
        let-editFile="editFile"
        let-extension="extension"
        let-i="i"
        let-mimetype="mimetype"
        let-name="name"
        let-previewImage="previewImage"
        let-size="size"
        let-type="type"
      >
        <ng-template #fileTemplateRef>
          <div class="input-file__item-preview newest-document-card__item-preview">
            <div class="newest-document-card__wrapper">
              <div class="newest-document-card__info">
                <div>
                  <img class="newest-document-card__extension" [src]="extension || mimetype | iconByType" alt="icon" />
                </div>

                <div class="newest-document-card__file-name-wr">
                  <div class="newest-document-card__file-name ellipsis">
                    {{ name }}
                  </div>

                  <div class="fz-12 newest-document-card__file-size">{{ size | filesize }}</div>
                </div>
              </div>

              <div class="newest-document-card__icons-wr">
                <ng-container
                  [ngTemplateOutletContext]="{ type: type, index: i, editFile: editFile }"
                  [ngTemplateOutlet]="downloadFileIconRef"
                ></ng-container>
                <ng-container
                  [ngTemplateOutletContext]="{ type: type, index: i, editFile: editFile }"
                  [ngTemplateOutlet]="removeFileIconRef"
                ></ng-container>
              </div>
            </div>
          </div>
        </ng-template>

        <div *ngIf="type === 'image' && previewImage; else fileTemplateRef" class="gap-wrapper">
          <div
            class="input-file__item-preview newest-document-card__item-preview"
            (click)="onPreviewImageHandler(previewImage, name, extension)"
          >
            <div class="newest-document-card__wrapper d-flex justify-content-between">
              <div class="newest-document-card__info w-100 ellipsis">
                <div>
                  <img class="newest-document-card__image-container" [src]="previewImage" alt="image" />
                </div>

                <div class="newest-document-card__file-name-wr">
                  <div class="newest-document-card__file-name ellipsis">
                    {{ name }}
                  </div>

                  <div *ngIf="size" class="fz-12 newest-document-card__file-size">{{ size | filesize }}</div>
                </div>
              </div>

              <div class="newest-document-card__icons-wr">
                <ng-container
                  [ngTemplateOutletContext]="{ type: type, index: i, editFile: editFile }"
                  [ngTemplateOutlet]="downloadFileIconRef"
                ></ng-container>

                <ng-container
                  [ngTemplateOutletContext]="{ editFile: editFile, index: i }"
                  [ngTemplateOutlet]="removeFileIconRef"
                ></ng-container>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-container *ngIf="listOfPreview?.length">
        <ng-container *ngFor="let listItem of listOfPreview; index as i; trackBy: trackByFn">
          <ng-container
            *ngIf="listItem.file"
            [ngTemplateOutletContext]="{
              editFile: listItem.editFile,
              mimetype: listItem.file.type,
              name: listItem.file.name,
              previewImage: listItem.previewImage,
              size: listItem.file.size,
              type: listItem.type,
              i: i
            }"
            [ngTemplateOutlet]="filePreviewCard"
          ></ng-container>
          <ng-container
            *ngIf="listItem.editFile"
            [ngTemplateOutletContext]="{
              editFile: listItem.editFile,
              extension: listItem.editFile.fileExtension,
              name: listItem.editFile.name,
              previewImage: listItem.previewImage,
              size: listItem.editFile.fileSize,
              type: listItem.type,
              i: i
            }"
            [ngTemplateOutlet]="filePreviewCard"
          ></ng-container>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>
