import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CustomNotesService {
  constructor(private http: HttpClient, @Inject('ENVIRONMENT') private environment: IEnvironment) {}

  getNote(projectId: number, id: number): Observable<string> {
    return this.http
      .get<{ data: { note: string } }>(`${this.environment.apiBaseUrl}api/v1/projects/${projectId}/custom_notes/${id}`)
      .pipe(map((res) => res.data.note));
  }
}
