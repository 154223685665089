import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'upperCaseFirst',
})
export class UpperCaseFirstPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }
    const firstSymbol = value[0].toUpperCase();
    const allOtherSymbols = value.slice(1);
    return `${firstSymbol}${allOtherSymbols}`;
  }
}
