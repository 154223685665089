import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconByTypePipeModule, TruncatePipeModule } from '@atlas-workspace/shared/pipes';

import { FolderCardComponent } from './folder-card.component';

@NgModule({
  imports: [CommonModule, IconByTypePipeModule, TruncatePipeModule],
  exports: [FolderCardComponent],
  declarations: [FolderCardComponent],
})
export class FolderCardModule {}
