import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from '@angular/core';
import { SafeHtmlPipe } from '@atl-libs-shared-pipes/safe-html/safe-html.pipe';
import { SharedPipesModule } from "@atlas-workspace/shared/pipes";
import { TranslateService } from '@ngx-translate/core';

import { ShadowDomComponent } from '../shadow-dom-content/shadow-dom-content.component';

@Component({
  selector: 'atl-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
  standalone: true,
  imports: [CommonModule, SharedPipesModule, ShadowDomComponent]
})
export class ReadMoreComponent implements OnInit {
  @Input() date: string | undefined;
  @Input() content!: string;
  @Input() limit!: number;
  @Input() completeWords: boolean | undefined;
  @Input() htmlMode = false;
  @Input() truncate!: number;

  public isContentToggled = false;
  public nonEditedContent!: string;

  @Input() readonly readMoreLabel: string = this.translateService.instant('Shared.Entity.Read_more');
  public readLessLabel: string = this.translateService.instant('Shared.Entity.Read_less');

  constructor(private translateService: TranslateService, private safeHtml: SafeHtmlPipe) {}

  ngOnInit(): void {
    this.nonEditedContent = this.content;
    this.content = this.formatContent(this.content);
  }

  public toggleContent(event: Event): void {
    event.stopPropagation();
    this.isContentToggled = !this.isContentToggled;
    this.content = this.isContentToggled ? this.nonEditedContent : this.formatContent(this.content);
  }

  public clickHandler(event: Event): void {
    event.stopPropagation();
  }

  private formatContent(content: string): string {
    if (this.completeWords) {
      this.limit = content.substr(0, this.limit).lastIndexOf(' ');
    }

    return this.content.length < this.limit ? this.content : `${content.substr(0, this.limit)}...`;
  }
}
