import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[atlRestoreScroll]',
  standalone: true,
})
export class RestoreScrollDirective implements OnDestroy {
  private scrollPosition = 0;
  private scrollPosition$?: Subject<void>;
  private readonly scrollFluff = 8;
  private readonly scrollPositionDebounceTime = 500;

  constructor(private element: ElementRef<HTMLElement>) {}

  public fire(): void {
    if (!this.scrollPosition$?.closed) {
      this.scrollPosition$?.next();
      this.scrollPosition$?.complete();
    }
    this.scrollPosition$ = new Subject();
    fromEvent(this.element.nativeElement, 'scroll')
      .pipe(
        takeUntil(this.scrollPosition$),
        debounceTime(this.scrollPositionDebounceTime),
        map(() => this.element.nativeElement.scrollTop)
      )
      .subscribe((scrollTop) => {
        this.scrollPosition = scrollTop;
      });
  }

  public restore(): void {
    const wrapper = this.element.nativeElement;
    const isAtBottom = Math.abs(wrapper.scrollHeight - wrapper.scrollTop - wrapper.clientHeight) <= this.scrollFluff;
    this.element.nativeElement.scrollTop = this.scrollPosition - (isAtBottom ? this.scrollFluff : 0);
  }

  ngOnDestroy(): void {
    if (this.scrollPosition$?.closed) {
      this.scrollPosition$.next();
      this.scrollPosition$.complete();
    }
  }
}
