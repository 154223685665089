<div class="newest-dropdown">
  <div *ngIf="inputTitle" class="newest-dropdown__title pointer-events-none">
    <img src="assets/dropdown-circle.svg" alt="icon" />
    <span *ngIf="inputTitle"> {{ inputTitle | translate }} </span>
  </div>
  <ng-select
    #ngSelect
    atlLockField
    [class.pointer-events-none]="locked"
    (isLocked)="isLocked($event, ngSelect)"
    [(ngModel)]="inputValue"
    [placeholder]="placeholder || label | translate"
    [items]="listOfItems"
    [bindLabel]="bindLabel"
    [bindValue]="bindValue"
    [dropdownPosition]="position"
    [disabled]="disabled"
    [clearable]="clearable"
    [multiple]="multiple"
    [notFoundText]="notFoundText"
    [searchable]="searchable"
    [closeOnSelect]="closeOnSelect"
    (close)="onClose()"
    (focus)="initSearch()"
    (change)="changeValue($event)"
    (input)="changeInput($event)"
    [appendTo]="appendTo"
    [editableSearchTerm]="false"
    [groupBy]="groupBy"
    [searchFn]="searchFn"
    (search)="onListenInput($event)"
    [tabIndex]="tabindex"
  >
    <ng-container *ngIf="customInput">
      <ng-template ng-header-tmp>
        <input
          atlReplaceInput
          class="custom-search"
          type="text"
          (input)="ngSelect.filter($event.target.value)"
          [placeholder]="placeholderCustomInput ? (placeholderCustomInput | translate) : placeholderCustomInput"
        />
      </ng-template>
    </ng-container>

    <ng-template ng-optgroup-tmp let-item="item">
      {{ item.name || ('Shared.Entity.Unnamed_group' | translate) }}
    </ng-template>

    <ng-container *ngIf="customUserForm">
      <ng-template ng-label-tmp let-item="item">
        <span>{{ item.select }}</span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item">
        <span>{{ item.option }}</span>
      </ng-template>
    </ng-container>

    <ng-template [ngIf]="type === 'select'">
      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <span class="ng-value-label">{{ item[bindLabel] }}</span>
        <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">
          <img src="assets/close-gray.svg" alt="icon" />
        </span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <div class="newest-dropdown__item {{ type }}">
          <span>{{ item[bindLabel] }}</span>
          <span *ngIf="multiple"><img src="assets/checked-blue.svg" alt="" /></span>
        </div>
      </ng-template>
    </ng-template>

    <ng-container *ngIf="!userNotFound">
      <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
        <ng-container *ngIf="searchTerm && !creation">
          <ng-template [ngIf]="createNotFound">
            <div class="newest-dropdown__notfound-create">
              <button (click)="createItem(searchTerm)">{{ 'Shared.Button.Create' | translate }}</button>
              <span>{{ searchTerm }}</span>
            </div>
          </ng-template>
          <ng-template [ngIf]="!createNotFound">
            <div class="ng-select__not-found ng-option disabled">
              <span>{{ notFoundText || 'Shared.Entity.Not_found' | translate }}</span>
            </div>
          </ng-template>
        </ng-container>
        <ng-container *ngIf="(searchTerm && creation) || (!searchTerm && !listOfItems.length)">
          <div class="ng-select__not-found ng-option disabled">
            <span>{{ notFoundText || 'Shared.Entity.Not_found' | translate }}</span>
          </div>
        </ng-container>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="userNotFound">
      <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
        <div class="ng-select__not-found ng-option disabled" *ngIf="notFoundText">
          <span [innerHTML]="notFoundText | translate"></span>
          <ng-content></ng-content>
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="creation">
      <ng-template ng-footer-tmp>
        <div class="newest-dropdown-footer">
          <div
            class="newest-dropdown-footer-label fw-400 br-4"
            [class.d-none]="isActiveStaticCreation"
            (click)="openCreation(true)"
          >
            <span class="fz-20 lh-16">+</span
            ><span class="fz-14 lh-24 ml-8">{{ 'Shared.Button.Create_new' | translate }}</span>
          </div>
          <div class="newest-dropdown-footer-control" [class.d-none]="!isActiveStaticCreation">
            <input
              #creationInput
              atlPreventDoubleSpace
              atlReplaceInput
              class="br-4 w-100 fz-14 lh-24"
              type="text"
              (input)="$event.stopPropagation()"
              (keydown.enter)="onCreation(creationInput.value, $event)"
            />
            <img
              src="assets/dropdown_accept_circle.svg"
              class="newest-dropdown-footer-actions"
              (click)="onCreation(creationInput.value)"
              alt="Accept"
            />
            <img
              src="assets/dropdown_close_circle.svg"
              class="newest-dropdown-footer-actions"
              (click)="closeCreation()"
              alt="Close"
            />
          </div>
        </div>
      </ng-template>
    </ng-container>

    <ng-container *ngIf="customItemView">
      <ng-container *ngIf="customLabelView">
        <ng-template ng-label-tmp let-item="item">
          <ng-container>
            <ng-template [ngTemplateOutlet]="labelTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-template>
          </ng-container>
        </ng-template>
      </ng-container>

      <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
        <ng-container>
          <ng-template [ngTemplateOutlet]="valueTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-template>
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-select>
</div>
