import { Injectable } from '@angular/core';
import { CopyState } from '@atlas-workspace/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { ReplaySubject } from 'rxjs';

import { ToasterService } from '../toaster/toaster.service';

@Injectable()
export class PageHelperService {
  specificPage$ = new ReplaySubject<{ page: string }>(1);
  errorMessage = this.translateService.instant('Description.Something_wrong');

  constructor(private toasterService: ToasterService, private translateService: TranslateService) {}

  public copyToClipboard(params: string = '', noUrl = false): void {
    let url = noUrl ? '' : window.location.href.split('?')[0];
    if (params) {
      url += params;
    }
    switch (this.onCheckStateClipboard()) {
      case CopyState.Clipboard:
        navigator.clipboard.writeText(url).catch(() => this.showErrorMessage());
        break;
      case CopyState.ClipboardData:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window['clipboardData'].setData('Text', this.url);
        break;
      case CopyState.Error:
        this.showErrorMessage();
        break;
    }
  }

  showErrorMessage(): void {
    this.toasterService.openErrorToast(this.errorMessage);
  }

  onCheckStateClipboard(): string {
    if ((navigator as any).clipboard !== undefined) {
      return CopyState.Clipboard;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
    } else if (window['clipboardData']) {
      return CopyState.ClipboardData;
    } else {
      return CopyState.Error;
    }
  }
}
