import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  forwardRef,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {ILockedTrigger, InputLabelTemplateDirective, InputTemplateDirective, LockFieldDirective, SharedDirectivesModule} from '@atlas-workspace/shared/directives';
import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'atl-confirm-dropdown',
  templateUrl: './confirm-dropdown.component.html',
  styleUrls: ['./confirm-dropdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ConfirmDropdownComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, NgbDropdownModule, TranslateModule, LockFieldDirective, SharedDirectivesModule],
})
export class ConfirmDropdownComponent implements ControlValueAccessor {
  public value!: any;
  public search = '';
  public locked = false;
  isOpen = false;

  @Input() public disabled = false;
  @Input() private listOfItems: any[] = [];
  @Input() private bindLabel!: string;
  @Input() private bindValue!: string;
  @Input() confirmTitle = '';
  @Input() index = 0;
  @Input() arrow = false;
  @Input() searchKey: string[] = [];
  @Input() useCustomButtonVal = false;
  @Input() allowEmptyVal = false;
  @Input() placeholder = '';
  @Input() customClass = '';
  @Input() useBody = true;
  @Input() placement: string[] = ['bottom', 'top'];

  @ViewChild('dropdownRef', { static: true }) dropdownRef!: NgbDropdown;

  @ContentChild(InputTemplateDirective, { read: TemplateRef, static: false })
  itemTemplate!: TemplateRef<any> | null;

  @ContentChild(InputLabelTemplateDirective, { read: TemplateRef, static: false })
  itemLabelTemplate!: TemplateRef<any> | null;

  public onChange!: (value: any | null) => void;
  public onTouched!: () => void;

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  writeValue(value: any): void {
    this.value = this.bindValue ? this.listOfItems.find((item) => item[this.bindValue] === value) : value;
    if (!this.value) return;
    if (this.listOfItems?.length) {
      this.selectItem(this.value);
    }
  }

  isLocked(value: ILockedTrigger): void {
    this.locked = value.locked ? !value.focused : value.locked;
  }

  searchInput(event: Event): void {
    this.search = (event.target as HTMLInputElement).value;
    this.dropdownRef.open();
  }

  get filterSearchList(): any[] {
    if (!this.searchKey.length) return this.listOfItems;
    return this.search
      ? this.listOfItems.filter((item) => {
          return this.searchKey.some((key) => {
            return item[key]?.toLowerCase().includes(this.search.toLowerCase());
          });
        })
      : this.listOfItems;
  }

  selectItem(item: any): void {
    const select = !item?.selected;
    this.listOfItems.map((i) => {
      i.selected = false;
      if (select && i[this.bindLabel] === item[this.bindLabel]) {
        i.selected = select;
      } else if (!select && item !== this.value && i === this.value) {
        i.selected = true;
      }
    });
  }

  get selectedItem(): boolean {
    return this.listOfItems.find((i) => i?.selected);
  }

  confirm(): void {
    const value = this.selectedItem;
    if (value || !value && this.allowEmptyVal) {
      this.value = value;
      this.search = '';
      this.dropdownRef.close();
      this.onChange(this.bindValue ? this.value[this.bindValue] : this.value);
    }
  }

  close():void {
    this.dropdownRef.close();
  }
}
