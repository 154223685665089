<div *ngIf="asIconOnly || (timer$ | async)" class="progress">
  <svg class="progress__svg" width="12" height="12" viewBox="0 0 120 120">
    <g>
      <circle class="progress__meter" cx="60" cy="60" [attr.r]="radius" stroke-width="12" />
      <circle
        class="progress__value"
        [style.strokeDasharray]="circumference"
        [style.strokeDashoffset]="getDashoffset$ | async"
        cx="60"
        cy="60"
        [attr.r]="radius"
        stroke-width="12"
      />
    </g>
  </svg>
</div>
