import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EPlatform } from '@atlas-workspace/shared/models';
import { FirmPrefixPipeModule } from '@atlas-workspace/shared/pipes';
import { RandomColorService } from '@atlas-workspace/shared/service';
import { WEB_PLATFORM_TYPE } from '@atlas-workspace/shared/translate';

@Component({
  selector: 'atl-firm-icon',
  templateUrl: './firm-icon.component.html',
  styleUrls: ['./firm-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterModule, FirmPrefixPipeModule],
})
export class FirmIconComponent implements OnInit {
  public readonly defaultName = 'unknown';

  @Input() size?: number;
  @Input() idKey!: string;
  @Input() nameKey!: string;
  @Input() link?: (string | number)[];
  @Input() isUser = false;
  @Input() isDropdown = false;
  @Input() id?: number;
  @Input() set isMonochrome(val: boolean) {
    this.monochrome = val;
  }

  @Input() set model(v: any) {
    this.value = v;
    this.initColorIcon();
    this.cdr.markForCheck();
  }

  public value!: any;
  public monochrome = false;
  public color?: string;
  public border?: string;
  private readonly monochromeStyleBackgroundVar = 'var(--color-white-1000)';
  private readonly monochromeStyleBorderVar = 'var(--color-gray-805)';
  public readonly monochromeStyleColorVar = 'var(--color-black-960)';

  constructor(
    private randomColorService: RandomColorService, 
    private cdr: ChangeDetectorRef,
    @Inject(WEB_PLATFORM_TYPE) private platformType: EPlatform
  ) {}

  ngOnInit(): void {
    this.initColorIcon();
  }

  private initColorIcon(): void {
    //[AT-9057] added monochrome only for client panel
    if (this.platformType !== EPlatform.WEB_CLIENT) this.monochrome = false; 

    if (this.isUser) {
      const { iconColor, iconBorderColor } = this.randomColorService.getUserColors(
        (this.nameKey ? this.value[this.nameKey] : this.value) ?? this.defaultName,
        55,
        50
      );
      this.color = this.monochrome ? this.monochromeStyleBackgroundVar : iconColor;
      this.border = '1px solid ' + (this.monochrome ? this.monochromeStyleBorderVar : iconBorderColor);
    } else {
      this.color = this.idKey
        ? this.randomColorService.getHslColor(' '.repeat(this.value[this.idKey]), 55, 50)
        : this.randomColorService.getHslColor(' '.repeat(this.value), 55, 50);
    }
  }
}
