import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ClickOutsideDirective } from '@atlas-workspace/shared/directives';
import { ECompaniesContractorRoles, IUnitMainBuyer, ReclamationContractorModel } from '@atlas-workspace/shared/models';
import { MessageBannerService, PageHelperService } from '@atlas-workspace/shared/service';
import { TranslateService } from '@ngx-translate/core';

import { FirmIconComponent } from '../firm-icon/firm-icon.component';

@Component({
  selector: 'atl-users-viewer',
  templateUrl: './users-viewer.component.html',
  styleUrls: ['./users-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective, FirmIconComponent],
})
export class UsersViewerComponent implements OnInit {
  @Input() public readonly hoverOption = false;
  @Input() set users(value: IUnitMainBuyer[] | ReclamationContractorModel[]) {
    if (value.length && value[0] instanceof ReclamationContractorModel) {
      value = value.map(i => {
        return {
          ...i,
          role: (i as ReclamationContractorModel).contractorCompanyRole === ECompaniesContractorRoles.KeyContact ?
            this.translateService.instant('Shared.Entity.Key_Contact') :
            this.translateService.instant('Shared.Entity.Member')
        };
      });
    }
    this._users = value;
    if (this._users?.length) {
      this.selectedUser = this._users[0];
    }
  }

  public expanded = false;
  public _users!: IUnitMainBuyer[];
  public selectedUser!: IUnitMainBuyer;
  public selectedIndex = 0;
  public contractorRoles = ECompaniesContractorRoles;
  public readonly messageBannerCopiedText = 'Shared.Entity.Copied_to_clipboard';
  public showingToolTip = false;

  constructor(
    private readonly pageHelperService: PageHelperService,
    private readonly translateService: TranslateService,
    private readonly messageBannerService: MessageBannerService,
  ) {}

  ngOnInit(): void {
    this.expanded = this.hoverOption;
  }

  public close(): void {
    if(this.hoverOption) return;
    this.expanded = false;
    this.resetData();
  }

  public toggle(): void {
    this.expanded = !this.expanded;
    if (!this.expanded) this.resetData();
  }

  private resetData(): void {
    this.selectedUser = this._users[0];
    this.selectedIndex = 0;
  }

  public copyToClipBoardData(data: string, e: Event): void {
    e.preventDefault();
    e.stopImmediatePropagation();
    this.pageHelperService.copyToClipboard(data, true);
    this.messageBannerService.triggerShowBanner({
      text: this.messageBannerCopiedText,
      icon: true
    });
    this.close();
  }

  public prev(): void {
    this.selectedIndex--;
    if (this.selectedIndex < 0) this.selectedIndex = this._users.length - 1;
    this.selectedUser = this._users[this.selectedIndex];
    this.scrollIntoView();
  }

  public next(): void {
    this.selectedIndex++;
    if (this.selectedIndex > this._users.length - 1) this.selectedIndex = 0;
    this.selectedUser = this._users[this.selectedIndex];
    this.scrollIntoView();
  }

  scrollIntoView(): void {
    const dots = document.querySelectorAll('.dot');
    const activeDot = dots[this.selectedIndex] as HTMLElement;

    if (activeDot) {
      activeDot.scrollIntoView({ behavior: 'auto', block: 'nearest' });
    }
  }
}
