import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { sharedEnvironment } from '@atlas-workspace/shared/environments';

declare const google: any;

@Injectable({
  providedIn: 'root',
})
export class GeocoderService {
  private renderer: Renderer2;

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  loadGoogleScript(): Promise<any> {
    if (
      typeof google !== 'undefined' &&
      typeof google.maps !== 'undefined' &&
      typeof google.maps.places !== 'undefined'
    ) {
      return Promise.resolve();
    }

    const possibleScript = document.getElementById('google-autocomplete-script');
    if (possibleScript) {
      return new Promise((resolve) => {
        possibleScript.onload = resolve;
      });
    }

    return new Promise<void>((resolve) => {
      const script = this.renderer.createElement('script');
      script.setAttribute('id', 'google-autocomplete-script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${sharedEnvironment.googleApiKey}&libraries=places,geometry&language=en`;
      this.renderer.appendChild(document.body, script);
      script.onload = resolve;
    });
  }
}
