import { HttpClient, HttpParams } from '@angular/common/http';
import { ETablePagination, ITablePagination } from '@atlas-workspace/shared/models';
import { PaginationUtil } from '@atlas-workspace/shared/service';
import { untilDestroyed } from '@ngneat/until-destroy';
import { finalize, forkJoin, Observable, of, shareReplay, switchMap } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export interface GetEntityListByChunksPropsCurrentObservable<T> {
  observable: Observable<T[]>;
}

interface GetEntityListByChunksProps<T> {
  http: HttpClient;
  url: string;
  componentInstance: unknown;
  httpParams?: Record<string, unknown>;
  currentObservable$: Partial<GetEntityListByChunksPropsCurrentObservable<T>>;
  onFinalizeCb?: () => void;
  onLoadedCb?: (records: T[]) => void;
  plainToClassCb: (body: any) => T[];
}

/**
 * @note Execution component should have 'UntilDestroy' decorator
 */
export function getEntityListByChunks<T>({
  http,
  url,
  componentInstance,
  httpParams,
  currentObservable$,
  onFinalizeCb,
  onLoadedCb,
  plainToClassCb,
}: GetEntityListByChunksProps<T>): GetEntityListByChunksPropsCurrentObservable<T> {
  if (currentObservable$.observable !== undefined) {
    return currentObservable$ as GetEntityListByChunksPropsCurrentObservable<T>;
  }

  const createPaginationWithPage = (currentPage: number) => ({ ...PaginationUtil.defaultPagination, currentPage });

  const chunk$ = (pagination: ITablePagination): Observable<{ totalPages: number; items: T[] }> => {
    const params: HttpParams = new HttpParams({
      fromObject: {
        page: pagination.currentPage,
        per_page: pagination.pageItems,
        ...httpParams,
      },
    });
    return http
      .get<{ data: { projects: T[] } }>(url, {
        params: params,
        observe: 'response',
      })
      .pipe(
        untilDestroyed(componentInstance),
        map(({ headers, body }) => ({
          totalPages: PaginationUtil.convertPaginationType(headers, ETablePagination.TotalPages),
          items: plainToClassCb(body),
        })),
      );
  };

  currentObservable$.observable = chunk$(createPaginationWithPage(1)).pipe(
    switchMap(({ totalPages, items }) => {
      if (totalPages <= 1) {
        return of(items);
      }
      const restChunks$: Observable<{ totalPages: number; items: T[] }>[] = [];
      for (let page = 2; page <= totalPages; page++) {
        restChunks$.push(chunk$(createPaginationWithPage(page)));
      }
      return forkJoin(restChunks$).pipe(
        map((restChunks) => {
          return restChunks.reduce((acc, chunk) => acc.concat(chunk.items), items);
        }),
      );
    }),
    tap((projects: T[]) => {
      !onLoadedCb || onLoadedCb(projects);
    }),
    finalize(() => {
      !onFinalizeCb || onFinalizeCb();
    }),
    shareReplay(1),
  );

  return currentObservable$ as GetEntityListByChunksPropsCurrentObservable<T>;
}
