<div
  #container
  atlLockField
  [sweechLockOff]="sweechLockOff"
  [class.pointer-events-none]="locked"
  (isLocked)="isLocked($event)"
  class="custom-select-dropdown newest-select-dropdown"
  atlClickOutside
  (clickOutside)="onCloseModal()"
>
  <div
    tabindex="0"
    class="newest-select-dropdown__container"
    [ngClass]="{ active: isOpen, disabled }"
    (click)="onOpenModal(); $event.stopImmediatePropagation()"
  >
    <ng-container *ngIf="customTitleTemplate; else defaultTitleView">
      <div class="newest-select-dropdown__selected-view">
        <ng-template
          [ngTemplateOutlet]="labelTemplate"
          [ngTemplateOutletContext]="{ item: selectedItem }"
        ></ng-template>
      </div>
    </ng-container>

    <ng-template #defaultTitleView>
      <span class="newest-select-dropdown__selected">
        <ng-container *ngIf="showIcon && selectedItem?.icon">
          <atl-custom-icon [icon]="selectedItem.icon" class="mr-8 newest-select-dropdown__item-icon"></atl-custom-icon>
        </ng-container>
        {{ displayValue ? displayValue : label }}
      </span>
    </ng-template>

    <atl-custom-icon
      *ngIf="showArrow"
      [icon]="arrowIcon"
      class="newest-select-dropdown__arrow"
      [class.open]="isOpen"
    ></atl-custom-icon>
  </div>

  <div
    class="newest-select-dropdown__list v-scrollbar"
    [class.open]="isOpen"
    *ngIf="isOpen"
    [style.width]="container.clientWidth + 'px'"
  >
    <ng-container *ngIf="listOfValues?.length > 0; else emptyState">
      <label
        class="newest-select-dropdown__item"
        *ngFor="let value of listOfValues"
        [class.selected]="value[bindValue] === formValue"
        [class.show-check]="showCheck"
        [attr.data-cy-id]="value[bindValue]"
        (click)="onChangeValue(value[bindValue]); $event.stopPropagation()"
      >
        <ng-container *ngIf="customTitleValue; else defaultView">
          <ng-template
            [ngTemplateOutlet]="valueTemplate"
            [ngTemplateOutletContext]="{ item: value, selected: value[bindValue] === formValue }"
          >
          </ng-template>
        </ng-container>

        <ng-template #defaultView>
          <div class="d-flex">
            <ng-container *ngIf="showIcon && value?.icon">
              <atl-custom-icon [icon]="value.icon" class="mr-8 newest-select-dropdown__item-icon"></atl-custom-icon>
            </ng-container>
            {{ value[bindTitle] }}
          </div>
        </ng-template>
      </label>
    </ng-container>

    <ng-template #emptyState>
      <div class="newest-select-dropdown__empty-state">{{ emptyText }}</div>
    </ng-template>
  </div>
</div>
