import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {ClickOutsideDirective, HorizontalAlignDirective} from '@atlas-workspace/shared/directives';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { MultiFilterDropdownComponent } from '../multi-filter-dropdown/multi-filter-dropdown.component';
import {CommonModule} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";

@UntilDestroy()
@Component({
  selector: 'atl-multi-filter-dropdown-v2',
  templateUrl: './multi-filter-dropdown-v2.component.html',
  styleUrls: ['./multi-filter-dropdown-v2.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, ClickOutsideDirective, HorizontalAlignDirective]
})
export class NewMultiFilterDropdownComponent extends MultiFilterDropdownComponent implements OnInit {
  @Input() selectedItems!: number;
  @Input() resetButtonLabel?: string;
  @Input() flexibleY = false;
  // @note: only use when items are loaded dynamically.
  @Input() useAsyncData = false;
  // @note: only use when 'useAsyncData' is true.
  @Input() set itemslength(value: unknown) {
    this.itemslengthHandler(Number(value || 0));
  }

  public asyncItemslength = 0;
  public isFirstItemslengthSetterExecution = true;

  @ViewChild(HorizontalAlignDirective, { static: false }) horizontalAlignDirective?: HorizontalAlignDirective;

  ngOnInit(): void {
    if (this.expandedState$) {
      this.expandedState$.pipe(untilDestroyed(this)).subscribe((isExpanded) => {
        if (!isExpanded) {
          this.isFirstItemslengthSetterExecution = true;
        }
        this.close();
        this.cdr.detectChanges();
      });
    }
  }

  private itemslengthHandler(length: number): void {
    this.asyncItemslength = length;
    if (this.isFirstItemslengthSetterExecution && this.useAsyncData && this.asyncItemslength !== 0) {
      queueMicrotask(() => {
        this.horizontalAlignDirective?.onResize();
        this.isFirstItemslengthSetterExecution = false;
        this.cdr.detectChanges();
      });
    } else if (!this.expanded) {
      this.isFirstItemslengthSetterExecution = true;
    }
  }
}
