import {CommonModule} from "@angular/common";
import {
  Component,
  OnInit,
} from '@angular/core';
import {FileSizePipeModule, IconByTypePipeModule} from "@atlas-workspace/shared/pipes";
import {IParsFileDrop, UploadS3Service} from "@atlas-workspace/shared/service";
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateModule} from "@ngx-translate/core";

@UntilDestroy()
@Component({
  selector: 'atl-progress-s3-loader',
  templateUrl: './progress-s3-loader.component.html',
  styleUrls: ['./progress-s3-loader.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    IconByTypePipeModule,
    FileSizePipeModule,
  ],
  standalone: true
})
export class ProgressS3LoaderComponent implements OnInit {

  public uploadData!: IParsFileDrop[] | null;
  public showLoader = true;
  public showList = true;

  constructor(private readonly uploadS3Service: UploadS3Service) {}

  ngOnInit(): void {
    this.uploadS3Service.uploadData.pipe(untilDestroyed(this)).subscribe(uploadData => {
      this.uploadData = uploadData;
      this.showLoader = !!uploadData;
    })
  }

  cancel(item: IParsFileDrop): void {
    item.isUploading = false;
  }

  showLoadList(): void {
    this.showList = !this.showList;
  }

  get totalProgress(): number {
    if (!this.uploadData?.length) return 0;
    const {loaded, total} = this.uploadData!.filter(item => !item.extensionError || !item.fileNestingError || !item.nameLengthError || !item.sizeError)
      .reduce((acc, item) => {
        acc.loaded += item.loaded;
        acc.total += item.size;
        return acc
      }, {loaded: 0, total: 0} );
    if (loaded === total) {
      this.showLoader = true;
    }
    return Math.floor(loaded * 100 / total);
  }

  get totalItems(): number {
    if (!this.uploadData?.length) return 0;
    return this.uploadData!.filter(item => !item.extensionError || !item.fileNestingError || !item.nameLengthError || !item.sizeError).length;
  }

  close(): void {
    if (this.totalProgress === 100) {
      this.uploadS3Service.clearUploadData();
      return;
    }
    this.showLoader = !this.showLoader;
  }

  cancelUpload(): void {
    this.uploadS3Service.cancelUpload();
  }
}
