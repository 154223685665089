<!-- 
  ******************************************************
  @Cypress
  - interaction with image
  - interaction with marks
  ******************************************************
-->
<div #imageWrap class="image-mark__img-wrapper">
  <img
    #image
    (load)="loadImage(image)"
    (click)="onMark($event)"
    [src]="imageUrl"
    [width]="width"
    [height]="height"
    alt="plan"
    data-cy="image-mark-img"
  />
  <span
    *ngFor="let item of markItems; trackBy: markTrackBy"
    class="image-mark__mark"
    data-cy="image-mark__mark"
    [style.left]="item.left"
    [style.top]="item.top"
    [attr.data-cy-x]="item.x"
    [attr.data-cy-y]="item.y"
    (click)="clickOnExistingMark(item)"
    [style.width.px]="item.width"
    [style.height.px]="item.height"
    cdkDrag
    [cdkDragDisabled]="true"
    (cdkDragStarted)="onMarkerDragStart($event, item)"
    (cdkDragEnded)="onDragEnd($event)"
  >
    <span [class.image-mark__mark__inner]="true" [class.hover]="item.hover">
      <span *ngIf="multiplePins" class="fz-12 fw-500 lh-16">{{ item.order }}</span>
    </span>
  </span>
</div>
