import { Pipe, PipeTransform } from '@angular/core';
import { acceptedRawExtensions } from '@atlas-workspace/shared/models';

@Pipe({
  name: 'isRaw'
})
export class IsRawPipe implements PipeTransform {
  transform(name: string): boolean {
    const arr = name.split('.');
    return acceptedRawExtensions.includes(arr[arr.length - 1].toLowerCase());
  }
}
