import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'atl-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputRadioComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule],
})
export class InputRadioComponent implements ControlValueAccessor {
  @Input() checked = false;
  @Input() label!: string;
  @Input() index = 0;
  @Input() name = 'radio';
  @Input() disabled = false;
  @Input() sizeInput: 'medium' | 'small' = 'small';
  @Input() value = '';

  public onChange!: (value: any) => void;
  public onTouched!: () => void;

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  writeValue(value: any): void {
    this.checked = this.value ? this.value === value : value;
  }

  change(el: Event): void {
    const checked = (el.target as HTMLInputElement).checked;
    this.value ? this.onChange(this.value) : this.onChange(checked);
    this.onTouched();
  }
}
