import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdown, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';
import { of } from 'rxjs';
import { delay, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'atl-limit-dropdown',
  templateUrl: './limit-dropdown.component.html',
  styleUrls: ['./limit-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LimitDropdownComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [CommonModule, TranslateModule, FormsModule, ReactiveFormsModule, NgbDropdownModule, NgxMaskDirective, ]
})
export class LimitDropdownComponent implements ControlValueAccessor, OnInit {
  @Input() index = 0;
  @Input() disabled = false;

  @ViewChild(NgbDropdown, { static: true }) dropdownRef!: NgbDropdown;
  @ViewChild('limitInput') limitInput!: ElementRef<HTMLInputElement>;

  public selectedValue = '';
  public limitCount = 0;

  private placeholder = '';

  private value!: number;

  public onChange!: (value: number) => void;
  public onTouched!: () => void;

  constructor(private readonly cdr: ChangeDetectorRef, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.dropdownRef.openChange.pipe(untilDestroyed(this)).subscribe((change) => {
      if (!change) {
        this.closeDropdown();
      } else {
        of(null)
          .pipe(take(1), delay(0))
          .subscribe(() => {
            if (this.limitInput?.nativeElement && this.limitCount) {
              this.limitInput.nativeElement.value = String(this.limitCount);
            }
          });
      }
    });

    this.placeholder = this.translateService.instant('Shared.Entity.No_limit');
    this.selectedValue = this.placeholder;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public selectLimit(limit: boolean): void {
    if (limit) {
      this.limitInput.nativeElement.focus();
      if (this.limitCount > 0) {
        this.selectedValue = this.limitCount.toString();
      }
    } else {
      this.selectedValue = this.placeholder;
      this.limitCount = 0;
    }
  }

  public inputCount(value: string): void {
    this.limitCount = +value.replace(/\D/g, '');
    if (this.limitCount > 0) {
      this.selectedValue = value;
    } else {
      this.selectedValue = this.placeholder;
    }
  }

  public closeInput(): void {
    this.selectedValue = this.limitCount.toString();
    this.closeDropdown();
  }

  closeDropdown(): void {
    this.dropdownRef.close();
    this.setOnChange();
  }

  initValue(): void {
    this.value = this.limitCount;
  }

  setOnChange(): void {
    this.initValue();
    this.onChange(this.value);
    this.cdr.markForCheck();
  }

  writeValue(value: number): void {
    if (!value) {
      this.limitCount = 0;
    } else {
      this.limitCount = value;
      this.initValue();
      this.inputCount(String(value));
    }
    this.cdr.detectChanges();
  }
}
