import { Injectable } from '@angular/core';
import { ELocalStorageKeys } from '@atlas-workspace/shared/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  public readonly sidebarExpandedValueChanged$ = new BehaviorSubject<boolean>(false);

  public set(key: string, value: any): void {
    this.hook(<ELocalStorageKeys>key, value);
    localStorage.setItem(key, JSON.stringify({ value }));
  }

  public get<T>(key: string): T | null {
    const data: string | null = localStorage.getItem(key);
    if (data !== null) {
      return JSON.parse(data).value;
    }
    return null;
  }

  public remove(key: string): void {
    localStorage.removeItem(key);
  }

  public clear(): void {
    const localStorageKeys = Object.keys(localStorage);
    for (const localStorageKey of localStorageKeys) {
      if (localStorageKey !== ELocalStorageKeys.Language) this.remove(localStorageKey);
    }
  }

  private hook(k: ELocalStorageKeys, v: unknown): void {
    if (k === ELocalStorageKeys.ExpandedSidebar) {
      this.sidebarExpandedValueChanged$.next(<boolean>v);
    }
  }
}
