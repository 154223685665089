<div class="global-label">
  <div *ngIf="label" class="global-label__header-undo">
    <span class="global-label__name"> {{ label | translate }} </span>
  </div>
  <div
    class="date-input-wrapper"
    tabindex="1"
    [atlDropdownTriggerFor]="dropdownRef"
    [offsetBottom]="offsetBottom"
    [offsetTop]="offsetTop"
    [disabledDropdown]="!lockedTrigger.focused"
    (isOpen)="isOpen($event)"
  >
    <input
      #input
      atlLockField
      autocomplete="off"
      (isLocked)="isLocked($event)"
      (click)="visiblePicker()"
      (focus)="onFocus()"
      (blur)="onBlur($event)"
      (change)="changeValue(input.value.trim()); input.focus()"
      (input)="hidePicker(); changeValue(input.value.trim())"
      [ngModel]="inputValue"
      [class.global_input--without-border]="isWithoutBorder"
      [disabled]="disabled"
      [placeholder]="placeholder | translate"
      [mask]="mask"
      [dropSpecialCharacters]="dropSpecialCharacters"
      class="global_input global_input--date-time"
      [class.global_input--date-time-disabled]="disabled"
      name="dp"
      type="text"
      [ngClass]="{ 'visible-calendar': visible }"
    />

    <span *ngIf="!disabled" (click)="clickIconVisiblePicker(input)" class="date-input-icon">
      <img alt="calendar" [src]="calendarIcon" />
    </span>

    <atl-dropdown #dropdownRef>
      <ngb-datepicker
        [class.selected]="inputValue"
        #dp
        class="input-date-picker__calendar"
        [ngModel]="inputValue"
        [ngClass]="visible ? 'datepicker-show' : 'datepicker-hide'"
        [firstDayOfWeek]="firstDayOfWeek"
        [navigation]="navigation"
        [weekdays]="0"
        (dateSelect)="selectedDate($event, dropdownRef)"
        [outsideDays]="outsideDays"
        [minDate]="min"
        [maxDate]="max"
      >
      </ngb-datepicker>
    </atl-dropdown>
  </div>
</div>
