import { IEnvironment } from './environment.interface';

export const sharedEnvironment: IEnvironment = {
  production: false,
  appVersion: '0.1',
  apiBaseUrl: 'https://api.journeyapp.dev.scrij.com/',
  apiShowroomUrl: 'https://digital-showroom-api.dev.scrij.com/',
  environment: 'stage',
  //AT-9713 temporary hidden for test
  //tinyMceApiKey: '3sz0v4q6pw6iew3ndhgk58gy8kccvujcm1nmtrmjaox21pmr',
  tinyMceApiKey: '',
  threadsWssUrl: 'wss://atlas-anycable.dev.scrij.com',
  googleApiKey: 'AIzaSyAyHQYUAe3Q3dq5giAaaQeGrr3XiXHiVHo',
  accessCode: '0AA6289695AB3216D452A6BAB1E6DB04EEC7CBF2AA99569097ADB5615069B1AA',
  analyticsIdClient: '',
  analyticsIdAdmin: '',
  mixpanelToken: '',
  apiClientUrl: 'http://localhost:3000/',
  picarioSceneUrl: 'https://studio3d.picarioxpo.com/',
};