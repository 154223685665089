import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { getSwiperInstanceFromElementRef, SwiperDirective } from '@atlas-workspace/shared/directives';
import { ProjectIllustrationModel } from '@atlas-workspace/shared/models';
import { RandomColorService } from '@atlas-workspace/shared/service';
import { SwiperOptions } from 'swiper/types';

import { IModalHeaderInterface } from '../../interfaces/modal-header.interface';

@Component({
  selector: 'atl-construction-modal',
  templateUrl: './construction-modal.component.html',
  styleUrls: ['./construction-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ConstructionModalComponent implements OnInit {
  @Input() slides: ProjectIllustrationModel[] = [];
  @Input() index!: number;
  @Input() swiperConfig!: SwiperOptions;

  public showComment = false;
  public readonly constructionPhoto = true;

  @ViewChild(SwiperDirective, { static: true })
  private swiper!: SwiperDirective;

  get headerData(): IModalHeaderInterface {
    return {
      title: this.slides[this.index]?.name,
      subtitle: this.slides[this.index]?.createdAt,
      downloadLink: this.slides[this.index]?.fileResources?.length
        ? this.slides[this.index]?.fileResources[0].filename.downloadUrl
        : this.slides[this.index]?.fileName?.downloadUrl,
      copyImage: true,
    };
  }

  constructor(protected randomColorService: RandomColorService) {}

  ngOnInit(): void {
    this.showComment = Boolean(this.slides[this.index].comment);
  }

  public onIndexChange(i: number): void {
    this.index = i;
    this.updateSwiper();
    this.showComment = Boolean(this.slides[i].comment);
  }

  protected updateSwiper(): void {
    getSwiperInstanceFromElementRef(this.swiper.el)?.update();
  }

  public toggleComment(state: boolean): void {
    this.updateSwiper();
    this.showComment = state;
  }
}
