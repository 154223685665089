import { Injectable } from '@angular/core';
import { IFileFolderLoading } from '@atlas-workspace/shared/models';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UploadingService {
  private _uploadingDocuments$ = new BehaviorSubject<IFileFolderLoading>({
    loading: false,
    type: '',
    alreadyLoaded: 0,
  });
  public amountForUpload$ = new BehaviorSubject<number>(0);
  private uploads$ = new BehaviorSubject<
    {
      subscription: Subscription | null;
      cancelSignal$: Subject<void>;
      alreadyLoaded: number;
      amountFilesForUpload: number;
    }[]
  >([]);
  private _uploads: {
    subscription: Subscription | null;
    cancelSignal$: Subject<void>;
    alreadyLoaded: number;
    amountFilesForUpload: number;
  }[] = [];

  private alreadyUploaded = 0;

  public incrementUploadCounter(index: number): void {
    if (this._uploads[index] !== undefined) {
      this._uploads[index].alreadyLoaded++;
      this.uploads$.next(this._uploads);
    }
  }

  public stopUploadingFiles(uploadStateIndex: number): void {
    if (this._uploads[uploadStateIndex] && this._uploads[uploadStateIndex].subscription) {
      this._uploads[uploadStateIndex].subscription?.unsubscribe();
      this._uploads[uploadStateIndex].cancelSignal$.next();
      delete this._uploads[uploadStateIndex];
    }

    this.uploads$.next(this._uploads);

    this.alreadyUploaded = 0;
    this.amountForUpload$.next(0);
    this.setUploadingDocuments({ loading: false, type: '', alreadyLoaded: 0 });
  }

  public getUploads(): Observable<{ alreadyLoaded: number; amountFilesForUpload: number }[]> {
    return this.uploads$.asObservable();
  }

  public uploads(): {
    subscription: Subscription | null;
    cancelSignal$: Subject<void>;
    alreadyLoaded: number;
    amountFilesForUpload: number;
  }[] {
    return this._uploads;
  }

  public setUploads(uploads: {
    subscription: Subscription | null;
    cancelSignal$: Subject<void>;
    alreadyLoaded: number;
    amountFilesForUpload: number;
  }): void {
    this._uploads.push(uploads);
    this.uploads$.next(this._uploads);
  }

  public updateUploads(
    uploads: {
      subscription: Subscription | null;
      cancelSignal$: Subject<void>;
      alreadyLoaded: number;
      amountFilesForUpload: number;
    }[]
  ): void {
    this.uploads$.next(uploads);
  }

  public getUploadByIndex(index: number): {
    subscription: Subscription | null;
    cancelSignal$: Subject<void>;
    alreadyLoaded: number;
    amountFilesForUpload: number;
  } {
    return this._uploads[index];
  }

  public removeUploadItem(index: number): void {
    this._uploads.splice(index, 1);
    this.uploads$.next(this._uploads);
  }

  get uploadingDocuments$(): Observable<IFileFolderLoading> {
    return this._uploadingDocuments$.asObservable();
  }

  public setUploadingDocuments(loadingData: IFileFolderLoading): void {
    this._uploadingDocuments$.next(loadingData);
  }
}
