import { Expose, Type } from 'class-transformer';

export interface IMyTaskRequest {
  all: number;
  sample_type: string;
  current: {
    count: number;
    localized_name: string;
    name: string;
  }[];
}

export interface TaskChartData {
  data: any[],
  label: boolean,
  borderDash: boolean,
  borderColor: string,
  fill: boolean,
}

export class MyTaskCurrentModel {
  @Expose({ name: 'count' }) count!: number;
  @Expose({ name: 'localized_name' }) localizedName!: string;
  @Expose({ name: 'name' }) name!: string;
  chartData?: TaskChartData;
}

export class HistoryInfoModel {
  @Expose({ name: 'open_count' }) allCount!: number;
  @Expose({ name: 'calc_point_data' }) calcPointData!: string;
  @Expose({ name: 'meta' }) meta!: MyTaskCurrentModel[];
}

export class MyTaskModel {
  @Expose({ name: 'all_open' }) all!: number;
  @Expose() newIssue!: number;
  @Expose({ name: 'sample_type' }) sampleType!: string;

  @Type(() => HistoryInfoModel)
  @Expose({ name: 'history_data' })
  historyData!: HistoryInfoModel[];

  @Type(() => MyTaskCurrentModel)
  @Expose({ name: 'current' })
  current!: MyTaskCurrentModel[];

  chartData?: TaskChartData;
  localizedName?: string;
}

export interface IMyTasksInfos {
  [key: string]: MyTaskModel;

  change_request: MyTaskModel;
  message: MyTaskModel;
  reclamation: MyTaskModel;
}
