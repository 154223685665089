/* eslint-disable max-len */
import {
  acceptedGlobalExtensions,
  attachmentsDefaultListOfMimeTypes,
  FileModel,
  imageJpegMime,
  imageJpgMime,
  ImageModel,
  imagePngMime,
} from '@atlas-workspace/shared/models';

export class FileValidators {
  private static readonly maxFileSize: number = 30000000;
  private static readonly globalMimeTypes: string[] = attachmentsDefaultListOfMimeTypes;
  private static readonly globalExtensions: string[] = acceptedGlobalExtensions.split(', ');

  public static filterValidGlobalFiles(files: File[]): { file: File[]; invalid: string[] } {
    const fileName: string[] = [];
    const validFile = files.filter((x: File) => {
      if (
        x.size <= this.maxFileSize &&
        (x.type
          ? this.globalMimeTypes.includes(x.type)
          : this.globalExtensions.some((el) => x.name.toLowerCase().endsWith(el)))
      ) {
        return true;
      }
      fileName.push(x.name);
      return false;
    });

    return { file: validFile, invalid: fileName };
  }

  // Note: on the back-end images are considered only jpegs and pngs, other considered as files ( tiff, svg, etc);
  public static isImage(file: Partial<File | FileModel | ImageModel>): boolean {
    if (file.type) {
      return (
        file.type === imageJpgMime || file.type === imageJpegMime || file.type === imagePngMime || file.type === 'image'
      );
    }
    return false;
  }

  public static isImageFieldValue(val: string): boolean {
    if (val) {
      return val === imageJpegMime || val === imagePngMime || val === 'image';
    }
    return false;
  }

  public static isImageExtension(file: any): boolean {
    if (file.filename.content_type) {
      const imgExtension = file.filename.content_type;
      return imgExtension === imageJpegMime || imgExtension === imagePngMime || file.type === 'image';
    }
    return false;
  }
}
