import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import dayjs from 'dayjs';

@Injectable()
export class CustomDateParserFormatterService extends NgbDateParserFormatter {
  readonly delimiter = '.';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.delimiter);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    if (!date) {
      return '';
    }
    const jsDate = new Date(date.year, date.month - 1, date.day);
    return dayjs(jsDate).format('DD.MM.YYYY');
  }
}
