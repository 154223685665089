<!-- 
  ******************************************************
  @Cypress
  - interaction with date input
  - interaction with day icons in ngb-datepicker-month
  ******************************************************
-->
<div class="inline-datepicker-container position-relative" tabindex="1" atlClickOutside (clickOutside)="hidePicker()">
  <input
    #input
    atlLockField
    autocomplete="off"
    (isLocked)="isLocked($event)"
    name="dp"
    type="text"
    class="inline-datepicker-input"
    [ngModel]="inputValue"
    [mask]="mask"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [dropSpecialCharacters]="dropSpecialCharacters"
    (click)="visiblePicker()"
    (blur)="onTouched()"
    (change)="changeValue(input.value.trim()); input.focus()"
    (input)="hidePicker(); changeValue(input.value.trim())"
    (keydown.enter)="onBlur()"
  />

  <span *ngIf="showIcon" class="inline-datepicker-icon">
    <img alt="calendar" src="assets/calendar-16.svg" />
  </span>

  <div #wrapDp class="inline-datepicker-wrap" [class.show]="visible">
    <div *ngIf="secondInput" class="inline-datepicker-wrap__input">
      <input
        #dpInput
        autocomplete="off"
        name="dp"
        type="text"
        [ngModel]="inputValue"
        [mask]="mask"
        [disabled]="disabled"
        [placeholder]="firstInputPlaceholder | translate"
        [dropSpecialCharacters]="dropSpecialCharacters"
        (blur)="onTouched()"
        (change)="changeValue(dpInput.value.trim()); dpInput.focus()"
        (input)="changeValue(dpInput.value.trim())"
      />
    </div>
    <ngb-datepicker
      #dp
      class="inline-datepicker-ngb"
      [ngModel]="inputValue"
      [navigation]="navigation"
      [outsideDays]="outsideDays"
      [firstDayOfWeek]="firstDayOfWeek"
      [weekdays]="0"
      [minDate]="min"
      [footerTemplate]="footerTemplate"
      (dateSelect)="selectedDate($event)"
      [disabled]="disabled"
    >
    </ngb-datepicker>
  </div>
</div>

<ng-template #footerTemplate>
  <div *ngIf="showFooter" class="inline-datepicker-ngb-footer">
    <span class="cursor-pointer fw-500 fz-14 lh-24" (click)="clearValue()">
      {{ 'Shared.Entity.Clear_all' | translate }}
    </span>
    <button
      *ngIf="setDateButton"
      (click)="hidePicker()"
      [disabled]="!inputValue"
      type="button"
      class="newest-button newest-button-sm"
    >
      {{ 'Meeting_Data_Picker.Confirmation_Button' | translate }}
    </button>
  </div>
</ng-template>
