import {Injectable} from '@angular/core';
import {ELocalStorageKeys} from '@atlas-workspace/shared/models';

import {LocalStorageService} from '../local-storge/local-storage.service';

@Injectable()
export class TreadMessageSaveService {
  private readonly dbName = 'messengerDB';
  private readonly dbStore = 'messages';
  private readonly dbVersion = 1;
  private db!: IDBDatabase;
  private userId!: number;

  constructor(private readonly localStorage: LocalStorageService) {}

  initializeDB(): Promise<void> {
    const userInfo = this.localStorage.get<{ fullName: string; id: number }>(ELocalStorageKeys.UserInfo);
    this.userId = userInfo!.id;
    return new Promise<void>((resolve, reject) => {
      const request = indexedDB.open(this.dbName, this.dbVersion);

      request.onerror = () => {
        reject();
      };

      request.onsuccess = (event: any) => {
        this.db = event.target.result;
        resolve();
      };

      request.onupgradeneeded = (event: any) => {
        this.db = event.target.result;
        if (!this.db.objectStoreNames.contains(this.dbStore)) {
          const objectStore = this.db.createObjectStore(this.dbStore, { keyPath: ['userId', 'threadId'] });
          objectStore.createIndex('userId', 'userId', { unique: false });
        }
      };

      request.onblocked = () => {
        reject();
      };
    });
  }

  async getMessages(threadId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const transaction = this.db.transaction([this.dbStore], 'readonly');
      const objectStore = transaction.objectStore(this.dbStore);
      const index = objectStore.index('userId');

      const request = index.openCursor(IDBKeyRange.only(this.userId));

      request.onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor) {
          if (cursor.value.threadId === threadId) {
            resolve(cursor.value.messages);
          } else {
            cursor.continue();
          }
        } else {
          resolve(null);
        }
      };

      request.onerror = () => {
        reject();
      };
    });
  }

  async saveMessages(threadId: number, messages: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const transaction = this.db.transaction([this.dbStore], 'readwrite');
      const objectStore = transaction.objectStore(this.dbStore);

      const request = objectStore.get([this.userId, threadId]);

      request.onsuccess = () => {
        const existingData = request.result;
        if (existingData) {
          existingData.messages = messages;
          objectStore.put(existingData);
        } else {
          objectStore.put({ userId: this.userId, threadId: threadId, messages: messages });
        }
        resolve();
      };

      request.onerror = () => {
        reject();
      };
    });
  }

  async deleteMessages(threadId: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const transaction = this.db.transaction([this.dbStore], 'readwrite');
      const objectStore = transaction.objectStore(this.dbStore);
      const index = objectStore.index('userId');

      const request = index.openCursor(IDBKeyRange.only(this.userId));

      request.onsuccess = (event: any) => {
        const cursor = event.target.result;
        if (cursor) {
          if (cursor.value.threadId === threadId) {
            cursor.delete();
            resolve();
          } else {
            cursor.continue();
          }
        } else {
          resolve();
        }
      };

      request.onerror = () => {
        reject();
      };
    });
  }
}
