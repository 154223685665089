import { Expose } from 'class-transformer';

export interface IFirmAuth {
  firmId: number;
  role: string;
  id: number;
}

export interface IFirmModel {
  id: number;
  title: string;
  country: string;
  address: string;
  currency: string;
  longitude: string;
  latitude: string;
}

export class FirmModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'title' })
  title!: string;
  @Expose({ name: 'country' })
  country!: string;
  @Expose({ name: 'address' })
  address!: string;
  @Expose({ name: 'currency' })
  currency!: string;
  @Expose({ name: 'longitude' })
  longitude!: string;
  @Expose({ name: 'latitude' })
  latitude!: string;
  @Expose({ name: 'role' })
  role!: string;

  selected = false;
}

export const listOfCountries = [
  { id: 'norway', country: 'Entity.Norway' },
  { id: 'germany', country: 'Entity.Germany' },
  { id: 'sweden', country: 'Entity.Sweden' },
  { id: 'usa', country: 'USA' },
  { id: 'united kingdom', country: 'Entity.UK' },
];

export enum EPriceSystem {
  Simple = 'default',
  MarkUp = 'vat',
}

export interface ICreateFirm {
  title: string;
  country: string;
  currency: string;
  price_provider: EPriceSystem;
  address?: string;
  latitude?: string;
  longitude?: string;
  markup?: number;
  vat?: number;
}
