import {CommonModule} from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output, TemplateRef,
  ViewChild,
} from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import {NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'atl-button-loader',
  templateUrl: './button-loader.component.html',
  styleUrls: ['./button-loader.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, NgbTooltipModule]
})
export class ButtonLoaderComponent implements AfterViewInit {
  public widthButton = 0;

  @Input() loading = false;
  @Input() disabled = false;
  @Input() label = '';
  @Input() addClass = {};
  @Input() newest = false;
  @Input() tooltip: string | TemplateRef<any> = ''
  @Input() tooltipDelay = 0;
  @Input() showTooltip = false;

  @Output() clickButton: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  @ViewChild('button', { static: true }) button!: ElementRef;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.widthButton = this.button.nativeElement.clientWidth;
    this.cdr.detectChanges();
  }

  onClickButton(event: MouseEvent): void {
    if (!this.loading) {
      this.clickButton.emit(event);
    }
  }
}
