import { HttpClient } from '@angular/common/http';
import { Inject, LOCALE_ID, StaticClassProvider } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import { ELang, ELocalStorageKeys, EPlatform, ITranslateMapResponse } from '@atlas-workspace/shared/models';
import { StateTranslateService } from '@atlas-workspace/shared/service';
import { TranslateLoader, TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { WEB_PLATFORM_TYPE } from '../providers/platform-token';

export class HttpTranslateLoader implements TranslateLoader {
  private webSharedPlatform: EPlatform = EPlatform.WEB_SHARED;

  constructor(
    private http: HttpClient,
    private _platform: EPlatform,
    @Inject('ENVIRONMENT') private env: IEnvironment,
    private stateTranslateService: StateTranslateService
  ) {}

  getTranslation(lang: ELang): Observable<JSON> {
    return forkJoin({
      main: this.http.get<JSON>(`${this.env.apiBaseUrl}api/v1/translates/${this._platform}/${lang}`),
      shared: this.http.get<JSON>(`${this.env.apiBaseUrl}api/v1/translates/${this.webSharedPlatform}/${lang}`),
    } as ITranslateMapResponse<Observable<JSON>>).pipe(
      catchError(() => {
        return of({
          main: {},
          shared: {},
        } as ITranslateMapResponse<JSON>);
      }),
      map((translations: ITranslateMapResponse<JSON>) => {
        return { ...(<JSON>translations.main), ...(<JSON>translations.shared) };
      }),
      tap(() => this.stateTranslateService.markAsLoadedLocalizationData(true))
    );
  }
}

export function httpTranslateLoaderFactory(
  http: HttpClient,
  platformtype: EPlatform,
  environment: IEnvironment,
  stateTranslate: StateTranslateService
): HttpTranslateLoader {
  return new HttpTranslateLoader(http, platformtype, environment, stateTranslate);
}

export const identifyDefaultLanguage: () => ELang = () => {
  let currentLocalLang = JSON.parse(<string>localStorage.getItem(ELocalStorageKeys.Language))?.value;
  const urlParams = new URLSearchParams(window.location.search);
  const lang = urlParams.get('lang');
  if (lang) {
    currentLocalLang = lang;
  }
  if (!currentLocalLang) {
    localStorage.setItem(ELocalStorageKeys.Language, JSON.stringify({ value: ELang.Norwegian }));
    currentLocalLang = ELang.Norwegian;
  }
  return currentLocalLang;
};

export const translateModuleLoader = {
  provide: TranslateLoader,
  useFactory: httpTranslateLoaderFactory,
  deps: [HttpClient, WEB_PLATFORM_TYPE, 'ENVIRONMENT', StateTranslateService],
};

export const translateModuleForRootConfig = {
  loader: translateModuleLoader,
  defaultLanguage: identifyDefaultLanguage(),
};

export const translateModuleForChildConfig = {
  isolate: false,
  extend: true,
  loader: translateModuleLoader,
  defaultLanguage: identifyDefaultLanguage(),
};

export class DynamicLocaleId extends String {
  constructor(protected translateService: TranslateService) {
    super('');
  }

  toString(): string {
    const currentLang = this.translateService.currentLang;
    switch (currentLang) {
      case ELang.Norwegian:
        return 'nb';
      default:
        return currentLang;
    }
  }
}

export const localizationDatePipeProvider: StaticClassProvider = {
  provide: LOCALE_ID,
  useClass: DynamicLocaleId,
  deps: [TranslateService],
};
