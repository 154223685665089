import { Injectable } from '@angular/core';
import { IBannerData } from '@atlas-workspace/shared/models';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class MessageBannerService {
  private _showBanner$ = new Subject<IBannerData>();

  get showMessageBannerEvent$(): Observable<IBannerData> {
    return this._showBanner$.asObservable();
  }

  public triggerShowBanner(data: IBannerData): void {
    this._showBanner$.next(data);
  }
}
