export enum EUnitRoles {
  PrimaryOwner = 'primary_owner',
  Member = 'member',
}

export enum ECompaniesContractorRoles {
  Member = 'member',
  KeyContact = 'key_contact',
}

export enum EFirmRoles {
  PrimaryOwner = 'primary_owner',
  Member = 'member',
  Admin = 'admin',
  Guest = 'guest',
  Contractor = 'contractor',
}

export enum ESystemRole {
  Member = 'member',
  Admin = 'admin',
}

export enum EProjectRoles {
  ProjectOwner = 'project_owner',
  ProjectAdmin = 'project_admin',
  ProjectMember = 'project_member',
  Custom = 'custom',
  Contractor = 'contractor',
}

export enum EProjectNotificationsRecipientRoles {
  PrimaryOwner = 'primary_owner',
  ProjectOwner = 'project_owner',
  ProjectAdmin = 'project_admin',
  Admin = 'admin',
  Custom = 'custom',
}

export type TProjectRole = 'primary_owner' | 'member' | 'admin' | 'project_member' | string;

export enum EUserTypeClient {
  Buyer = 'buyer',
  Banker = 'banker',
  Investor = 'investor',
}

export enum EUserTypeAdmin {
  Buyer = 'buyer',
  Owner = 'project_owner',
  Admin = 'project_admin',
  Custom = 'custom',
  Contractor = 'contractor',
}

export const translateRolesMap: { [K: string]: string } = {
  primary_owner: 'Shared.Entity.Primary_owner',
  member: 'Shared.Entity.Member',
  admin: 'Shared.Entity.Admin',
  guest: 'Shared.Entity.Guest',
  project_owner: 'Shared.Entity.Project_owner',
  project_member: 'Shared.Entity.Project_member',
  custom: 'Shared.Entity.Custom',
  project_admin: 'Shared.Entity.Project_admin',
  customer: 'Shared.Entity.Customer',
  contractor: 'Shared.Entity.Contractor',
  owner: 'Entity.Owner',
  multiple: 'Shared.Entity.Multiple',
  buyer: 'Entity.Buyer',
  coOwner: 'Entity.Owner',
};

export enum EBuyerRole {
  Member = 'member',
  Owner = 'owner',
  Multiple = 'multiple',
}
