import {CommonModule} from "@angular/common";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewEncapsulation
} from '@angular/core';
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {ITextEditorInitConfig} from "@atlas-workspace/shared/models";
import {
  NgbModalFooterComponent,
  NgbModalHeaderComponent,
} from "@atlas-workspace/shared/ui";
import {NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule, TranslateService} from "@ngx-translate/core";
import {EditorModule} from "@tinymce/tinymce-angular";

import {NewestTextEditorComponent} from "../newest-text-editor/newest-text-editor.component";

const blockFormats = [
  {title: 'Editor.Blocks.Paragraph', tag: '=p;'},
  {title: 'Editor.Blocks.Heading_1', tag: '=h1;'},
  {title: 'Editor.Blocks.Heading_2', tag: '=h2;'},
  {title: 'Editor.Blocks.Heading_3', tag: '=h3;'},
]

const translateKey = {
  no: 'nb_NO',
  sv: 'sv_SE',
  en: 'en_US',
};

@Component({
  selector: 'atl-text-editor-modal',
  templateUrl: './text-editor-modal.component.html',
  styleUrls: ['./text-editor-modal.component.scss'],
  standalone: true,
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule, EditorModule, TranslateModule, ReactiveFormsModule, NgbModalFooterComponent, NgbModalHeaderComponent]
})
export class TextEditorModalComponent extends NewestTextEditorComponent implements OnInit {
  editorInitConfig!: ITextEditorInitConfig;
  focused = false;
  @Input() modalRef!: NgbModalRef;
  @Input() value!: string;
  @Input() set config(config: ITextEditorInitConfig) {
    config.block_formats = this.getBlockFormats();
    this.editorInitConfig = {
      ...config,
    };

    if (this.translateService.currentLang !== 'en') {
      // @ts-ignore
      this.editorInitConfig.language = translateKey[this.translateService.currentLang];
    }
  }

  @Output() private readonly saveData = new EventEmitter<string>();

  public control = new FormControl();

  constructor(
    protected readonly translateService: TranslateService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly renderer: Renderer2
  ) {
    super(translateService, cdr, renderer)
  }

  ngOnInit(): void {
    this.control.setValue(this.value);
  }

  public closeModal(): void {
    this.modalRef.dismiss();
  }

  private getBlockFormats(): string {
    return blockFormats.map(block => (this.translateService.instant(block.title) + block.tag)).join(' ')
  }

  public save(): void {
    const data = this.control.value;
    this.saveData.emit(data);
    this.closeModal();
  }

  public get disabledButtons(): boolean {
    return this.value === this.control.value;
  }
}
