<div class="comment">
  <div class="comment__content" (click)="clickHandler($event)">
    <div class="comment__text">
      <ng-container *ngIf="htmlMode">
        <span
          *ngIf="!isContentToggled"
          [innerHTML]="nonEditedContent | removeHtmlTag | removeNbsp | truncate: truncate"
        ></span>
        <atl-shadow-dom-content *ngIf="isContentToggled" class="product-description">
          <span [innerHTML]="nonEditedContent | removeNbsp | safeHtml"></span>
        </atl-shadow-dom-content>
      </ng-container>
      <span *ngIf="!htmlMode">{{ content }} </span>
      <ng-container *ngIf="content?.length > limit">
        <span *ngIf="!isContentToggled; else notToggled" class="comment__action" (click)="toggleContent($event)"
          >{{ readMoreLabel }}
        </span>
      </ng-container>
    </div>
  </div>
</div>

 <ng-template #notToggled>
  <span class="comment__action" (click)="toggleContent($event)">{{ readLessLabel }}</span>
</ng-template>
