import { Expose } from 'class-transformer';

import { IUnitMainBuyer } from './unit.model';

export enum EAdminUnitStatus {
  Completed = 'Completed',
  NotCompleted = 'Not completed',
}

export class AdminUnitModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'identifier' })
  identifier: number;
  @Expose({ name: 'buyer_name' })
  buyerName: string;
  @Expose({ name: 'is_done' })
  isDone: boolean;

  @Expose({ name: 'co_owners' })
  coOwners!: IUnitMainBuyer[];
  @Expose({ name: 'main_buyer' })
  mainBuyer!: IUnitMainBuyer | null;
  @Expose({ name: 'primary_owners' })
  primaryOwners!: IUnitMainBuyer[];
  @Expose({ name: 'main_primary_owner' })
  mainPrimaryOwner!: IUnitMainBuyer | null;

  status: EAdminUnitStatus = EAdminUnitStatus.NotCompleted;

  constructor() {
    this.id = 0;
    this.identifier = 0;
    this.buyerName = '0';
    this.isDone = false;
  }
}
