import {Injectable} from '@angular/core';
import {EDateFormat, IDateFormat} from '@atlas-workspace/shared/models';
import {TranslateService} from '@ngx-translate/core';
import dayjs from 'dayjs';
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(quarterOfYear);

@Injectable()
export class DateFormatService {
  listOfFormats: IDateFormat[] = [];
  private types!: EDateFormat[];
  private typeFormat = EDateFormat;
  private readonly exceptMonth: { [key: string]: string } = {
    März: 'Mar',
    Juni: 'Jun',
    Juli: 'Jul',
    Sept: 'Sep',
    Dez: 'Des',
  };

  constructor(private translateService: TranslateService) {
    this.types = [
      this.typeFormat.Month,
      this.typeFormat.Quarter,
      this.typeFormat.Half,
      this.typeFormat.Year,
      this.typeFormat.NotDisplay,
    ];
  }

  listOfGenerateDate(date: string): IDateFormat[] {
    this.listOfFormats = [];
    const quarter = dayjs(date).quarter();
    const partOfYear = quarter <= 2 ? 'H1' : 'H2';
    const year = dayjs(date).format('YYYY');

    this.types.forEach((type) => {
      switch (type) {
        case this.typeFormat.Month:
          const tempMonth = dayjs(date).format('MMM');
          const tempYear = dayjs(date).format('YYYY');
          const nameOfMonth = this.exceptMonth[tempMonth] ? `${this.exceptMonth[tempMonth]}, ${tempYear}` : `${tempMonth}, ${tempYear}`;
          this.listOfFormats.push(this.createType(type, nameOfMonth));
          break;
        case this.typeFormat.Quarter:
          this.listOfFormats.push(this.createType(type, `Q${quarter}, ${year}`));
          break;
        case this.typeFormat.Half:
          this.listOfFormats.push(this.createType(type, `${partOfYear}, ${year}`));
          break;
        case this.typeFormat.Year:
          this.listOfFormats.push(this.createType(type, year));
          break;
        case this.typeFormat.NotDisplay:
          this.listOfFormats.push(this.createType(type, this.translateService.instant('Shared.Entity.Dont_shown')));
          break;
      }
    });

    return this.listOfFormats;
  }

  createType(type: EDateFormat, value: string): IDateFormat {
    return {type, value};
  }
}
