import { Pipe, PipeTransform } from "@angular/core";
import * as dayjs from 'dayjs';

@Pipe({
  name: 'newItemTimePassed',
  standalone: true,
})
export class NewItemTimePassed implements PipeTransform {
  transform(date: string): string {
    if (date) {
      const currentDate = dayjs();
      const actionDate = dayjs(date);

      // Check if action was done within the current date
      if (actionDate.isSame(currentDate, 'day')) {
        return actionDate.format('HH:mm');
      }

      // Check if action was done within the current year
      if (actionDate.isSame(currentDate, 'year')) {
        return actionDate.format('DD.MM');
      }

      // For previous years
      return actionDate.format('DD.MM.YY');
    } else {
      return '';
    }
  }
}
