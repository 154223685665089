import { Inject, Injectable } from '@angular/core';
import { defaultSwiperOptions, practicalInfoFullScreenSwiperOptions } from '@atlas-workspace/shared/directives';
import {
  acceptedImagesExtensions,
  acceptedImagesExtensionsPreview,
  acceptedVideoExtensions,
  DocumentModel,
  EFileType,
  EPlatform,
  FileModel,
  FileNameModel,
  IDocumentPreview,
  ImageModel,
  mimeTypesFiles,
  mimeTypesImages,
  supportedNgxDocViewerExtensions,
} from '@atlas-workspace/shared/models';
import { ModalFacadeService } from '@atlas-workspace/shared/service';
import { WEB_PLATFORM_TYPE } from '@atlas-workspace/shared/translate';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import * as mime from 'mime';
import { Observable } from 'rxjs';

import { DocumentPreviewComponent } from '../components/documents-preview/document-preview.component';
import { ImageModalComponent } from '../components/image-modal/image-modal.component';
import { NewestConfirmModalComponent } from '../components/newest-confirm-modal/newest-confirm-modal.component';
import { VideoModalComponent } from '../components/video-modal/video-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ModalHelpersService {
  constructor(
    private modalFacadeService: ModalFacadeService,
    private translateService: TranslateService,
    @Inject(WEB_PLATFORM_TYPE) private readonly platformType: EPlatform
  ) {}

  public deleteItem(title: string, description: string): Observable<boolean> {
    const modalRef = this.modalFacadeService.openModal(NewestConfirmModalComponent, {
      centered: true,
      windowClass: 'confirm-action-modal-v2',
    });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.description = description;
    modalRef.componentInstance.button.text = this.translateService.instant('Shared.Button.Yes_delete');
    modalRef.componentInstance.icon = 'basket.svg';
    return modalRef.componentInstance.passConfirm;
  }

  public previewImages(files: (DocumentModel | FileModel | any)[], index: number, useCopyImage = true): void {
    files.forEach((doc, i) => {
      //TODO added this because in some documents object we have differences between title and name after renaming
      if (!doc.title && doc.name) doc.title = doc.name;
      if (!doc.createdAt && doc.uploadDate) doc.createdAt = doc.uploadDate;
    });
    if (
      acceptedImagesExtensions.includes(files[index]?.fileExtension?.toLowerCase()) ||
      files[index]?.type === EFileType.Image ||
      mimeTypesImages.indexOf(files[index]?.fileExtension) !== -1
    ) {
      const fileName = files[index].name;
      const preparedFiles = files.filter(
        (file) =>
          file.type === EFileType.Image || acceptedImagesExtensionsPreview.includes(file?.fileExtension?.toLowerCase()),
      );
      const foundIndex = preparedFiles.findIndex((item) => item.name === fileName);
      if (foundIndex === -1) return;
      const modalClass =
        this.platformType === EPlatform.WEB_CLIENT ? 'image-preview-modal client' : 'image-preview-modal';
      const modalRef = this.modalFacadeService.openModal(ImageModalComponent, {
        centered: true,
        windowClass: 'full-screen-modal full-screen-modal--image',
        modalDialogClass: modalClass,
      });
      modalRef.componentInstance.index = foundIndex;
      modalRef.componentInstance.slides = preparedFiles;
      modalRef.componentInstance.showTitle = true;
      modalRef.componentInstance.useCopyImage = useCopyImage;
      modalRef.componentInstance.swiperConfig = cloneDeep({
        ...practicalInfoFullScreenSwiperOptions,
        initialSlide: foundIndex,
      });
    } else {
      this.previewDocument(files[index]);
    }
  }

  public previewDocument(document: DocumentModel | FileModel, showTitle = true, useCopyImage = true, showDownload = true): void {
    if (
      acceptedImagesExtensions.includes(document.fileExtension?.toLowerCase()) ||
      document.type === EFileType.Image ||
      mimeTypesImages.indexOf(document.fileExtension) !== -1
    ) {
      this.openImageFullScreen(document, showTitle, useCopyImage, showDownload);
    } else {
      this.openDocumentFullScreen(document, showDownload);
    }
    window.dispatchEvent(new Event('resize'));
  }

  public openFloorPlans(document: DocumentModel | FileModel): void {
    this.openImageFullScreen(document);
    window.dispatchEvent(new Event('resize'));
  }

  private openImageFullScreen(
    document: DocumentModel | FileModel, 
    showTitle = true, 
    useCopyImage = false, 
    showDownload = true
  ): void {
    if (document.title) {
      //TODO added this because in some documents object we have differences between title and name after renaming
      document.name = document.title;
    }
    const modalClass =
      this.platformType === EPlatform.WEB_CLIENT ? 'document-preview-modal client' : 'document-preview-modal';
    const modalRef = this.modalFacadeService.openModal(ImageModalComponent, {
      centered: true,
      // eslint-disable-next-line sonarjs/no-duplicate-string
      windowClass: 'full-screen-modal--document',
      modalDialogClass: modalClass,
    });
    modalRef.componentInstance.index = 0;
    modalRef.componentInstance.slides = [document];
    modalRef.componentInstance.showTitle = showTitle;
    modalRef.componentInstance.useCopyImage = useCopyImage;
    modalRef.componentInstance.showDownload = showDownload;
    modalRef.componentInstance.swiperConfig = cloneDeep({
      ...defaultSwiperOptions,
      navigation: false,
    });
  }

  private openDocumentFullScreen(document: DocumentModel | FileModel | ImageModel, showDownload = true): void {
    const modalClass =
      this.platformType === EPlatform.WEB_CLIENT ? 'document-preview-modal client' : 'document-preview-modal';
    if (
      !supportedNgxDocViewerExtensions.includes(document.fileExtension!) &&
      !mimeTypesFiles.includes(document.fileExtension!)
    ) {
      if (acceptedVideoExtensions.includes(document.fileExtension!)) {
        const modalRef = this.modalFacadeService.openModal(VideoModalComponent, {
          centered: true,
          windowClass: 'full-screen-modal--document',
          modalDialogClass: modalClass,
        });
        modalRef.componentInstance.slides = document;
        return;
      } else {
        return;
      }
    }

    if (!document.title && document.name) {
      //TODO added this because in some documents object we have differences between title and name after renaming
      document.title = document.name;
    }
    if (!document.createdAt && document instanceof ImageModel && document.uploadDate) {
      document.createdAt = document.uploadDate;
    }

    const modalRef = this.modalFacadeService.openModal(DocumentPreviewComponent, {
      centered: true,
      windowClass: 'full-screen-modal--document',
      modalDialogClass: modalClass,
    });
    modalRef.componentInstance.documentInfo = {
      name: document.title || '',
      createdAt: document.createdAt || '',
      previewLink: document.fileName.url,
      downloadLink: showDownload ? document.fileName.downloadUrl : '',
      fileExtension: document.fileExtension,
    } as IDocumentPreview;
    modalRef.componentInstance.modalRef = modalRef;
    modalRef.componentInstance.showDownload = showDownload;
  }

  checkFileType(document: DocumentModel | FileModel): EFileType {
    if (acceptedImagesExtensions.includes(document.fileExtension.toLowerCase()) || document.type === EFileType.Image) {
      return EFileType.Image;
    } else {
      if (!supportedNgxDocViewerExtensions.includes(document.fileExtension)) {
        if (acceptedVideoExtensions.includes(document.fileExtension)) {
          return EFileType.Video;
        } else {
          return EFileType.Unknown;
        }
      }
      return EFileType.File;
    }
  }

  previewImageByUrl(url: string, title: string): void {
    const modalClass =
      this.platformType === EPlatform.WEB_CLIENT ? 'document-preview-modal client' : 'document-preview-modal';
    const modalRef = this.modalFacadeService.openModal(ImageModalComponent, {
      centered: true,
      // eslint-disable-next-line sonarjs/no-duplicate-string
      windowClass: 'full-screen-modal--document',
      modalDialogClass: modalClass,
    });
    modalRef.componentInstance.index = 0;
    modalRef.componentInstance.slides = [this.singleUrlToImageModel(url, title)];
    modalRef.componentInstance.showTitle = true;
    modalRef.componentInstance.useCopyImage = false;
    modalRef.componentInstance.showDownload = false;
    modalRef.componentInstance.showSubtitle = false;
    modalRef.componentInstance.swiperConfig = cloneDeep({
      ...defaultSwiperOptions,
      navigation: false,
    });
  }

  private singleUrlToImageModel(url: string, title: string): ImageModel {
    const image: ImageModel = {
      id: 1,
      name: title,  
      createdAt: new Date().toISOString(),
      fileName: new FileNameModel(url),
      fullImage: new FileNameModel(url),
      fileExtension: undefined,
      fileSize: undefined,
      position: 1,
      title: title,
      type: EFileType.Image
    };

    return image
  }
}
