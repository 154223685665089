import { Expose, Transform, Type } from 'class-transformer';

import { FileNameModel, IVisibleTo } from './file.model';

export interface IAdminPracticalInfo {
  id: number;
  name: string;
  description: string;
  updated_at: string;
  created_at: string;
  draft: boolean;
  file_resources: IFile[];
  images: IImgFile[];
}

interface IFile {
  id: string;
  name: string;
  updated_at: string;
  created_at: string;
  file_extension: string;
  file_size: string;
  filename: IFileName;
}

interface IFileName {
  url: string;
  download_url: string;
}

interface IImgFile {
  download_url: string;
  url: string;
  w260: {
    url: string;
  };
}

export enum EPracticalInfoModalState {
  RemoveTemplate = 'removed_practical_info',
  CreatedTemplate = 'created_practical_info',
  EditTemplate = 'edit_practical_info',
}

export interface IPracticalInfoModalUpdate {
  state: EPracticalInfoModalState;
  updatedTemplate?: AdminPracticalInfoModal;
}

export interface IFormPracticalInfo {
  name: string;
  type: string;
  text: string;
  document: (FileModel | ImageModel | File)[];
}

export class AdminPracticalInfoModal {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'description' })
  description!: string;
  @Expose({ name: 'draft' })
  draft!: boolean;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'updated_at' })
  updatedAt!: string;
  @Expose({ name: 'images' })
  @Type(() => ImageModel)
  images!: ImageModel[];
  @Expose({ name: 'file_resources' })
  @Type(() => FileModel)
  fileResources!: FileModel[];
  @Expose({ name: 'units_ids' })
  unitsIds!: number[];
  @Expose({ name: 'layout_type_ids' })
  layoutTypesIds!: number[];

  selected = false;
}

class ImageModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'filename' })
  @Type(() => FileNameModel)
  fileName!: FileNameModel;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  position?: number;

  type = 'image';

  constructor() {
    this.id = 0;
  }
}

class FileModel {
  @Expose({ name: 'id' })
  id!: string;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'title' })
  title!: string;
  @Expose({ name: 'visible_to' })
  visibleTo!: IVisibleTo[];
  @Expose({ name: 'updated_at' })
  updatedAt!: string;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'file_extension' })
  fileExtension!: string;
  @Expose({ name: 'file_size' })
  fileSize!: number;
  @Expose({ name: 'filename' })
  @Type(() => FileNameModel)
  fileName!: FileNameModel;
  position?: number;
  @Expose({ name: 'upload_date' })
  uploadDate?: string;

  @Expose({ name: 'type' })
  @Type(() => String)
  @Transform(() => 'file', { toClassOnly: true })
  type = 'file';
}

export class PracticalInfoTemplateModal {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'description' })
  description!: string;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'updated_at' })
  updatedAt!: string;
  @Expose({ name: 'draft' })
  draft!: boolean;
  @Expose({ name: 'is_template' })
  isTemplate!: boolean;
  @Expose({ name: 'images' })
  @Type(() => ImageModel)
  images!: ImageModel[];
  @Expose({ name: 'file_resources' })
  @Type(() => FileModel)
  fileResources!: FileModel[];
  @Expose({ name: 'firm_id' })
  firmId!: string;
  @Expose({ name: 'author' })
  author!: { id: number, name: string } | null;

  selected = false;
}
