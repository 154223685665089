<ul class="fdv-folder">
  <li class="fdv-folder__list" *ngFor="let folder of folders">
    <ng-container *ngIf="folder.documentType === documentType.Folder">
      <div
        (click)="selectItem(folder)"
        (dblclick)="activeItem(folder)"
        class="fdv-folder__item"
        [class.selected]="folder.id === selectFolderId"
      >
        <img
          (click)="$event.stopImmediatePropagation(); activeItem(folder)"
          class="fdv-folder__item-arrow"
          src="/assets/fdv_arrow.svg"
          alt="arrow"
          [class.active]="folder.active"
        />
        <div class="fdv-folder__item-name">
          <div>
            <img *ngIf="!folder.active" class="fdv-folder__item-icon" src="/assets/fdv_folder.svg" alt="folder" />
            <img
              *ngIf="folder.active"
              class="fdv-folder__item-icon"
              src="/assets/fdv_folder_open.svg"
              alt="folder-open"
            />
            <img *ngIf="folder.connected" class="sub-icon" src="/assets/fdv_connect.svg" alt="sub-icon" />
          </div>
          <span class="d-block ellipsis">{{ folder.title }} </span>
          <div
            *ngIf="folderMenu"
            #dropdownMenu="ngbDropdown"
            (click)="$event.stopImmediatePropagation()"
            ngbDropdown
            container="body"
            [placement]="['bottom-right', 'top-right']"
            dropdownClass="dropdown-fdv"
            class="dropdown-folder"
          >
            <div
              ngbDropdownToggle
              id="dropdownFolder{{folder.id}}"
              class="fdv-folder__item-action"
              [class.active]="dropdownMenu._open"
            >
              <img alt="icon" src="/assets/dropdown-dot-2.svg">
            </div>
            <div ngbDropdownMenu aria-labelledby="dropdownFolder{{folder.id}}">
              <atl-fdv-tree-menu
                (create)="create(folder)"
                (uploadFile)="uploadFile(folder)"
                (uploadFolder)="uploadFolder(folder)"
                (rename)="rename(folder)"
                (download)="download(folder)"
                (delete)="delete(folder)"
              ></atl-fdv-tree-menu>
            </div>
          </div>
        </div>
      </div>
      <ng-container *ngIf="folder.active">
        <ul class="fdv-folder__child">
          <atl-fdv-tree
            [folders]="folder.children"
            [selectFolderId]="selectFolderId"
            (selectHandler)="selectEvent($event)"
            (createHandler)="create($event)"
            (uploadFileHandler)="uploadFile($event)"
            (uploadFolderHandler)="uploadFolder($event)"
            (renameHandler)="rename($event)"
            (downloadHandler)="download($event)"
            (deleteHandler)="delete($event)"
            [folderMenu]="folderMenu"
          ></atl-fdv-tree>
        </ul>
      </ng-container>
    </ng-container>
  </li>
</ul>
