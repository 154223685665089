import { Component, Inject, Input, OnInit } from '@angular/core';
import { DocumentPreviewHelper, EPlatform, IDocumentPreview } from '@atlas-workspace/shared/models';
import { WEB_PLATFORM_TYPE } from '@atlas-workspace/shared/translate';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { viewerType } from 'ngx-doc-viewer';

/**
 * This component utilizes the ngx-doc-viewer library for document preview.
 * For more information on ngx-doc-viewer, please refer to the documentation
 * at: https://www.npmjs.com/package/ngx-doc-viewer
 */
@Component({
  selector: 'atl-document-preview',
  templateUrl: './document-preview.component.html',
  styleUrls: ['./document-preview.component.scss'],
})
export class DocumentPreviewComponent implements OnInit {
  @Input() set documentInfo(obj: IDocumentPreview) {
    if (obj.fileExtension) {
      this.previewType = DocumentPreviewHelper.getDocumentViewerType(obj.fileExtension);
    }
    this.doc = obj;
  }
  @Input() previewType: viewerType = 'google';
  @Input() modalRef!: NgbModalRef;

  @Input() readonly googleCheckContentLoaded = true;
  @Input() readonly googleCheckInterval = 2e3;
  @Input() readonly googleMaxChecks = 10;

  public readonly pdfViewerType = 'pdf';
  public readonly tooltipDelay = 500;
  public platform!: EPlatform;
  public platformTypes = EPlatform;
  public doc!: IDocumentPreview;

  constructor(@Inject(WEB_PLATFORM_TYPE) private platformType: EPlatform) {}

  ngOnInit(): void {
    this.platform = this.platformType;
  }

  public close(): void {
    this.modalRef.close();
  }
}
