import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { IndividualConfig } from 'ngx-toastr/toastr/toastr-config';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private defaultConfig = {
    closeButton: true,
    positionClass: 'toast-bottom-center',
    toastClass: 'ngx-toastr',
    timeOut: 5000,
  };

  constructor(private toastr: ToastrService) {}

  /*
  pass additional parameter true/false to hide/show icon
   */
  openSuccessToast(message: string, hideIcon = false): void {
    const config = this.setConfig(hideIcon);
    this.toastr.success(message, '', config);
  }

  /*
  pass additional parameter true/false to hide/show icon
   */
  openAccentToast(message: string, hideIcon = false, bottom = false): void {
    const config = this.setConfig(hideIcon, bottom);
    this.toastr.info(message, '', config);
  }

  /*
  pass additional parameter true/false to hide/show icon
   */
  openWarningToast(message: string, hideIcon = false, bottom = false): void {
    const config = this.setConfig(hideIcon, bottom);
    this.toastr.warning(message, '', config);
  }

  /*
  pass additional parameter true/false to hide/show icon
   */
  openErrorToast(message: string, hideIcon = false, bottom = false): void {
    const config = this.setConfig(hideIcon, bottom);
    this.toastr.error(message, '', config);
  }

  private setConfig(hideIcon: boolean, bottom = false): Partial<IndividualConfig> {
    this.defaultConfig.toastClass = `ngx-toastr ${hideIcon ? 'no-icon' : ''}`;
    if (bottom) {
      this.defaultConfig.positionClass = 'toast-bottom-full-width';
    }
    return this.defaultConfig;
  }
}
