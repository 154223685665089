<atl-default-modal
  [class.comment-btn--active]="!!slides[index]?.comment"
  [headerData]="headerData"
  [commentBtn]="!!slides[index]?.comment"
  [commentStatus]="showComment"
  [constructionPhoto]="constructionPhoto"
  [isClient]="true"
  (toggleComment)="toggleComment($event)"
>
  <div class="d-flex w-100">
    <div class="swiper-container-wrapper position-relative w-100">
      <swiper-container
        atlSwiper
        [config]="swiperConfig"
        [slides]="slides"
        init="false"
        class="swiper-container w-100"
        (indexChanged)="onIndexChange($event)"
      >
        <swiper-slide
          *ngFor="let slide of slides; let slideIndex = index"
          class="swiper-slide custom-modal-construction__slide"
        >
          <div
            class="d-flex justify-content-center h-100 align-items-center"
            [ngClass]="{ images: !slide.comment, 'hide-img': slideIndex !== index }"
          >
            <ng-container *ngIf="!slide.fileResources?.length; else emptyVideo">
              <ng-container *ngIf="!(slide.name | isRaw); else emptyRaw">
                <img [src]="slide.fileName.url" alt="image" class="slide" />
              </ng-container>
            </ng-container>
          </div>
        </swiper-slide>
      </swiper-container>
    </div>
    <div *ngIf="!!slides[index].comment && showComment" class="comment-block v-scrollbar">
      <atl-firm-icon
        [isUser]="true"
        [model]="slides[index].comment.author"
        nameKey="name"
        [isMonochrome]="true"
        class="comment-block__avatar mr-8 cursor-default"
        [size]="24"
      ></atl-firm-icon>
      <div class="comment-block__body">
        <p class="fz-12 lh-16 comment-block__date">
          <span class="comment-block__author-name">{{ slides[index].comment.author.name }}</span>
          {{ slides[index].comment.updatedAt | date: 'MMM d' }}
        </p>
        <p class="fz-16 lh-24 comment-block__body">{{ slides[index].comment.body | truncate: 500 }}</p>
      </div>
    </div>
  </div>
</atl-default-modal>

<ng-template #emptyVideo>
  <div class="custom-modal-construction__empty-video">
    <img src="assets/video-empty.svg" alt="video-empty" />
    <span>{{ 'Shared.Description.Not_supporting_video' | translate }}</span>
  </div>
</ng-template>

<ng-template #emptyRaw>
  <div class="custom-modal-construction__empty-video">
    <img src="assets/no_images.png" alt="raw-empty" />
  </div>
</ng-template>
