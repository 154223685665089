import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filesize',
})
export class FileSizePipe implements PipeTransform {
  transform(bytes: number, decimals = 1): string {
    if (bytes === 0 || bytes === null) {
      return '0 KB';
    }
    const k = 1000;
    const extensions = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${extensions[i]}`;
  }
}
