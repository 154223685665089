import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClickOutsideDirective } from '@atlas-workspace/shared/directives';
import { IChangeRequestCount } from '@atlas-workspace/shared/models';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';

import arrowDownIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/arrow-down-sm.svg';
import filterIcon from '!!raw-loader?!@atlas-workspace/shared/assets/lib/filter-button-icon.svg';

import { CustomIconComponent } from '../custom-icon/custom-icon.component';

export enum EChangeRequestStatusFilter {
  All = '',
  Pending = 'pending',
  OfferSent = 'offer_sent',
  OfferExpired = 'offer_expired',
  OfferDeclined = 'offer_declined',
  OfferCancelled = 'offer_cancelled',
  OfferApproved = 'offer_approved',
  RequestDeclined = 'request_declined',
  InProgress = 'in_progress',
}

export const statusList = [
  { status: EChangeRequestStatusFilter.All },
  { status: EChangeRequestStatusFilter.Pending },
  { status: EChangeRequestStatusFilter.InProgress },
  { status: EChangeRequestStatusFilter.OfferSent },
  { status: EChangeRequestStatusFilter.OfferApproved },
  { status: EChangeRequestStatusFilter.OfferExpired },
  { status: EChangeRequestStatusFilter.OfferCancelled },
  { status: EChangeRequestStatusFilter.OfferDeclined },
  { status: EChangeRequestStatusFilter.RequestDeclined },
];

export enum EChangeRequestFilter {
  MyTasks = 'my_task',
  HasMessage = 'has_messages',
}

export interface IFilterList {
  value: EChangeRequestFilter;
  active: boolean;
}

export const filterList: IFilterList[] = [
  { value: EChangeRequestFilter.MyTasks, active: false },
  { value: EChangeRequestFilter.HasMessage, active: false },
];

@Component({
  selector: 'atl-change-request-filter',
  templateUrl: './change-request-filter.component.html',
  styleUrls: ['./change-request-filter.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, NgbTooltipModule, ClickOutsideDirective, CustomIconComponent],
})
export class ChangeRequestFilterComponent {
  @Input() set statusCount(value: IChangeRequestCount) {
    if (value) {
      this.changeRequestsStatus = value;
      this.calcLastIndex();
    }
  }
  @Input() totalCount!: number;
  @Input() viewItemsCount = 3;
  @Input() customListView = true;

  @Output() private readonly statusHandler = new EventEmitter<EChangeRequestStatusFilter>();
  @Output() private readonly filterHandler = new EventEmitter<EChangeRequestFilter>();

  public changeRequestsStatus!: IChangeRequestCount;
  public activeStatus: EChangeRequestStatusFilter = EChangeRequestStatusFilter.All;
  public activeStatusIndex: number | null = 0;
  public lastIndex!: number;
  public statusExpanded = false;
  public dropdownMobileCollapsed = true;
  public isShowExpand = true;
  public readonly statusList = statusList;
  private readonly filterList = cloneDeep(filterList);
  public actualFilterList!: IFilterList[];
  public readonly arrowDownIcon = arrowDownIcon;
  public readonly filterIcon = filterIcon;

  public setStatus(value: EChangeRequestStatusFilter, index: number | null = null): void {
    this.activeStatus = this.activeStatus !== value ? value : EChangeRequestStatusFilter.All;
    this.activeStatusIndex = this.activeStatusIndex === index ? 0 : index;
    this.statusHandler.emit(this.activeStatus);
    this.closeMobileDropdown();
  }

  public setFilter(value: EChangeRequestFilter): void {
    this.actualFilterList.forEach((item) => {
      if (item.value === value) {
        item.active = !item.active;
      }
    });
    this.filterHandler.emit(value);
    this.closeMobileDropdown();
  }

  public statusExpandHandler(): void {
    this.statusExpanded = !this.statusExpanded;
  }

  public statusExpandClose(): void {
    this.statusExpanded = false;
  }

  private calcLastIndex(): void {
    this.actualFilterList = [];
    this.lastIndex = this.statusList
      .slice(1, this.viewItemsCount)
      .map((item) => this.changeRequestsStatus[item.status].count)
      .filter((item) => item).length;

    this.isShowExpand = !!this.statusList
      .slice(this.viewItemsCount)
      .map((item) => this.changeRequestsStatus[item.status].count)
      .filter((item) => item).length;

    if (this.customListView) {
      this.actualFilterList = this.filterList.filter((item) => this.changeRequestsStatus[item.value]?.count);
    }
  }

  public showMobileDropdown(): void {
    this.dropdownMobileCollapsed = !this.dropdownMobileCollapsed;
  }

  public closeMobileDropdown(): void {
    this.dropdownMobileCollapsed = true;
  }
}
