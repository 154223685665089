import {Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output} from "@angular/core";
import {LockFieldService} from "@atlas-workspace/shared/service";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

export interface ILockedTrigger {
  locked: boolean;
  focused: boolean;
}

@UntilDestroy()
@Directive({
  selector: '[atlLockField]',
  standalone: true,
})
export class LockFieldDirective implements OnInit, OnDestroy {
  private focused = false;
  private locked = false;

  @Input() private sweechLockOff = false;

  @Output() private readonly isLocked = new EventEmitter<ILockedTrigger>();


  @HostListener('mousedown', ['$event', '$event.target']) lister(event: Event, targetElement: HTMLElement): void {
    if (this.sweechLockOff) return;
    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside && this.focused) return;

    if (this.focused) return;
    if (this.locked) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      this.focused = true;
      this.lockFieldService.setLocked(true)
      this.lockedHandler();
    }
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    if (this.sweechLockOff) return;
    if (!targetElement) {
      return;
    }

    const clickedInside = this._elementRef.nativeElement.contains(targetElement);
    if (!clickedInside && this.focused) {
      this.focused = false;
      this._elementRef.nativeElement?.blur();
      this.lockFieldService.setLocked(false);
      this.lockedHandler();
    }
  }

  constructor(private _elementRef: ElementRef, private lockFieldService: LockFieldService) {}

  ngOnInit(): void {
    if (this.sweechLockOff) return;
    this.initLocked()
  }

  ngOnDestroy(): void {
    this.lockFieldService.setLocked(false);
  }

  initLocked(): void {
    this.lockFieldService.locked.pipe(untilDestroyed(this)).subscribe(value => {
      this.locked = value;
      this.lockedHandler();
    })
  }

  lockedHandler(): void {
    this.isLocked.emit({locked: this.locked, focused: this.focused});
  }
}
