import { HttpHeaders } from '@angular/common/http';
import { ITablePagination } from '@atlas-workspace/shared/models';

export class PaginationUtil {
  public static defaultPagination: ITablePagination = {
    currentPage: 1,
    pageItems: 50,
    totalCount: 10,
    totalPages: 1,
  };

  public static convertPaginationType(headers: HttpHeaders, value: string): number {
    const headerValue = headers.get(value);
    if (headerValue !== null) {
      return +headerValue;
    }
    return 0;
  }
}
