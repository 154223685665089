import {CommonModule} from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {SharedDirectivesModule} from "@atlas-workspace/shared/directives";
import { EPriceType, EWishlistStatus, ProductListItemModelCPB } from '@atlas-workspace/shared/models';
import {SharedPipesModule, TruncatePipeModule} from "@atlas-workspace/shared/pipes";
import {TranslateModule} from "@ngx-translate/core";

import {DefaultDropdownModule} from "../default-dropdown/default-dropdown.module";

@Component({
  selector: 'atl-options-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    SharedDirectivesModule,
    DefaultDropdownModule,
    TranslateModule,
    TruncatePipeModule,
    SharedPipesModule
  ]
})
export class OptionsProductCardComponent {
  public readonly priceType = EPriceType;
  public readonly wishlistStatus = EWishlistStatus;
  @Input() readonly isClient = false;
  @Input() readonly hideConfirmationModal = true;
  @Input() products: ProductListItemModelCPB[] = [];

  @Input() currentProduct!: ProductListItemModelCPB;
  @Input() disabled = false;
  @Input() showClientPrice = false;
  @Output() onselectProduct = new EventEmitter();
  @Output() closeDropdown = new EventEmitter();
  @Output() openDropdown = new EventEmitter();

  @Output() private readonly unselectProduct = new EventEmitter<ProductListItemModelCPB>();
  @Output() private readonly confirmSelection = new EventEmitter<ProductListItemModelCPB>();

  public openChange(value: boolean): void {
    if (!value) {
      this.products = [];
      this.closeDropdown.emit();
    } else {
      this.openDropdown.emit();
    }
  }

  handleSelectProduct(product: ProductListItemModelCPB): void {
    if (
      product.wishlistItemId !== this.currentProduct?.id ||
      this.currentProduct?.status === this.wishlistStatus.NotSelected
    ) {
      this.selectProduct(product);
      return;
    }
    this.emitUnselectItem(product);
  }

  private selectProduct(product: ProductListItemModelCPB): void {
    if (this.isClient && product.dependableProducts?.length && !this.hideConfirmationModal) {
      this.confirmSelection.emit(product);
      return;
    }
    this.onselectProduct.emit(product);
  }

  private emitUnselectItem(product: ProductListItemModelCPB): void {
    this.unselectProduct.emit(product);
  }
}
