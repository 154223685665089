import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FdvNodeComponent} from "./fdv-node/fdv-node.component";
import {FdvTreeComponent} from "./fdv-tree/fdv-tree.component";
import {NgbDropdownModule} from "@ng-bootstrap/ng-bootstrap";
import {TranslateModule} from "@ngx-translate/core";
import {FdvTreeMenuComponent} from "../fdv-tree-menu/fdv-tree-menu.component";



@NgModule({
  declarations: [FdvNodeComponent, FdvTreeComponent],
  exports: [FdvNodeComponent, FdvTreeComponent],
  imports: [
    CommonModule,
    NgbDropdownModule,
    TranslateModule,
    FdvTreeMenuComponent
  ]
})
export class FdvTreeModule { }
