import { Injectable } from '@angular/core';
import { IMark, MarkPosition } from '@atlas-workspace/shared/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PlanMarkService {
  private readonly _updatedMark$ = new BehaviorSubject<IMark[] | null>(null);
  private readonly _markPosition$ = new BehaviorSubject<MarkPosition | null>(null);
  private readonly _updatedMarkOnRemove$ = new BehaviorSubject<IMark[] | null>(null);
  private readonly _markPin$ = new BehaviorSubject<IMark[] | null>(null);
  private readonly _existingMarkClick$ = new BehaviorSubject<{
    left: string;
    top: string;
    order: number;
    floorId: number;
  } | null>(null);

  public get updatedMark$(): Observable<IMark[] | null> {
    return this._updatedMark$.asObservable();
  }

  public setUpdatedMark(value: IMark[] | null): void {
    this._updatedMark$.next(value);
  }

  public get markPosition$(): Observable<MarkPosition> {
    return this._markPosition$.asObservable().pipe(filter((position): position is MarkPosition => position !== null));
  }

  public setMarkPosition(value: MarkPosition | null): void {
    this._markPosition$.next(value);
  }

  public get updatedMarkRemove$(): Observable<IMark[] | null> {
    return this._updatedMarkOnRemove$.asObservable();
  }

  public setMarkOnRemove(value: IMark[] | null): void {
    this._updatedMarkOnRemove$.next(value);
  }

  public get existingMarkClicked$(): Observable<{ left: string; top: string; order: number; floorId: number } | null> {
    return this._existingMarkClick$.asObservable();
  }

  public setExistingMarkClick(value: { left: string; top: string; order: number; floorId: number } | null): void {
    this._existingMarkClick$.next(value);
  }

  public setMarkPin(mark: IMark[] | null): void {
    this._markPin$.next(mark);
  }

  public get markPin$(): Observable<IMark[]> {
    return this._markPin$.asObservable().pipe(filter((mark): mark is IMark[] => mark !== null));
  }
}
