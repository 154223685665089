import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Router } from '@angular/router';
import { ELang, ELocalStorageKeys, EPlatform } from '@atlas-workspace/shared/models';
import { LocalStorageService } from '@atlas-workspace/shared/service';

import { InputComponent } from '../input/input.component';
import { WEB_PLATFORM_TYPE } from '@atlas-workspace/shared/translate';

@Component({
  selector: 'atl-newest-input',
  templateUrl: './newest-input.component.html',
  styleUrls: ['./newest-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewestInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewestInputComponent extends InputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Input() showCloseIcon = true;
  @Input() readonly = false;
  @Input() noMobile = false;
  @Input() noBorderWhenHasValue = false;

  public lang!: ELang;
  public platform!: EPlatform;
  public platformTypes = EPlatform;

  @Output() readonly blurHandler = new EventEmitter<unknown>();
  @Output() readonly focusHandler = new EventEmitter<unknown>();

  constructor(
    @Inject(WEB_PLATFORM_TYPE) platformType: EPlatform,
    router: Router, cdr: ChangeDetectorRef, 
    private localStorageService: LocalStorageService
  ) {
    super(platformType, router, cdr);
  }

  ngOnInit(): void {
    this.lang = this.localStorageService.get<ELang>(ELocalStorageKeys.Language) ?? ELang.Norwegian;
    this.platform = this.platformType;
  }

  onRemoveValue(): void {
    this.inputValue = '';
    this.onChange(this.inputValue);
  }

  checkFocus(): void {
    this.focusHandler.emit();
  }

  checkBlur(): void {
    this.blurHandler.emit();
  }

  ngOnDestroy(): void {
    this.inputElement.nativeElement.blur();
  }
}
