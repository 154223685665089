import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'atl-custom-text-area',
  templateUrl: './custom-text-area.component.html',
  styleUrls: ['./custom-text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomTextAreaComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTextAreaComponent implements ControlValueAccessor {
  @Input() hasUndo = false;
  @Input() label!: string;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() showNumberOfSymbols = false;
  @Input() maxLength = Infinity;
  @Input() capitalize = false;
  undoDuration = 5;
  undoTimer$ = new BehaviorSubject(0);
  inputValue = '';
  public onTouched!: () => void;
  public showUndo$ = new BehaviorSubject(false);
  private initValue!: string;
  protected onChange!: (value: string) => void;

  constructor(protected cdr: ChangeDetectorRef) {}

  changeValue(value: string): void {
    this.inputValue = value;
    if (this.hasUndo) {
      this.undoTimer$.next(0);
      this.showUndo$.next(true);
    } else {
      this.onChange(value);
    }
    this.cdr.markForCheck();
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  enter(e: Event): void {
    e.stopPropagation();
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    if (this.hasUndo) {
      this.initValue = value;
    }
    this.inputValue = value;
    this.cdr.markForCheck();
  }

  finishUndo(): void {
    this.showUndo$.next(false);
    this.onChange(this.inputValue);
    this.initValue = this.inputValue;
  }

  cancelUndo(): void {
    this.undoTimer$.next(0);
    this.showUndo$.next(false);
    this.inputValue = this.initValue;
  }

  startTimer(): void {
    if (this.hasUndo) {
      this.onTouched();
      this.undoTimer$.next(this.undoDuration);
    }
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
