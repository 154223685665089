import {CommonModule} from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {RouterLink, RouterLinkActive} from "@angular/router";
import {IsVisibleDirective} from "@atlas-workspace/shared/directives";
import { ISettingsMenu, ISimpleTab } from '@atlas-workspace/shared/models';
import {TruncatePipeModule} from "@atlas-workspace/shared/pipes";
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {TranslateModule} from "@ngx-translate/core";
import { of, Subject } from 'rxjs';
import { delay, take } from 'rxjs/operators';

import {TooltipComponent} from '../tooltip/tooltip.component';

@UntilDestroy()
@Component({
  selector: 'atl-simple-tabs',
  templateUrl: './simple-tabs.component.html',
  styleUrls: ['./simple-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, RouterLinkActive, RouterLink, TranslateModule, IsVisibleDirective, TruncatePipeModule, TooltipComponent]
})
export class SimpleTabsComponent implements OnInit {
  @Input() queryParams: { [key: string]: string } = {};
  @Input() set tabs(data: ISimpleTab[] | ISettingsMenu[]) {
    this.tabsData = data;
  }

  @Input() routeNavigation = true;
  @Input() activeTab!: string;
  @Input() useTooltip = false;
  @Input() skipTranslation = false;
  @Input() truncateLimit: number = 0;
  @Input() exact = true;
  @Input() withId = false;
  @Input() activeId!: string | number;
  @Input() scrollControl = false;
  @Input() hideWithZeroCounters = false;
  @Input() moreCount = false;
  @Input() inlineTabs = false;
  @Input() listenVisibility = false;
  @Input() hideCounters = false;

  @Input() detectUIChanges!: Subject<void>;

  @Output() private readonly selectingTab = new EventEmitter();
  @Output() private readonly visibilityEvent = new EventEmitter<boolean[]>();

  @ViewChild('scroll') set scrollWrap(el: ElementRef<HTMLDivElement>) {
    this.scrollEl = el;
    this.initEventHandling();
  }

  private scrollEl!: ElementRef<HTMLDivElement>;
  public tabsData: ISimpleTab[] | ISettingsMenu[] = [];
  public arrowLeft = false;
  public arrowRight = false;
  public readonly maxCounterNum = 99;
  private readonly debounceTime = 0;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.onListenUIChanges();
  }

  public onItemVisibility(isFirst: boolean, val: boolean): void {
    this.visibilityEvent.emit([isFirst, val]);
  }
  
  public scrollEvent(): void {
    const scrollLeft = Math.ceil(this.scrollEl.nativeElement.scrollLeft);
    const scrollWidth = this.scrollEl.nativeElement.scrollWidth - this.scrollEl.nativeElement.offsetWidth;
    this.arrowLeft = scrollLeft !== 0;
    this.arrowRight =
      scrollLeft + this.scrollEl.nativeElement.clientWidth < this.scrollEl.nativeElement.scrollWidth &&
      scrollWidth !== 0;
    this.cdr.detectChanges();
  }

  public scrolling(event: MouseEvent, arrow: 'left' | 'right'): void {
    if (event.button === 0) {
      const scrollLeft = this.scrollEl.nativeElement.scrollLeft;
      this.scrollEl.nativeElement.scrollLeft = arrow === 'left' ? scrollLeft - 60 : scrollLeft + 60;
    }
  }

  public onMouseOut(item: ISimpleTab): void {
    item.isShowTooltip = false;
  }

  public onMouseEnter(item: ISimpleTab): void {
    item.isShowTooltip = true;
  }

  public selectTab(tabName: string, isDisabled: boolean, itemId?: number): void {
    if (isDisabled) {
      return;
    }
    if (!this.withId) {
      this.selectingTab.emit({
        tabName,
      });
    } else {
      this.selectingTab.emit({
        tabName,
        itemId,
      });
    }
  }

  private onListenUIChanges(): void {
    if (this.detectUIChanges) {
      this.detectUIChanges.pipe(untilDestroyed(this)).subscribe(() => this.initEventHandling());
    }
  }

  private initEventHandling(): void {
    of([])
      .pipe(take(1), delay(this.debounceTime))
      .subscribe(() => this.scrollEvent());
  }

  public get shiftTooltip(): number {
    return this.scrollEl?.nativeElement?.scrollLeft;
  }
}
