import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ClientOnboardingModalComponent } from '@atlas-workspace/shared/modals';
import { LocalStorageService, OnboardingService } from '@atlas-workspace/shared/service';
import { HeaderIconComponent } from '@atlas-workspace/shared/ui';
import { NgbActiveModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'atl-client-options-onboarding-modal',
  templateUrl: './client-options-onboarding-modal.component.html',
  styleUrls: ['./client-options-onboarding-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, HeaderIconComponent, NgbTooltipModule],
})
export class ClientOptionsOnboardingModalComponent extends ClientOnboardingModalComponent implements OnInit {
  @Input() isDualView!: boolean;

  constructor(
    ngbActiveModal: NgbActiveModal,
    localStorageService: LocalStorageService,
    onboardingService: OnboardingService,
  ) {
    super(ngbActiveModal, localStorageService, onboardingService);
  }

  public skipAllSteps(): void {
    this.ngbActiveModal.close();
    this.localStorageService.remove(this.localStorageKey);
    this.onboardingService.setCompleteOnboarding();
  }
}
