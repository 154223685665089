import { Exclude, Expose } from 'class-transformer';

export enum EModelSettings {
  PageContacts = 'pageContacts',
  PageDocuments = 'pageDocuments',
  PageFdv = 'pageFdv',
  PageHomeModuleMonitoring = 'pageHomeModuleMonitoring',
  PageHomeModuleProjectProgress = 'pageHomeModuleProjectProgress',
  PageHomeModulePropertyValue = 'pageHomeModulePropertyValue',
  PageHomeModuleTimeline = 'pageHomeModuleTimeline',
  //TODO PageMessages used for threads
  PageMessages = 'pageMessages',
  PageOptions = 'pageOptions',
  PagePracticalInfo = 'pagePracticalInfo',
  PageSurveys = 'pageSurveys',
  PageTasks = 'pageTasks',
  PageUpdates = 'pageUpdates',
  PageValueDemographics = 'pageValueDemographics',
  PageValueEstimatedValue = 'pageValueEstimatedValue',
  PageValueLocalMarket = 'pageValueLocalMarket',
  PageValuePriceDevelopment = 'pageValuePriceDevelopment',
  PageValueSalesAlternatives = 'pageValueSalesAlternatives',
  PageValueSalesInNeighbourhood = 'pageValueSalesInNeighbourhood',
  PageVisuals = 'pageVisuals',
  PageHomeValue = 'pageHomeValue',
  PageSettings = 'pageSettings',
  PageMeetings = 'pageMeetings',
  PageReclamations = 'pageReclamations',
  PageChangeRequests = 'pageChangeRequests',
}

export class ModelSettings {
  @Expose({ name: 'page_contacts' })
  pageContacts: boolean;
  @Expose({ name: 'page_documents' })
  pageDocuments: boolean;
  @Expose({ name: 'page_home_module_monitoring' })
  pageHomeModuleMonitoring: boolean;
  @Expose({ name: 'page_home_module_projectprogress' })
  pageHomeModuleProjectProgress: boolean;
  @Expose({ name: 'page_home_module_propertyvalue' })
  pageHomeModulePropertyValue: boolean;
  @Expose({ name: 'page_home_module_timeline' })
  pageHomeModuleTimeline: boolean;
  @Expose({ name: 'page_module_map' })
  pageHomeModuleMap: boolean;
  @Expose({ name: 'page_messages' })
  pageMessages: boolean;
  @Expose({ name: 'page_options' })
  pageOptions: boolean;
  @Expose({ name: 'page_practicalinfo' })
  pagePracticalInfo: boolean;
  @Expose({ name: 'page_surveys' })
  pageSurveys: boolean;
  @Expose({ name: 'page_tasks' })
  pageTasks: boolean;
  @Expose({ name: 'page_updates' })
  pageUpdates: boolean;
  @Expose({ name: 'page_value_demographics' })
  pageValueDemographics: boolean;
  @Expose({ name: 'page_value_estimatedvalue' })
  pageValueEstimatedValue: boolean;
  @Expose({ name: 'page_value_localmarket' })
  pageValueLocalMarket: boolean;
  @Expose({ name: 'page_value_pricedevelopment' })
  pageValuePriceDevelopment: boolean;
  @Expose({ name: 'page_value_salesalternatives' })
  pageValueSalesAlternatives: boolean;
  @Expose({ name: 'page_value_salesinneighbourhood' })
  pageValueSalesInNeighbourhood: boolean;
  @Expose({ name: 'page_visuals' })
  pageVisuals: boolean;
  @Expose({ name: 'page_home_value' })
  pageHomeValue: boolean;
  @Expose({ name: 'page_settings' })
  pageSettings: boolean;
  @Expose({ name: 'page_meetings' })
  pageMeetings: boolean;
  @Expose({ name: 'page_reclamations' })
  pageReclamations: boolean;
  @Expose({ name: 'page_change_requests' })
  pageChangeRequests: boolean;
  @Expose({ name: 'page_fdv' })
  pageFdv: boolean;
  @Expose({ name: 'allow_notifications_management' })
  notificationsManagement: boolean;

  constructor() {
    this.pageContacts = false;
    this.pageDocuments = false;
    this.pageHomeModuleMonitoring = false;
    this.pageHomeModuleProjectProgress = false;
    this.pageHomeModulePropertyValue = false;
    this.pageHomeModuleTimeline = false;
    this.pageHomeModuleMap = false;
    this.pageMessages = false;
    this.pageOptions = false;
    this.pagePracticalInfo = false;
    this.pageSurveys = false;
    this.pageTasks = false;
    this.pageUpdates = false;
    this.pageValueDemographics = false;
    this.pageValueEstimatedValue = false;
    this.pageValueLocalMarket = false;
    this.pageValuePriceDevelopment = false;
    this.pageValueSalesAlternatives = false;
    this.pageValueSalesInNeighbourhood = false;
    this.pageVisuals = false;
    this.pageHomeValue = false;
    this.pageSettings = false;
    this.pageMeetings = true;
    this.pageReclamations = true;
    this.pageChangeRequests = true;
    this.pageFdv = true;
    this.notificationsManagement = true;
  }
}

export class ProjectSettingsModel extends ModelSettings {
  @Expose({ name: 'created_at' })
  @Exclude({ toPlainOnly: true })
  createdAt: string;
  @Expose({ name: 'id' })
  @Exclude({ toPlainOnly: true })
  id: number;
  @Expose({ name: 'project_id' })
  @Exclude({ toPlainOnly: true })
  projectId: number;
  @Expose({ name: 'updated_at' })
  @Exclude({ toPlainOnly: true })
  updatedAt: string;

  constructor() {
    super();
    this.createdAt = '';
    this.id = -1;
    this.projectId = -1;
    this.updatedAt = '';
  }
}
