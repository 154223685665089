import { Expose } from 'class-transformer';

export interface IProjectContact {
  id: number;
  name: string;
  email: string;
  phone_number: string;
  company_name: string;
  website_url: string;
  address: string;
  longitude: number;
  latitude: number;
  project_id: number;
}

export class ProjectContactModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'email' })
  email!: string;
  @Expose({ name: 'phone_number' })
  phoneNumber!: string;
  @Expose({ name: 'company_name' })
  companyName!: string;
  @Expose({ name: 'address' })
  address!: string;
  @Expose({ name: 'longitude' })
  longitude!: string;
  @Expose({ name: 'latitude' })
  latitude!: string;
  @Expose({ name: 'project_id' })
  projectId!: string;
  @Expose({ name: 'role' })
  role!: string;
  @Expose({ name: 'company' })
  company!: {
    address: string;
    id: number;
    latitude: number;
    longitude: number;
    org_number: number;
    title: string;
  };
  @Expose({ name: 'created_at' })
  createAt!: string;

  selected?: boolean;
}

export interface IContactCreation {
  manager_contact: {
    name: string;
    phone_number: string;
    email: string;
    website_url?: string;
    company?: {
      id: string;
    };
    address?: string;
    longitude?: number;
    latitude?: number;
  };
}
