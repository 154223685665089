<div class="expanded-textarea-container">
  <label
    *ngIf="!expanded"
    for="expanded-textarea"
    class="expanded-textarea-inline expanded-textarea-inline-preview f-family-sfpro fw-400 fz-14 lh-24 w-100"
    [class.disabled]="disabled"
    [style.height.px]="lineHeight"
    >{{ inputValue }}</label
  >

  <textarea
    #textarea
    tabindex="-1"
    atlReplaceInput
    atlMaxLength
    id="expanded-textarea"
    class="
      expanded-textarea expanded-textarea-inline expanded-textarea-inline-input
      f-family-sfpro
      fw-400
      fz-14
      lh-24
      v-scrollbar
    "
    [class.expanded]="expanded"
    [class.hide-scroll]="!viewScroll"
    [style.height.px]="lineHeight"
    [value]="inputValue"
    [readonly]="disabled"
    [placeholder]="placeholder ? (placeholder | translate) : ''"
    [currentValue]="inputValue"
    [maxLength]="maxLength"
    (input)="changeValue(textarea.value); setTextareaHeight()"
    (blur)="onBlur($event.target.value)"
    (focus)="onFocus($event)"
  ></textarea>
</div>
