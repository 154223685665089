export enum EMixpanelEvent {
  SignIn = 'Authorization',
  SingOut = 'End session',
  LogOutClicked = 'Log out button clicked',
  DeleteAccountButton = 'Delete account button clicked',
  PropertyDetail = 'Property detail section view',
  ProductClicked = 'Product button clicked',
  ProjectClicked = 'Project clicked',
  CreateProjectClicked = 'Create project button clicked',
  NewProjectClicked = 'New project button clicked',
  LoginButtonClick = 'Login button click',
  ForgetButtonClicked = 'Forget password button clicked"',
  SelectPackage = 'Select package button clicked',
  PageSignIn = 'Login page view',
  PageContacts = 'Contact page view',
  PageDocuments = 'Document page view',
  PageHome = 'Home page view',
  PageOption = 'Option page view',
  PageIntroduction = 'Introduction page view',
  PagePackages = 'Packages page view',
  PagePractical = 'Practical info page view',
  PageReclamation = 'Reclamation page view',
  PageSurveys = 'Surveys page view',
  PageTask = 'Task page view',
  PageMessage = 'Message page view',
  PageUpdate = 'Update page view',
  PageMeetings = 'Meeting page view',
  PageProjects = 'Project page view',
  PageOverview = 'Overview page view',
  PageUnits = 'Unit page view',
  PageTimeline = 'Timeline page view',
  PageMonitoring = 'Monitoring page view',
  PageChangeRequest = 'Change request page view',
  PageUsers = 'User page view',
  PageCompanies = 'Companies page view',
  PageGeneral = 'General page view',
  PageOrganization = 'Organization page view',
  DownloadAppClicked = 'Download app button clicked',
}

export enum EMixpanelPlatform {
  Admin = 'Admin',
  Client = 'Client',
}
