import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'atl-comment-textarea',
  templateUrl: './comment-textarea.component.html',
  styleUrls: ['./comment-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommentTextAreaComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommentTextAreaComponent implements ControlValueAccessor {
  @Input() placeholder = '';
  @Input() disabled = false;

  @Input() inputValue!: string;
  public onTouched!: () => void;

  private initValue!: string;
  public focused = false;
  protected onChange!: (value: string) => void;

  constructor(protected cdr: ChangeDetectorRef) {}

  changeValue(value: string): void {
    this.onChange(value);
    this.inputValue = value;
    return;
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    this.initValue = value;
    this.inputValue = value;
    this.cdr.markForCheck();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onFocus(event: boolean): void {
    this.focused = event;
  }
}
