import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';

@Injectable()
export class SentryService extends ErrorHandler {
  constructor(private dns: string, private isProdEnv: boolean) {
    super();
    if (this.isProdEnv) {
      Sentry.init({
        dsn: this.dns,
        environment: 'prod',
        beforeSend: (event) => {
          return event;
        },
      });
    }
  }

  handleError(error: any): void {
    if (error.status === 401) return;
    if (this.isProdEnv) {
      Sentry.captureException(error.originalError || error);
    }
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      window.location.reload();
    }
    super.handleError(error);
  }
}
