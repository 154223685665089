import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { NgbCalendar, NgbDate, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import dayjs from 'dayjs';
import { of, Subject } from 'rxjs';
import { delay, take } from 'rxjs/operators';

import { DateAdapterService } from '../../services/date-adapter.service';
import {PlacementArray} from "@ng-bootstrap/ng-bootstrap/util/positioning";

@UntilDestroy()
@Component({
  selector: 'atl-phase-date-range',
  templateUrl: './phase-date-range.component.html',
  styleUrls: ['./phase-date-range.component.scss'],
})
export class PhaseDateRangeComponent implements OnInit, OnChanges {
  @Input() mask = 'd0.M0.0000';
  @Input() navigation: 'select' | 'arrows' | 'none' = 'arrows';
  @Input() outsideDays: 'visible' | 'hidden' | 'collapsed' = 'visible';
  @Input() set updateDate(date: any | null) {
    if (!date) {
      this.clearAll();
    } else {
      this.updateDateInput(date);
    }
  }
  @Input() dates?: { from: string; to: string };
  @Input() showEndDate = true;
  @Input() disabledClear = false;
  @Input() parentScroll?: Subject<any>;
  @Input() phaseName = '';
  @Input() placement: PlacementArray = ['bottom-right', 'top-right', 'left'];
  @Input() showClear = true;
  @Input() validateToDate = true;

  @Output() private readonly selectDate = new EventEmitter<{ from: string; to: string | null } | null>();
  @Output() private readonly resetEvent = new EventEmitter();

  @ViewChild('datepicker') datePicker!: NgbInputDatepicker;
  @ViewChild('container') container!: ElementRef<HTMLDivElement>;
  @ViewChild('fromInputRef') fromInputRef!: ElementRef<HTMLInputElement>;
  @ViewChild('toInputRef') toInputRef!: ElementRef<HTMLInputElement>;

  public activeInput = false;
  public fromPlaceholder = 'Shared.Input.Start_date.Placeholder';
  public toPlaceholder = 'Shared.Input.End_date.Placeholder';

  public hoveredDate: NgbDate | null = null;

  public from = '';
  public to = '';

  public fromInput = '';
  public toInput = '';

  public fromDate: NgbDateStruct | null = null;
  public toDate: NgbDateStruct | null = null;

  public isEndDate = true;
  public currentDate!: NgbDate;

  private readonly focusDelay = 200;
  private currentValue?: { from: string; to: string } | null = null;
  private readonly dateTemplate = 'YYYY-MM-DD';

  private isFocused = [false, false];

  constructor(
    private readonly dateAdapter: DateAdapterService,
    private readonly calendar: NgbCalendar,
  ) {
    this.currentDate = this.calendar.getToday();
  }

  ngOnInit(): void {
    if (this.dates) {
      this.updateDateInput(this.dates);
    }

    this.parentScroll?.pipe(untilDestroyed(this)).subscribe(() => {
      this.datePicker.close();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.dates &&
      (changes.dates.previousValue?.from !== changes.dates.currentValue?.from ||
        changes.dates.previousValue?.to !== changes.dates.currentValue?.to)
    ) {
      this.updateDateInput(this.dates);
    }
  }

  confirmDate(): void {
    this.setDate();
    this.datePicker.close();
  }

  isFocusedToggle(item: number): void {
    this.isFocused[item] = !this.isFocused[item];
    if (!this.isFocused.includes(true) && !this.datePicker.isOpen()) {
      this.activeInput = false;
    }
  }

  focus(el?: HTMLInputElement): void {
    this.datePicker.open();
    this.activeInput = true;

    if (el) {
      of(true)
        .pipe(take(1), delay(this.focusDelay))
        .subscribe(() => {
          el.focus();
        });
    }
  }

  blur(): void {
    if (this.isFocused.includes(true)) {
      this.focus();
      return;
    }
    this.datePicker.close();
    this.activeInput = false;
    this.clickOutside();
  }

  clickOutside(): void {
    of(true)
      .pipe(take(1), delay(this.focusDelay))
      .subscribe(() => {
        this.fromInputRef.nativeElement.blur();
        this.toInputRef.nativeElement.blur();
      });
  }

  focusToInput(): void {
    if (this.toDate || (this.showEndDate && !this.isEndDate)) return;
    of(true)
      .pipe(take(1), delay(this.focusDelay))
      .subscribe(() => {
        this.toInputRef.nativeElement.focus();
      });
  }

  onDateSelection(date: NgbDate): void {
    this.focus();
    if (!this.fromDate && !this.toDate) {
      this.setFromDate(date);
    } else if ((this.isEndDate || !this.showEndDate) && this.fromDate && !this.toDate && date.after(this.fromDate)) {
      if (this.currentDate.after(date) && this.validateToDate) return;
      this.setToDate(date);
    } else {
      this.clearInputTo();
      this.setFromDate(date);
    }
  }

  isHovered(date: NgbDate): boolean | null {
    return (
      this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate): boolean | null {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate): boolean | null {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  inputFrom(e: Event): void {
    this.focus();
    const target = e.target as HTMLInputElement;
    const date = target.value;
    target.focus();
    if (!date.length) {
      this.clearInputFrom();
      return;
    }
    const value = this.dateTransform(date);
    const toDateObj = this.toDate ? new NgbDate(this.toDate?.year, this.toDate?.month, this.toDate?.day) : undefined;
    if ((this.currentDate.after(value) || this.currentDate.equals(value)) && (!toDateObj || toDateObj.after(value))) {
      this.setFromDate(new NgbDate(value.year, value.month, value.day));
      this.datePicker.navigateTo({ year: this.fromDate!.year, month: this.fromDate!.month, day: this.fromDate!.day });
      this.focusToInput();
    } else if (this.currentDate.before(value)) {
      this.setFromDate(new NgbDate(value.year, value.month, value.day));
      this.datePicker.navigateTo({ year: this.fromDate!.year, month: this.fromDate!.month, day: this.fromDate!.day });
      this.clearInputTo();
    }
  }

  inputTo(e: Event): void {
    const target = e.target as HTMLInputElement;
    this.focus();
    if (!target.value.length) {
      return;
    }
    const value = this.dateTransform(target.value);
    const fromDateObj = this.fromDate
      ? new NgbDate(this.fromDate?.year, this.fromDate?.month, this.fromDate?.day)
      : undefined;
    if (
      (this.currentDate.before(value) || this.currentDate.equals(value)) &&
      (!fromDateObj || fromDateObj.before(value))
    ) {
      this.setToDate(new NgbDate(value.year, value.month, value.day));
      this.datePicker.navigateTo({ year: this.toDate!.year, month: this.toDate!.month, day: this.toDate!.day });
    } else {
      if (fromDateObj?.after(value) || fromDateObj?.equals(value) || this.currentDate.after(value)) {
        this.clearInputTo();
      }
    }
  }

  dateTransform(value: string): NgbDateStruct {
    const transform = this.dateAdapter.convertToFormat(value, 'DD.MM.YYYY', this.dateTemplate);
    return this.dateAdapter.transformDate(transform, true) as NgbDateStruct;
  }

  setFromDate(date: NgbDate): void {
    this.fromDate = date;
    this.fromInput = this.dateAdapter.toModel(this.fromDate);
    this.from = this.dateAdapter.toModelYYYYMMDD(this.fromDate);
  }

  setToDate(date: NgbDate): void {
    this.toDate = date;
    this.toInput = this.dateAdapter.toModel(this.toDate);
    this.to = this.dateAdapter.toModelYYYYMMDD(this.toDate);
  }

  clearInputFrom(): void {
    this.fromInput = '';
    this.from = '';
    this.fromDate = null;
  }

  clearInputTo(): void {
    this.toDate = null;
    this.toInput = '';
    if (this.toInputRef) this.toInputRef.nativeElement.value = '';
  }

  clearAll(): void {
    this.fromInput = '';
    this.toInput = '';
    this.fromDate = null;
    this.toDate = null;

    this.resetEvent.emit();
  }

  setDate(): void {
    if (this.fromDate) {
      const from = this.dateAdapter.toModelYYYYMMDD(this.fromDate);
      const to =
        ((this.showEndDate && this.isEndDate) || !this.showEndDate) && this.toDate
          ? this.dateAdapter.toModelYYYYMMDD(this.toDate)
          : null;

      this.selectDate.emit({ from, to });
    } else {
      this.selectDate.emit(null);
    }
  }

  get disabled(): boolean {
    return this.toDate
      ? dayjs(dayjs(this.currentValue?.from).format(this.dateTemplate)).isSame(
          dayjs(this.dateAdapter.toModelYYYYMMDD(this.fromDate as NgbDateStruct)).format(this.dateTemplate),
        ) &&
          dayjs(dayjs(this.currentValue?.to).format(this.dateTemplate)).isSame(
            dayjs(this.dateAdapter.toModelYYYYMMDD(this.toDate)).format(this.dateTemplate),
          )
      : this.fromDate
        ? true
        : this.currentValue === this.toDate;
  }

  private updateDateInput(date?: { from: string; to: string }): void {
    if (!date) {
      this.currentValue = null;
      this.fromInput = '';
      this.toInput = '';
      this.fromDate = null;
      this.toDate = null;
      return;
    }
    this.currentValue = date;
    const from = this.dateAdapter.transformDate(date.from, true) as NgbDateStruct;
    const to = this.dateAdapter.transformDate(date.to, true) as NgbDateStruct;

    of(null)
      .pipe(take(1), delay(100))
      .subscribe(() => {
        this.setFromDate(new NgbDate(from!.year, from!.month, from!.day));
        this.toDate = new NgbDate(to!.year, to!.month, to!.day);
        this.toInput = this.dateAdapter.toModel(this.toDate);
        this.to = this.dateAdapter.toModelYYYYMMDD(this.toDate);
        if (date?.from && date?.to) {
          this.isEndDate = true;
        }
      });
  }
}
