import {CommonModule} from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {CapitalLetterDirective, NoEmojiDirective} from "@atlas-workspace/shared/directives";
import {TranslateModule} from "@ngx-translate/core";
import {AutoResizeModule} from "../../directive/auto-height/input-auto-height.module";

@Component({
  selector: 'atl-free-text',
  templateUrl: './free-text.component.html',
  styleUrls: ['./free-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, NoEmojiDirective, AutoResizeModule, CapitalLetterDirective]
})
export class FreeTextComponent {
  @Input() set freeText(text: string | null) {
    if (text !== null) {
      this.text = text;
      this.changeText = text;
    }
  }
  @Input() editable = true;
  @Input() completed = false;
  @Input() previewMode = false;
  @Output() saveText = new EventEmitter<string>();

  public isString = true;
  public text = '';
  public readonly placeholder = 'Shared.Input.Placeholder.Free_text';

  private changeText = '';

  get isChange(): boolean {
    return this.text === this.changeText;
  }

  inputText(value: string): void {
    this.changeText = value;
  }

  focusInput(): void {
    this.isString = false;
  }

  blurInput(): void {
    if (this.isChange) {
      this.isString = true;
    }
  }

  save(): void {
    if (!this.isChange) {
      this.saveText.emit(this.changeText);
      this.isString = true;
    }
  }
}
