<atl-default-dropdown>
  <div ngbDropdown (openChange)="openChange($event)" [class.pointer-events-none]="disabled">
    <div class="trigger" id="productCardDropdown" ngbDropdownToggle>
      <ng-content></ng-content>
    </div>

    <div
      ngbDropdownMenu
      aria-labelledby="productCardDropdown"
      class="product-dropdown"
      [class.invisible]="!products?.length"
    >
      <div
        ngbDropdownItem
        *ngFor="let product of products"
        class="d-inline-flex align-items-center justify-content-between cursor-pointer"
        (click)="handleSelectProduct(product)"
        [class.blocked-product]="product.blocked"
      >
        <div class="d-inline-flex ellipsis">
          <img
            width="40"
            height="40"
            [src]="product.preview"
            alt="image"
            class="mr-8 ml-16 br-8 object-fit-cover image"
          />
          <img *ngIf="product.dependableProducts.length > 0" class="rule-icon" src="/assets/rule-icon.svg" alt="Icon" />
          <div class="ellipsis">
            <p class="fz-14 lh-24 ellipsis mr-8">{{ product.title }}</p>
            <ng-container
              *ngIf="
                !product.blocked || (product.blocked && !product.dependableProducts.length);
                else dependableBlockedPlaceholder
              "
            >
              <p class="fz-12 lh-16 text-black-810">
                <ng-container *ngIf="!product.addedToWishlistByForcingRuleTriggerNames.length; else forcedPlacegolder">
                  <span *ngIf="product.priceType !== priceType.OnRequest; else onRequestPlaceholder">
                    {{
                      !product.standard
                        ? (product.fullPrice || product.price | customDecimal)
                        : ('Shared.Title.Included' | translate) + ' · 0,-'
                    }},-
                  </span>
                </ng-container>

                <ng-template #forcedPlacegolder>
                  {{ 'Shared.Tilte.This_product_is_selected_because' | translate }}
                  {{ product.addedToWishlistByForcingRuleTriggerNames?.join(', ') }}
                </ng-template>

                <ng-template #onRequestPlaceholder>
                  <ng-container *ngIf="!product.priceOffer">
                    <span>{{('Shared.Entity.Option' | translate) + ' · ' +  ('Shared.Price_on_request' | translate) }}</span>
                  </ng-container>

                  <ng-container *ngIf="product.priceOffer">
                    <ng-container *ngIf="product.priceOffer.isOfferAccepted; else notAcceptedBlock">
                      <span>
                        {{ 'Shared.Title.Offer_accepted' | translate }} •
                        {{ product.priceOffer.price | customDecimal }},-
                      </span>
                    </ng-container>
                    <ng-template #notAcceptedBlock>
                      {{ 'ChangeRequest.Status.Offered' | translate }} •
                      {{ product.priceOffer.price | customDecimal }},-
                    </ng-template>
                  </ng-container>
                </ng-template>
              </p>
            </ng-container>

            <ng-template #dependableBlockedPlaceholder>
              <p class="fz-12 lh-16 text-black-810">
                {{
                  'Shared.Title.Cannot_be_selected_due_selection'
                    | translate: { VALUE: product.dependableProducts[0].itemableName }
                }}
              </p>
            </ng-template>
          </div>
        </div>

        <div class="d-inline-flex align-items-center mr-16" *ngIf="!product.blocked">
          <button
            *ngIf="
              product.wishlistItemId !== currentProduct?.id || currentProduct?.status === wishlistStatus.NotSelected;
              else currentProductBtn
            "
            class="newest-button h-px-32"
          >
            {{ 'Button.Select' | translate }}
          </button>
        </div>

        <ng-template #currentProductBtn>
          <button class="newest-button h-px-32 selected-button">
            {{ 'Shared.Title.Selected' | translate }}
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</atl-default-dropdown>
