import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxIntlTelInputModule } from '../phone-library/ngx-intl-tel-input.module';
import { UndoCHangeslModule } from '../undo-changes/undo-changes.module';
import { PhoneInputComponent } from './phone-input.component';
import {TranslateModule} from "@ngx-translate/core";

@NgModule({
  imports: [CommonModule, NgxIntlTelInputModule, ReactiveFormsModule, FormsModule, UndoCHangeslModule, TranslateModule],
  declarations: [PhoneInputComponent],
  exports: [PhoneInputComponent],
})
export class PhoneInputModule {}
