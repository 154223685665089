import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[atlHorizontalAlign]',
  standalone: true,
})
export class HorizontalAlignDirective implements OnInit {
  @Input() left = 0;
  @Input() positionFixed = false;
  @Input() relativeElement!: HTMLElement;
  @Input() right = 0;
  @Input() top = 0;
  @Input() permanentPosition: string | undefined;
  // @note: when prop is defined as 'true', previous flow with 'permanentPosition' prop would be ignored
  @Input() flexibleY = false;
  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.calculatePosition();
  }

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.calculatePosition();
  }

  private calculatePosition(): void {
    const domRect = this.relativeElement.getBoundingClientRect();
    if (this.positionFixed) {
      this.elementRef.nativeElement.style.position = 'fixed';
    } else {
      this.elementRef.nativeElement.style.position = 'absolute';
    }

    if (this.flexibleY) {
      const targetElement: HTMLElement = this.elementRef.nativeElement.firstChild || this.elementRef.nativeElement;
      const _position = window.innerHeight - domRect.bottom > targetElement.clientHeight ? 'bottom' : 'top';
      if (_position === 'bottom') {
        this.elementRef.nativeElement.style.bottom = 'auto';
        this.elementRef.nativeElement.style.top = domRect.top + this.relativeElement.offsetHeight + 'px';
      } else {
        this.elementRef.nativeElement.style.top = 'auto';
        this.elementRef.nativeElement.style.bottom = (window.innerHeight - domRect.top || 0) + 'px';
      }
      return;
    }

    this.elementRef.nativeElement.style.top = this.top + domRect.top + 'px';
    const windowWidth = window.innerWidth;
    const parentOffsetLeft = this.elementRef.nativeElement.parentElement.offsetLeft;
    const elementPosition = windowWidth / 2 > parentOffsetLeft ? 'left' : 'right';
    if (this.permanentPosition === 'right') {
      this.elementRef.nativeElement.style.right = windowWidth - (this.right + domRect.right) + 'px';
      return;
    }
    if (this.permanentPosition === 'bottom') {
      this.elementRef.nativeElement.style.top =
        this.elementRef.nativeElement.offsetTop + this.relativeElement.offsetHeight + 'px';
      return;
    }
    if (this.permanentPosition === 'top') {
      this.elementRef.nativeElement.style.top = 'auto';
      const windowHeight = window.innerHeight;
      const elementTopOffset = this.relativeElement.getBoundingClientRect().top;
      const offsetBottom = windowHeight - elementTopOffset + 'px';
      this.elementRef.nativeElement.style.bottom = offsetBottom;
      return;
    }

    if (elementPosition === 'right') {
      if (this.positionFixed) {
        this.elementRef.nativeElement.style.right = windowWidth - (this.right + domRect.right) + 'px';
      } else {
        this.elementRef.nativeElement.style.right = 0;
      }
    } else {
      if (this.positionFixed) {
        this.elementRef.nativeElement.style.left = this.left + domRect.left + 'px';
      } else {
        this.elementRef.nativeElement.style.left = 0;
      }
    }
  }
}
