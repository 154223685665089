import { Pipe, PipeTransform } from '@angular/core';
import { dayjsLocalesMap, ELang } from "@atlas-workspace/shared/models";
import { TranslateService } from '@ngx-translate/core';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

import { settings } from './constants/time-passed';
import { EException, ITimePassedConditionals } from './interfaces/time-passed';

dayjs.extend(duration);
dayjs.extend(relativeTime, settings.dayjsConfig);

@Pipe({
  name: 'timePassed',
})
export class TimePassedPipe implements PipeTransform {
  private conditionals: ITimePassedConditionals = settings.conditionals;
  private exceptions = EException;


  constructor(private translateService: TranslateService) {}

  transform(value: string): unknown {
    const currentDay = dayjs();
    const transformedDate = dayjs(value);
    let prepDate = '';

    let currentLocale = dayjs.locale();
    currentLocale === 'nb' && (currentLocale = 'no');
    if (currentLocale !== this.translateService.currentLang) {
      dayjs.locale(dayjsLocalesMap.get(<ELang>this.translateService.currentLang));
    }

    Object.keys(this.conditionals).forEach((conditional: string) => {
      const specificConditional = this.conditionals[conditional];
      const time = currentDay.diff(transformedDate, specificConditional.type);
      if (
        (conditional === this.exceptions.Years && time >= specificConditional.min) ||
        (time >= specificConditional.min && time <= specificConditional.max)
      ) {
        if (conditional === this.exceptions.Seconds) {
          prepDate = this.translateService.instant('Shared.Entity.Now');
        } else {
          prepDate = `${dayjs.duration(time, specificConditional.type).humanize()} ${this.translateService.instant(
            'Shared.Entity.Ago'
          )}`;
        }
      }
    });

    return prepDate;
  }
}
