import {HttpParams, HttpResponse} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {ETablePagination, ITablePagination, TemplateUpdateModel} from '@atlas-workspace/shared/models';

import {PaginationUtil} from '../../helpers/pagination.util';
import {map} from "rxjs/operators";
import {ClassConstructor, plainToClass} from "class-transformer";

@Injectable({
  providedIn: 'root',
})
export class DataTableHelperService {
  private readonly defaultPerPage = 50;

  public paramsHandler(search: string, sort: string, paginate?: Partial<ITablePagination>): HttpParams {
    let params: HttpParams = new HttpParams();
    if (search) params = params.set('search', search);
    if (sort) params = params.set('sort_by', sort);
    if (paginate?.pageItems) {
      params = params.set('per_page', paginate.pageItems.toString());
    } else {
      params = params.set('per_page', this.defaultPerPage.toString());
    }
    if (paginate?.currentPage) params = params.set('page', paginate.currentPage.toString());
    return params;
  }

  getPagination(body: any): ITablePagination {
    return {
      currentPage: PaginationUtil.convertPaginationType(body.headers, ETablePagination.CurrentPage),
      pageItems: PaginationUtil.convertPaginationType(body.headers, ETablePagination.PageItems),
      totalCount: PaginationUtil.convertPaginationType(body.headers, ETablePagination.TotalCount),
      totalPages: PaginationUtil.convertPaginationType(body.headers, ETablePagination.TotalPages),
    };
  }

  transformResponse<T>(res: HttpResponse<{data: any}>, model: ClassConstructor<T>, key: string): {value: T[], pagination: ITablePagination} {
    const {body} = res;
    const pagination = this.getPagination(res);
    return {value: plainToClass(model, body?.data[key] as T[]), pagination};
  }
}
