<p *ngIf="title" class="title">{{ title }}</p>
<div
  [ngClass]="{ 'checkbox-container__checked-container': value || checkedPartially }"
  [ngStyle]="{ height: height + 'px' }"
  class="checkbox-container"
  (click)="changed(!checkboxRef.checked)"
  [attr.data-cy]="uid ? uid : null"
>
  <div
    (click)="stopProp($event)"
    [ngClass]="{ 'checkbox-container__checkbox__partially': checkedPartially }"
    [ngStyle]="{
      '--checkbox-size': checkboxSize + 'px',
      height: checkboxSize + 'px',
      minHeight: checkboxSize + 'px',
      width: checkboxSize + 'px',
      minWidth: checkboxSize + 'px',
      fontSize: checkboxSize + 'px'
    }"
    class="checkbox-container__checkbox-wrapper"
  >
    <input
      #checkboxRef
      (change)="changed(checkboxRef.checked)"
      [disabled]="disabledState"
      [id]="randomizedID"
      [checked]="value"
      class="checkbox-container__checkbox"
      type="checkbox"
    />
    <label [for]="checkboxRef.id" class="cursor-pointer"><!--      required for mozilla firefox--></label>
  </div>
  <ng-content></ng-content>
  <label *ngIf="checkboxLabel" class="checkbox-container__checkbox-label">{{ checkboxLabel }}</label>
</div>
