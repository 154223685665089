import {CommonModule} from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {SharedDirectivesModule} from "@atlas-workspace/shared/directives";

@Component({
  selector: 'atl-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SharedDirectivesModule]
})
export class TooltipComponent implements OnChanges {
  get tooltipEl(): ElementRef {
    return this._tooltipEl;
  }

  @ViewChild('tooltipEl') set tooltipEl(value: ElementRef) {
    if (value) {
      this._tooltipEl = value;
      this.prepareView();
    }
  }

  @ViewChild('tooltipContainer') private tooltipContainer!: ElementRef;
  private _tooltipEl!: ElementRef;

  @Input() showClose = false;
  @Input() showSpinner = false;
  @Input() text!: string;
  @Input() isDisplayTooltip!: boolean;
  @Input() dynamicPosition = false;
  @Input() dynamicYPosition = false;
  @Input() horizontalShiftTooltipOn?: number;

  @Output() closeTooltipHandler = new EventEmitter<unknown>();

  public rectanglePosition = 0;
  public tooltipPosition = 0;

  public tooltipYPosition = 0;
  private readonly minContainerWidth = 40;

  constructor(private cdr: ChangeDetectorRef) {}

  private prepareView(): void {
    if (this.isDisplayTooltip) {
      const { clientWidth } = this.tooltipEl.nativeElement;
      this.rectanglePosition = clientWidth / 2 - 5;
      if (this.dynamicPosition) {
        this.setTooltipXPosition();
      } else if (this.dynamicYPosition) {
        this.setTooltipYPosition();
      }
      this.cdr.detectChanges();
    }
  }

  private setTooltipXPosition(): void {
    const { x } = this.tooltipContainer.nativeElement.getBoundingClientRect();
    const width = this.tooltipContainer.nativeElement.clientWidth;
    if (width < this.minContainerWidth) {
      this.tooltipPosition = x - 10;
      return;
    }
    this.tooltipPosition = x;
  }

  private setTooltipYPosition(): void {
    const { y } = this.tooltipContainer.nativeElement.getBoundingClientRect();
    this.tooltipYPosition = y + 10;
  }

  public closeTooltip(): void {
    this.closeTooltipHandler.emit();
  }

  ngOnChanges({ isDisplayTooltip }: SimpleChanges): void {
    if (isDisplayTooltip && isDisplayTooltip.currentValue) {
      this.isDisplayTooltip = isDisplayTooltip.currentValue;
      this.cdr.detectChanges();
    }
  }
}
