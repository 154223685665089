import {Component, inject, Input, OnInit} from '@angular/core';
import {
  ChangeRequestModel,
  ClientChangeRequestModel,
  EPlatform,
  FloorModel,
  ISettingsMenu,
  UnitFloorModel,
  UnitUserModel
} from '@atlas-workspace/shared/models';
import {WEB_PLATFORM_TYPE} from "@atlas-workspace/shared/translate";
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'atl-floor-plan-version-modal',
  templateUrl: './floor-plan-version-modal.component.html',
  styleUrls: ['./floor-plan-version-modal.component.scss']
})
export class FloorPlanVersionModalComponent implements OnInit {
  readonly platformType = inject(WEB_PLATFORM_TYPE) as EPlatform;

  @Input() public readonly modalRef!: NgbModalRef;
  @Input() public readonly changeRequest!: ChangeRequestModel | ClientChangeRequestModel;
  @Input() public readonly unit!: UnitUserModel;
  @Input() public readonly version!: number;

  public activeId?: number;
  public navList?: ISettingsMenu[];
  public readonly header = 'Shared.Entity.Floor_plan';
  public readonly tooltipDelay= 500;
  public readonly isClient = this.platformType === EPlatform.WEB_CLIENT;
  public previewImage?: string | null = '';
  private floors?: UnitFloorModel[] | FloorModel[];

  ngOnInit(): void {
    this.floors = this.unit.hasOwnFloorPlans ? this.unit.unitFloors : this.unit.floors;
    this.initNavList();
  }

  closeModal(): void {
    this.modalRef.close();
  }

  public selectTab(event: { itemId: number }): void {
    this.activeId = event.itemId;

    this.activePreviewImage();
  }

  activePreviewImage(): void {
    const tab = this.navList?.find(n => n.id === this.activeId);
    if (tab) {
      const version = this.changeRequest.floorDrawVersions.find(v => v.id === tab.id);
      if (version) {
        this.previewImage = version?.filenameRemoteUrl || version?.filename?.url;
      } else {
        const floor = this.floors?.find(f => f.id === tab.id);
        this.previewImage = floor?.plan?.fullImage?.url;
      }
    }
  }

  private initNavList(): void {
    if (!this.floors?.length) return;
    // @ts-ignore
    this.navList = this.floors.map<ISettingsMenu>((floor: UnitFloorModel | FloorModel) => {
      const version = this.changeRequest.floorDrawVersions.find(v => v.versionNumber === this.version && v.floor?.id === floor.id);
      return {
        name: floor.name,
        route: '',
        disabled: false,
        id: version ? version.id : floor.id
      };
    });
    // @ts-ignore
    this.activeId = this.navList?.[0].id;
    this.activePreviewImage();
  }
}
