<!--
  ******************************************************
  @Cypress
  - interaction with Reuse button
  ******************************************************
-->
<div *ngIf="modalView" class="version-select__header">
  <span class="fz-16 lh-24 fw-500">{{ header | translate }}</span>
  <div
    (click)="closeModal()"
    class="version-select__header-icon"
    [ngbTooltip]="'Shared.Close' | translate"
    [openDelay]="tooltipDelay"
    triggers="hover touchstart"
  >
    <img src="assets/close_newest_gray.svg" alt="close" />
  </div>
</div>
<div *ngIf="floorVersionList[0].floor?.length; else empty" class="version-select__main v-scrollbar">
  <h3 class="fz-24 lh-32 mb-8">{{ title | translate }}</h3>
  <p class="fz-16 lh-24 text-black-810 mb-24">{{ description | translate }}</p>

  <div *ngFor="let item of floorVersionList" class="version-select__version">
    <div class="version-select__version-header">
      <div class="d-flex flex-column">
        <span class="fz-20 lh-24 fw-500 mb-8">{{ 'Shared.Entity.Version' | translate }} {{ item.version }}</span>
        <span *ngIf="item.creator" class="fz-16 lh-24 text-black-810">
          {{ 'Shared.Entity.Created' | translate }} {{ item.createAt | date: 'dd.MM.YYYY' }}
          {{ 'Shared.Entity.By' | translate }} {{ item.creator }}
        </span>
      </div>
      <div *ngIf="reuseDrawVersion">
        <button
          (click)="reuseVersion(item.version)"
          type="button"
          class="newest-button newest-button-sm version-select__reuse-btn"
          data-cy="version-select-reuse-btn"
        >
          {{ 'Shared.Entity.Reuse_version' | translate }}
        </button>
      </div>
    </div>
    <div class="version-select__version-plan">
      <div *ngFor="let floor of item.floor" class="version-select__plan" [class.reuse-version]="reuseDrawVersion">
        <div
          #parent
          class="version-select__plan-view"
          [ngClass]="{
            selected: floor.selected && !reuseDrawVersion,
            'cursor-default': !(
              floor.plan?.filename?.url ||
              floor.plan?.filenameRemoteUrl ||
              floor.plan?.plan?.fileName?.url
            ),
            'reuse-version': reuseDrawVersion
          }"
          (click)="onOpenView(floor)"
        >
          <span *ngIf="floor.plan?.floorName || floor.plan?.name" class="version-select__plan-title">{{
            floor.plan?.floorName || floor.plan?.name
          }}</span>
          <ng-container
            *ngIf="
              floor.plan?.filename?.url || floor.plan?.filenameRemoteUrl || floor.plan?.plan?.fileName?.url;
              else noImage
            "
          >
            <img
              [atlImageResize]="parent"
              [src]="floor.plan?.filename?.url || floor.plan?.filenameRemoteUrl || floor.plan?.plan?.fullImage?.url"
              alt="floor_plan"
            />
          </ng-container>
        </div>
        <ng-container *ngIf="!reuseDrawVersion">
          <div
            *ngIf="floor.plan?.filename?.url || floor.plan?.filenameRemoteUrl || floor.plan?.plan?.fileName?.url"
            class="version-select__plan-select"
          >
            <atl-titled-checkbox
              [value]="floor.selected"
              [checkboxSize]="20"
              [checkboxLabel]="(floor.selected ? 'Shared.Title.Selected' : 'Shared.Title.Select') | translate"
              (changeEvent)="selectFloor(floor)"
            ></atl-titled-checkbox>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
<ng-container *ngIf="floorVersionList[0].floor?.length">
  <div *ngIf="modalView" class="version-select__footer">
    <button
      type="button"
      class="newest-button"
      (click)="sendFloors()"
      [disabled]="!selectedFloors.length"
      data-cy="add-selected-floors"
    >
      {{ 'Shared.Change_Request.Floor_Plan_Select.Button' | translate }}
    </button>
  </div>
</ng-container>

<ng-template #empty>
  <div class="d-flex flex-column align-items-center justify-content-center w-100 h-100">
    <span class="fz-20 lh-24 fw-500 mb-8">{{ 'Shared.Entity.Floor_plan' | translate }}</span>
    <span class="fz-16 lh-24 text-black-810 w-50 text-align-center">
      {{ 'Shared.Change_Request.Floor_Plan_Select.Title' | translate }}
      <span (click)="redirectToUnit()" class="cursor-pointer text-blue-980">
        {{ 'Shared.Change_Request.Floor_Plan_Select.Link' | translate }}</span
      >
    </span>
  </div>
</ng-template>

<ng-template #noImage>
  <span class="fz-16 lh-24 text-black-810">{{ 'Shared.Mark.Message.Floor_plan_empty' | translate }}</span>
</ng-template>
