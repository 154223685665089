import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'atl-no-data-plug',
  templateUrl: './no-data-plug.component.html',
  styleUrls: ['./no-data-plug.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule],
})
export class NoDataPlugComponent {
  public boxHeight: string | number = '100%';

  @Input() iconPath!: string;
  @Input() translateKey?: string | undefined;
  @Input() set height(h: number) {
    if (h) this.boxHeight = h + 'px';
  }
}
