import { Expose, Transform, Type } from 'class-transformer';

import { ERuleKind, PreviewModel } from './product.model';

export class RuleTriggerableItem {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'itemable_name' }) itemableName!: string;
  @Expose({ name: 'kind' }) kind!: string;
  @Expose({ name: 'layout_product_type_id' }) layoutProductTypeId!: number;
  @Expose({ name: 'rule_itemable_id' }) ruleItemableId!: number;
  @Expose({ name: 'rule_itemable_type' }) ruleItemableType!: string;
  @Expose({ name: 'rule_kind' }) ruleKind!: ERuleKind;
  @Expose({ name: 'wishlist_item_status' }) wishlistItemStatus!: string;
  @Type(() => PreviewModel)
  @Transform(({ value }) => value?.filename?.url || '/assets/group-placeholder.svg', { toClassOnly: true })
  preview?: string;
}

export class OptionRuleModel {
  @Expose({ name: 'id' }) id!: number;
  @Expose({ name: 'kind' }) kind!: string;

  @Expose({ name: 'triggerable_item' })
  @Type(() => RuleTriggerableItem)
  triggerableItem!: RuleTriggerableItem;

  @Expose({ name: 'dependable_items' })
  @Type(() => RuleTriggerableItem)
  dependableItems!: RuleTriggerableItem[];

  selected = false;
}

export enum EOptionRuleKind {
  Exclusion = 'exclusion',
  Forcing = 'forcing',
}

export enum EOptionRuleItemType {
  LayoutProductType = 'LayoutProductType',
  LayoutProductTypeItem = 'LayoutProductTypeItem',
}

export enum EOptionRuleItemsKind {
  Triggerable = 'triggerable',
  Dependable = 'dependable',
}
