import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firmPrefix',
})
export class FirmPrefixPipe implements PipeTransform {
  transform(value: string | undefined): unknown {
    if (value) {
      const matches = value.split(' ').map((v) => v.slice(0, 1));
      return matches.slice(0, 2).join('').trim().toUpperCase();
    }
    return null;
  }
}
