import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[atlDisableInputDrop]',
})
export class DisableInputDropDirective {
  // It is forbidden to drag anything into text fields
  @HostListener('drop', ['$event'])
  onListenDrags(e: DragEvent): void {
    const toElement = e.target as HTMLInputElement;
    if (toElement.localName === 'input' && toElement.type === 'text') {
      e.preventDefault();
    }
  }
}
