import { Expose, Transform, Type } from 'class-transformer';

import { FileNameModel } from './file.model';

export interface IImgFile {
  download_url: string;
  url: string;
  w260: {
    url: string;
  };
}

export class ImageModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'filename' })
  @Type(() => FileNameModel)
  fileName!: FileNameModel;
  @Expose({ name: 'name' })
  name!: string;  
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'upload_date' })
  uploadDate?: string;
  @Expose({ name: 'full_image' })
  @Type(() => FileNameModel)
  fullImage?: FileNameModel;
  @Expose({ name: 'file_extension' })
  @Transform(({ value, obj }) => {
    if (!value) {
      if (!obj.name) {
        return null;
      }
      const arr = obj.name.split('.');
      return arr[arr.length - 1];
    } else {
      return value;
    }
  })
  fileExtension?: string;
  @Expose({ name: 'file_size' })
  fileSize?: string;
  position?: number;
  title?: string;

  type = 'image';

  constructor() {
    this.id = 0;
  }
}
