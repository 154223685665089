import { Injectable } from '@angular/core';
import { IKonvaObject } from '@atlas-workspace/shared/models';
import { Arrow } from 'konva/lib/shapes/Arrow';
import { Circle } from 'konva/lib/shapes/Circle';
import { Line } from 'konva/lib/shapes/Line';
import { Rect } from 'konva/lib/shapes/Rect';

@Injectable()
export class KonvaService {
  brushOpacity!: number;

  private readonly defaultColor = '#D92C26';
  private readonly defaultSize = 5;

  public draggable = false;
  createShapeFromData(data: IKonvaObject): Line | Arrow | Rect | Circle | null {
    if (!data || !data.className) {
      return null;
    }

    switch (data.className) {
      case 'Line':
        return new Line(data);
      case 'Arrow':
        return new Arrow(data as any);
      case 'Rect':
        return new Rect(data);
      case 'Circle':
        return new Circle(data);
      default:
        return null;
    }
  }

  brush(pos: { x: number; y: number }, size = this.defaultSize, color = this.defaultColor, opacity: number): Line {
    this.brushOpacity = opacity;
    return new Line({
      stroke: color,
      strokeWidth: size,
      globalCompositeOperation: 'source-over',
      points: [pos.x, pos.y, pos.x, pos.y],
      lineCap: 'round',
      lineJoin: 'bevel',
      opacity: opacity,
      tension: 0,
      draggable: this.draggable,
      resizeEnabled: false,
    });
  }

  arrow(pos: { x: number; y: number }, size = this.defaultSize, color = this.defaultColor, opacity: number): Arrow {
    return new Arrow({
      points: [pos.x, pos.y, pos.x, pos.y],
      opacity: opacity,
      fill: color,
      stroke: color,
      strokeWidth: size,
      draggable: this.draggable,
      resizeEnabled: false,
    });
  }

  rect(pos: { x: number; y: number }, color = this.defaultColor): Rect {
    const rect: any = new Rect({
      stroke: color,
      width: 0,
      height: 0,
      x: pos.x,
      y: pos.y,
      strokeWidth: this.defaultSize,
      draggable: this.draggable,
      resizeEnabled: false,
    });
    return rect;
  }

  line(pos: { x: number; y: number }, size = this.defaultSize, color = this.defaultColor, opacity: number): Line {
    const line = new Line({
      stroke: color,
      strokeWidth: size,
      globalCompositeOperation: 'source-over',
      points: [pos.x, pos.y, pos.x, pos.y],
      opacity: opacity,
      tension: 100,
      draggable: this.draggable,
      resizeEnabled: false,
    });
    return line;
  }

  circle(pos: { x: number; y: number }, color = this.defaultColor): Circle {
    return new Circle({
      stroke: color,
      x: pos.x,
      y: pos.y,
      radius: 1,
      strokeWidth: this.defaultSize,
      draggable: this.draggable,
      resizeEnabled: false,
    });
  }
}
