import { Directive, Renderer2, ElementRef, Input, AfterViewInit, EventEmitter, Output } from '@angular/core';
import { PlanMarkService } from '@atlas-workspace/shared/service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as Hammer from 'hammerjs';

@UntilDestroy()
@Directive({
  selector: '[atlZoom]',
})
export class ZoomDirective implements AfterViewInit {
  @Input() private targetModalClass = '.zoom-element';
  @Output() private readonly setScalePercentage = new EventEmitter<number>();

  private reverseScale = 1;
  private zoomPercentage = 100;
  private lastMark!: { x: number; y: number };
  constructor(
    private readonly renderer: Renderer2,
    private readonly el: ElementRef,
    private readonly planMarkService: PlanMarkService
  ) {}

  ngAfterViewInit() {
    this.initHammerPinchListener();

    this.planMarkService.markPin$.pipe(untilDestroyed(this)).subscribe((data) => {
      this.lastMark = data[0]?.mark[data[0]?.mark.length - 1] ?? undefined;
      this.calculateZoomPercentage();
    });
  }

  private initHammerPinchListener(): void {
    const hammer = new Hammer(document.body);
    hammer.get('pinch').set({ enable: true });
    hammer.on('pinchend', (event) => {
      //  ToDO: uncomment in case modal size must be recalculated on each pinch event
      // this.calculateZoomPercentage();
    });
  }

  private calculateZoomPercentage(): void {
    this.zoomPercentage = Math.round((window.outerWidth / window.innerWidth) * 100);

    if (this.zoomPercentage > 100) {
      const breakpoints: { [key: number]: number } = {
        120: 0.9,
        140: 0.8,
        160: 0.6,
        200: 0.5,
        250: 0.4,
        300: 0.3,
      };

      for (const breakpoint in breakpoints) {
        if (this.zoomPercentage < parseInt(breakpoint)) {
          this.reverseScale = breakpoints[breakpoint];
          break;
        }
      }

      if (this.zoomPercentage >= 300) {
        this.reverseScale = 0.25;
      }
    } else {
      this.reverseScale = 1;
    }

    this.setScalePercentage.emit(this.reverseScale);
    this.applyTransform();
  }

  private applyTransform(): void {
    const modalElement = this.el.nativeElement.closest(this.targetModalClass);
    if (!modalElement || this.zoomPercentage === 100 || !this.lastMark) {
      return;
    }

    this.renderer.setStyle(modalElement, 'transform', `scale(${this.reverseScale})`);

    const scaledModalElement = this.el.nativeElement.closest(this.targetModalClass);
    if (!scaledModalElement) {
      return;
    }

    if (this.reverseScale <= 0.8) {
      // Center modal vertically on the viewport, considering current scroll position
      const viewportHeight = window.innerHeight;
      const modalHeight = scaledModalElement.getBoundingClientRect().height;
      const scrollY = window.scrollY || window.pageYOffset;
      const topPosition = `${scrollY + (viewportHeight - modalHeight) / 2}px`;
      this.renderer.setStyle(modalElement, 'top', topPosition);

      // Center modal horizontally on the viewport
      const viewportWidth = window.innerWidth;
      const modalWidth = scaledModalElement.getBoundingClientRect().width;
      const scrollX = window.scrollX || window.pageXOffset;
      const leftPosition = `${scrollX + (viewportWidth - modalWidth) / 2}px`;

      this.renderer.setStyle(modalElement, 'left', leftPosition);
      return;
    } else {
      // Set default position of the modal element
      this.renderer.setStyle(modalElement, 'left', 'unset');
      this.renderer.setStyle(modalElement, 'right', '80px');
      this.renderer.setStyle(modalElement, 'top', '158px');
    }
  }
}
