<div class="modal-editor">
  <editor
    class="thread-view-editor"
    #editor
    [formControl]="valControl"
    [apiKey]="apiKey"
    [init]="editorInitConfig"
    data-cy="cy-editor"
  ></editor>
</div>
