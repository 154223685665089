import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MaxLengthDirective, NoEmojiDirective } from '@atlas-workspace/shared/directives';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'atl-expanded-textarea',
  templateUrl: './expanded-textarea.component.html',
  styleUrls: ['./expanded-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ExpandedTextareaComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, MaxLengthDirective, NoEmojiDirective]
})
export class ExpandedTextareaComponent implements ControlValueAccessor, AfterViewInit {
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() maxLength = 30;
  @Input() lineHeight = 32;
  @Input() maxHeight = 227;

  @Output() blurEvent = new EventEmitter<string>();

  public inputValue!: string;
  public focused = false;
  public expanded = false;

  @ViewChild('textarea') textarea!: ElementRef;

  constructor(protected cdr: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this.setDefaultTextareaHeight();
  }

  private onChange: (value: any) => void = () => null;
  private onTouched: () => void = () => null;

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  changeValue(value: string): void {
    this.inputValue = value;
    this.onChange(this.inputValue);
    this.cdr.markForCheck();
  }

  get viewScroll(): boolean {
    return this.textarea?.nativeElement.scrollHeight > this.maxHeight && this.expanded;
  }

  writeValue(value: string): void {
    this.inputValue = value;
    this.cdr.markForCheck();
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  onFocus(event: boolean): void {
    this.expanded = true;
    this.focused = event;
    this.setTextareaHeight();
    this.textarea.nativeElement.scrollTop = 0;
  }

  onBlur(value: string): void {
    this.expanded = false;
    this.onTouched();
    this.setDefaultTextareaHeight();
    this.blurEvent.emit(value);
  }

  public setTextareaHeight(): void {
    this.textarea.nativeElement.style.height = `0px`;
    this.textarea.nativeElement.style.height = `${this.textarea.nativeElement.scrollHeight}px`;
  }

  private setDefaultTextareaHeight(): void {
    this.textarea.nativeElement.style.height = `${this.lineHeight}px`;
  }
}
