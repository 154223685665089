import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FileSizePipeModule } from '@atl-libs-shared-pipes/file-size/file-size.pipe.module';
import { IconByTypePipeModule } from '@atl-libs-shared-pipes/file-type-pipe/icon-by-type-pipe.module';
import { TruncatePipeModule } from '@atl-libs-shared-pipes/truncate/truncate.pipe.module';
import {CustomIconComponent} from "@atlas-workspace/shared/ui";

import { EditorMultiFileUploadComponent } from './text-editor-multi-file-upload.component';

@NgModule({
  declarations: [EditorMultiFileUploadComponent],
  exports: [EditorMultiFileUploadComponent],
  imports: [CommonModule, FileSizePipeModule, IconByTypePipeModule, TruncatePipeModule, CustomIconComponent],
})
export class TextEditorMultiFileUploadModule {}
