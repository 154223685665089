import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  AdminFirmsModel,
  AdminModel,
  ELocalStorageKeys,
  EUserAuthHeaders,
  IAuthHeaders,
  IFirmAuth,
  IResetPassParams,
  ISignUpParams,
} from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import jwt_decode from 'jwt-decode';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { LocalStorageService } from '../local-storge/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthAdminService {
  private loginEmail$ = new BehaviorSubject<string | null>(null);
  private leftMenuStateHandle = new ReplaySubject<boolean>(1);

  constructor(
    @Inject('ENVIRONMENT') private environment: IEnvironment,
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private router: Router
  ) {}

  public signIn(email: string, password: string): Observable<AdminModel> {
    const signInValue = {
      email: email,
      password: password,
    };
    return this.http
      .post<any>(`${this.environment.apiBaseUrl}api/v1/admin_auth/sign_in`, signInValue, { observe: 'response' })
      .pipe(
        tap((x) => {
          this.saveAuthHeaders(x.headers);
        }),
        map((res) => res.body.data),
        map((data) => plainToClass(AdminModel, data)),
        tap((user) => this.saveUserInfo(user))
      );
  }

  public validateAuthHeaders(): boolean {
    return !!this.localStorageService.get<IAuthHeaders>(ELocalStorageKeys.AuthHeaders);
  }

  public validateAuthFirm(): boolean {
    return !!this.localStorageService.get<IFirmAuth>(ELocalStorageKeys.Firm);
  }

  // ToDo: remove company data from storage, add company id to routing instead. AT-808
  public saveFirm(firm: AdminFirmsModel): void {
    this.localStorageService.set(ELocalStorageKeys.Firm, firm);
  }

  public saveUserInfo(user: AdminModel): void {
    this.localStorageService.set(ELocalStorageKeys.UserInfo, { fullName: user.name, id: user.id });
  }

  get getUserInfo(): { fullName: string; id: number } | null {
    return this.localStorageService.get<{ fullName: string; id: number }>(ELocalStorageKeys.UserInfo);
  }

  public getNewUserData(token: string): any {
    return jwt_decode(token);
  }

  get firm(): AdminFirmsModel | null {
    return this.localStorageService.get<AdminFirmsModel>(ELocalStorageKeys.Firm);
  }

  get authHeaders(): IAuthHeaders | null {
    return this.localStorageService.get<IAuthHeaders>(ELocalStorageKeys.AuthHeaders);
  }

  public refreshAdminToken(username: string, refreshToken: string): Observable<any> {
    const body = {
      username: username,
      refresh_token: refreshToken,
    };
    return this.http
      .post(this.environment.apiBaseUrl + 'api/v1/admin_auth/refresh_token', body, {
        observe: 'response',
      })
      .pipe(
        tap((x) => {
          this.saveAuthHeaders(x.headers);
        })
      );
  }

  private saveAuthHeaders(headers: HttpHeaders): void {
    const authHeaders: any = {
      token: headers.get(EUserAuthHeaders.AccessToken),
      uid: headers.get(EUserAuthHeaders.Uid),
      client: headers.get(EUserAuthHeaders.Client),
    };

    const refreshToken = headers.get(EUserAuthHeaders.RefreshToken);
    if (refreshToken) {
      authHeaders.refreshToken = refreshToken;
    } else {
      const existingHeaders: IAuthHeaders | null = this.localStorageService.get(ELocalStorageKeys.AuthHeaders);
      const existingRefreshToken = existingHeaders?.refreshToken;
      if (existingRefreshToken) {
        authHeaders.refreshToken = existingRefreshToken;
      }
    }

    this.localStorageService.set(ELocalStorageKeys.AuthHeaders, authHeaders);
  }

  // ToDO: deprecated and will be removed
  signUp(body: ISignUpParams): Observable<AdminModel> {
    return this.http
      .post<any>(this.environment.apiBaseUrl + 'api/v1/admin_auth', body, {
        observe: 'response',
      })
      .pipe(
        tap((x) => {
          this.saveAuthHeaders(x.headers);
        }),
        map((res) => res.body.data),
        map((admin) => {
          return plainToClass(AdminModel, admin);
        }),
        tap((user) => this.saveUserInfo(user))
      );
  }

  registerUser(body: ISignUpParams): Observable<AdminModel> {
    return this.http
      .patch<any>(this.environment.apiBaseUrl + 'api/v1/admin_auth/register', body, {
        observe: 'response',
      })
      .pipe(
        tap((x) => {
          this.saveAuthHeaders(x.headers);
        }),
        map((res) => res.body.data),
        map((admin) => {
          return plainToClass(AdminModel, admin);
        }),
        tap((user) => this.saveUserInfo(user))
      );
  }

  public logOut(): Observable<unknown> {
    return this.http.delete(this.environment.apiBaseUrl + '/api/v1/admin_auth/sign_out').pipe(
      tap(() => {
        this.clearLocalStorage();
      })
    );
  }

  clearLocalStorage(): void {
    this.localStorageService.clear();
    void this.router.navigateByUrl('/auth/sign-out');
  }

  public forgotPassword(email: string, redirect_url: string): Observable<string> {
    return this.http
      .post(`${this.environment.apiBaseUrl}/api/v1/admin_auth/password`, { email, redirect_url })
      .pipe(map((res: any) => <string>res?.message));
  }

  public getEmailByToken(token: string): Observable<string> {
    const params: HttpParams = new HttpParams().set('reset_password_token', token);
    return this.http
      .get(`${this.environment.apiBaseUrl}api/v1/admin/email_by_reset_password_token`, {
        params: params,
      })
      .pipe(map((res: any) => <string>res.data.email));
  }

  public resetPassword(resetData: IResetPassParams): Observable<string> {
    return this.http
      .put(`${this.environment.apiBaseUrl}api/v1/admin_auth/password`, resetData)
      .pipe(map((res: any) => <string>res?.message));
  }

  public set savedLoginEmail(email: string | null) {
    this.loginEmail$.next(email);
  }

  public get savedLoginEmail$(): Observable<string | null> {
    return this.loginEmail$.asObservable();
  }

  public leftMenuState(): ReplaySubject<boolean> {
    return this.leftMenuStateHandle;
  }
}
