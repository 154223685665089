import { Exclude, Expose, Type } from 'class-transformer';

export enum EUserAuthHeaders {
  AccessToken = 'access-token',
  RefreshToken = 'Refresh_token',
  Uid = 'uid',
  Client = 'client',
  AcceptLanguage = 'accept-language',
}

export interface IAuthHeaders {
  token: string;
  uid: string;
  client: string;
  refreshToken?: string;
}

interface IUnitsUser {
  id: number;
  unit_id: number;
  user_type: string;
  user_role: string;
}

interface IProjectUsers {
  id: number;
  project_id: number;
  user_type: string;
  user_role: string;
}

export interface IUser {
  id: number;
  name: string;
  email: string;
  phone: string;
  created_at: string;
  updated_at: string;
  provider: string;
  uid: string;
  units_users: IUnitsUser[];
  project_users: IProjectUsers[];
  hidden_password?: string;
}

export enum EUserType {
  Banker = 0,
  Buyer = 1,
  Investor = 2,
  Vendor = 3,
}

export class ProjectUsersModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'project_id' })
  projectId: number;
  @Expose({ name: 'user_type' })
  userType: string;
  @Expose({ name: 'user_role' })
  userRole: string;

  constructor() {
    this.id = 0;
    this.projectId = 0;
    this.userType = '';
    this.userRole = '';
  }
}

export class UnitsUsersModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'unit_id' })
  unitId: number;
  @Expose({ name: 'user_type' })
  userType: string;
  @Expose({ name: 'user_role' })
  userRole: string;
  @Expose({ name: 'project_id' })
  projectId!: number;

  constructor() {
    this.id = 0;
    this.unitId = 0;
    this.userType = '';
    this.userRole = '';
  }
}

export class UserModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'name' })
  name: string;
  @Expose({ name: 'email' })
  email: string;
  @Expose({ name: 'phone' })
  phone: string;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'updated_at' })
  updatedAt!: string;
  @Expose({ name: 'provider' })
  provider: string;
  @Expose({ name: 'uid' })
  uid: string;
  @Expose({ name: 'units_users' })
  @Type(() => UnitsUsersModel)
  unitUsers!: UnitsUsersModel[];
  @Expose({ name: 'project_users' })
  @Type(() => ProjectUsersModel)
  projectUnits!: ProjectUsersModel[];
  @Expose({ name: 'hidden_password' })
  hiddenPassword!: string;
  @Expose({ name: 'pubsub_token' })
  @Exclude({ toPlainOnly: true })
  pubsubToken!: string;
  @Expose({ name: 'dual_sign_in' })
  dualSignIn?: boolean;
  @Expose({ name: 'company_name' })
  company!: string;
  @Expose({ name: 'onboarding_completed' })
  onboardingCompleted!: boolean;
  @Expose({ name: 'option_onboarding_completed' })
  optionOnboardingCompleted!: boolean;
  @Expose({ name: 'show_app_block' })
  showAppBlock!: boolean;
  @Expose({ name: 'activate_new_email_flow' })
  activateNewEmailFlow!: boolean;
  @Expose({ name: 'new_email' })
  newEmail!: string | null;

  constructor() {
    this.id = 0;
    this.name = '';
    this.email = '';
    this.phone = '';
    this.provider = '';
    this.uid = '';
    this.dualSignIn = false;
  }
}

export class UserTypeModel {
  @Expose({ name: 'id' })
  id!: number | null;
  @Expose({ name: 'title' })
  title!: string;
}

export interface ILocalStorageUserInfo {
  fullName: string;
  id: number;
}
