import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { FeatureFlagService } from '../feature-flags/feature-flags.service';

@Injectable({
  providedIn: 'root',
})
export class PageAccessGuard  {
  constructor(private featureFlagService: FeatureFlagService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const tag = route.data?.accessTag;
    if (!tag) return true;
    return this.featureFlagService.getPageAccess$().pipe(
      filter((x) => !!x),
      map((pages) => {
        const page = pages?.find((x) => x.name === tag);
        if (page && !page.enabled) {
          void this.router.navigate(['/404']);
        }
        return !page ? true : page.enabled;
      })
    );
  }
}
