import { AfterViewInit, Directive, ElementRef, HostListener, Input, NgZone } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { take } from 'rxjs';

@UntilDestroy()
@Directive({
  selector: '[atlAutoresize]',
})
export class AutoresizeDirective implements AfterViewInit {
  constructor(private elementRef: ElementRef, private ngZone: NgZone) {}
  @Input() offset?: number;
  @Input() maxHeight?: number;

  @HostListener(':input')
  onInput(): void {
    this.resize();
  }

  @HostListener(':paste')
  onPaste(): void {
    this.resize();
  }

  ngAfterViewInit(): void {
    if (this.elementRef.nativeElement.scrollHeight) {
      this.resize();
    }
  }

  private resize(): void {
      this.ngZone.onStable.pipe(take(1)).subscribe(() => {
        this.updateItemHeight();
      });
  }

  private updateItemHeight(): void {
    const maxH = this.maxHeight || Infinity;
    this.elementRef.nativeElement.style.height = '0';
    const newHeight = this.elementRef.nativeElement.scrollHeight + (this.offset || 0);
    this.elementRef.nativeElement.style.height = Math.min(newHeight, maxH) + 'px';
  }
}


