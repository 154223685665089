import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AutoresizeDirective } from './input-auto-height.directive';


@NgModule({
  imports: [CommonModule],
  declarations: [AutoresizeDirective],
  exports: [AutoresizeDirective],
})
export class AutoResizeModule {}
