<div class="d-flex align-items-center">
  <div
    ngbDropdown
    #dropdown="ngbDropdown"
    class="drop-down__wrapper"
    tabindex="0"
    [class.open]="dropdown._open"
    [class.drop-down__single]="listOfMenu.length === 1"
    [atlOnboardingSelector]="3"
    display="static"
  >
    <atl-firm-icon
      *ngIf="asFirmIconView"
      ngbDropdownToggle
      class="drop-down__firmicon-toogle"
      [class.drop-down__single]="listOfMenu.length === 1"
      [model]="selectedItem?.projectName"
      [isUser]="true"
      [isMonochrome]="true"
    ></atl-firm-icon>

    <div *ngIf="!asFirmIconView" ngbDropdownToggle class="drop-down__title" id="grouped-drop-down">
      <div
        id="cy-drop-menu-project"
        [class.drop-down__name--no-pointer]="listOfMenu?.length === 1"
        class="drop-down__selected-item ellipsis"
      >
        <h2 class="drop-down__name fz-15 lh-24 fw-400 ellipsis">
          {{ selectedItem ? selectedItem.projectName : ('Shared.Entity.No_projects_yet' | translate) }}
        </h2>
        <span class="drop-down__dot"> · </span>
        <h3 class="drop-down__unit-name fz-12 lh-16 fw-600 ellipsis">
          {{ selectedItem ? selectedItem.unitName : ('Shared.Entity.No_unit_yet' | translate) }}
        </h3>
      </div>
      <div *ngIf="listOfMenu?.length > 1" class="drop-down__arrow-wr">
        <div
          [class.rotate]="dropdown._open"
          [class.open]="dropdown._open"
          class="drop-down__arrow"
        ></div>
      </div>
    </div>

    <div
      ngbDropdownMenu
      aria-labelledby="grouped-drop-down"
      id="cy-drop-menu-project-list"
      class="drop-down__wrapper-selecting"
      *ngIf="dropdown._open && listOfMenu.length > 1"
    >
      <div class="drop-down__selection-container v-scrollbar">
        <ng-container *ngFor="let value of listOfMenu; let index = index">
          <div
            ngbDropdownItem
            class="drop-down__selection-item"
            [ngClass]="{
              'selected': value.projectId === selectedItem.projectId && value.unitId === selectedItem.unitId,
              'collapsed': asFirmIconView
            }"
            (click)="selectProjectAndUnit($event, value)"
          >
            <atl-firm-icon
              *ngIf="asFirmIconView"
              class="drop-down__firmicon-item"
              ngbDropdownToggle
              [model]="value.projectName"
              [isUser]="true"
              [isMonochrome]="true"
            ></atl-firm-icon>
            <div class="drop-down__selection-item-wrapper">
              <div class="drop-down__selection-item-project ellipsis">{{ value.unitName }}</div>
              <div class="drop-down__selection-item-unit ellipsis">{{ value.projectName }}</div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
