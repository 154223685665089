import { Expose, Type } from 'class-transformer';

export class OptionSales {
  @Expose({ name: 'completed_wishlists_count' }) completedWishlistsCount!: number;
  @Expose({ name: 'progress_percentage' }) progressPercentage!: number;
  @Expose({ name: 'sold_units_count' }) soldUnitsCount!: number;
  @Expose({ name: 'wishlists_sales_revenue' }) wishlistsSalesRevenue!: string;
  @Expose({ name: 'profit_price' }) profitPrice!: string;
  @Expose({ name: 'average_option_price' }) averageOptionPrice!: string;
  @Expose({ name: 'units_count' }) unitsCount!: number;
}

export class OptionSalesByUnitType {
  @Expose({ name: 'name' }) name!: string;
  @Expose({ name: 'progress_percentage' }) progressPercentage!: number;
  @Expose({ name: 'sold_units_count' }) soldUnitsCount!: number;
  @Expose({ name: 'units_count' }) unitsCount!: number;
}

class UnitSalesGraph {
  @Expose({ name: 'month' }) month!: string;
  @Expose({ name: 'progress_percentage' }) progressPercentage!: number;
  @Expose({ name: 'sold_units_count' }) soldUnitsCount!: number;
}

class UnitSales {
  @Expose({ name: 'progress_percentage' }) progressPercentage!: number;
  @Expose({ name: 'sold_units_count' }) soldUnitsCount!: number;
  @Expose({ name: 'units_count' }) unitsCount!: number;
  @Expose({ name: 'unit_sales_graph' })
  @Type(() => UnitSalesGraph)
  unitSalesGraph!: UnitSalesGraph[];
}

export class ProjectSetupStep {
  @Expose({ name: 'completed' }) completed!: boolean;
  @Expose({ name: 'step_name' }) stepName!: string;
}

class SetupSteps {
  @Expose({ name: 'progress_percentage' }) progressPercentage!: number;
  @Expose({ name: 'steps' })
  @Type(() => ProjectSetupStep)
  steps!: ProjectSetupStep[];
}

class SalesRevenueGraph {
  @Expose({ name: 'currency' }) currency!: string;
  @Expose({ name: 'month' }) month!: string;
  @Expose({ name: 'sales_revenue' }) salesRevenue!: string;
}

class SalesRevenue {
  @Expose({ name: 'currency' }) currency!: string;
  @Expose({ name: 'sales_revenue' }) salesRevenue!: string;
  @Expose({ name: 'sales_revenue_graph' })
  @Type(() => SalesRevenueGraph)
  salesRevenueGraph!: SalesRevenueGraph[];
}

export class OptionReport {
  @Expose({ name: 'option_sales' })
  @Type(() => OptionSales)
  optionSales!: OptionSales;

  @Expose({ name: 'sales_by_unit_types' })
  @Type(() => OptionSalesByUnitType)
  salesByUnitTypes!: OptionSalesByUnitType[];

  @Expose({ name: 'unit_sales' })
  @Type(() => UnitSales)
  unitSales!: UnitSales;

  @Expose({ name: 'setup_steps' })
  @Type(() => SetupSteps)
  setupSteps!: SetupSteps;

  @Expose({ name: 'sales_revenue' })
  @Type(() => SalesRevenue)
  salesRevenue!: SalesRevenue;
}

export class ColumnsField {
  @Expose({ name: 'value' }) id!: string;
  @Expose({ name: 'name' }) name!: string;
  groupSelect?: string;
}

export enum EDefaultOptionReportType {
  BrokerReport = 'broker_report',
  ContractorEP = 'contractor_ep',
  OrderConfirmation = 'order_confirmation',
  Economy = 'economy',
  Settlement = 'settlement',
  CustomReport = 'custom_report'
}

export interface TDefaultOptionReportType {
  type: EDefaultOptionReportType;
  translateKey: string;
  descriptionTranslateKey?: string;
}

export enum BaseReportType {
  PDF = 'pdf',
  XLS = 'xls'
}

export interface IDropdownReportsItem {
  type: BaseReportType;
  icon: string;
  labelTranslateKey: string;
  commingSoon?: boolean;
}

export interface ICustomReportReqData {
  type: EDefaultOptionReportType;
  unitid?: number;
  unitsIds?: number[];
  columns?: string[];
}