import { Injectable } from '@angular/core';
import { IHeaderDropdown, ILayoutTypeData } from '@atlas-workspace/shared/models';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  specificPage$ = new ReplaySubject<{ page: string }>(1);
  private _selectedUnitTypeData$ = new ReplaySubject<ILayoutTypeData>(1);

  private readonly _selectedUnitOfMenu$ = new BehaviorSubject<IHeaderDropdown | null>(null);

  private readonly _listOfMenu$ = new BehaviorSubject<IHeaderDropdown[]>([]);

  public get selectedUnitTypeData$(): Observable<ILayoutTypeData> {
    return this._selectedUnitTypeData$.asObservable();
  }

  public setSelectedUnitTypeData(data: ILayoutTypeData): void {
    this._selectedUnitTypeData$.next(data);
  }

  public get listOfMenu$(): Observable<IHeaderDropdown[]> {
    return this._listOfMenu$.asObservable();
  }

  public setListOfMenu(data: IHeaderDropdown[]): void {
    this._listOfMenu$.next(data);
  }

  public get selectedUnitOfMenu$(): Observable<IHeaderDropdown | null> {
    return this._selectedUnitOfMenu$.asObservable();
  }

  public setSelectedUnitOfMenu(data: IHeaderDropdown | null): void {
    this._selectedUnitOfMenu$.next(data);
  }
}
