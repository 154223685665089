import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'displayDownloadLink',
  standalone: true,
})
export class DisplayDownloadLinkPipe implements PipeTransform {
  transform(value: string, args: { type: string; showAsImg: boolean }): string {
    if (args.type === 'jpg' || args.type === 'jpeg' || (args.showAsImg && args.type === 'png')) {
      return 'url(' + value + ')';
    } else {
      return '';
    }
  }
}
