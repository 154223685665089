import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LeftMenuService {
  private leftMenuClientStateHandle = new ReplaySubject<boolean>(1);
  private _isUnitSet$ = new Subject<boolean>();

  public leftMenuStateClient(): ReplaySubject<boolean> {
    return this.leftMenuClientStateHandle;
  }

  public setUnitSetup(val: boolean): void {
    this._isUnitSet$.next(val);
  }

  get isUnitSet$(): Observable<boolean> {
    return this._isUnitSet$.asObservable();
  }
}
