export class FormLimits {
  public static readonly defaultInputMaxLength = 50;
  public static readonly defaultMaxFloors = 99;
  public static readonly updateInputMaxLength = 100;

  public static readonly defaultTextareaMaxLength = 300;

  public static readonly passwordValidationMessage = 'Shared.Input.Password.Digit_capital_letter';
  public static readonly passwordLengthValidationMessage = 'Shared.Input.Password.Length_letter';
}
