<!-- 
  ******************************************************
  @Cypress
  - interaction with begin/end date
  - interaction with Prev/next month arrows 
  - interaction with day icons in ngb-datepicker-month
  ******************************************************
-->
<div class="date-range" [class.active]="activeInput" (click)="focus()" atlClickOutside (clickOutside)="blur()">
  <input
    (input)="inputFrom($event)"
    [value]="fromInput"
    [mask]="mask"
    [placeholder]="placeholder"
    class="date-range__input"
    data-cy="input-date-range-begin"
    type="text"
    [class.w-100]="(!fromDate && !showEndDate) || (showEndDate && !isEndDate)"
  />
  <span class="date-range__line" *ngIf="(fromDate && !showEndDate) || (showEndDate && isEndDate)"></span>
  <input
    [class.d-none]="!((fromDate && !showEndDate) || (showEndDate && isEndDate))"
    #toInputRef
    (input)="inputTo($event)"
    [value]="toInput"
    [mask]="mask"
    [placeholder]="placeholder"
    class="date-range__input"
    data-cy="input-date-range-end"
    type="text"
    [disabled]="!isEndDate && showEndDate"
  />
  <div *ngIf="activeInput" class="date-range__datepicker">
    <ngb-datepicker
      #dp
      (dateSelect)="onDateSelection($event)"
      [displayMonths]="1"
      [dayTemplate]="isEndDate || !showEndDate ? rangeTemplate : noTemplate"
      [firstDayOfWeek]="1"
      [navigation]="navigation"
      [weekdays]="0"
      [outsideDays]="outsideDays"
      [footerTemplate]="footer"
      [maxDate]="currentDate"
      class="datepicker-ngb"
    >
    </ngb-datepicker>
  </div>
</div>

<ng-template #rangeTemplate let-date let-currentMonth="currentMonth">
  <span
    class="custom-day"
    [class.outside]="currentMonth !== date.month"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>

<ng-template #noTemplate let-date let-selected="selected" let-currentMonth="currentMonth">
  <span
    class="custom-day"
    [class.selected]="
      selected || (date?.day === fromDate?.day && date?.month === fromDate?.month && date?.year === fromDate?.year)
    "
    [class.outside]="currentMonth !== date.month"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>

<ng-template #footer>
  <div class="date-range__footer">
    <div class="toggle" *ngIf="showEndDate">
      <div>
        <span class="fz-14 lh-24 fw-500 text-black-810">{{ 'Shared.Input.End_date.Placeholder' | translate }}</span>
        <atl-custom-checkbox
          data-cy="input-date-range-checkbox"
          [checked]="isEndDate"
          (toggleCheckBox)="toggleEndDate($event)"
          sizeInput="small"
        ></atl-custom-checkbox>
      </div>
    </div>
    <div class="action">
      <span (click)="clearAll()" data-cy="date-range-clear-all-button" class="fz-14 lh-24 fw-500 cursor-pointer ml-8">{{
        'Shared.Entity.Clear_all' | translate
      }}</span>
      <button (click)="setDate()" data-cy="date-range-set-button" class="newest-button newest-button-sm" [disabled]="disabled || !isValidEnteredDate">
        {{ 'Meeting_Data_Picker.Confirmation_Button' | translate }}
      </button>
    </div>
  </div>
</ng-template>
