<div class="radio {{ sizeInput }}">
  <input
    id="radio-{{ index }}"
    name="radio"
    type="radio"
    class="{{ sizeInput }}"
    [checked]="checked"
    [value]="value"
    [disabled]="disabled"
    [name]="name"
    (change)="change($event)"
  />
  <label for="radio-{{ index }}" class="radio-label">
    <span>{{ label | translate }}</span>
  </label>
</div>
