import { Expose } from "class-transformer";

export enum ETermsConditionSettingsType {
  Reclamation = 'project_reclamation',
  ChangeRequest = 'project_change_request'
}

export class TermsConditionsDataModel {
  @Expose({ name: 'confirmation_info' })
  termsCheckboxLabel!: string;
  @Expose({ name: 'description' })
  termsDescription!: string;
  @Expose({ name: 'name' })
  termsTitle!: string;
  @Expose({ name: 'show_confirmation_checkbox' })
  termsCheckbox!: boolean;
}