import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'atl-undo-changes',
  templateUrl: './undo-changes.component.html',
  styleUrls: ['./undo-changes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UndoChangesComponent implements OnChanges {
  @Input() showMarker$!: BehaviorSubject<boolean | null>;
  @Input() fieldName!: string;
  @Input() showLoader!: boolean;
  @Input() undoTimer = 0;

  @Output() undoClick = new EventEmitter();
  @Output() countdownFinished = new EventEmitter();

  public undoNote: string = this.translateService.instant('Shared.Entity.Undo');

  constructor(private cdr: ChangeDetectorRef, private translateService: TranslateService) {
  }

  resetField(): void {
    this.undoTimer = 0;
    this.undoClick.emit();
  }

  onCountdownFinished(): void {
    this.undoTimer = 0;
    this.countdownFinished.emit();
  }

  ngOnChanges({undoTimer}: SimpleChanges): void {
    if (!isNaN(undoTimer?.currentValue)) {
      this.cdr.detectChanges();
    }
  }
}
