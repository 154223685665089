import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  EAccessTag,
  EReclamationScope,
  EUserTypeAdmin,
  FloorModel,
  IMark,
  ISettingsMenu,
  UnitFloorModel,
} from '@atlas-workspace/shared/models';
import { ModalFacadeService, PlanMarkService, ProjectService } from '@atlas-workspace/shared/service';
import { ImageMarkComponent } from '@atlas-workspace/shared/ui';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { delay, filter, tap } from 'rxjs/operators';

import { PlanMarkModalComponent } from '../plan-mark-modal/plan-mark-modal.component';

@UntilDestroy()
@Component({
  selector: 'atl-plan-mark',
  templateUrl: './plan-mark.component.html',
  styleUrls: ['./plan-mark.component.scss'],
})
export class PlanMarkComponent implements OnChanges, OnInit {
  @ViewChild('imageMark') imageMark!: ImageMarkComponent;

  @Input() floorPlan!: (UnitFloorModel | FloorModel)[];
  @Input() mark: IMark[] = [];
  @Input() multiplePins = false;
  @Input() isContractor = false;

  @Input() disabled?: boolean;

  @Input() client = false;
  @Input() scope!: EReclamationScope;

  @Input() displayFloors = false;
  @Input() disableDrag = true;

  @Input() noFloorPlanBtnText = '';

  @Output() private readonly setMarkEvent = new EventEmitter<IMark[]>();
  @Output() private readonly redirectToUnit = new EventEmitter();
  @Output() private readonly existingMarkClicked = new EventEmitter();

  public previewPlan?: string;
  public readonly messageTooltip = 'Shared.Mark.Message.Unit_redirect';
  private readonly floorField = 'floorPlan';
  private readonly markField = 'mark';
  public scopeTypes = EReclamationScope;

  private modalRef!: NgbModalRef;

  public hasUnitAccess!: boolean;
  public activeId!: number;
  public navList!: ISettingsMenu[];
  public loading = false;

  constructor(
    private modalFacadeService: ModalFacadeService,
    private projectService: ProjectService,
    private translate: TranslateService,
    private planMarkService: PlanMarkService,
    private cdr: ChangeDetectorRef
  ) {
    this.isUnitAccess();
  }

  ngOnInit(): void {
    this.setPerview();
    if (this.multiplePins) {
      this.initPlanMarkListener();
      this.initPlanMarkRemoveListener();
    }
    if (this.displayFloors) {
      this.initNavList();
    }
  }

  private initPlanMarkListener(): void {
    this.planMarkService.updatedMark$
      .pipe(
        filter((res) => !!res),
        untilDestroyed(this),
        tap(() => (this.loading = true)),
        delay(200)
      )
      .subscribe((res: IMark[] | null) => {
        if (res) {
          this.mark = res;
          this.loading = false;
          this.cdr.detectChanges();
        }
      });
  }

  private initPlanMarkRemoveListener(): void {
    this.planMarkService.updatedMarkRemove$
      .pipe(
        filter((res) => !!res),
        untilDestroyed(this)
      )
      .subscribe((res: IMark[] | null) => {
        if (res) {
          this.mark = res;
        }
      });
  }

  private isUnitAccess(): void {
    this.projectService.adminProject$.pipe(untilDestroyed(this)).subscribe((project) => {
      this.hasUnitAccess =
        project.currentRole !== EUserTypeAdmin.Custom ||
        project.projectAccess?.some((x) => x.accTag === EAccessTag.Units);
    });
  }

  private initNavList(): void {
    let activeTabIndex = 0;
    this.navList = this.floorPlan.map((p, i) => {
      if (this.mark.length && p.id === this.mark[0].floorId) activeTabIndex = i;
      return {
        id: p.id,
        name: p.name,
        route: '',
        disabled: false,
        hasPlan: !!p.plan,
      };
    });

    if (this.navList.length) {
      this.activeId = activeTabIndex
        ? this.navList[activeTabIndex].id  as number
        : this.navList[0].id as number;
    }
  }

  private setPerview(): void {
    if (!this.floorPlan) return;

    const floorsWithPlan = this.floorPlan?.filter((i) => i.plan);

    if (floorsWithPlan.length) {
      if (this.imageMark) this.imageMark.mark = {} as IMark;
      if (!this.mark?.length && this.floorPlan?.length) {
        const tempFloorPlan = this.floorPlan.filter((i) => i.plan);
        this.previewPlan = tempFloorPlan[0].plan?.fullImage?.url || '';
      } else if (this.mark?.length && this.floorPlan.length) {
        const flPlan: (UnitFloorModel | FloorModel)[] = [];
        this.mark.forEach((m) => {
          const tempItem: (UnitFloorModel | FloorModel)[] = this.floorPlan.filter((fl) => fl.id === m.floorId);
          flPlan.push(...tempItem);
        });
        if (flPlan.length) {
          this.previewPlan = flPlan[0].plan?.fullImage?.url;
        } else {
          this.mark = [];
        }
      }
    } else {
      this.previewPlan = '';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.floorField in changes) {
      this.floorPlan = changes.floorPlan.currentValue;
      this.mark = [];
      this.setMarkEvent.emit([]);
      this.setPerview();
    }
    if (this.markField in changes) {
      this.mark = changes.mark.currentValue;
      this.setPerview();
    }
  }

  public selectTab(event: { itemId: number }): void {
    this.activeId = event.itemId;
  }

  public unitRedirect(): void {
    this.redirectToUnit.emit();
  }

  public selectFloorPlanModal(activeIndex?: number): void {
    this.modalRef = this.modalFacadeService.openModal(PlanMarkModalComponent, {
      centered: true,
      windowClass: 'full-screen-modal floor-plan-modal',
    });

    this.modalRef.componentInstance.modalRef = this.modalRef;
    this.modalRef.componentInstance.floorPlan = this.floorPlan;
    this.modalRef.componentInstance.oneMarkMode = this.multiplePins ? false : true;
    this.modalRef.componentInstance.mark = this.mark;
    this.modalRef.componentInstance.disabled = this.disabled;
    this.modalRef.componentInstance.client = this.client;
    this.modalRef.componentInstance.multiplePins = this.multiplePins;
    this.modalRef.componentInstance.disableDrag = this.disableDrag;
    this.modalRef.componentInstance.hasUnitAccess = this.hasUnitAccess;
    this.modalRef.componentInstance.activeIndex = activeIndex;
    this.modalRef.result
      .then((mark?: IMark[]) => {
        if (mark && this.floorPlan.length) {
          const idx = this.floorPlan?.findIndex((floor: FloorModel | UnitFloorModel) => floor.id === mark[0].floorId);
          if (idx !== -1) {
            this.previewPlan = this.floorPlan[idx].plan?.fullImage?.url;
            this.mark = mark;
            this.setMarkEvent.emit(this.mark);
          }
        }
      })
      .catch(() => null);
  }
}
