import { Injectable } from '@angular/core';
import { ELocalStorageKeys, FullInfoClientModel } from '@atlas-workspace/shared/models';
import { LocalStorageService, ModuleAccessService, ProjectService } from '@atlas-workspace/shared/service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { firstValueFrom } from 'rxjs';

const regExpProject = /project\/(\d*)/;
const regExpUnit = /unit\/(\d*)/;

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  constructor(
    private readonly accessService: ModuleAccessService,
    private readonly projectService: ProjectService,
    private readonly localStorageService: LocalStorageService,
  ) {}

  init(): Promise<unknown> {
    let foundUnit = false;
    const { projectId, unitId } = this.getParams();
    return new Promise((resolve) => {
      const token = this.localStorageService.get(ELocalStorageKeys.AuthHeaders);
      if (!token) {
        resolve(true);
        return;
      }

      if (!projectId && !unitId) {
        resolve(true);
        return;
      }
      firstValueFrom(this.projectService.getFullInfo())
        .then((data: FullInfoClientModel[]) => {
          data.forEach((info) => {
            info.units.forEach((unit) => {
              if (info.projectId === projectId && unit.id === unitId) {
                foundUnit = true;
                this.loadModuleAccess(info.projectId, () => {
                  resolve(true);
                });
              }
            });
          });

          if (!foundUnit) {
            this.loadModuleAccess(projectId, () => {
              resolve(true);
            });
          }
        })
        .catch(() => {
          resolve(true);
        });
    });
  }

  loadModuleAccess(projectId: number, cb: () => void): void {
    this.accessService
      .getModuleAccess(projectId)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (moduleAccess) => {
          this.accessService.setAccesses(moduleAccess);
        },
        error: () => {
          window.open(window.location.origin, '_self');
        },
        complete: () => {
          cb();
        },
      });
  }

  getParams(): InitId {
    //TODO: at the moment a working solution, you need to change the logic for obtaining ID
    const url = window.location.pathname;
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
    const projectId = +url.match(regExpProject)?.[1]!;
    // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain, @typescript-eslint/no-non-null-assertion
    const unitId = +url.match(regExpUnit)?.[1]!;

    return { projectId, unitId };
  }
}

interface InitId {
  projectId: number;
  unitId: number;
}
