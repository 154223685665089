<atl-ngb-modal-header title="Title.Message_editor" (closeModalEvent)="closeModal()"></atl-ngb-modal-header>
<div class="modal-editor">
  <editor
    class="thread-view-editor"
    #editor
    [formControl]="formControl"
    [apiKey]="apiKey"
    [init]="editorInitConfig"
    data-cy="cy-editor"
    [class.focus]="focused"
  ></editor>
</div>
<atl-ngb-modal-footer>
  <div class="d-flex align-items-center justify-content-end w-100">
    <button (click)="closeModal()" class="newest-button newest-button--outline mr-16">{{'Shared.Button.Cancel' | translate}}</button>
    <button (click)="save()" [disabled]="disabledButtons" class="newest-button">{{'Button.Save_message' | translate}}</button>
  </div>
</atl-ngb-modal-footer>
