import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  EUnitRoles,
  IProfileToSave,
  ITransferOwnership,
  ITransferOwnershipForUnit,
  UserModel,
} from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  private changeUserName$ = new Subject<string>();
  private isDualView$ = new BehaviorSubject<boolean>(false);
  private profile$ = new BehaviorSubject<UserModel | null>(null);
  private isGuest$ = new BehaviorSubject<boolean>(false);
  private userData!: UserModel;

  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private environment: IEnvironment,
  ) {}

  /**
   * Get User Profile
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Profile/paths/~1api~1v1~1profile/get
   */
  public getUserProfile(): Observable<UserModel> {
    return this.http.get(`${this.environment.apiBaseUrl}api/v1/profile`).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(UserModel, data)),
      tap((data) => {
        this.isDualView$.next(!!data?.dualSignIn);
        this.userData = data;
        this.setProfile(data);
      }),
    );
  }

  /**
   * Update User Profile
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Profile/paths/~1api~1v1~1profile/put
   */
  public saveProfileData(profile: IProfileToSave): Observable<UserModel> {
    return this.http.put(`${this.environment.apiBaseUrl}api/v1/profile`, profile).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(UserModel, data)),
      tap((data) => {
        this.changeUserName$.next(data.name);
        this.isDualView$.next(!!data?.dualSignIn);
        this.userData = data;
        this.setProfile(data);
      }),
    );
  }

  /**
   * Update User Profile
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Profile/paths/~1api~1v1~1profile/put
   */
  public changeEmail(email: string, password: string): Observable<UserModel> {
    const body = {
      user: {
        new_email: email,
        new_email_password: password,
      },
    };

    return this.http.put(`${this.environment.apiBaseUrl}api/v1/profile`, body).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(UserModel, data)),
      tap((data) => {
        this.userData = data;
        this.setProfile(data);
      }),
    );
  }

  /**
   * Transfer Ownership
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Profile/paths/~1api~1v1~1profile~1transfer_ownership/post
   */
  public transferOwnership(email: string, unitId: number): Observable<UserModel> {
    const transferData: ITransferOwnership = {
      user: {
        email: email,
        unit_id: unitId,
      },
    };
    return this.http.post(`${this.environment.apiBaseUrl}api/v1/profile/transfer_ownership`, transferData).pipe(
      map((res: any) => res.data),
      map((data) => plainToClass(UserModel, data)),
    );
  }

  /**
   * Change ownership for unit
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Client-Units/paths/~1api~1v1~1client~1projects~1{project_id}~1units~1{id}~1change_ownership/post
   */
  public transferOwnershipForUnit(
    value: { name: string; email: string; phone?: string },
    projectId: number,
    unitId: number,
  ): Observable<any> {
    const transferData: ITransferOwnershipForUnit = {
      member: value,
    };
    return this.http.post(
      `${this.environment.apiBaseUrl}api/v1/client/projects/${projectId}/units/${unitId}/change_ownership`,
      transferData,
    );
  }

  public completeOnboarding(): Observable<any> {
    return this.http.put(`${this.environment.apiBaseUrl}api/v1/profile/complete_onboarding`, {});
  }

  public completeOptionOnboarding(): Observable<any> {
    return this.http.put(`${this.environment.apiBaseUrl}api/v1/profile/complete_option_onboarding`, {});
  }

  public get onChangeUserName(): Observable<string> {
    return this.changeUserName$.asObservable();
  }

  public get isDualView(): Observable<boolean> {
    return this.isDualView$.asObservable();
  }

  public setDualView(dualView: boolean): void {
    this.isDualView$.next(dualView);
  }

  public setProfile(user: UserModel): void {
    this.profile$.next(user);
  }

  public get getProfile(): Observable<UserModel | null> {
    return this.profile$.asObservable();
  }

  public setIsGuest(unitId: number): void {
    const isGuest = this.userData?.unitUsers.filter((u) => u.unitId === +unitId)[0]?.userRole === EUnitRoles.Member;
    this.isGuest$.next(isGuest);
  }

  public get getIsGuest(): Observable<boolean> {
    return this.isGuest$.asObservable();
  }
}
