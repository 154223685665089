import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ClientOnboardingModalComponent } from '@atlas-workspace/shared/modals';
import { LocalStorageService, OnboardingService } from '@atlas-workspace/shared/service';
import { HeaderIconComponent } from '@atlas-workspace/shared/ui';
import { NgbActiveModal, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'atl-digital-showroom-onboarding',
  templateUrl: './digital-showroom-onboarding-modal.component.html',
  styleUrls: ['./digital-showroom-onboarding-modal.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, HeaderIconComponent, NgbTooltipModule],
})
export class ClientShowroomOnboardingModalComponent extends ClientOnboardingModalComponent implements OnInit {
  @Input() isDualView!: boolean;
  @Output() private readonly completeOnboarding = new EventEmitter<void>();

  constructor(
    ngbActiveModal: NgbActiveModal,
    localStorageService: LocalStorageService,
    onboardingService: OnboardingService,
  ) {
    super(ngbActiveModal, localStorageService, onboardingService);
  }

  public skipAllSteps(): void {
    this.ngbActiveModal.close();
    this.localStorageService.remove(this.localStorageKey);
    this.completeOnboarding.emit();
  }
}
