import {Pipe, PipeTransform} from '@angular/core';
import {
  ClientMeetingModel,
  ClientMeetingsModel,
  EMeetingStatusValue, MeetingModel,
  MeetingsModel,
  statusList
} from '@atlas-workspace/shared/models';

@Pipe({
  name: 'clientMeetingStatus'
})
export class ClientMeetingStatusPipe implements PipeTransform {

  transform(meeting: ClientMeetingsModel | ClientMeetingModel | MeetingsModel | MeetingModel):
    { style: string, title: string, status: string }
  {
    if (
      !(meeting instanceof MeetingsModel || meeting instanceof MeetingModel)
      && meeting.units && meeting.status === EMeetingStatusValue.Available) {
      // @ts-ignore
      return {...statusList[meeting.units.status], status: meeting.units.status};
    }
    if (
      meeting.status === EMeetingStatusValue.Completed
      || meeting.status === EMeetingStatusValue.Available
      || meeting.status === EMeetingStatusValue.Confirmed) {
      // @ts-ignore
      return {...statusList[meeting.status], status: meeting.status};
    }
    if (!(meeting instanceof MeetingsModel || meeting instanceof MeetingModel) && meeting.units) {
      // @ts-ignore
      return {...statusList[meeting.units.status], status: meeting.units?.status};
    }
    if (meeting instanceof MeetingsModel || meeting instanceof MeetingModel && meeting.units?.length) {
      // @ts-ignore
      return {...statusList[meeting.units[0].status], status: meeting.units[0].status};
    }
    // @ts-ignore
    return {...statusList[meeting.status], status: meeting.status};
  }

}
