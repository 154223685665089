import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import ResizeObserver from 'resize-observer-polyfill';
import {Subject, Subscription} from "rxjs";
import {debounceTime} from "rxjs/operators";

@Directive({
  selector: '[atlBlockResizeListener]',
  standalone: true,
})
export class BlockResizeListenerDirective implements AfterViewInit, OnDestroy {
  @Input() isSpecialElement = false;
  @Input() resizeDebounceTime = 0;
  @Output() resizeBlockHandler: EventEmitter<unknown> = new EventEmitter<unknown>();

  private resizeBlockHandler$ = new Subject<void>();
  private resizeObserver!: ResizeObserver;
  private subscription = new Subscription();

  constructor(private el: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.isSpecialElement) {
      this.resizeObserver = new ResizeObserver((entries) => {
        entries.forEach(() => {
          this.emitInnerStream();
        });
      });
      this.resizeObserver.observe(this.el.nativeElement);
      this.subscribeToInnerStream();
    }
  }

  ngOnDestroy(): void {
    if (this.isSpecialElement) {
      this.resizeObserver.disconnect();
      this.subscription.unsubscribe();
      this.resizeBlockHandler$.complete();
    }
  }

  private subscribeToInnerStream(): void {
    this.subscription.add(
      this.resizeBlockHandler$.pipe(debounceTime(this.resizeDebounceTime)).subscribe(() => {
        this.resizeBlockHandler.emit();
      })
    );
  }

  private emitInnerStream(): void {
    this.resizeBlockHandler$.next();
  }
}
