import { Injectable } from '@angular/core';
import { AdminFirmsModel, EFirmRoles, EUnitRoles, UnitMemberModel } from '@atlas-workspace/shared/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RoleService {
  private clientUnitRole$ = new BehaviorSubject('');

  public setClientActiveUnitRole(role: string): void {
    this.clientUnitRole$.next(role);
  }

  public get isPrimaryOwner$(): Observable<boolean> {
    return this.clientUnitRole$.asObservable().pipe(map((role) => role === EUnitRoles.PrimaryOwner));
  }

  public get clientUserUnitRole$(): Observable<string> {
    return this.clientUnitRole$.asObservable();
  }

  public isUnitOwner(user: UnitMemberModel): boolean {
    return user.role === EUnitRoles.PrimaryOwner;
  }

  public isUnitMember(user: UnitMemberModel): boolean {
    return user.role === EUnitRoles.Member;
  }

  public isCompanyMember(company: AdminFirmsModel): boolean {
    return company.role === EFirmRoles.Member;
  }

  // ToDO: all role-related checks must be added to the service
}
