<div (clickOutside)="close()" atlClickOutside class="multi-filter__wrapper" #relativeRef>
  <button
    (click)="toggle()"
    class="global-button global-button--transparent d-flex flex-nowrap filters multi-filter__button br-8"
    [class.multi-filter__button-active]="expanded"
  >
    <ng-content select="buttonContent"></ng-content>
  </button>
  <div
    *ngIf="expanded"
    atlHorizontalAlign
    [permanentPosition]="position"
    [top]="dropdownContentTop"
    [positionFixed]="true"
    [relativeElement]="relativeRef"
    class="card multi-filter__dropdown-card"
  >
    <ng-content select="dropdownContent"></ng-content>
    <div *ngIf="footerEnabled" class="pad-1 card__footer card__footer__bordered multi-filter__dropdown-card__footer">
      <div class="d-flex flex-gap-1">
        <div class="gap-wrapper">
          <button
            (click)="resetAllFilters()"
            class="global-button global-button--h40 global-button--gray multi-filter__dropdown-card__reset-button"
            type="button"
          >
            {{ resetButtonText | translate }}
          </button>
        </div>
        <div class="gap-wrapper">
          <button
            (click)="applyAllFilters()"
            class="global-button global-button--h40 multi-filter__dropdown-card__apply-button"
            type="button"
          >
            {{ applyButtonText | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
