<div (clickOutside)="close()" atlClickOutside class="multi-filter__wrapper" #relativeRef>
  <button
    data-cy="cy-filter-btn"
    [class.expanded]="expanded"
    [class.active]="selectedItems"
    (click)="toggle()"
    class="global-button global-button--transparent global-button__no-transition d-flex flex-nowrap"
  >
    <ng-content select="buttonContent"></ng-content>
  </button>
  <div
    *ngIf="expanded"
    atlHorizontalAlign
    [permanentPosition]="position"
    [top]="dropdownContentTop"
    [positionFixed]="true"
    [relativeElement]="relativeRef"
    [flexibleY]="flexibleY"
    class="card multi-filter__dropdown-card"
    [attr.hidden]="useAsyncData && !asyncItemslength && isFirstItemslengthSetterExecution"
  >
    <ng-content select="dropdownContent"></ng-content>
    <div *ngIf="footerEnabled" class="multi-filter__resetall-wr pad-12-16">
      <div class="d-flex flex-gap-1">
        <p (click)="resetAllFilters()" class="multi-filter__resetall fz-14 lh-24 cursor-pointer">
          {{ resetButtonLabel ? (resetButtonLabel | translate) : ('Shared.Entity.Clear_all' | translate) }}
        </p>
      </div>
    </div>
  </div>
</div>
