import { Expose, Type } from 'class-transformer';

import { IFileName } from './file.model';

interface ICommentAuthor {
  id: number;
  name: string;
}

interface IComment {
  id: number;
  body: string;
  created_at: string;
  author: ICommentAuthor;
}

export interface ICommentToSave {
  comment: {
    body: string;
  };
}

export interface IProjectIllustration {
  id: number;
  name: string;
  created_at: string;
  comment: IComment;
  filename: IFileName;
}

export class UrlFileModel {
  @Expose({ name: 'url' })
  url!: string;
  @Expose({ name: 'download_url' })
  downloadUrl!: string;
}

export class CommentModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'body' })
  body!: string;
  @Expose({ name: 'updated_at' })
  updatedAt!: string;
  @Expose({ name: 'author' })
  author!: ICommentAuthor;
}

export class MonitoringFileNameModel {
  @Expose({ name: 'url' })
  url!: string;
  @Expose({ name: 'download_url' })
  downloadUrl!: string;
  @Expose({ name: 'type' })
  type!: string;
  @Expose({ name: 'construction_camera' })
  @Type(() => UrlFileModel)
  constructionCamera!: UrlFileModel;
  @Expose({ name: 'w260' })
  @Type(() => UrlFileModel)
  w260!: UrlFileModel;
  @Expose({ name: 'w684' })
  @Type(() => UrlFileModel)
  w684!: UrlFileModel;
}

export class FileResourcesModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'file_size' })
  fileSize!: number;
  @Expose({ name: 'file_extension' })
  fileExtension!: string;
  @Expose({ name: 'position' })
  position!: number;
  @Expose({ name: 'type' })
  type!: string;
  @Expose({ name: 'upload_date' })
  uploadDate!: string;
  @Expose({ name: 'filename' })
  @Type(() => UrlFileModel)
  filename!: UrlFileModel;
}

export class ProjectIllustrationModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'created_at' })
  createdAt!: string;
  @Expose({ name: 'filename' })
  @Type(() => MonitoringFileNameModel)
  fileName!: MonitoringFileNameModel;
  @Expose({ name: 'comment' })
  @Type(() => CommentModel)
  comment!: CommentModel | null;
  @Expose({ name: 'file_resources' })
  @Type(() => FileResourcesModel)
  fileResources!: FileResourcesModel[];
}
