import {
  Attribute,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ILockedTrigger, InputTemplateDirective } from '@atlas-workspace/shared/directives';
import { DropdownPosition, NgSelectComponent } from '@ng-select/ng-select';
import emojiRegex from 'emoji-regex';

@Component({
  selector: 'atl-newest-drop-down',
  templateUrl: './newest-drop-down.component.html',
  styleUrls: ['./newest-drop-down.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NewestDropDownComponent),
      multi: true,
    },
  ],
})
export class NewestDropDownComponent implements ControlValueAccessor {
  @Input() inputTitle!: string;
  @Input() listOfItems!: any[];
  @Input() bindLabel!: string;
  @Input() bindValue?: string;
  @Input() groupBy?: string;
  @Input() position: DropdownPosition = 'bottom';
  @Input() disabled = false;
  @Input() closeOnSelect = true;
  @Input() chips = false;
  @Input() clearable = false;
  @Input() multiple = false;
  @Input() searchable = true;
  @Input() notFoundText!: string;
  @Input() type: 'select' | 'status' | 'user' = 'select';
  @Input() createNotFound = false;
  @Input() appendTo = '';
  @Input() customInput = false;
  @Input() placeholder = '';
  @Input() placeholderCustomInput = '';
  @Input() creation = false;
  @Input() customUserForm = false;
  @Input() customItemView = false;
  @Input() customLabelView = false;
  @Input() searchParams: string[] = [];
  @Input() userNotFound = false;
  @Input() tabindex = 0;

  @Output() create = new EventEmitter<string>();
  @Output() changeValueHandler = new EventEmitter<any>();
  @Output() emitValueSearch = new EventEmitter();
  @Output() clear = new EventEmitter();

  savedValue!: string;
  isActiveStaticCreation = false;
  public inputValue: any;

  private regex = emojiRegex() as RegExp;

  public locked = false;

  @ViewChild('ngSelect') dropDownInput!: NgSelectComponent;
  @ViewChild('creationInput') creationInput?: ElementRef;

  @ContentChild(InputTemplateDirective, { read: TemplateRef, static: false })
  labelTemplate: TemplateRef<ElementRef> | undefined;

  @ContentChild(InputTemplateDirective, { read: TemplateRef, static: false })
  valueTemplate: TemplateRef<ElementRef> | undefined;

  constructor(@Attribute('label') public label: string) {}

  protected onChange: (value: any) => void = () => null;
  protected onTouched: () => void = () => null;

  isLocked(value: ILockedTrigger, ngSelect: NgSelectComponent): void {
    this.locked = value.locked ? !value.focused : value.locked;
    if (!this.locked) {
      ngSelect.blur();
    }
  }

  changeValue(value: any): void {
    if (value instanceof Event) {
      value = Object.assign({}, {
        [this.bindValue || this.bindLabel]: (<HTMLInputElement>value.target).value,
      });
    }
    const specificValue = this.bindValue ? value[this.bindValue] : value;
    this.onChange(specificValue);
    this.changeValueHandler.emit(specificValue);
  }

  changeInput(event: any): void {
    const replaceEmoji = event.target.value.replaceAll(this.regex, '');
    event.target.value = replaceEmoji.replace(/[а-яА-ЯёЁ]/g, '');
  }

  initSearch(): void {
    if (!this.dropDownInput.isOpen) {
      this.dropDownInput.open();
    }
    if (this.savedValue) {
      this.dropDownInput.searchTerm = this.savedValue;
    } else if (this.searchable) {
      this.emitValueSearch.emit({ term: '' });
    }
  }

  onListenInput(value: { term: string; items: any[] }): void {
    this.emitValueSearch.emit(value);
  }

  searchFn = (term: string, item: any): boolean => {
    if (this.searchParams.length) {
      return this.searchParams.some((x) => item[x]?.toLowerCase().includes(term.toLowerCase()));
    }
    return item[this.bindLabel].toLowerCase().includes(term.toLowerCase());
  };

  createItem(searchTerm: string): void {
    if (searchTerm) {
      this.create.emit(searchTerm);
      this.dropDownInput.close();
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.inputValue = value;
  }

  onClose() {
    this.savedValue = '';
    this.closeCreation();
  }

  openCreation(): void {
    this.isActiveStaticCreation = true;
    setTimeout(() => this.creationInput?.nativeElement.focus(), 0);
  }

  closeCreation(): void {
    this.isActiveStaticCreation = false;
    if (this.creationInput) {
      this.creationInput.nativeElement.value = '';
    }
  }

  onCreation(term: string, e?: KeyboardEvent): void {
    if (e) {
      e.stopPropagation();
    }
    this.createItem(term);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
