import { Directive, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { ELocalStorageKeys } from '@atlas-workspace/shared/models';
import { LocalStorageService } from '@atlas-workspace/shared/service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[atlWindowInnerHeight]',
})
export class WindowInnerHeightDirective implements OnInit {
  @Output() isMobile = new EventEmitter<boolean>();
  @Input() allowResize = true;
  private readonly mobileSize = 768;
  private readonly resizeDelay = 500;
  private readonly resizeSubject$ = new Subject<void>();

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.resizeSubject$.next();
  }

  constructor(
    private readonly render: Renderer2,
    private readonly elementRef: ElementRef,
    private readonly localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.mobileResize();
    if(this.allowResize) {
      this.listenResizeEvent();
    }
  }

  private listenResizeEvent(): void {
    this.resizeSubject$.pipe(untilDestroyed(this), debounceTime(this.resizeDelay)).subscribe(() => {
      if (this.allowResize) {
        this.mobileResize();
      }
    });
  }

  private mobileResize(): void {
    this.isMobile.emit(window.innerWidth <= this.mobileSize);
    const height = window.innerHeight;
    const visualHeight = window?.visualViewport?.height;
    let style = `--window-inner-height: ${height}px; --window-visual-height: ${visualHeight}px`;
    const widthSidebar = document.documentElement.style.getPropertyValue('--admin-sidebar-width');
    const reclTableHeight = document.documentElement.style.getPropertyValue('--admin-reclamation-filters-heigth');

    if (widthSidebar) {
      style += `; --admin-sidebar-width: ${widthSidebar}`;
    }

    if (reclTableHeight) {
      style += `; --admin-reclamation-filters-heigth: ${reclTableHeight}`;
    }

    if (window.innerWidth <= this.mobileSize)  {
      const savedStep: number = this.localStorageService.get(ELocalStorageKeys.GlobalOnboardingStep) || 0;

      if (savedStep === 4) {
        const element = this.elementRef.nativeElement.querySelector('.left-menu__profile-wrapper');
        if (element) {
          const rect = element.getBoundingClientRect();
          style += `; --onboarding-profile-top: ${rect.top}px`;
        }
      }
    }

    this.render.setProperty(document.documentElement, 'style', style);
  }
}
