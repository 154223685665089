import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedDirectivesModule } from '@atlas-workspace/shared/directives';
import { ModalFacadeService } from '@atlas-workspace/shared/service';
import { SharedTranslateForChildModule } from '@atlas-workspace/shared/translate';
import { AutoResizeModule, CustomIconComponent, MoreDropdownComponent } from '@atlas-workspace/shared/ui';
import { NgbDropdownItem, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmModalComponent } from './components/confirm-modal/confirm-modal.component';
import { DeleteModalComponent } from './components/delete-modal/delete-modal.component';
import { DocumentsPreviewModule } from './components/documents-preview/documents-preview.module';
import { FreeTextModalComponent } from './components/free-text-modal/free-text-modal.component';
import { NewestConfirmModalComponent } from './components/newest-confirm-modal/newest-confirm-modal.component';

@NgModule({
  declarations: [DeleteModalComponent, ConfirmModalComponent, NewestConfirmModalComponent, FreeTextModalComponent],
  exports: [DeleteModalComponent, ConfirmModalComponent, NewestConfirmModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedTranslateForChildModule,
    DocumentsPreviewModule,
    AutoResizeModule,
    NgbTooltipModule,
    SharedDirectivesModule,
    CustomIconComponent,
    MoreDropdownComponent,
    NgbDropdownItem,
  ],
  providers: [ModalFacadeService],
})
export class SharedModalsModule {}
