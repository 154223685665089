import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ELocalStorageKeys, IAuthHeaders } from '@atlas-workspace/shared/models';
import { LocalStorageService } from '@atlas-workspace/shared/service';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'atl-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, RouterModule],
})
export class NotFoundPageComponent {
  public buttonLabel = '';
  public buttonLink = '';

  constructor(private localStorageService: LocalStorageService) {
    const isLogged = !!this.localStorageService.get<IAuthHeaders>(ELocalStorageKeys.AuthHeaders);
    this.buttonLabel = isLogged ? 'Shared.Not_found_page.Back_to_home' : 'Shared.Not_found_page.Back_to_signIn';
    this.buttonLink = isLogged ? '/' : '/auth/sign-in';
  }
}
