import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StateTranslateService {
  private onLoadedLocalizationData$: ReplaySubject<boolean> = new ReplaySubject<boolean>();

  public markAsLoadedLocalizationData(isLoaded: boolean): void {
    this.onLoadedLocalizationData$.next(isLoaded);
  }

  public get onLoadedLocalizationData(): Observable<boolean> {
    return this.onLoadedLocalizationData$.asObservable();
  }
}
