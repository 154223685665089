<!--
  ******************************************************
  @Cypress
  - interaction with begin/end date
  - interaction with Prev/next month arrows
  - interaction with day icons in ngb-datepicker-month
  ******************************************************
-->
<div #container class="date-range" [class.active]="activeInput">
  <div (click)="focus()" class="date-range__phase-date">
    <span
      #phaseTitle
      *ngIf="phaseName"
      class="date-range__phase-name d-block ellipsis"
      [ngbTooltip]="phaseName"
      [disableTooltip]="phaseTitle.offsetWidth >= phaseTitle.scrollWidth"
    >{{phaseName}}</span>
    <div class="d-flex align-items-center">
      <span>{{dates?.from  | date: 'dd.MM.YY'}}</span>
      <span>→</span>
      <span>{{dates?.to  | date: 'dd.MM.YY'}}</span>
    </div>
  </div>
  <input
    #fromInputRef
    (input)="inputFrom($event)"
    (click)="focus(fromInputRef)"
    (focus)="isFocusedToggle(0)"
    (blur)="isFocusedToggle(0)"
    [ngModel]="fromInput"
    [mask]="mask"
    [placeholder]="fromPlaceholder | translate"
    class="date-range__input"
    data-cy="phase-date-range-begin"
    type="text"
  />
  <img src="assets/arrow-connect-unit.svg" draggable="false" alt="arrow" />
  <input
    #toInputRef
    (input)="inputTo($event)"
    (click)="focus(toInputRef)"
    (focus)="isFocusedToggle(1)"
    (blur)="isFocusedToggle(1)"
    [ngModel]="toInput"
    [mask]="mask"
    [placeholder]="toPlaceholder | translate"
    class="date-range__input"
    data-cy="phase-date-range-end"
    type="text"
    [disabled]="!fromDate"
  />
  <input
    name="datepicker"
    ngbDatepicker
    #datepicker='ngbDatepicker'
    (dateSelect)="onDateSelection($event)"
    [autoClose]="'outside'"
    [displayMonths]="1"
    [dayTemplate]="rangeTemplate"
    [firstDayOfWeek]="1"
    [navigation]="navigation"
    [weekdays]="0"
    [outsideDays]="outsideDays"
    [footerTemplate]="footer"
    [placement]="placement"
    (closed)="blur()"
    container="body"
    datepickerClass="phase-datepicker"
    class="datepicker-ngb"
    tabindex="-1"
  />
</div>

<ng-template #rangeTemplate let-date let-currentMonth="currentMonth">
  <span
    class="custom-day"
    [class.outside]="currentMonth !== date.month"
    [class.range]="isRange(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>

<ng-template #noTemplate let-date let-selected="selected" let-currentMonth="currentMonth">
  <span
    class="custom-day"
    [class.selected]="
      selected || (date?.day === fromDate?.day && date?.month === fromDate?.month && date?.year === fromDate?.year)
    "
    [class.outside]="currentMonth !== date.month"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>

<ng-template #footer>
  <div class="date-range__footer">
    <div class="action">
      <span  (click)="clearAll()" [class.pointer-events-none]="disabledClear" [class.invisible]="!showClear" data-cy="date-range-clear-all-button" class="fz-14 lh-24 fw-500 cursor-pointer ml-8">
        {{ 'Shared.Entity.Clear_all' | translate }}
      </span>
      <button (click)="confirmDate()" [disabled]="disabled" class="newest-button newest-button-sm">
        {{'Shared.Button.Confirm' | translate}}
      </button>
    </div>
  </div>
</ng-template>
