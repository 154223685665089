import {CommonModule} from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {TranslateModule} from "@ngx-translate/core";
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component({
  selector: 'atl-action-message-banner',
  templateUrl: './action-message-banner.component.html',
  styleUrls: ['./action-message-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule]
})
export class ActionMessageBannerComponent implements OnInit {
  @Input() messageText!: string;
  @Input() timeDuration = 2000;
  @Input() closeButton = true;
  @Input() icon = false;
  @Input() spinner = false;
  @Input() endCloseButton = false;
  @Output() private readonly closeEvent = new EventEmitter<unknown>();

  ngOnInit(): void {
    of([])
      .pipe(delay(this.timeDuration))
      .subscribe(() => this.closeBanner());
  }

  closeBanner(): void {
    this.closeEvent.emit();
  }
}
