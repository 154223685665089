import { SafeHtml } from '@angular/platform-browser';
import { Expose } from 'class-transformer';

export interface IIntroduction {
  id: number;
  description: string;
  layout_type_id: number;
  layout_type_name: string;
  project_id: number;
  project_name: string;
  title: string;
}

export class IntroductionModel {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'description' })
  description!: string | SafeHtml;
  @Expose({ name: 'layout_type_id' })
  layoutTypeId!: number;
  @Expose({ name: 'layout_type_name' })
  layoutTypeName!: string;
  @Expose({ name: 'project_id' })
  projectId!: number;
  @Expose({ name: 'project_name' })
  projectName!: string;
  @Expose({ name: 'title' })
  title!: string;

  saveDescription!: SafeHtml;
}
