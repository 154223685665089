export enum EMeetingType {
  MoveIn = 'Meeting.Type.Move_in',
  WallClosing = 'Meeting.Type.Wall_closing',
  PreInspection = 'Meeting.Type.Pre_inspection',
  OneYearInspection = 'Shared.Meeting.Type.One_year_inspection',
  OptionsMeeting = 'Shared.Meeting.Type.Options_meeting',
  InformationMeeting = 'Shared.Meeting.Type.Information_meeting',
}

export enum EMeetingTypeId {
  PreInspection = 1,
  WallClosing,
  MoveIn,
  OneYearInspection = 6,
  OptionsMeeting,
  InformationMeeting,
}

export const meetingTypeTranslateKeyMap = new Map<EMeetingTypeId, EMeetingType>([
  [EMeetingTypeId.PreInspection, EMeetingType.PreInspection],
  [EMeetingTypeId.WallClosing, EMeetingType.WallClosing],
  [EMeetingTypeId.MoveIn, EMeetingType.MoveIn],
  [EMeetingTypeId.OneYearInspection, EMeetingType.OneYearInspection],
  [EMeetingTypeId.OptionsMeeting, EMeetingType.OptionsMeeting],
  [EMeetingTypeId.InformationMeeting, EMeetingType.InformationMeeting],
]);
