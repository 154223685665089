import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'atl-custom-icon',
  templateUrl: './custom-icon.component.html',
  styleUrls: ['./custom-icon.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
})
export class CustomIconComponent implements OnInit, OnChanges {
  constructor(private dsr: DomSanitizer) {}

  @Input() icon!: string;
  @Input() size!: number;

  sanitizedIcon!: SafeHtml;

  ngOnInit(): void {
    this.sanitizedIcon = this.dsr.bypassSecurityTrustHtml(this.icon);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.icon) {
      this.sanitizedIcon = this.dsr.bypassSecurityTrustHtml(this.icon);
    }
  }
}
