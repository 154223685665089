import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SwiperDirectiveModule } from '@atlas-workspace/shared/directives';

import { ImageSliderComponent } from './image-slider.component';

@NgModule({
  imports: [CommonModule, SwiperDirectiveModule],
  declarations: [ImageSliderComponent],
  exports: [ImageSliderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ImageSliderModule {}
