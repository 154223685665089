import {Component} from '@angular/core';

import { FdvNodeComponent } from '../fdv-node/fdv-node.component';

@Component({
  selector: 'atl-fdv-tree',
  templateUrl: './fdv-tree.component.html',
  styleUrls: ['../fdv-node/fdv-node.component.scss'],
})
export class FdvTreeComponent extends FdvNodeComponent {
}
