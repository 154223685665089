import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[atlMaxLength]',
  standalone: true,
})
export class MaxLengthDirective {
  @Input() maxLength = 30;
  @Input() currentValue: string | undefined;
  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (this.el.nativeElement.value?.length >= this.maxLength) {
      event.preventDefault();
      return;
    }
    if (event.key !== ' ') {
      return;
    }

    const selectionStart: number = this.el.nativeElement.selectionStart;
    if (selectionStart === 0 && event.key === ' ') {
      event.preventDefault();
      return;
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent): void {
    const dataToPaste = event?.clipboardData?.getData('text');
    if (!dataToPaste) {
      return;
    }

    if (dataToPaste && dataToPaste.length) {
      const totalSymbols = this.currentValue?.length
        ? this.currentValue.length + dataToPaste.length
        : dataToPaste.length;
      if (totalSymbols > this.maxLength) {
        event.preventDefault();
      }
    }
  }
}
