import {cloneDeep} from 'lodash';

// eslint-disable-next-line
export function ImmutableSelector(): Function {
  // eslint-disable-next-line prefer-arrow/prefer-arrow-functions
  return function(_target: any, _key: string, descriptor: PropertyDescriptor): PropertyDescriptor {
    const method = descriptor.value;

    descriptor.value = function(state: any, ...args: any[]): any {
      return method.apply(this, [cloneDeep(state), ...args]);
    };

    return descriptor;
  };
}
