import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalHelpersService } from '@atlas-workspace/shared/modals';
import {
  EFileType,
  FloorDrawVersionModel,
  FloorModel,
  IFloorItemVersionList,
  IFloorVersionList,
  selectedFloors,
  UnitFloorModel,
} from '@atlas-workspace/shared/models';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'atl-floor-plan-version-select',
  templateUrl: './floor-plan-version-select.component.html',
  styleUrls: ['./floor-plan-version-select.component.scss'],
})
export class FloorPlanVersionSelectComponent implements OnInit {
  @Input() readonly modalView = true;
  @Input() readonly header = 'Shared.Entity.Add_floor_plan';
  @Input() readonly button = '';
  @Input() readonly title = 'Shared.Change_Request.Floor_Plan_Select.Select.Title';
  @Input() readonly description = 'Shared.Change_Request.Floor_Plan_Select.Select.Description';
  @Input() readonly projectId!: number;
  @Input() readonly unitId!: number;
  @Input() readonly reuseDrawVersion = false;
  @Input() floorDrawVersions!: FloorDrawVersionModel[];
  @Input() private floors?: FloorModel[] | UnitFloorModel[];
  @Input() modalRef!: NgbModalRef;

  @Output() private readonly reuseVersionHandler = new EventEmitter<number>();

  public floorVersionList!: IFloorVersionList[];
  public readonly tooltipDelay = 500;

  constructor(private router: Router, private modalHelpersService: ModalHelpersService) {}

  ngOnInit(): void {
    this.initFloorVersionList();
  }

  public closeModal(): void {
    this.modalRef.dismiss();
  }

  public reuseVersion(version: number): void {
    this.reuseVersionHandler.emit(version);
  }

  private initFloorVersionList(): void {
    if (!this.floors) return;
    const versionList = Array.from(new Set(this.floorDrawVersions.map((f) => f.versionNumber)));
    if (!versionList.includes(1)) {
      versionList.push(1);
      versionList.sort();
    }
    // @ts-ignore
    const floorNumList = this.floors.map((floor: FloorModel | UnitFloorModel) => floor.floorNumber);
    const floorNumVersionList = this.floorDrawVersions.map((v) => v.floorNumber);
    const floorList = Array.from(new Set([...floorNumList, ...floorNumVersionList])).sort();
    this.floorVersionList = versionList.reduce<IFloorVersionList[]>((acc: IFloorVersionList[], v: number) => {
      const data: IFloorVersionList = {
        version: v,
        creator: '',
        createAt: '',
        floor: [],
      };

      floorList.map((floor: number) => {
        const drawVersion = this.floorDrawVersions.filter((dw) => dw.versionNumber === v && dw.floorNumber === floor);
        if (drawVersion?.length) {
          data.creator = drawVersion[0].creator.name;
          data.createAt = drawVersion[0].createdAt;
          data.floor.push({ plan: drawVersion[0], selected: false });
        } else {
          const plan = this.floors?.find((f) => f.floorNumber === floor);
          data.floor.push({ plan: plan, selected: false });
        }
      });

      acc.push(data);
      return acc;
    }, []);
  }

  public selectFloor(floor: { selected: boolean }): void {
    floor.selected = !floor.selected;
  }

  get selectedFloors(): (FloorDrawVersionModel | FloorModel | UnitFloorModel)[] {
    return selectedFloors(this.floorVersionList);
  }

  public sendFloors(): void {
    this.modalRef.dismiss(this.floorVersionList);
  }

  public redirectToUnit(): void {
    const url = `/base-layout/projects/specific_project/${this.projectId}/units/view/units?unitId=${this.unitId}`;
    void this.router.navigateByUrl(url);
  }

  public onOpenView(floor: IFloorItemVersionList): void {
    let plan = undefined;
    if (floor.plan instanceof FloorModel || floor.plan instanceof UnitFloorModel) {
      if (!floor.plan.plan) return;
      plan = floor.plan.plan;
    } else {
      plan = floor?.plan?.floor?.plan;
      if (plan && plan.fileName.url) {
        if (floor?.plan?.filenameRemoteUrl) {
          plan.fileName.url = floor.plan.filenameRemoteUrl;
        } else if (plan?.fullImage?.url) {
          plan.fileName.url = plan.fullImage?.url;
          plan.type = EFileType.Image;
        } else if (floor?.plan?.filename.url) {
          plan.fileName.url = floor.plan.filename.url;
        } else {
          plan.fileName.url = '';
        }
      }
    }

    this.modalHelpersService.previewImages([plan], 0);
  }
}
