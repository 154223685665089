import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[atlInfiniteScroll]',
})
export class InfiniteScrollDirective implements OnInit, AfterViewInit, OnDestroy {
  @Input() debounceTime = 0;
  @Input() threshold = 1;
  @Input() unobserveAfterEmit = true;

  @Output() atlInfiniteScroll = new EventEmitter<HTMLElement>();
  @Output() visibilityChange = new EventEmitter<boolean>();

  private observer: IntersectionObserver | undefined;
  private subject$ = new Subject<{
    entry: IntersectionObserverEntry;
    observer: IntersectionObserver;
  } | void>();

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    this.createObserver();
  }

  ngAfterViewInit(): void {
    this.startObservingElements();
  }

  private isVisible(element: HTMLElement): Promise<boolean> {
    return new Promise((resolve) => {
      const observer = new IntersectionObserver(([entry]) => {
        resolve(entry.intersectionRatio >= this.threshold);
        if (this.unobserveAfterEmit) {
          observer.disconnect();
        }
      });

      observer.observe(element);
    });
  }

  private createObserver(): void {
    const options = {
      rootMargin: '0px',
      threshold: this.threshold,
    };

    const isIntersecting = (entry: IntersectionObserverEntry): boolean =>
      entry.isIntersecting || entry.intersectionRatio > 0;

    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (isIntersecting(entry)) {
          this.subject$.next({ entry, observer });
        } else {
          this.visibilityChange.emit(false);
        }
      });
    }, options);
  }

  startObservingElements(): void {
    if (!this.observer) {
      return;
    }
    this.observer.observe(this.element.nativeElement);
    this.subject$.pipe(debounceTime(this.debounceTime)).subscribe(async (observe) => {
      if (observe) {
        const { entry, observer } = observe;
        const target = entry.target as HTMLElement;
        const isStillVisible = await this.isVisible(target);
        this.visibilityChange.emit(isStillVisible);
        if (isStillVisible) {
          this.atlInfiniteScroll.emit(target);
          if (this.unobserveAfterEmit) {
            observer.unobserve(target);
          }
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = undefined;
    }

    this.subject$.next();
    this.subject$.complete();
  }
}
