import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  ELang,
  ELocalStorageKeys,
  IPluckedTranslateLocalize,
  ITranslateLocalize,
} from '@atlas-workspace/shared/models';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { LocalStorageService } from '../local-storge/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class RestTranslateService {
  public onChangeUserLocalization$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private http: HttpClient,
    @Inject('ENVIRONMENT') private env: IEnvironment,
    private localStorageService: LocalStorageService,
    private translateService: TranslateService
  ) {}

  public getClientUserLocalization(): Observable<IPluckedTranslateLocalize> {
    return this.http.get(`${this.env.apiBaseUrl}api/v1/client/localize`).pipe(
      map((res: any) => res.data),
      map((data) => <IPluckedTranslateLocalize>data)
    );
  }

  public getAdminUserLocalization(): Observable<IPluckedTranslateLocalize> {
    return this.http.get(`${this.env.apiBaseUrl}api/v1/localize`).pipe(
      map((res: any) => res.data),
      map((data) => <IPluckedTranslateLocalize>data)
    );
  }

  public updateClientUserLocalization(lg: ELang): Observable<IPluckedTranslateLocalize> {
    return this.http
      .put(`${this.env.apiBaseUrl}api/v1/client/localize`, {
        localize: {
          locale: lg,
        },
      } as ITranslateLocalize)
      .pipe(
        map((res: any) => res.data),
        mergeMap((resp: any) =>
          this.translateService.use(resp.locale).pipe(
            tap(() => {
              this.setLocalStorageLocalize(resp.locale);
              this.onChangeUserLocalization$.next(true);
            })
          )
        ),
        catchError(() => EMPTY)
      );
  }

  public updateAdminUserLocalization(lg: ELang): Observable<IPluckedTranslateLocalize> {
    return this.http
      .put(`${this.env.apiBaseUrl}api/v1/localize`, {
        localize: {
          locale: lg,
        },
      } as ITranslateLocalize)
      .pipe(
        map((res: any) => res.data),
        mergeMap((resp: any) =>
          this.translateService.use(resp.locale).pipe(
            tap(() => {
              this.setLocalStorageLocalize(resp.locale);
              this.onChangeUserLocalization$.next(true);
            })
          )
        ),
        catchError(() => EMPTY)
      );
  }

  private setLocalStorageLocalize(lg: ELang): void {
    this.localStorageService.set(ELocalStorageKeys.Language, lg);
  }

  public setLocalLocalization(admin = false): Observable<IPluckedTranslateLocalize> {
    const fn = admin ? this.getAdminUserLocalization : this.getClientUserLocalization;
    return fn.call(this).pipe(
      catchError(() => {
        return of({ locale: ELang.Norwegian });
      }),
      switchMap(({ locale }: IPluckedTranslateLocalize) => {
        if (this.translateService.currentLang == locale) {
          return of({ locale });
        }
        return this.translateService.use(locale).pipe(
          tap(() => this.setLocalStorageLocalize(locale)),
          switchMap(() => of({ locale }))
        );
      }),
      take(1)
    );
  }
}
