/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import { DocumentUrlModel, IFloorType } from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ConverterService {
  constructor(private http: HttpClient, @Inject('ENVIRONMENT') private environment: IEnvironment) {}

  converterPdfToPng(data: FormData): Observable<any> {
    return this.http
      .post<{ data: { url: string } }>(this.environment.apiBaseUrl + 'api/v1/files/convert_pdf_to_png', data)
      .pipe(
        map((res) => res.data.url),
        switchMap((url: string) => this.http.get(url, { responseType: 'blob' }))
      );
  }

  downloadFileFromAmazon(
    floorId: number,
    unitId: number,
    floorType: any = IFloorType.UnitFloor
  ): Observable<HttpResponse<Blob>> {
    const params = new HttpParams()
      .append('unit_id', String(unitId))
      .append('floor_type', floorType)
      .append('floor_id', String(floorId));
    return this.http.get(`${this.environment.apiBaseUrl}api/v1/files/download_floorable_plan`, {
      params,
      responseType: 'blob',
      observe: 'response',
    });
  }

  /**
   * @Cypress
   */
  public generateFilenameUrl(file: File): Observable<DocumentUrlModel> {
    const fd = new FormData();
    fd.append('filename', file.name);
    return this.http.post<any>(`${this.environment.apiBaseUrl}api/v1/files/generate_presigned_url`, fd).pipe(
      map((res) => res.data),
      map((data: DocumentUrlModel) => plainToClass(DocumentUrlModel, data))
    );
  }

  uploadFileToS3(url: string, file: File): Observable<any> {
    const fileType = file.type;
    const headers = new HttpHeaders({ 'Content-Type': fileType });
    return this.http.put(url, file, { headers });
  }

  convertSignatureToImageFile(canvas: HTMLCanvasElement, fileName = 'signature.png'): Observable<File> {
    const base64String = canvas.toDataURL('image/png');
    return this.convertBase64ToFile(base64String, fileName);
  }

  private convertBase64ToFile(base64String: string, fileName: string): Observable<File> {
    const byteCharacters = atob(base64String.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });
    const file = new File([blob], fileName, { type: 'image/png' });

    return from(file.arrayBuffer()).pipe(
      map(() => file),
      catchError((error) => throwError(error))
    );
  }
}
