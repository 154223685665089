import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { ActionsBottomPanelComponent } from './actions-bottom-panel.component';

@NgModule({
  declarations: [ActionsBottomPanelComponent],
  exports: [ActionsBottomPanelComponent],
  imports: [CommonModule, NgbTooltipModule, TranslateModule],
})
export class ActionsBottomPanelModule {}
