import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import {
  ContractorCompanyForReclamationsModel,
  FirmContractorModel,
  ISelectedReclamationFilters,
} from '@atlas-workspace/shared/models';
import { plainToClass } from 'class-transformer';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ContractorService {
  constructor(@Inject('ENVIRONMENT') private environment: IEnvironment, private http: HttpClient) {}

  /**
   * @Cypress
   */
  getFirmContractors(
    page: number,
    perPage: number,
    companyId?: number,
    registered = false
  ): Observable<FirmContractorModel[]> {
    let params = new HttpParams({
      fromObject: {
        page: page.toString(),
        per_page: perPage.toString(),
      },
    });
    if (companyId) {
      params = params.append('contractor_company_id', companyId.toString());
    }

    if (registered) {
      params = params.append('registered_only', registered);
    }
    return this.http
      .get<{ data: { contractors: FirmContractorModel[] } }>(`${this.environment.apiBaseUrl}api/v1/contractors`, {
        params,
      })
      .pipe(
        map((res) => res.data.contractors),
        map((value) => plainToClass(FirmContractorModel, value))
      );
  }

  /**
   * @Cypress
   * @see https://api.journeyapp.dev.scrij.com/api-docs#tag/Reclamations/paths/~1api~1v1~1projects~1%7Bproject_id%7D~1reclamations~1contractor_companies/get
   */
  getContractorsForReclamations(
    projectId: number,
    filter: ISelectedReclamationFilters
  ): Observable<ContractorCompanyForReclamationsModel[]> {
    let params = new HttpParams();
    params = this.processSelectedFilters(params, filter);

    return this.http
      .get<{ data: { contractor_companies: ContractorCompanyForReclamationsModel[] } }>(
        `${this.environment.apiBaseUrl}api/v1/projects/${projectId}/reclamations/contractor_companies`,
        {
          params,
        }
      )
      .pipe(
        map((res) => res.data.contractor_companies),
        map((value) => plainToClass(ContractorCompanyForReclamationsModel, value))
      );
  }

  private processSelectedFilters(params: HttpParams, selectedFilters: ISelectedReclamationFilters): HttpParams {
    if (selectedFilters?.statuses.length) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const idsParams = new HttpParams({ fromObject: { 'statuses[]': selectedFilters.statuses } });
      params = this.appendParams(params, idsParams);
    }

    if (selectedFilters?.categoryIds.length) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const idsParams = new HttpParams({ fromObject: { 'category_ids[]': selectedFilters.categoryIds } });
      params = this.appendParams(params, idsParams);
    }

    if (selectedFilters?.typeIds.length) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const idsParams = new HttpParams({ fromObject: { 'type_ids[]': selectedFilters.typeIds } });
      params = this.appendParams(params, idsParams);
    }

    if (selectedFilters?.unitIds.length) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const idsParams = new HttpParams({ fromObject: { 'unit_ids[]': selectedFilters.unitIds } });
      params = this.appendParams(params, idsParams);
    }

    if (selectedFilters?.responsibleIds.length) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const idsParams = new HttpParams({ fromObject: { 'responsible_ids[]': selectedFilters.responsibleIds } });
      params = this.appendParams(params, idsParams);
    }

    if (selectedFilters?.layoutTypeIds.length) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const idsParams = new HttpParams({ fromObject: { 'layout_type_ids[]': selectedFilters.layoutTypeIds } });
      params = this.appendParams(params, idsParams);
    }

    if (selectedFilters?.meetingTypes.length) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const idsParams = new HttpParams({ fromObject: { 'meeting_type_ids[]': selectedFilters.meetingTypes } });
      params = this.appendParams(params, idsParams);
    }

    if (selectedFilters?.author.length) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const idsParams = new HttpParams({ fromObject: { 'author_types[]': selectedFilters.author } });
      params = this.appendParams(params, idsParams);
    }

    if (selectedFilters?.threadState.length) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const idsParams = new HttpParams({ fromObject: { 'message_states[]': selectedFilters.threadState } });
      params = this.appendParams(params, idsParams);
    }

    if (selectedFilters?.date) {
      if (selectedFilters.date[0]?.from) {
        const dateFromParams = new HttpParams({
          fromObject: { min_reported_date: selectedFilters.date[0]?.from as string },
        });
        params = this.appendParams(params, dateFromParams);
      }
      if (selectedFilters.date[0]?.to) {
        const dateToParams = new HttpParams({
          fromObject: { max_reported_date: selectedFilters.date[0]?.to as string },
        });
        params = this.appendParams(params, dateToParams);
      }
    }

    return params;
  }

  private appendParams(params: HttpParams, sourceParams: HttpParams): HttpParams {
    sourceParams.keys().forEach((key: string) => {
      const values = sourceParams.getAll(key);

      if (values) {
        values.forEach((value: string | null) => {
          if (value !== null) {
            params = params.append(key, value);
          }
        });
      }
    });

    return params;
  }
}
