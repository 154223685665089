<!--
  ******************************************************
  @Cypress
  - interaction with container element
  - interaction with Close button
  ******************************************************
-->
<div class="tooltip__wrapper position-relative" #tooltipContainer>
  <ng-content></ng-content>
  <div
    *ngIf="isDisplayTooltip"
    class="tooltip__container"
    data-cy="tooltip-container"
    #tooltipEl
    [ngStyle]="{
        left: tooltipPosition ? (tooltipPosition + 'px') : null,
        top: dynamicYPosition ? (tooltipYPosition + 'px') : null,
        transform: horizontalShiftTooltipOn ? ('translateX(-' + horizontalShiftTooltipOn + 'px)') : null
    }"
  >
    <img
      class="tooltip__container-rectangle"
      [style.left.px]="rectanglePosition"
      src="/assets/tooltip_rectangle.svg"
      alt="Rectangle"
    />
    <div class="d-flex align-items-center">
      <img *ngIf="showSpinner" class="spinner" src="assets/spinner.svg" alt="Spinner" />
      <p>{{ text }}</p>
      <img
        class="tooltip__close-tooltip"
        data-cy="tooltip-close"
        src="/assets/close_tooltip_icon.svg"
        alt="Close tooltip"
        *ngIf="showClose"
        (click)="closeTooltip()"
      />
    </div>
  </div>
</div>
