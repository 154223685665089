import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

interface IButtonParams {
  text: string;
  class: string;
}

@Component({
  selector: 'atl-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmModalComponent {
  constructor(private ngbActiveModal: NgbActiveModal, public translateService: TranslateService) {}
  @Input() isCenteredTitle = false;
  @Input() title!: string;
  @Input() description!: string;
  @Input() icon!: string;
  @Input() button: IButtonParams = {
    text: this.translateService.instant('Shared.Button.Yes_confirm'),
    class: 'global-button',
  };
  @Output() passConfirm: EventEmitter<boolean> = new EventEmitter();
  @Output() passCancel: EventEmitter<boolean> = new EventEmitter();
  public closeModal(): void {
    this.passCancel.emit(true);
    this.ngbActiveModal.close();
  }

  public confirm(): void {
    this.passConfirm.emit(true);
  }
}
