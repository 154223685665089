import { Pipe, PipeTransform } from '@angular/core';
import { acceptedVideoExtensions } from '@atlas-workspace/shared/models';

@Pipe({
  name: 'isVideo',
  standalone: true,
})
export class IsVideoPipe implements PipeTransform {
  transform(name: string): boolean {
    const arr = name.split('.');
    return acceptedVideoExtensions.includes(arr[arr.length - 1].toLowerCase());
  }
}
