import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[atlReplaceZero]',
  standalone: true
})
export class ReplaceZeroDirective {
  @Input('atlReplaceZero') readonly replaceZero = false;
  constructor(private readonly el: ElementRef<HTMLInputElement>) {}

  @HostListener('input', ['$event']) onInput(event: InputEvent): void {
    if (!this.replaceZero) {
      return;
    }
    const inputElement = this.el.nativeElement;
    const inputValue = inputElement.value;
    if (inputValue.startsWith('0')) {
      const newValue = event.data as string;
      inputElement.value = newValue;
    }
  }
}
