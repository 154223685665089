<div
  class="download-card__wrapper"
  [class.download-card__wrapper--fluid]="isFluid"
  [class.download-card__message-document]="isChat"
  [class.download-card__image]="downloadLink | displayDownloadLink: { type: fileType, showAsImg: showPNGAsImg }"
  [style.background-image]="downloadLink | displayDownloadLink: { type: fileType, showAsImg: showPNGAsImg }"
>
  <div class="download-card__info">
    <div class="fz-14 download-card__file-name ellipsis">
      {{ fileName }}
    </div>

    <div class="fz-12 download-card__download">
      <div class="d-flex align-items-center">
        <ng-container>
          <img class="download-card__extension" [src]="fileType | iconByType" alt="icon" />
          <span class="fz-12 download-card__file-size"> {{ fileSize | filesize }} </span>
        </ng-container>
      </div>

      <a
        *ngIf="downloadLink"
        (click)="$event.stopPropagation()"
        tabindex="-1"
        [href]="downloadLink"
        download
        [attr.id]="id || null"
      >
        <img src="/assets/download_simple.svg" alt="Download" />
      </a>
    </div>
  </div>
</div>
