import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { DocumentModel, IDocumentsBreadcrumbs } from '@atlas-workspace/shared/models';
import { ProjectDocumentsService } from '@atlas-workspace/shared/service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { DeleteModalComponent } from '../delete-modal/delete-modal.component';

@UntilDestroy()
@Component({
  selector: 'atl-move-to-modal',
  templateUrl: './move-to-modal.component.html',
  styleUrls: ['./move-to-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MoveToModalComponent extends DeleteModalComponent implements OnChanges, OnInit {
  @Input() folders: DocumentModel[] = [];
  @Input() selectedItems!: DocumentModel[];
  @Input() breadCrumbs: IDocumentsBreadcrumbs | null = null;

  @Input() set newFolder(value: DocumentModel) {
    this.newFolder$.next(value);
  }

  @Output() selectedFolder: EventEmitter<number | null> = new EventEmitter();
  @Output() createFolder: EventEmitter<{ name: string; id: number | undefined }> = new EventEmitter();
  @Output() editFolder: EventEmitter<{ name: string; id: number | undefined }> = new EventEmitter();
  @Output() loadInnerFolder: EventEmitter<number | string | null> = new EventEmitter();
  @Output() moveToState: EventEmitter<boolean> = new EventEmitter();
  @Output() renameFolderState: EventEmitter<boolean> = new EventEmitter();

  public newFolderName = '';
  public editedName = '';
  public currentFolder: DocumentModel | null = null;
  public showMoveTo = false;
  public showRenameFolder = false;
  public selectedFolderId: number | null = null;
  public renameFolderItem: DocumentModel | null | undefined = null;
  public disabledRenameFolderButton = true;
  public isDownload = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public folderBreadcrumbs: Array<any> = [
    {
      id: null,
      title: 'Select folder',
    },
  ];
  public createState = false;
  public readonly maxNestedFolders = 10;
  private newFolder$ = new BehaviorSubject<DocumentModel | null>(null);
  public selectedFolderItem: DocumentModel | null = null;

  constructor(protected documentsService: ProjectDocumentsService, protected cdr: ChangeDetectorRef) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedItems) {
      if (this.selectedItems.some((x) => x.id === this.selectedFolderId)) {
        this.selectedFolderId = null;
      }
    }
  }

  trackByFn: (i: number, item: DocumentModel) => number = (i: number, item: DocumentModel): number => item.id;

  ngOnInit(): void {
    this.initNavigation();
    this.documentsService.renamedFolder$.pipe(untilDestroyed(this)).subscribe(() => {
      this.disabledRenameFolderButton = true;
      this.cdr.detectChanges();
    });
    this.documentsService.downloadLoading.pipe(untilDestroyed(this)).subscribe((download) => {
      this.isDownload = download;
      this.cdr.markForCheck();
    });
  }

  private initNavigation(): void {
    if (this.breadCrumbs?.breadCrumbs) {
      this.folderBreadcrumbs = [...(this.breadCrumbs?.breadCrumbs ?? [])];
    }
    this.currentFolder = this.folderBreadcrumbs[this.folderBreadcrumbs.length - 1] ?? null;
  }

  public cancelModal(): void {
    this.selectedFolderId = null;
    this.selectedFolderItem = null;
    this.loadInnerFolder.emit('');
    super.cancel();
    this.moveToState.emit(false);
    this.renameFolderState.emit(false);
  }

  public closeMoveToBlock(): void {
    this.showMoveTo = false;
    this.selectedFolderId = null;
    this.selectedFolderItem = null;
    this.moveToState.emit(false);
  }

  public back(): void {
    this.selectedFolderId = null;
    this.selectedFolderItem = null;
    const idx = this.breadCrumbs?.breadCrumbs.findIndex((breadCrumbs) => breadCrumbs.id === this.currentFolder?.id);
    if (idx && idx > 0 && this.breadCrumbs?.breadCrumbs.length) {
      this.selectedFolderId = this.breadCrumbs.breadCrumbs[idx - 1].id;
    }
    if (this.folderBreadcrumbs.length === 1) {
      this.currentFolder = null;
      this.loadInnerFolder.emit('');
      this.selectedFolderId = null;
      this.createState = false;
      return;
    }

    if (this.folderBreadcrumbs.length > 1) {
      const parentFolder = this.folderBreadcrumbs[this.folderBreadcrumbs.length - 2];
      this.currentFolder = parentFolder?.id ? parentFolder : null;
      this.folderBreadcrumbs.pop();
      this.loadInnerFolder.emit(this.currentFolder?.id);
    }
  }

  public renameFolder(): void {
    this.editedName = this.renameFolderItem?.localizedTitle || '';
    this.showMoveTo = false;
    this.showRenameFolder = !this.showRenameFolder;
    this.moveToState.emit(this.showMoveTo);
    this.renameFolderState.emit(this.showRenameFolder);
  }

  public moveTo(): void {
    this.showRenameFolder = false;
    this.showMoveTo = !this.showMoveTo;
    this.renameFolderState.emit(this.showRenameFolder);
    this.moveToState.emit(this.showMoveTo);
  }

  public selectFolder(folder: DocumentModel): void {
    this.selectedFolderId = folder.id;
    this.createState = false;
  }

  public changeCreateFolderState(): void {
    if (this.folderBreadcrumbs.length >= this.maxNestedFolders) {
      return;
    }

    this.selectedFolderId = null;
    this.createState = !this.createState;
  }

  public moveSelectedToFolder(): void {
    this.showMoveTo = false;
    this.selectedFolder.emit(this.selectedFolderId);
    this.selectedFolderId = null;
    this.moveToState.emit(false);
  }

  public createNewFolder(): void {
    if (this.newFolderName) {
      const newFolder = {
        name: this.newFolderName,
        id: this.currentFolder?.id,
      };
      this.createFolder.emit(newFolder);
      this.createState = false;
      this.newFolderName = '';
      this.newFolder$
        .pipe(
          filter((x) => !!x),
          take(1)
        )
        .subscribe((createdFolder) => {
          this.openInnerFolder(createdFolder);
          this.newFolder$.next(null);
        });
    }
  }

  renameSelectedFolder(): void {
    const folder = {
      name: this.editedName,
      id: this.selectedItems[0].id,
    };
    this.disabledRenameFolderButton = true;
    this.renameFolder();
    this.editFolder.emit(folder);
    this.renameFolderState.emit(this.showRenameFolder);
  }

  public openInnerFolder(folder: DocumentModel | null): void {
    if (folder) {
      this.selectedFolderId = folder.id;
      this.selectedFolderItem = folder;
    }
    this.currentFolder = folder;
    this.loadInnerFolder.emit(folder?.id || '');
    this.folderBreadcrumbs.push(folder);
  }

  public changeName(event: string): void {
    this.disabledRenameFolderButton = event === this.renameFolderItem?.title;
  }
}
