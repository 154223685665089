import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { ProgressLoaderComponent } from './progress-loader.component';


@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [ProgressLoaderComponent],
  exports: [ProgressLoaderComponent],
})
export class ProgressLoaderModule {}
