<div class="position-relative h-100" [class.disabled]="disabled" (clickOutside)="closeMenu()" atlClickOutside>
  <editor
    class="thread-view-editor"
    #editor
    [formControl]="formControl"
    [apiKey]="apiKey"
    [init]="editorInitConfig"
    (onKeyDown)="onKeyDown($event.event)"
    (onKeyUp)="onKeyUp($event.event)"
    data-cy="cy-editor"
    [class.focus]="focused"
    (onClick)="hasFocus()"
    (onBlur)="hasBlur()"
  ></editor>

  <div class="custom-toolbar" *ngIf="isDropdownOpen" [style.top.px]="48">
    <button (click)="setHeadingLevel(1)">
      <img src="/assets/editor-h1.svg" alt="Icon" class="mr-8" />
      {{ 'Title.Heading' | translate }} 1
    </button>
    <button (click)="setHeadingLevel(2)">
      <img src="/assets/editor-h2.svg" alt="Icon" class="mr-8" />
      {{ 'Title.Heading' | translate }} 2
    </button>
    <button (click)="setHeadingLevel(3)">
      <img src="/assets/editor-h3.svg" alt="Icon" class="mr-8" />
      {{ 'Title.Heading' | translate }} 3
    </button>
    <button (click)="toggleBulletList()">
      <img src="/assets/edit-icon.svg" alt="Icon" class="mr-8" />

      {{ 'Title.Bullet_list' | translate }}
    </button>
    <button (click)="toggleNumberedList()">
      <img src="/assets/number-list.svg" alt="Icon" class="mr-8" />
      {{ 'Title.Numbered_list' | translate }}
    </button>

    <ng-container *ngIf="!asTextArea">
      <button (click)="openImageUploader()">
        <img src="/assets/editor-image.svg" alt="Icon" class="mr-8" />
        {{ 'Alt.Image' | translate }}
      </button>
    </ng-container>
  </div>

  <ng-container *ngIf="asTextArea">
    <span
      class="show-text d-block ellipsis fz-14 lh-24 white-space-nowrap"
      [class.placeholder]="!formControl.value"
      [innerHtml]="
        formControl.value ? (formControl.value | removeHtmlTag | removeNbsp | safeHtml) : (textAreaPlaceholder | translate)
      "
    ></span>
  </ng-container>
</div>
