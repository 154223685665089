import { Pipe, PipeTransform } from '@angular/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { CommonHelper } from '@atlas-workspace/shared/service';

@Pipe({
  name: 'iterableAlreadyIncludesPrimitive',
})
export class IterableAlreadyIncludesPrimitivePipe implements PipeTransform {
  commonHelper = CommonHelper;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  transform(sourceArray: any | string, soughtItem: any | string): boolean {
    if (!sourceArray || !soughtItem) {
      return false;
    }
    return this.commonHelper.iterableAlreadyIncludesPrimitive(sourceArray, soughtItem);
  }
}
