import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'atl-scalable-bordered-img',
  templateUrl: './scalable-bordered-img.component.html',
  styleUrls: ['./scalable-bordered-img.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class ScalableBorderedImgComponent {
  @Input() borderRadius = '0';
  @Input() border = 'none';
  @Input() height = '8px';
  @Input() src!: string;
  @Input() width = '8px';
}
