import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import {CommonModule} from "@angular/common";
import {ClickOutsideDirective, HorizontalAlignDirective} from "@atlas-workspace/shared/directives";
import {TranslateModule} from "@ngx-translate/core";

@UntilDestroy()
@Component({
  selector: 'atl-multi-filter-dropdown',
  templateUrl: './multi-filter-dropdown.component.html',
  styleUrls: ['./multi-filter-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ClickOutsideDirective, HorizontalAlignDirective, TranslateModule]
})
export class MultiFilterDropdownComponent implements OnInit {
  @Input() position = '';
  @Input() applyButtonText = 'Shared.Button.Apply';
  @Input() footerEnabled = true;
  @Input() resetButtonText = 'Shared.Button.Reset_all';
  @Input() disabled = false;
  // !!! it should be 40;
  @Input() dropdownContentTop = 40;
  @Output() applyEvent = new EventEmitter();
  @Output() closeEvent = new EventEmitter();
  @Output() resetEvent = new EventEmitter();
  @Output() expandEvent = new EventEmitter<boolean>();

  @Input() expandedState$!: BehaviorSubject<boolean> | undefined;

  @Input() expanded = false;

  constructor(protected cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.expandedState$) {
      this.expandedState$.pipe(untilDestroyed(this)).subscribe(() => {
        this.close();
        this.cdr.detectChanges();
      });
    }
  }

  toggle(): void {
    if (this.disabled) return;
    this.expanded = !this.expanded;
    if (!this.expanded) {
      this.closeEvent.emit();
    }
    this.expandEvent.emit(this.expanded);
  }

  public close(): void {
    if (this.expanded) {
      this.expanded = false;
      this.closeEvent.emit();
      this.expandEvent.emit(this.expanded);
    }
  }

  applyAllFilters(): void {
    this.applyEvent.emit();
    this.close();
  }

  resetAllFilters(): void {
    this.resetEvent.emit();
    this.close();
  }
}
