<div [ngStyle]="{ background: customBackground }" class="plan-warning-wrapper" [class]="class">
  <div class="plan-warning-wrapper__image">
    <img [src]="'assets/' + imageName" alt="No plan" />
  </div>
  <div class="plan-warning-wrapper__title">
    <p>{{ title | translate }}</p>
  </div>
  <div class="fz-16 plan-warning-wrapper__message">
    <p>{{ message | translate }}</p>
  </div>

  <ng-content> </ng-content>
</div>
