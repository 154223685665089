import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {NgbModalFooterComponent, NgbModalHeaderComponent} from "@atlas-workspace/shared/ui";
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'atl-message-popup',
  templateUrl: './message-popup.component.html',
  styleUrls: ['./message-popup.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, NgbModalHeaderComponent, NgbModalFooterComponent],
})
export class MessagePopupComponent {
  @Input() modalRef!: NgbModalRef;
  @Input() title = 'Title.Options_store_open_now';
  @Input() textStart = 'Modal.Options_open_now.Following_phases';
  @Input() textEnd = 'Modal.Options_open_now.Make_sure';
  @Input() list!: string[];
  @Input() closeLabel = 'Shared.Title.Later';
  @Input() confirmLabel = 'Title.Go_to_options';

  closeModal(value?: boolean): void {
    this.modalRef.dismiss(value);
  }
}
