import { Expose, Type } from "class-transformer";

export interface IScene {
    id: number;
    name: string;
    projectsCount: number;
    preview: string;
    selected: boolean;
  }
  
  export interface IConnectedSceneProduct {
    name: string;
    producer: string;
    type: string;
    preview: string;
    selected: boolean;
  }
  

  export class PicrioScene {
    @Expose({ name: 'id' }) id!: number;
    @Expose({ name: 'name' }) name!: string;
    @Expose({ name: 'preview' }) preview!: string;
    @Expose({ name: 'products_count' }) productsCount!: number;
    selected = false;
  }

  export class PicarioScenesData {
    @Expose({ name: 'total_count' }) totalCount!: number;
    @Expose({ name: 'picario_scenes' })
    @Type(() => PicrioScene)
    scenes!: PicrioScene[];
  }

  export class PicarioProduct{
    @Expose({ name: 'id' }) id!: number;
    @Expose({ name: 'preview' }) preview!: string | null;
    @Expose({ name: 'product_enabled' }) productEnabled!: boolean;
    @Expose({ name: 'product_producer' }) productProducer!: {
      id: number,
      name: string
    } | null
    @Expose({ name: 'product_type' }) productType!: {
      id: number,
      system: boolean,
      title: string
    }

    selected = false
  }