import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'atl-shadow-dom-content',
  templateUrl: './shadow-dom-content.component.html',
  styleUrls: ['./shadow-dom-content.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: true,
})
export class ShadowDomComponent {}
