import {
  AfterViewInit,
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Inject,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[contenteditable][formControlName],' + '[contenteditable][formControl],' + '[contenteditable][ngModel],',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContenteditableValueAccessorDirective),
      multi: true,
    },
  ],
})
export class ContenteditableValueAccessorDirective implements ControlValueAccessor, AfterViewInit, OnDestroy {
  constructor(
    @Inject(ElementRef) private readonly elementRef: ElementRef,
    @Inject(Renderer2) private readonly renderer: Renderer2
  ) {}

  private readonly observer: MutationObserver = new MutationObserver((): void => {
    // It is required set timeout https://habr.com/ru/company/tinkoff/blog/443714/
    setTimeout(() => {
      this.onChange(ContenteditableValueAccessorDirective.processValue(this.elementRef.nativeElement.innerHTML));
    });
  });

  private static processValue(value: string | null): string {
    return value || '';
  }

  @HostListener('input') onInput(): void {
    this.observer.disconnect();
    this.onChange(this.elementRef.nativeElement.innerText);
  }

  @HostListener('blur') onBlur(): void {
    this.onTouched();
  }

  registerOnChange(onChange: (value: string) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  writeValue(value: unknown): void {
    this.renderer.setProperty(this.elementRef.nativeElement, 'innerText', value);
  }

  setDisabledState(disabled: boolean): void {
    this.renderer.setAttribute(this.elementRef.nativeElement, 'contenteditable', String(!disabled));
  }

  ngAfterViewInit(): void {
    this.observer.observe(this.elementRef.nativeElement, {
      characterData: true,
      childList: true,
      subtree: true,
    });
  }

  ngOnDestroy(): void {
    this.observer.disconnect();
  }

  private onTouched: () => void = () => null;

  private onChange: (value: string) => void = (value) => value;
}
