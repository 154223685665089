import { Component, Input } from '@angular/core';
import {SharedPipesModule} from "@atlas-workspace/shared/pipes";
import {
  NgbModalFooterComponent,
  NgbModalHeaderComponent,
  ShadowDomComponent,
} from "@atlas-workspace/shared/ui";
import {NgbModalRef} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'atl-message-editor-view',
  templateUrl: './message-editor-view.component.html',
  styleUrls: ['./message-editor-view.component.scss'],
  standalone: true,
  imports: [ShadowDomComponent, SharedPipesModule, NgbModalHeaderComponent, NgbModalFooterComponent]
})
export class MessageEditorViewComponent {
  @Input() value!: string;
  @Input() modalRef!: NgbModalRef
  @Input() isClient = true;

  public closeModal(): void {
    this.modalRef.dismiss();
  }
}
