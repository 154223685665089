import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpSentEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ELang, ELocalStorageKeys, EUserAuthHeaders } from '@atlas-workspace/shared/models';
import { LocalStorageService } from '@atlas-workspace/shared/service';
import { Observable } from 'rxjs';


@Injectable()
export class TranslateInterceptor implements HttpInterceptor {
  constructor(private localStorageService: LocalStorageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<HttpSentEvent>> {
    const lang: ELang = this.localStorageService.get<ELang>(ELocalStorageKeys.Language) ?? ELang.Norwegian;

    request = request.clone({
      headers: request.headers.set(EUserAuthHeaders.AcceptLanguage, lang),
    });

    return next.handle(request);
  }
}
