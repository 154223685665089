import {FloorDrawVersionModel, IFloorVersionList, } from '../models/chenge-request.model';
import { FloorModel, UnitFloorModel} from '../models/unit.model';

export function selectedFloors(floorVersionList:IFloorVersionList[]): (FloorDrawVersionModel | FloorModel | UnitFloorModel)[] {
  return floorVersionList?.reduce((acc: (FloorDrawVersionModel | FloorModel | UnitFloorModel)[], v) => {
    const plan = v.floor.filter(f => f.selected).map(f => f.plan);
    if (plan) {
      // @ts-ignore
      acc = acc.length  ? [...acc, ...plan] : plan;
    }
    return acc;
  }, []) || [];
}
