import { Inject, Injectable } from '@angular/core';
import { EPlatform } from '@atlas-workspace/shared/models';
import { WEB_PLATFORM_TYPE } from '../../../../../translate/src/lib/providers/platform-token';


@Injectable({
  providedIn: 'root',
})
export class RandomColorService {
  private defaultColorText = 'AA';
  private readonly monochromeStyleBackgroundVar = 'var(--color-white-1000)';
  private readonly monochromeStyleBorderVar = 'var(--color-gray-805)';

  constructor(@Inject(WEB_PLATFORM_TYPE) private platformType: EPlatform) {}

  getHslColor(str: string, s: number, l: number): string {
    let hash = 0;
    let i = 0;
    const length = str.length;

    for (; i < length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  getHslColorByName(str: string, s: number, l: number): string {
    let i = 0,
      hash = 0;
    const maxCharts = 2;
    const modifiedStr =
      str?.length > 0
        ? str
            .split(' ')
            .map((text, index) => (index < maxCharts ? text.charAt(0) : null))
            .filter(Boolean)
            .join('')
        : this.defaultColorText;
    const length = modifiedStr.length;

    for (; i < length; i++) {
      hash = modifiedStr.charCodeAt(i) + ((hash << 5) - hash);
    }

    const h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  getUserColors(text: string, s: number = 55, l: number = 50, monochrome = true): { iconColor: string; iconBorderColor: string } {
    if (monochrome) {
    //[AT-9057] added monochrome only for client panel
     monochrome = this.platformType === EPlatform.WEB_CLIENT ? monochrome : false;
      s = 55;
      l = 50;
    }
    return {
      iconBorderColor: monochrome ? this.monochromeStyleBorderVar : this.getHslColorByName(text, s, l - 10),
      iconColor: monochrome ? this.monochromeStyleBackgroundVar : this.getHslColorByName(text, s, l),
    };
  }
}
