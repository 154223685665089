import { RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer } from '@ngxs/router-plugin';

import { RouterStateModel } from './router.model';

export class CustomRouterStateSerializer implements RouterStateSerializer<RouterStateModel> {
  serialize(routerState: RouterStateSnapshot): RouterStateModel {
    const {
      url,
      root: { queryParams }
    } = routerState;

    let params = {};
    let data = {};
    let { root: route } = routerState;
    while (route.firstChild) {
      route = route.firstChild;

      if (Object.keys(route?.params).length) {
        params = {...params, ...route?.params};
      }

      if (Object.keys(route?.data).length) {
        data = {...data, ...route?.data};
      }
    }

    return { url, params, queryParams, data };
  }
}
