import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { OnboardingSelectorDirective } from '@atlas-workspace/shared/directives';
import { IHeaderDropdown } from '@atlas-workspace/shared/models';
import { NgbDropdownModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from '@ngx-translate/core';

import { FirmIconComponent } from "../firm-icon/firm-icon.component";

@Component({
  selector: 'atl-grouped-drop-down',
  templateUrl: './grouped-drop-down.component.html',
  styleUrls: ['./grouped-drop-down.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, OnboardingSelectorDirective, NgbDropdownModule, FirmIconComponent],
})
export class GroupedDropDownComponent {
  @Input() listOfMenu!: IHeaderDropdown[];
  @Input() selectedItem!: IHeaderDropdown;
  @Input() asFirmIconView = false;
  @Output() private readonly changeInfo = new EventEmitter<IHeaderDropdown>();

  selectProjectAndUnit(event: MouseEvent, info: IHeaderDropdown): void {
    event.stopPropagation();
    this.changeInfo.emit(info);
  }
}
