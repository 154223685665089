import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import {DropdownComponent, ILockedTrigger} from '@atlas-workspace/shared/directives';
import { IDataTime } from '@atlas-workspace/shared/models';
import { NgbDate, NgbDatepicker, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { PlacementArray } from '@ng-bootstrap/ng-bootstrap/util/positioning';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { DateAdapterService } from '../../services/date-adapter.service';

@UntilDestroy()
@Component({
  selector: 'atl-input-date-time-picker',
  templateUrl: './input-date-time-picker.component.html',
  styleUrls: ['./input-date-time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputDateTimePickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputDateTimePickerComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class InputDateTimePickerComponent implements ControlValueAccessor, OnChanges, Validator {
  @Input() hasUndo = false;
  @Input() label!: string;
  @Input() isWithoutBorder = false;
  @Input() placeholder = '';
  @Input() disabled = false;
  @Input() placement: PlacementArray = 'bottom';
  @Input() mask = 'd0.M0.0000';
  @Input() dropSpecialCharacters = false;
  @Input() scrollEvent!: Observable<unknown>;
  @Input() processHiding = true;
  @Input() navigation: 'select' | 'arrows' | 'none' = 'arrows';
  @Input() outsideDays: 'visible' | 'hidden' | 'collapsed' = 'visible';
  @Input() calendarIcon = 'assets/calendar.svg';
  @Input() firstDayOfWeek = 1;
  @Input() min!: NgbDateStruct;
  @Input() max!: NgbDateStruct;
  @Input() offsetBottom = {
    offsetX: 0,
    offsetY: 8,
  };

  @Input() offsetTop = {
    offsetX: 0,
    offsetY: -42,
  };

  @Output() focused = new EventEmitter();
  @Output() changeDate = new EventEmitter();

  @ViewChild('dp', { static: true }) datepicker!: NgbDatepicker;
  @ViewChild('input', { static: true }) input!: ElementRef;

  visible = false;
  undoDuration = 5;
  undoTimer$ = new BehaviorSubject(0);
  inputValue!: string;
  initDatePicker!: NgbDateStruct | null;
  public showUndo$ = new BehaviorSubject(false);
  public initValue!: string;
  private readonly dateSelector = 'ngb-dp-day';
  public lockedTrigger: ILockedTrigger = {locked: false, focused: true};

  constructor(public cdr: ChangeDetectorRef, public dateAdapter: DateAdapterService) {}

  onChange: (value: NgbDateStruct | IDataTime | string | null) => void = () => null;
  onTouched: () => void = () => null;

  onBlur(e?: FocusEvent): void {
    const target = e?.relatedTarget as Element;
    if (target?.className === this.dateSelector) {
      return;
    }

    this.onTouched();
    this.focused.emit({ focused: false, value: this.inputValue });
  }

  onFocus(): void {
    this.focused.emit({ focused: true, value: this.inputValue });
  }

  changeValue(value: string): void {
    if (!this.hasUndo) {
      this.updateFormValue(value);
      if (this.inputValue !== value) {
        this.changeDate.emit();
      }
      this.inputValue = value;
      return;
    }
    if (this.inputValue !== value) {
      this.inputValue = value;
      this.undoTimer$.next(0);
      this.showUndo$.next(true);
    }
    this.initDatePicker = this.dateAdapter.fromModel(this.inputValue);
  }

  parseFormat(value: string): string {
    const date = value.split('.');
    const year = parseInt(date[2]);
    if (year > 99) return value;
    if (year > 9 && year < 100) {
      date[2] = (2000 + year).toString();
    }
    return date.join('.');
  }

  registerOnChange(fn: () => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  writeValue(value: NgbDateStruct): void {
    this.initDatePicker = value;
    if (value === null) {
      this.inputValue = '';
      return;
    }
    const invalidDate = Object.keys(value)?.some((key) => isNaN(value[key as keyof NgbDateStruct]));
    if (invalidDate) {
      this.inputValue = '';
      return;
    }
    this.inputValue = this.dateAdapter.toModel(value);
    this.datepicker.dateSelect.next(this.initDatePicker as NgbDate);
    this.showSpecificCalendar();
    if (this.hasUndo) {
      this.initValue = this.inputValue;
    }
    if (this.inputValue) {
      this.datepicker.focusDate(this.dateAdapter.fromModel(this.inputValue || null) as NgbDate);
    }
    this.cdr.markForCheck();
  }

  showSpecificCalendar(): void {
    if (this.initDatePicker?.year && this.initDatePicker?.month) {
      this.datepicker.navigateTo({ year: Number(this.initDatePicker.year), month: Number(this.initDatePicker.month) });
    }
  }

  finishUndo(): void {
    this.showUndo$.next(false);
    this.updateFormValue(this.inputValue);
    this.initValue = this.inputValue;
  }

  cancelUndo(): void {
    this.undoTimer$.next(0);
    this.showUndo$.next(false);
    this.updateFormValue(this.initValue);
    this.inputValue = this.initValue;
    this.datepicker.dateSelect.next(this.dateAdapter.fromModel(this.initValue) as NgbDate);
  }

  protected updateFormValue(date: string): void {
    if (date) {
      const modelValue = this.dateAdapter.fromModel(date);
      if (!modelValue?.day || !modelValue?.month || !modelValue?.year) {
        this.onChange(null);
      } else {
        this.datepicker.navigateTo(modelValue);
        this.datepicker.focusDate(modelValue);
        this.onChange(modelValue);
      }
    } else {
      this.onChange(null);
    }
  }

  selectedDate(initDate: NgbDateStruct, dropdownRef?: DropdownComponent): void {
    const simpleDate = this.dateAdapter.toModel(initDate);
    this.changeValue(simpleDate);
    if (this.visible) {
      this.onBlur();
    }
    this.hidePicker();
    this.onTouched();
    if (dropdownRef) {
      dropdownRef.closed.emit();
    }
  }

  isLocked(locked: {locked: boolean, focused: boolean}): void {
    this.lockedTrigger = locked;
  }

  hidePicker(): void {
    this.visible = false;
  }

  visiblePicker(): void {
    if (!this.lockedTrigger.focused) return;
    this.visible = true;
  }

  clickIconVisiblePicker(el: HTMLInputElement): void {
    this.visible = !this.visible;
    this.lockedTrigger.focused = this.visible;
    if (this.visible) {
      el.focus();
    }
  }

  isOpen(value: boolean): void {
    if (!this.lockedTrigger.focused) return;
    this.visible = value;
  }

  listenScrollEvent(): void {
    this.scrollEvent
      .pipe(
        filter(() => this.visible),
        untilDestroyed(this)
      )
      .subscribe(() => {
        if (this.processHiding) {
          this.hidePicker();
          this.cdr.detectChanges();
        }
      });
  }

  ngOnChanges({ scrollEvent }: SimpleChanges): void {
    if (scrollEvent && scrollEvent.currentValue) {
      this.listenScrollEvent();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const simpleDate = control.value;
    if (!simpleDate?.year || !simpleDate?.month || !simpleDate?.day) return null;
    const date = new Date(simpleDate?.year, simpleDate?.month - 1, simpleDate?.day);
    if (
      date.getFullYear() === +simpleDate?.year &&
      date.getMonth() === +simpleDate?.month - 1 &&
      date.getDate() === +simpleDate?.day
    ) {
      return null;
    }
    return { invalid: true };
  }

  @HostListener('document:keydown.enter', ['$event']) preventEnter(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
