import { Directive, HostListener } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';
import emojiRegex from 'emoji-regex';

@Directive({
  selector: '[atlReplaceInput]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: NoEmojiDirective,
      multi: true,
    },
  ],
  standalone: true,
})
export class NoEmojiDirective implements Validator {
  regex = emojiRegex() as RegExp;
  control!: AbstractControl;

  @HostListener('input', ['$event.target']) input(target: any): void {
    const replaceEmoji = target.value.replaceAll(this.regex, '');
    const replaceCyrillic = replaceEmoji.replace(/[а-яА-ЯёЁ]/g, '');
    target.value = replaceCyrillic;
    this.control?.setValue(replaceCyrillic);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    this.control = control;
    return null;
  }
}
