import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HeaderIconComponent } from '@atlas-workspace/shared/ui';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxDocViewerModule } from 'ngx-doc-viewer';

import { DocumentPreviewComponent } from './document-preview.component';

@NgModule({
  declarations: [DocumentPreviewComponent],
  imports: [CommonModule, NgxDocViewerModule, HeaderIconComponent, TranslateModule, NgbTooltipModule, PdfViewerModule],
  exports: [],
})
export class DocumentsPreviewModule {}
