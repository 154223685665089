import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { IExtendedEvent } from '@atlas-workspace/shared/models';
import { fromEventPattern, Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class EventListenerService implements OnDestroy {
  private _destroy$ = new Subject<void>();

  public onClick$!: Observable<IExtendedEvent>;

  constructor(private rendererFactory2: RendererFactory2) {
    const renderer = this.rendererFactory2.createRenderer(null, null);

    this.createOnClickObservable(renderer);
  }

  private createOnClickObservable(renderer: Renderer2): void {
    let removeClickEventListener: () => void;
    const createClickEventListener = (handler: (e: Event) => boolean | void): void => {
      removeClickEventListener = renderer.listen('document', 'click', handler);
    };

    this.onClick$ = fromEventPattern<IExtendedEvent>(createClickEventListener, () => removeClickEventListener()).pipe(
      takeUntil(this._destroy$)
    );
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
