import { Inject, Injectable } from '@angular/core';
import { IEnvironment } from '@atlas-workspace/shared/environments';
import { EMixpanelPlatform } from '@atlas-workspace/shared/models';
import mixpanel from 'mixpanel-browser';

enum EMixPanelEnv {
  Client = 'CW',
  Admin = 'AW',
}

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  private isToken = false;

  constructor(@Inject('ENVIRONMENT') private env: IEnvironment) {
    this.isToken = !!this.env.mixpanelToken;
  }

  init(): void {
    if (this.isToken) {
      mixpanel.init(this.env.mixpanelToken);
    }
  }

  login(email: string): void {
    if (this.isToken) {
      mixpanel.identify(email);
    }
  }

  alias(email: string): void {
    if (this.isToken) {
      mixpanel.alias(email);
    }
  }

  setPeople(email: string): void {
    if (this.isToken) {
      mixpanel.people.set({ ['$email']: email });
    }
  }

  track(id: string, action: any = {}, client = true): void {
    const web = client ? EMixPanelEnv.Client : EMixPanelEnv.Admin;
    if (this.isToken) {
      mixpanel.track(`${web} ${id}`, action);
    }
  }

  register(name: string, email: string, client = true): void {
    if (this.isToken) {

      mixpanel.register({
        ['Name']: name,
        ['Email']: email,
        ['Platform']: client ? EMixpanelPlatform.Client : EMixpanelPlatform.Admin,
      });
    }
  }
}
