import { Expose, Type } from 'class-transformer';

import { CompanyModel, ICompany } from './company.model';
import { ContactPersonModel, IContactPerson } from './contact-persone.model';
import { FileModel, IFile } from './file.model';
import { ImageModel } from './image.model';
import { IUnit, UnitModel } from './unit.model';

/* eslint-disable */

export interface IResponseTaskInformation {
  data: {
    unit_tasks: ITask[];
    totalPages: string;
  };
}

export interface IOutputTaskInformation {
  listOfTasks: TaskModel[];
  totalPages: string | null;
}

export enum ETaskStatus {
  uncompleted = 'uncompleted',
  completed = 'completed',
  urgent = 'urgent',
  pastDue = 'past_due',
}

export interface ITask {
  id: number;
  name: string;
  description: string;
  due_date: string;
  status: ETaskStatus;
  unit: IUnit;
  company: ICompany;
  contact_person: IContactPerson;
  file_resources: IFile[];
  images: ImageModel[];
}

export interface ITaskProgress {
  total_tasks_count: number;
  completed_tasks_count: number;
  urgent_tasks_count: number;
  percentage: number;
}

export interface IChartTaskProgress {
  progress: number;
}

export interface IRequestTaskTemplate {
  id: number;
  name: string;
  created: string;
  created_by: string;
}

export class TaskTemplate {
  @Expose({ name: 'id' })
  id!: number;
  @Expose({ name: 'name' })
  name!: string;
  @Expose({ name: 'created' })
  created!: string;
  @Expose({ name: 'created_by' })
  createdBy!: string;

  selected = false;
}

export class TaskModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'name' })
  name: string;
  @Expose({ name: 'description' })
  description: string;
  @Expose({ name: 'status' })
  status: ETaskStatus;
  @Expose({ name: 'due_date' })
  dueDate: string;

  @Expose({ name: 'is_done' })
  isDone: boolean;

  @Expose({ name: 'unit' })
  @Type(() => UnitModel)
  unit!: UnitModel;

  @Expose({ name: 'contact_person' })
  @Type(() => ContactPersonModel)
  contactPerson!: ContactPersonModel;

  @Expose({ name: 'file_resources' })
  @Type(() => FileModel)
  fileResources!: FileModel[];

  @Expose({ name: 'company' })
  @Type(() => CompanyModel)
  company!: CompanyModel;

  @Expose({ name: 'images' })
  @Type(() => ImageModel)
  images!: ImageModel[];

  @Expose({ name: 'layout_option_id' })
  layoutOptionId!: number;
  @Expose({ name: 'layout_type_id' })
  layoutTypeId!: number;
  @Expose({ name: 'layout_type_name' })
  layoutTypeName!: string;
  @Expose({ name: 'type' })
  type!: string;

  @Expose({ name: 'contact_email' })
  contactEmail!: string;
  @Expose({ name: 'contact_phone' })
  contactPhone!: string;
  @Expose({ name: 'contact_vendor' })
  contactVendor!: string;
  @Expose({ name: 'responsible_vendor' })
  responsibleVendor!: string;

  show = false;
  selected = false;
  attachmentCount = 0;

  constructor() {
    this.id = 0;
    this.name = '';
    this.description = '';
    this.status = ETaskStatus.completed;
    this.dueDate = '';
    this.isDone = false;
  }
}

export class TaskProgressModel {
  @Expose({ name: 'total_tasks_count' })
  totalTasksCount: number;
  @Expose({ name: 'completed_tasks_count' })
  completedTasksCount: number;
  @Expose({ name: 'urgent_tasks_count' })
  urgentTasksCount: number;
  @Expose({ name: 'percentage' })
  percentage: number;
  @Expose({ name: 'remaining_tasks_count' })
  remainingTasksCount: number;

  constructor() {
    this.totalTasksCount = 0;
    this.completedTasksCount = 0;
    this.percentage = 0;
    this.urgentTasksCount = 0;
    this.remainingTasksCount = 0;
  }
}

export class IChartTaskProgressModel {
  @Expose({ name: 'progress' })
  progress: number;

  constructor() {
    this.progress = 0;
  }
}
