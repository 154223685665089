import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IMapLocation } from '@atlas-workspace/shared/models';

import { GeocoderService } from '../../services/geocoder.service';

declare const google: any;

@Component({
  selector: 'atl-google-autocomplete',
  templateUrl: './google-autocomplete.component.html',
  styleUrls: ['./google-autocomplete.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GoogleAutocompleteComponent),
      multi: true,
    },
  ],
})
export class GoogleAutocompleteComponent implements OnInit, ControlValueAccessor, OnChanges {
  @Input() isRequire = true;
  @Input() isHideMap = false;
  @Input() isHeightMap = false;
  @Input() label!: string;
  @Input() placeholder = '';
  @Input() location = { lat: 40.749933, lng: -73.98633 };
  @Input() createModal = false;
  @Input() set country(value: string) {
    if (value) {
      this.specificCountry = value;
      this.autocomplete?.setComponentRestrictions({
        country: this.country,
      });
    }
  }

  get country(): string {
    return this.specificCountry;
  }

  @Output() changedAddress = new EventEmitter<IMapLocation>();

  @ViewChild('inputElement', { static: true }) inputElement!: ElementRef;
  @ViewChild('googleMap', { static: true }) googleMap!: ElementRef;

  value = '';
  disabled = false;
  specificCountry!: string;

  map!: any;
  marker!: any;
  elMap!: HTMLElement;
  autocomplete!: any;
  geocoderMap!: any;
  infoWindow!: any;

  constructor(private host: ElementRef, private geocoder: GeocoderService) {}

  ngOnInit(): void {
    this.initAutoComplete();
  }

  public inputFocus(): void {
    this.inputElement.nativeElement.focus();
  }

  private initAutoComplete(): void {
    this.geocoder.loadGoogleScript().then(() => {
      const input = this.inputElement.nativeElement;
      this.elMap = this.googleMap?.nativeElement;

      const option = {
        fields: ['formatted_address', 'geometry', 'name'],
        mapTypeControl: false,
      };
      if (this.country) {
        const countryRestrictions = { country: this.country };
        Object.assign(option, countryRestrictions);
      }
      this.autocomplete = new google.maps.places.Autocomplete(input, option);

      this.initUpdates(input, this.elMap, this.autocomplete);

      const observer = new MutationObserver((mutations, me) => {
        const container = this.host.nativeElement;
        const autoElement = document.querySelector('.pac-container') as HTMLElement;
        if (autoElement) {
          const safariStyle = this.createModal ? 'safari-position-create' : 'safari-position';
          autoElement.classList.add(navigator.vendor.indexOf('Apple') != -1 ? safariStyle : 'chrome-position');
          container.appendChild(autoElement);
          me.disconnect();
          return;
        }
      });
      observer.observe(document, {
        childList: true,
        subtree: true,
      });
    });
  }

  private initUpdates(input: any, elMap: HTMLElement, autocomplete: any): void {
    input.addEventListener('blur', () => {
      this.onChange(input.value);
    });

    this.initMap(elMap);

    autocomplete.addListener('place_changed', () => {
      this.onChange(input.value);

      this.setVisibilityMarker(false);
      const place = autocomplete.getPlace();

      if (!place.geometry || !place.geometry.location) {
        return;
      }

      this.changedAddress.emit({ latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng() });

      if (place.geometry.viewport) {
        this.map.fitBounds(place.geometry.viewport);
      } else {
        this.map.setCenter(place.geometry.location);
        this.map.setZoom(17);
      }
      this.setPositionMarker(place.geometry.location);
      this.setVisibilityMarker(true);
    });
  }

  private initMap(elMap: HTMLElement): void {
    if (elMap && this.location) {
      this.map = new google.maps.Map(elMap, {
        center: this.location,
        zoom: 13,
        mapTypeControl: false,
        panControl: false,
        streetViewControl: false,
      });

      this.marker = new google.maps.Marker({
        map: this.map,
        draggable: true,
        optimized: false,
        anchorPoint: new google.maps.Point(0, -29),
      });

      this.geocoderMap = new google.maps.Geocoder();
      this.infoWindow = new google.maps.InfoWindow();
      this.marker.setPosition(this.location);
      this.markerEvent();
    }
  }

  private markerEvent(): void {
    this.marker.addListener('mouseup', (event: any) => {
      this.location = { lat: event.latLng.lat(), lng: event.latLng.lng() };
      this.setPositionMarker(this.location);
      this.geocodeLatLng(this.location);
    });
  }

  private geocodeLatLng(latlng: any): void {
    this.geocoderMap
      .geocode({ location: latlng })
      .then((response: any) => {
        if (response.results[0]) {
          const address = response.results[0].formatted_address;
          this.change(address);
        } else {
          new Error('No results found');
        }
      })
      .catch((e: string) => new Error('Geocoder failed due to: ' + e));
  }

  private setVisibilityMarker(state: boolean): void {
    this.marker.setVisible(state);
  }

  private setPositionMarker(position: { lat: number; lng: number }): void {
    this.marker.setPosition(position);
  }

  onChange: any = () => {
    //
  };

  onTouched: any = () => {
    //
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: any): void {
    this.value = value;
  }

  change(value: string): void {
    this.writeValue(value);
    this.onChange(value);
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnChanges({ location }: SimpleChanges): void {
    if (location && location.currentValue) {
      this.initMap(this.elMap);
    }
  }
}
