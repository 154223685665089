import {Injectable} from '@angular/core';
import {fromEvent, merge, Observable, of} from 'rxjs';
import {map, share} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  get isOnline(): Observable<boolean> {
    return merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    ).pipe(map(() => navigator.onLine), share());
  }
}
