<div class="global-label">
  <div *ngIf="label" class="global-label__header-undo">
    <span class="global-label__name"> {{ label | translate }} </span>
    <atl-undo-changes
      (countdownFinished)="finishUndo()"
      (undoClick)="cancelUndo()"
      *ngIf="showUndo$ | async"
      [fieldName]="label"
      [undoTimer]="undoTimer$ | async"
    ></atl-undo-changes>
  </div>

  <textarea
    #input
    atlLockField
    (blur)="onTouched(); startTimer()"
    (input)="changeValue(input.value)"
    [disabled]="disabled"
    data-cy="cy-add-description-to-task"
    [placeholder]="placeholder | translate"
    [value]="inputValue"
    class="global_input global_input--textarea v-scrollbar text-black-900"
    atlReplaceInput
    atlMaxLength
    [currentValue]="inputValue"
    [maxLength]="maxLength"
    atlCapitalLetter
    [capitalize]="capitalize"
    (keydown)="enter($event)"
    atlPreventDoubleSpace
  ></textarea>
</div>
