import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EFeatureFlags } from '@atlas-workspace/shared/models';
import { FeatureFlagService, MixpanelService, ModalFacadeService } from '@atlas-workspace/shared/service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, take } from 'rxjs/operators';
import { AuthService } from './modules/shared/service/auth/auth.service';

@UntilDestroy()
@Component({
  selector: 'atl-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'atlas-client';

  constructor(
    private router: Router,
    private modalFacadeService: ModalFacadeService,
    private featureFlagService: FeatureFlagService,
    private mixpanelService: MixpanelService,
    private render: Renderer2,
    private authService: AuthService
  ) {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((e): e is NavigationEnd => {
          if (e instanceof NavigationEnd && e.urlAfterRedirects.includes('options-catalogue')) {
            return false;
          }
          return e instanceof NavigationEnd;
        })
      )
      .subscribe(() => this.modalFacadeService.closeModal());
  }

  ngOnInit(): void {
    this.mixpanelService.init();
    if (this.authService.getUserInfo) {
      this.getFeatureFlagsAccess();
    }
  }

  private getFeatureFlagsAccess(): void {
    this.featureFlagService
      .getFeatureFlags()
      .pipe(take(1))
      .subscribe((value) => {
        const isChat = value.find((v) => v.name === EFeatureFlags.ClientSupportChat);
        if (isChat?.enabled) {
          this.setScript();
        }
      });
  }

  private setScript(): void {
    const body = this.render.selectRootElement('body', true);
    const scriptChat = this.render.createElement('script');
    this.render.setAttribute(scriptChat, 'id', 'hs-script-loader');
    this.render.setAttribute(scriptChat, 'src', '//js-na1.hs-scripts.com/19802956.js');
    this.render.appendChild(body, scriptChat);
  }
}
