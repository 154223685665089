import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncate',
})
export class TruncatePipe implements PipeTransform {
  transform(value: string | null, limit = 25, completeWords = false, ellipsis = '...'): string {
    if (!value) return '';

    const changeableString = value ? value?.trim() : value;
    if (ellipsis.includes('Read more')) {
      ellipsis = '... <span> Read more</span>';
    }
    if (completeWords) {
      limit = value.substring(0, limit).lastIndexOf(' ');
    }
    return changeableString?.length > limit ? changeableString.substring(0, limit) + ellipsis : changeableString;
  }
}
