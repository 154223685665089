<div #tableWrapper class="shared-table__wrapper scrollbar overlay-y" (scroll)="onScroll()" tabindex="0">
  <table class="shared-table__table">
    <thead>
      <tr>
        <ng-content select="shared-table-header"></ng-content>
      </tr>
    </thead>

    <tbody #tbody [ngClass]="{ 'v-scrollbar': bodyOverflow }">
      <ng-content select="shared-table-body"></ng-content>
      <ng-content select="shared-table-loading"></ng-content>
    </tbody>
  </table>
  <div *ngIf="loading" class="loader">
    <span class="custom-spinner custom-spinner-48"></span>
  </div>
</div>
