import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { EMixpanelEvent } from '@atlas-workspace/shared/models';
import { MixpanelService } from '@atlas-workspace/shared/service';
import { Observable } from 'rxjs';

import { AuthService } from '../service/auth/auth.service';

@Injectable()
export class AuthGuard  {
  constructor(private authService: AuthService, private router: Router, private mixpanelService: MixpanelService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.validateAuthHeaders()) {
      return true;
    }

    void this.router.navigate(['/auth/sign-in']);
    this.mixpanelService.track(EMixpanelEvent.SingOut);
    return false;
  }
}
