<div class="input-file__input-title">
  <span class="input-file__input-title__label">
    {{ label | translate }}
    <span *ngIf="isOptional" class="global-label--optional">({{ optionalText | translate }})</span>
  </span>
  <ng-container *ngIf="!asUploadButton">
    <label *ngIf="disabled || (!asGallery && listOfPreview?.length && listOfPreview.length < maxUploadedFiles)">
      <ng-container *ngIf="!disabled" [ngTemplateOutlet]="uploadInputRef"></ng-container>
      <span *ngIf="!disabled" class="input-file__title--upload">{{ 'Shared.Entity.Upload_file' | translate }}</span>
    </label>
  </ng-container>
</div>

<ng-template #uploadInputRef>
  <input
    #uploadInput
    (change)="selectedFile($event)"
    [multiple]="multiple"
    [accept]="inputAcceptString"
    [disabled]="disabled"
    class="file-input"
    hidden
    id="file-input"
    type="file"
  />
</ng-template>

<ng-template #dragFilesRef let-asDraggableGallery="asDraggableGallery">
  <div
    atlFileDragDrop
    (dragenter)="handleDragEnter()"
    (dragleave)="handleDragLeave()"
    (dragover)="handleDragOver($event)"
    (drop)="handleDrop($event)"
    *ngIf="!asGallery || listOfPreview?.length < maxUploadedFiles || disabled"
    [class.input-file__draggable-gallery]="asDraggableGallery"
    class="input-file__container"
    [class.disabled]="disabled"
    tabindex="0"
  >
    <ng-container *ngIf="!asDraggableGallery">
      <ng-container *ngIf="!asUploadButton">
        <img *ngIf="uploadIcon" [src]="uploadIcon" alt="upload icon" />
        <span *ngIf="disabled && !listOfPreview?.length" class="input-file__title">
          {{ 'Shared.Entity.No_uploaded_files' | translate }}
        </span>
        <span *ngIf="!disabled" class="input-file__title">
          {{ 'Shared.Description.Drag_file_or' | translate }}
          <span class="input-file__title--underline"> {{ 'Shared.Entity.Upload_file' | translate | lowercase }} </span>
        </span>
      </ng-container>
      <ng-container *ngIf="asUploadButton && !disabled">
        <img src="/assets/new-plus-icon.svg" alt="icon" />
      </ng-container>
      <ng-container *ngIf="asUploadButton && disabled">
        <span class="input-file__disabled-title">
          {{ 'Shared.Reclamation.No_attachments' | translate }}
        </span>
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<ng-template #removeFileIconRef let-index="index" let-editFile="editFile">
  <div
    *ngIf="!disabled && (!disableDelete || (asGallery && listOfPreview?.length !== 1))"
    (click)="removeFile(index, $event, editFile)"
    class="newest-document-card__icon-control newest-document-card__icon-control-trash newest-document-card__icon-control-trash--specific-position"
  >
    <atl-custom-icon [icon]="trashIcon"></atl-custom-icon>
  </div>
</ng-template>

<div (click)="handleUpload()" *ngIf="!files?.length && !editFiles?.length" class="global-label">
  <ng-container [ngTemplateOutlet]="uploadInputRef"></ng-container>
  <ng-container [ngTemplateOutlet]="dragFilesRef"></ng-container>
</div>

<ng-container *ngIf="listOfPreview?.length">
  <div class="input-file__preview" [class.v-scrollbar]="scrollable">
    <ng-template
      #filePreviewCard
      let-editFile="editFile"
      let-extension="extension"
      let-i="i"
      let-mimetype="mimetype"
      let-name="name"
      let-size="size"
    >
      <div class="input-file__item-preview" (click)="onPreviewImageHandler(i)">
        <div class="file-card__wrapper">
          <div class="file-card__info">
            <img [src]="extension || mimetype | iconByType" alt="{{ name }}" class="h-px-24 file-card__file-icon" />
            <div class="file-card__file-additional-info">
              <div class="fz-14 file-card__file-name">{{ name | truncate: truncateFileName }}</div>
              <span class="fz-12 file-card__file-size"> {{ size | filesize }} </span>
            </div>
            <ng-container
              [ngTemplateOutletContext]="{ index: i, editFile: editFile }"
              [ngTemplateOutlet]="removeFileIconRef"
            ></ng-container>
          </div>
        </div>
      </div>
    </ng-template>

    <ng-container *ngIf="listOfPreview?.length">
      <ng-container *ngFor="let listItem of listOfPreview; index as i; trackBy: trackByFn">
        <ng-container
          *ngIf="listItem.file"
          [ngTemplateOutletContext]="{
            mimetype: listItem.file.type,
            name: listItem.file.name,
            previewImage: listItem.previewImage,
            size: listItem.file.size,
            type: listItem.type,
            i: i
          }"
          [ngTemplateOutlet]="filePreviewCard"
        ></ng-container>
        <ng-container
          *ngIf="listItem?.editFile"
          [ngTemplateOutletContext]="{
            editFile: listItem.editFile,
            extension: listItem.editFile.fileExtension,
            downloadLink: listItem.editFile.fileName.downloadUrl,
            name: listItem.editFile.name,
            previewImage: listItem.previewImage,
            size: listItem.editFile.fileSize,
            type: listItem.type,
            i: i
          }"
          [ngTemplateOutlet]="filePreviewCard"
        ></ng-container>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="asUploadButton">
      <input
        #uploadInput
        (change)="selectedFile($event)"
        [multiple]="multiple"
        [accept]="inputAcceptString"
        [disabled]="disabled"
        class="file-input"
        hidden
        id="file-input"
        type="file"
      />
      <div
        (click)="handleUpload()"
        atlFileDragDrop
        (dragenter)="handleDragEnter()"
        (dragleave)="handleDragLeave()"
        (dragover)="handleDragOver($event)"
        (drop)="handleDrop($event)"
        *ngIf="listOfPreview?.length < maxUploadedFiles"
        class="input-file__container"
        [class.disabled]="disabled"
        tabindex="0"
      >
        <img *ngIf="!disabled" src="/assets/new-plus-icon.svg" alt="icon" />
      </div>
    </ng-container>
  </div>
</ng-container>
