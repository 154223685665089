import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[atlFileDragDrop]',
})
export class FileDragNDropDirective {
  @Output() private readonly filesChangeEmiter: EventEmitter<File[]> = new EventEmitter();
  @Output() private readonly hovered: EventEmitter<boolean> = new EventEmitter();
  @Output() private readonly dropEvent: EventEmitter<DragEvent> = new EventEmitter();

  @HostBinding('style.background') private background = '';
  @HostBinding('style.border') private borderStyle = '';
  @HostBinding('style.border-radius') private borderRadius = '8px';

  @HostListener('dragover', ['$event']) public onDragOver(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();
    this.borderStyle = '1px dashed var( --color-blue-930)';
    this.hovered.emit(true);
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(e: DragEvent | any): void {
    if (e?.currentTarget?.contains(e.relatedTarget)) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    this.clearStyles();
    this.hovered.emit(false);
  }

  @HostListener('drop', ['$event']) public onDrop(e: DragEvent): void {
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer?.files;
    this.clearStyles();
    this.hovered.emit(false);
    if (files) {
      this.filesChangeEmiter.emit(Array.from(files));
      this.dropEvent.emit(e);
    }
  }

  private clearStyles(): void {
    this.background = '';
    this.borderStyle = '';
  }
}
