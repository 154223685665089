import { Expose, Type } from 'class-transformer';

export enum ESurveysSorting {
  Name = 'Title.Name',
  Status = 'Shared.Entity.Status',
  Question = 'Entity.Questions',
  AddedDate = 'Shared.Entity.Added_date',
}

export enum EQuestionType {
  Text = 'text',
  Score = 'score',
  SingleChoice = 'single_choice',
  MultiChoice = 'multi_choice',
}

export type SurveysSortingValue = 'name' | 'answered' | 'questionsCount' | 'createdAt';
export type QuestionType = 'text' | 'score' | 'single_choice' | 'multi_choice';

export class SurveyResultModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'name' })
  name: string;
  @Expose({ name: 'description' })
  description: string;
  @Expose({ name: 'draft' })
  draft: boolean;
  @Expose({ name: 'created_at' })
  created: string;
  @Expose({ name: 'updated_at' })
  updated: string;
  @Expose({ name: 'project_id' })
  projectId: number;
  @Expose({ name: 'answered_count' })
  answeredCount: number;
  @Expose({ name: 'answered_percent' })
  answeredPercent: number;
  @Expose({ name: 'questions' })
  @Type(() => QuestionResultModel)
  questions: QuestionResultModel[];
  @Expose({ name: 'unit_answers' })
  @Type(() => UnitAnswerResultModel)
  unitAnswers: UnitAnswerResultModel[];

  constructor() {
    this.id = 0;
    this.name = '';
    this.description = '';
    this.draft = false;
    this.created = '';
    this.updated = '';
    this.projectId = 0;
    this.answeredCount = 0;
    this.answeredPercent = 0;
    this.questions = [];
    this.unitAnswers = [];
  }
}

export class UnitAnswerResultModel {
  @Expose({ name: 'qtype' })
  qType: string;
  @Expose({ name: 'description' })
  description: string;
  @Expose({ name: 'order' })
  order: number;
  @Expose({ name: 'score_average' })
  scoreAverage: number | null;
  @Expose({ name: 'score_median' })
  scoreMedian: number | null;
  @Expose({ name: 'score_max' })
  scoreMax: number | null;
  @Expose({ name: 'text_answers' })
  @Type(() => TextAnswersResultModel)
  textAnswers: TextAnswersResultModel[];
  @Expose({ name: 'choices' })
  @Type(() => ChoicesResultModel)
  choices: ChoicesResultModel[];

  constructor() {
    this.qType = '';
    this.description = '';
    this.order = 0;
    this.scoreAverage = 0;
    this.scoreMedian = 0;
    this.scoreMax = 0;
    this.textAnswers = [];
    this.choices = [];
  }
}

export class TextAnswersResultModel {
  @Expose({ name: 'identifier' })
  identifier: string;
  @Expose({ name: 'description' })
  description: string;

  constructor() {
    this.identifier = '';
    this.description = '';
  }
}

export class ChoicesResultModel {
  @Expose({ name: 'description' })
  description: string;
  @Expose({ name: 'chosen_count' })
  choicesCount: number;
  @Expose({ name: 'chosen_rate' })
  choicesRate: number;

  constructor() {
    this.description = '';
    this.choicesCount = 0;
    this.choicesRate = 0;
  }
}

export class QuestionResultModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'order' })
  order: number;
  @Expose({ name: 'description' })
  description: string;
  @Expose({ name: 'qtype' })
  qType: string;
  @Expose({ name: 'score_max' })
  scoreMax: number | null;
  @Expose({ name: 'survey_id' })
  surveyId: number;
  @Expose({ name: 'created_at' })
  created: string;
  @Expose({ name: 'updated_at' })
  updated: string;
  @Expose({ name: 'question_choices' })
  @Type(() => QuestionChoiceResultModel)
  questionChoices: QuestionChoiceResultModel[];

  constructor() {
    this.id = 0;
    this.order = 0;
    this.description = '';
    this.qType = '';
    this.scoreMax = 0;
    this.surveyId = 0;
    this.created = '';
    this.updated = '';
    this.questionChoices = [];
  }
}

export class QuestionChoiceResultModel {
  id: number;
  order: number;
  description: string;

  constructor() {
    this.id = 0;
    this.order = 0;
    this.description = '';
  }
}

export class SurveyListModel {
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'project_id' })
  projectId: number;
  @Expose({ name: 'name' })
  name: string;
  @Expose({ name: 'questions_count' })
  questionsCount: number;
  @Expose({ name: 'answered_count' })
  answeredCount: number;
  @Expose({ name: 'answered_rate' })
  answeredRate: number;
  @Expose({ name: 'created_at' })
  created: string;
  @Expose({ name: 'draft' })
  draft: boolean;

  constructor() {
    this.id = 0;
    this.projectId = 0;
    this.name = '';
    this.questionsCount = 0;
    this.answeredCount = 0;
    this.answeredRate = 0;
    this.created = '';
    this.draft = false;
  }
}

export class SurveyListResponseModel {
  @Expose({ name: 'surveys' })
  @Type(() => SurveyListModel)
  surveys: SurveyListModel[];

  constructor() {
    this.surveys = [];
  }
}

export interface ISurveyRequest {
  survey: ISurveyToSave;
  unitIds: number[];
}

export interface ISurveyToSave {
  name: string;
  description: string;
  draft: boolean;
  isTemplate: boolean;
  projectId: number;
  questions: IQuestionsToSave[];
  id?: number;
}

export interface IQuestionsToSave {
  order: number;
  description: string;
  qType: QuestionType;
  scoreMax: number;
  choices: IChoicesToSave[];
}

export interface IChoicesToSave {
  order: number;
  description: string;
}

export class SurveyApiModel {
  @Expose({ name: 'name' })
  name: string;
  @Expose({ name: 'description' })
  description: string;
  @Expose({ name: 'draft' })
  draft: boolean;
  @Expose({ name: 'isTemplate' })
  is_template: boolean;
  @Expose({ name: 'projectId' })
  project_id: number;
  @Expose({ name: 'questions' })
  @Type(() => QuestionApiModel)
  questions_attributes: QuestionApiModel[];

  constructor() {
    this.name = '';
    this.description = '';
    this.draft = true;
    this.is_template = false;
    this.project_id = 0;
    this.questions_attributes = [];
  }
}

export class SurveyRequestModel {
  @Expose({ name: 'survey' })
  @Type(() => SurveyApiModel)
  survey: SurveyApiModel;
  @Expose({ name: 'unitIds' })
  unit_ids: number[];

  constructor() {
    this.survey = new SurveyApiModel();
    this.unit_ids = [];
  }
}

export class QuestionApiModel {
  @Expose({ name: 'order' })
  order: number;
  @Expose({ name: 'description' })
  description: string;
  @Expose({ name: 'qType' })
  qtype: QuestionType;
  @Expose({ name: 'scoreMax' })
  score_max: number;
  @Expose({ name: 'choices' })
  @Type(() => ChoicesApiModel)
  question_choices_attributes: ChoicesApiModel[];

  constructor() {
    this.order = 0;
    this.description = '';
    this.qtype = EQuestionType.Text;
    this.score_max = 10;
    this.question_choices_attributes = [];
  }
}

export class ChoicesApiModel {
  @Expose({ name: 'order' })
  order: number;
  @Expose({ name: 'description' })
  description: string;

  constructor() {
    this.order = 0;
    this.description = '';
  }
}

export interface ISurveysListItem {
  answered: boolean;
  created_at: string;
  id: number;
  name: string;
  project_survey_id: number;
  questions_count: number;
  unit_id: number;
}

export interface ISurveysSorting {
  name: ESurveysSorting;
  isAsc: boolean;
  value: SurveysSortingValue;
}

export interface ISurveyAnswerBody {
  unit_survey: {
    answers: {
      question_id: number;
      description: string | null;
      score: string | null;
      choices_ids: number[] | null;
    }[];
  };
}

export interface IFormAnswer {
  id: number;
  singleChoice?: number;
  score?: number;
  text?: string;
  multiChoice?: {
    checked: boolean;
    value: number;
  }[];
}

export class SurveysListItemModel {
  @Expose({ name: 'answered' })
  answered: boolean;
  @Expose({ name: 'created_at' })
  createdAt: string;
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'name' })
  name: string;
  @Expose({ name: 'project_survey_id' })
  projectSurveyId: number;
  @Expose({ name: 'questions_count' })
  questionsCount: number;
  @Expose({ name: 'unit_id' })
  unitId: number;

  constructor() {
    this.answered = false;
    this.createdAt = '';
    this.id = 0;
    this.name = '';
    this.projectSurveyId = 0;
    this.questionsCount = 0;
    this.unitId = 0;
  }
}

export class SurveyDetailsModel extends SurveysListItemModel {
  @Expose({ name: 'description' })
  description!: string;
  @Expose({ name: 'questions' })
  @Type(() => SurveyQuestion)
  questions: SurveyQuestion[];

  constructor() {
    super();
    this.questions = [];
  }
}

export class SurveyQuestion {
  @Expose({ name: 'answer' })
  @Type(() => SurveyQuestionAnswer)
  answer!: SurveyQuestionAnswer | null;
  @Expose({ name: 'created_at' })
  createdAt: string;
  @Expose({ name: 'description' })
  description: string;
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'order' })
  order: number;
  @Expose({ name: 'qtype' })
  qType: QuestionType | null;
  @Expose({ name: 'question_choices' })
  @Type(() => QuestionChoice)
  questionChoices: QuestionChoice[];
  @Expose({ name: 'score_max' })
  scoreMax: number;
  @Expose({ name: 'survey_id' })
  surveyId: number;
  @Expose({ name: 'updated_at' })
  updatedAt: string;

  constructor() {
    this.answer = null;
    this.createdAt = '';
    this.description = '';
    this.id = 0;
    this.order = 0;
    this.qType = null;
    this.questionChoices = [];
    this.scoreMax = 0;
    this.surveyId = 0;
    this.updatedAt = '';
  }
}

class SurveyQuestionAnswer {
  @Expose({ name: 'description' })
  description: string;
  @Expose({ name: 'score' })
  score: number | null;
  @Expose({ name: 'choices_ids' })
  choicesIds: number[];

  constructor() {
    this.description = '';
    this.score = 0;
    this.choicesIds = [];
  }
}

class QuestionChoice {
  @Expose({ name: 'description' })
  description: string;
  @Expose({ name: 'id' })
  id: number;
  @Expose({ name: 'order' })
  order: number;

  constructor() {
    this.description = '';
    this.id = 0;
    this.order = 0;
  }
}
